import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../config/api";
import PageHeader from "./../../components/PageHeader";
import JobListItemDetailed from "./components/JobListItemDetailed";

const JobsCandidatesStyled = styled.div``;

function JobsCandidates() {
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const [job, setJob] = useState(null);
  const [candidates, setCandidates] = useState(null);
  useEffect(() => {
    async function getJob() {
      let id = searchParams.get("id");
      let response = await api.get(`jobs/${id}`);
      setJob(response.data);
    }
    getJob();
  }, []);

  return job ? (
    <JobsCandidatesStyled>
      <PageHeader title={`Buscar Candidatos`} />
      <div className="container">
        <JobListItemDetailed {...job} />
        <div className="row">
          <h1>Busca detalhada</h1>
        </div>
      </div>
    </JobsCandidatesStyled>
  ) : null;
}

export default JobsCandidates;
