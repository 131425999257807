import * as Yup from "yup";
import { validatePhone, validateCNPJ, validateCep } from "validations-br";

export const companySchema = Yup.object({
  name: Yup.string().required("Preencha o campo Nome"),
  description: Yup.string().required("Preencha a descrição"),
  cnpj: Yup.string()
    .required("Preencha o campo CNPJ")
    .test("is-cnpj", "CNPJ Invalido", (value) => validateCNPJ(value)),
  collaborators_amount: Yup.string().required(
    "Selecione a quantidade de funcionarios"
  ),
  company_class: Yup.string().required("Selecione a classificacao da empresa"),
  activity_id: Yup.number()
    .required("Selecione um Ramo de atividade")
    .typeError("Selecione um Ramo de atividade"),
  phone: Yup.string()
    .required("Preencha o campo Telefone")
    .test("is-phone", "Telefone invalido", (value) => validatePhone(value)),
  email: Yup.string()
    .email("Email invalido")
    .required("Preencha o campo Email"),
});

export const adressSchema = Yup.object({
  adress: Yup.string().required("Preencha o campo Endereço").typeError("Preencha o campo Endereço"),
  number: Yup.string().required("Preencha o campo Número").typeError("Preencha o campo Número"),
  complement: Yup.string().nullable(),
  reference: Yup.string().nullable(),
  district: Yup.string().required("Preencha o campo Bairro").typeError("Preencha o campo Bairro"),
  region: Yup.string().required("Selecione uma região").typeError("Selecione uma região"),
  cep: Yup.string()
    .required("Preencha o CEP")
    .typeError("Preencha o CEP")
    .test("is-cep", "CEP invalido", (value) => validateCep(value)),
  city: Yup.string().required("Preencha o campo cidade").typeError("Preencha o campo cidade"),
});

export const responsableSchema = Yup.object({
  full_name: Yup.string().required("Preencha o campo Nome"),
  job_role: Yup.string().required("Preencha o campo Cargo"),
  email: Yup.string()
    .email("Email invalido")
    .required("Preencha o campo Email"),
  primary: Yup.boolean(),
});

export const cateSchema = Yup.object({
  name: Yup.string().required("Preencha o campo Nome"),
  adress: Yup.string().required("Preencha o campo Endereço"),
  number: Yup.string().required("Preencha o campo Número"),
  complement: Yup.string().nullable(),
  reference: Yup.string().nullable(),
  district: Yup.string().required("Preencha o campo Bairro"),
  region: Yup.string().required("Selecione uma região"),
  cep: Yup.string()
    .required("Preencha o CEP")
    .test("is-cep", "CEP invalido", (value) => validateCep(value)),
  city: Yup.string().required("Selecione uma cidade"),
});

export const scheduleSchema = Yup.object({
  start_at: Yup.date()
    .required("Preencha o campo Data e hora de inicio")
    .typeError("Preencha o campo Data e hora de inicio"),
  end_at: Yup.date()
    .required("Preencha o campo Data e hora de fim")
    .typeError("Preencha o campo Data e hora de fim"),
  seats: Yup.number()
    .typeError("Preencha o campo Limite de candidatos")
    .required("Preencha o campo Limite de candidatos"),
});

export const meetPhoneSchema = Yup.object({
  name: Yup.string().required("Preencha o campo Nome").nullable(false),
  phone: Yup.string()
    .required("Preencha o campo Telefone")
    .test("is-phone", "Telefone invalido", (value) => validatePhone(value))
    .nullable(false),
});

export const draftJobSchema = Yup.object({
  status: Yup.string()
    .required("Selecione um status para a vaga")
    .oneOf(["active", "schedule", "draft", "closed", "stopped"])
    .nullable(false, "Selecione um status para a vaga"),
  occupation_id: Yup.number()
    .required("selecione uma ocupacao")
    .typeError("selecione uma ocupacao"),
  company_id: Yup.number().required("Selecione a empresa"),
});

export const jobSchema = Yup.object({
  status: Yup.string()
    .required("Selecione um status para a vaga")
    .oneOf(["active", "schedule", "draft", "closed", "stopped"]),
  occupation_id: Yup.number()
    .required("selecione uma ocupacao")
    .typeError("selecione uma ocupacao"),
  company_id: Yup.number().required("Selecione a empresa"),
  segment_id: Yup.number()
    .required("Selecione o área de atuação/segmento")
    .typeError("Selecione o área de atuação/segmento"),
  description: Yup.string().required("Preencha o campo descrição"),
  available_jobs: Yup.number()
    .positive("Apenas números positivos")
    .min(1, "Precisa ter no minimo 1 vagas disponivel")
    .required("Preencha o campo Vagas disponíveis"),
  seats: Yup.number()
    .positive("Apenas números positivos")
    .min(1, "Limite de candidatos, minimo 1")
    .required("Preencha o campo Limite de candidatos"),
  sine_id: Yup.string().nullable(true),
  is_cate_managed: Yup.boolean().required(
    "Preencha o campo gerenciado pelo CATE"
  ),
  salary_type: Yup.string().required("Preencha o tipo de remuneração"),
  benefits: Yup.array().of(Yup.string()),
  contract_type: Yup.string().required("Preencha o campo Tipo de contrato"),
  journey: Yup.string().required("Preencha o campo Jornada de trabalho"),
  job_scale: Yup.string().required("Escala de trabalho"),
  job_type: Yup.string().required("Tipo de trabalho"),
  schooling_level_id: Yup.number()
    .required("Selecione uma Escolaridade miníma")
    .positive()
    .integer(),
  experience_level_id: Yup.number()
    .required("Selecione uma Experiência miníma")
    .positive()
    .integer(),
  must_job_register: Yup.boolean().required(
    "Preencha o campo Deve apresentar registro em carteira"
  ),
  // requirements: Yup.array().of(
  //   Yup.object({
  //     key: Yup.string().required(),
  //     value: Yup.string().required(),
  //     option: Yup.string().required(),
  //   })
  // ),
});

export const resumeExperience = Yup.object({
  company: Yup.string()
    .required("Preencha o campo Empresa")
    .typeError("Preencha o campo Empresa"),
  occupation_id: Yup.string()
    .required("Selecione uma ocupação")
    .typeError("Selecione uma ocupação"),
  start_at: Yup.date()
    .required("Preencha o campo Data de início")
    .typeError("Preencha o campo Data de início"),
  description: Yup.string()
    .required("Preencha o campo descrição")
    .typeError("Preencha o campo descrição"),
});

export const candidateSchema = Yup.object({
  pcd: Yup.array().of(Yup.string()).nullable(),
  cnh: Yup.array().of(Yup.string()).nullable(),
  language: Yup.array().of(Yup.string()).nullable(),
  region: Yup.string().nullable(),
  presentation: Yup.string().nullable(),
  other_requirements: Yup.array().of(Yup.string()).nullable(),
});
