import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompanyRegisterAttendanceStyled } from "./style";
import AttendanceContext from "../../contexts/attendance";
import AttendanceHeader from "../../pages/Attendance/components/AttendanceHeader";
import {
  companySchema,
  responsableSchema,
  adressSchema,
} from "../../helpers/validatorSchemas";
import PageHeader from "./../../components/PageHeader";
import Section from "./../../components/Section";
import Input from "./../../components/FormHook/Input";
import TextArea from "./../../components/FormHook/TextArea";
import WithMask from "./../../components/FormHook/WithMask";
import CompanySelect from "../../components/FormHook/CompanySelect";
import ReactSelect from "./../../components/FormHook/ReactSelect";
import Switch from "../../components/FormHook/Switch";
import api from "../../config/api";
import { companyAttendenceTypesOptions } from "./../../helpers/dicts";
import { confirm } from "../../components/Confirmation";

import { optionsRegion } from "./../Jobs/database";
import { validatePhone } from "validations-br";

function CompanyRegisterAttendance() {
  const navigate = useNavigate();
  let { uuid } = useParams();

  const requireAccompanyingLetterActions = [
    "PRESENTIAL_UNEMPLOYMENT_INSURANCE_REQUIREMENT",
    "PRESENTIAL_UNEMPLOYMENT_INSURANCE_RETURN",
    "PRESENTIAL_IMO",
  ];

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const [attendancesRegister, setAttendanceRegister] = useState([]);
  const actionWatch = watch("action", "value");

  function translateActionRequirement(reqAction) {
    let translated = null;
    companyAttendenceTypesOptions.map((r) => {
      if (r.value === reqAction) {
        translated = r.label;
      }
    });
    return translated;
  }

  async function onSubmit(data) {
    if (!data.company_select) {
      toast.error("Selecione a empresa que foi atendida");
      return false;
    }
    if (!data.action) {
      toast.error("Selecione o tipo de atendimento");
      return false;
    }
    if (!data.target_person) {
      toast.error("Informe a pessoa contatada");
      return false;
    }

    let att = {
      company_id: data.company_select.value,
      company_name: data.company_select.label,
      target_person: data.target_person,
      action: data.action,
    };

    let alreadyRegistered = false;
    attendancesRegister.map((registered) => {
      if (
        registered.action == att.action &&
        registered.company_id == att.company_id
      ) {
        alreadyRegistered = true;
      }
    });

    if (alreadyRegistered) {
      toast.error("Atendimento já registrado para essa empresa");
      return false;
    }

    setAttendanceRegister([...attendancesRegister, att]);
    reset({
      company_select: null,
      target_person: null,
      action: null,
    });
  }

  async function handeRemoveRegister(action, company_id) {
    setAttendanceRegister(
      attendancesRegister.filter(
        (att) => att.action !== action && att.company_id !== company_id
      )
    );
  }

  async function handleFinish() {
    if (attendancesRegister.length === 0) {
      toast.error("Nenhum atendimento adicionado");
      return false;
    }

    if (
      await confirm(
        "Deseja enviar esse registro de atendimento de empresas?",
        "Sim",
        "Não",
        {
          title: "Confirmar informações",
        }
      )
    ) {
      try {
        await api.post(`company-attendance`, { actions: attendancesRegister });
        toast.success("Atendimento registrado com sucesso");
        setAttendanceRegister([]);
        reset();
      } catch (e) {
        toast.error("Erro ao registrar atendimento, tente novamente.");
      }
    }
  }

  return (
    <CompanyRegisterAttendanceStyled>
      <PageHeader title={`Registrar atendimento de empresas`} />
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Section title="Formulário de registro de atendimento para empresas">
            <CompanySelect
              name="company_select"
              control={control}
              label="Empresa:"
              required
            />
            <ReactSelect
              name="action"
              control={control}
              options={companyAttendenceTypesOptions}
              label="Tipo de atendimento:"
              placeholder={`Selecione o tipo de atendimento`}
            />
            <Input
              type="text"
              register={register}
              name="target_person"
              label="Pessoa contatada:"
            />
            <div className="d-flex gap-3 mt-4">
              <button className="btn btn-primary" type="submit">
                Adicionar atendimento
              </button>
            </div>
          </Section>
          <Section title="Atendimentos registrados">
            <div className="attendance_list mb-4">
              {attendancesRegister.length > 0 ? (
                attendancesRegister.map((attend) => {
                  return (
                    <div
                      className="attendance_list_item"
                      key={`ATT_ITEM_${attend.action}_${attend.company_id}`}
                    >
                      <div className="content">
                        <div className="title">
                          {translateActionRequirement(attend.action)}
                        </div>
                        <div className="value">{attend.company_name}</div>
                      </div>
                      <div className="action">
                        <FontAwesomeIcon
                          icon="fa-solid fa-xmark"
                          className="read-notification"
                          onClick={() =>
                            handeRemoveRegister(
                              attend.action,
                              attend.company_id
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>
                  Nenhum atendimento adicionado ainda. Preencha o formulário
                  acima.
                </p>
              )}
            </div>
            <button
              className="btn btn-alternative"
              type="button"
              onClick={handleFinish}
            >
              Finalizar registro de atendimento
            </button>
          </Section>
        </form>
      </div>
    </CompanyRegisterAttendanceStyled>
  );
}

export default CompanyRegisterAttendance;
