import { lastDayOfDecade } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../../../config/api";
import styled from "styled-components";

const JobDashContainer = styled.div`
  padding: 0rem 0;
  font-family: "Raleway", sans-serif;

  .kpi-container {
    display: flex;
    align-items: center;
  }
`;

const KPICardStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  color: #737373;

  .kpi-card-title {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
  }

  .kpi-card-value {
    font-size: 3rem;
    font-weight: bold;
    border-top: 4px solid;
    border-bottom: 4px solid;
    margin: 1rem 0;
    line-height: 4rem;
    font-family: "Open Sans", sans-serif;
  }
`;

const StatusColor = {
  INVITE_EXPIRED: "",
  INVITE_REJECTED: "",
  APPROVED: "",
  MASSIVE_REJECT: "",
  REJECTED: "",
  INVITED: "",
  NEW: "",
  CANCELED: "",
  CLOSED: "",
};

const StatusLabel = {
  INVITE_EXPIRED: "Não responderam o convite no prazo",
  INVITE_REJECTED: "Rejeitaram o convite para o processo seletivo",
  APPROVED: "Aprovados para entrevista",
  REJECTED: "Reprovados",
  MASSIVE_REJECT: "Reprovados em massa",
  INVITED: "Convidados a participar do processo seletivo",
  NEW: "Aguardando análise",
  CANCELED: "Candidatura cancelada",
  CLOSED: "Fechada",
};

const KPICard = ({ title, value }) => {
  return (
    <KPICardStyled>
      <span className="kpi-card-title">{title}</span>
      <span className="kpi-card-value">{value}</span>
    </KPICardStyled>
  );
};

export default function JobDash({
  availableJobs = 0,
  availableSeats = 0,
  jobId,
  seats = 0
}) {
  const [applicationsAmount, setApplicationsAmount] = useState([]);

  useEffect(() => {
    api.get(`jobs/${jobId}/dashboard`).then((resp) => {
      setApplicationsAmount(
        resp.data.map((data) => {
          return {
            color: StatusColor[data.status],
            label: StatusLabel[data.status],
            value: data.amount,
          };
        })
      );
    });
  }, []);

  return jobId ? (
    <JobDashContainer>
      <div className="row">
        <div className="col-md-2 kpi-container col-sm-12">
          <KPICard title={`Total de vagas`} value={availableJobs} />
        </div>
        <div className="col-md-2 kpi-container col-sm-12">
          <KPICard title={`Candidaturas disponíveis`} value={availableSeats} />
        </div>
        <div className="col-md-2 kpi-container col-sm-12">
          <KPICard title={`Limite de candidatos`} value={seats} />
        </div>
        <div className="col-md-6 col-sm-12" style={{padding: "40px"}}>
          <Chart
            options={{
              chart: {
                width: "80%",
                type: "donut",
              },
              labels: applicationsAmount.map((d) => d.label),
              dataLabels: {
                formatter(val, opts) {
                  return [applicationsAmount[opts.seriesIndex]?.value];
                },
              },
              legend: {
                show: true,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      value: {
                        show: true,
                        fontSize: "1.4rem",
                        fontFamily: "Raleway, sans-serif",
                        fontWeight: "bold",
                        color: "#737373",
                       
                      },
                      total: {
                        show: true,
                        showAlways: true,
                        label: "INSCRITOS",
                        fontSize: "1.4rem",
                        fontWeight: "bold",
                        color: "#737373",
                        fontFamily: "Raleway, sans-serif",
                      },
                    },
                  },
                },
              },
            }}
            series={applicationsAmount.map((d) => d.value)}
            type="donut"
          />
        </div>
      </div>
    </JobDashContainer>
  ) : null;
}
