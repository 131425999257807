import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { Collapse } from "react-collapse";
import { maskCPF } from "./../../../../helpers/mask";
import {
  TranslateJobStatus,
  TranslateJobType,
  TranslateJobJouney,
  TranslateJobSalaryType,
  TranslateRegions,
} from "./../../../../helpers/translateFields";
import id from "date-fns/esm/locale/id/index.js";

// import { Container } from './styles';

const JobActionTranslate = {
  create: "Vaga aberta",
  update: "Vaga editada",
};

const TranslateCloseReason = {
  company_cancel_order: "Contratação cancelada pela empresa",
  complete_cate: "Vagas preenchidas por indicacao do Cate",
  complete_other: "Vagas preenchidas por outros meios",
};

const JobFieldsTranslate = {
  available_jobs: "Vagas disponíveis",
  seats: "Limite de candidatos",
  sine_id: "ID Sine",
  salary: "Salario",
  contract_type: "Tipo de contrato",
  is_meet_tell: "Primeiro contato por telefone?",
  responsable_id: "Reponsavel",
  schooling_level_id: "Escolaridade miníma",
  experience_level_id: "Experiência miníma",
  requirements: "Requisitos da vaga",
  address: "Endereço",
  meet_phone: "Primeiro contato por telefone",
  schedule: "Agenda",
  occupation: "Ocupação",
  company_id: "Empresa", //TODO: Verificar log
  occupation_area_id: "Campo/Área de atuação", //TODO: Verificar log
  description: "Descrição da vaga",
  is_cate_managed: "Vaga gerenciada pelo cate",
  salary_type: "Tipo de remuneração",
  benefits: "Benefícios oferecidos",
  journey: "Jornada de trabalho",
  job_scale: "Escala de tarbalho",
  job_type: "Tipo de trabalho",
  obs: "Observações",
  must_job_register: "Deve apresentar registro em carteira",
  is_meet_on_company:
    "O endereço da entrevista será o mesmo endereço da empresa",
  is_meet_on_cate: "A entrevista será realizada em uma unidade do cate",
  status: "Status",
  close_cpf: "Vagas preenchidas por indicacao do Cate",
  active_at: "Vagas ativa em",
  close_reason: "Motivo do encerramento",
  available_seats: "Limite de candidatos",
  segment_id: "Área de atuação/Segmento",
  occupation_id: "Ocupação",
  hidden_salary: "Esconder remueração na exibição?",
  same_job_location: "Mesmo local do cadastro da empresa?",
  interview_obs: "Observações para entrevista",
  cate_responsable: "Pessoa responsável pela vaga no Cate",
  work_regions: "Local de trabalho",
  closed_at: "Encerrada em:"
};

const fieldsValueTransform = {
  requirements: (value) => value.split(",").join(", "),
  benefits: (value) => value.split(",").join(", "),
  is_meet_on_company: (value) => (value === "1" ? "Sim" : "Não"),
  is_meet_on_cate: (value) => (value === "1" ? "Sim" : "Não"),
  is_cate_managed: (value) => (value === "1" ? "Sim" : "Não"),
  is_meet_tell: (value) => (value === "1" ? "Sim" : "Não"),
  must_job_register: (value) => (value === "1" ? "Sim" : "Não"),
  close_cpf: (value) => maskCPF(value),
  status: (value) => TranslateJobStatus[value],
  job_type: (value) => TranslateJobType[value],
  journey: (value) => TranslateJobJouney[value],
  active_at: (value) => format(new Date(value), "dd/MM/yyyy HH:mm"),
  closed_at: (value) => format(new Date(value), "dd/MM/yyyy HH:mm"),
  close_reason: (value) => TranslateCloseReason[value],
  salary_type: (value) => TranslateJobSalaryType[value],
  hidden_salary: (value) => (value === "1" ? "Sim" : "Não"),
  same_job_location: (value) => (value === "1" ? "Sim" : "Não"),
  work_regions: (value) =>
    value
      ? value
          .split(",")
          .map((v) => TranslateRegions[v])
          .join(", ")
      : "-",
  salary: (value) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value),
};

const HistoryByType = (type, oldValue, newValue, key) => {
  if (type == "update") {
    return (
      <React.Fragment>
        <p>
          <b>Estava: </b>
          {fieldsValueTransform[key]
            ? fieldsValueTransform[key](oldValue)
            : oldValue || "-"}
        </p>
        <p>
          <b>Mudou para: </b>
          {fieldsValueTransform[key]
            ? fieldsValueTransform[key](newValue)
            : newValue || "-"}
        </p>
      </React.Fragment>
    );
  }
  if (type == "create") {
    return <React.Fragment></React.Fragment>;
  }
  if (type == "add") {
    return (
      <React.Fragment>
        <p>
          <b>Incluiu: </b>
          {fieldsValueTransform[key]
            ? fieldsValueTransform[key](newValue)
            : newValue || "-"}
        </p>
      </React.Fragment>
    );
  }

  if (type == "delete") {
    return (
      <React.Fragment>
        <p>
          <b>Removeu: </b>
          {fieldsValueTransform[key]
            ? fieldsValueTransform[key](oldValue)
            : oldValue || "-"}
        </p>
      </React.Fragment>
    );
  }
};

function JobHistoryLine({ histories, date, user, action }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="job-history-line">
      <div className="job-history-line-header">
        <span>
          <b>{format(new Date(date), "dd/MM/yyyy HH:mm")}</b>
          {` - ${JobActionTranslate[action]} - `}
          <b>{user}</b>
        </span>
        <button onClick={() => setOpen(!open)} className="btn btn-alternative">
          {!open ? `Abrir` : `Fechar`}
        </button>
      </div>
      <Collapse isOpened={open}>
        {histories.map((history) => {
          return (
            <div className="history-line-single" key={uuidv4()}>
              <div className="action-header">
                {history.key ? (
                  <h6>{`${JobFieldsTranslate[history.key]}:`}</h6>
                ) : null}
                <small>{`Horário da ação: ${format(
                  new Date(history.created_at),
                  "HH:mm:ss"
                )}`}</small>
              </div>
              <div className="action-body">
                {HistoryByType(
                  history.action,
                  history.old_value,
                  history.new_value,
                  history.key
                )}
              </div>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
}

export default JobHistoryLine;
