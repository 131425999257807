import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import Input from "./../../components/FormHook/Input";
import api from "../../config/api";
import AuthContext from "../../contexts/auth";

import { SigninStyles } from "./../Signin/styles";

function UpdatePassword() {
  const { signIn } = useContext(AuthContext);
  const { register, handleSubmit, reset, watch, setValue, control } = useForm();
  const [sendMail, setSendMail] = useState(false);
  const { uuid } = useParams();

  useEffect(() => {}, []);

  async function onSubmit(data) {
    data.uuid = uuid;
    if (!data.pass || !data.confirm_pass || !data.code) {
      toast.error(`Preencha todos os campos`);
      return false;
    }

    if (data.pass != data.confirm_pass) {
      toast.error(`As senhas não conferem`);
      return false;
    }

    if (
      !data.pass.match(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
      )
    ) {
      toast.error(
        `A senha precisa ter um letra maiuscula, um letra minuscula, um número e um caracter especial`
      );
      return false;
    }

    try {
      let response = await api.put(`reset-password`, { ...data });
      setSendMail(true);
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  return (
    <SigninStyles>
      {sendMail ? (
        <p className="reset-pass-reply">
          Senha alterada com sucesso. <br />{" "}
          <Link to="/">Voltar para login.</Link>
        </p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <img
            width="100%"
            alt="Cate - Centro de Apoio ao Trabalhador e Empreendedor"
            src={process.env.PUBLIC_URL + "/assets/logo-cate-login.png"}
          />
          <div className="login-welcome">
            <h2>Reset de senha</h2>
          </div>
          <Input
            label={`Código (enviado via email):`}
            name="code"
            register={register}
          />
          <Input
            type="password"
            label={`Nova Senha:`}
            name="pass"
            register={register}
          />
          <Input
            label={`Confirmar Nova Senha:`}
            type="password"
            name="confirm_pass"
            register={register}
          />
          <div className="form-text">Instruções para senha: </div>
          <div className="form-text">Mínimo 8 caracteres.</div>
          <div className="form-text">Ao menos 1 letra maiuscula.</div>
          <div className="form-text">Ao menos 1 número.</div>
          <div className="form-text">Ao menos 1 caractere especial.</div>
          <button type="submit" className="btn btn-primary mt-3">
            Alterar Senha
          </button>
        </form>
      )}
    </SigninStyles>
  );
}

export default UpdatePassword;
