import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { confirmable, createConfirmation } from "react-confirm";

const AlertStyled = styled.div`
  .header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  .close {
    display: flex;
    justify-content: end;
    font-size: 2rem;
    margin-right: -2.5rem;
    color: #4d4d4d;

    .pointer {
      cursor: pointer;
    }
  }

  .header span {
    font-size: 1.8rem;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    background: #105ab5;
    color: white;
    padding: 0.3rem 2rem;
    text-align: center;
  }

  .content {
    font-family: "Raleway", sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    .strong {
      font-weight: bold;
    }

    .big {
      font-size: 1.8rem;
    }
  }

  .content-actions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 1rem;
    flex-direction: column;

    button {
      width: 100%;
    }
  }

  .modal-body {
    padding: 1rem 4rem;
  }

  @media only screen and (max-width: 600px) {
    .header span {
      font-size: 1.4rem;
    }

    .modal-body {
      padding: 1rem 2rem;
    }

    .close {
      margin-right: -1rem;
      font-size: 1.4rem;
    }
  }
`;

function Alert({
  content,
  show,
  proceed,
  enableEscape = true,
  title,
  actions = null,
  closeButton = false,
}) {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      backdrop={enableEscape ? true : "static"}
      keyboard={enableEscape}
    >
      <AlertStyled>
        <Modal.Body>
          {closeButton ? (
            <div className="close">
              <FontAwesomeIcon
                className="pointer"
                onClick={() => proceed(false)}
                icon="fa-solid fa-xmark"
              />
            </div>
          ) : null}
          <div className="header">
            <span>{title}</span>
          </div>
          <div className="content">
            {content()}
            {actions ? (
              <div className="content-actions">
                {actions.map((action, idx) => {
                  return (
                    <div
                      key={`alert-action-${idx}`}
                      onClick={() => proceed(false)}
                    >
                      {action}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </AlertStyled>
    </Modal>
  );
}

Alert.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
  closeButton: PropTypes.bool,
};

export function confirm(options = {}) {
  return createConfirmation(confirmable(Alert))({
    ...options,
  });
}
