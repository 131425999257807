import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { validateCPF } from "validations-br";
import { SocialAttendanceStyled } from "./style";

import { currencyToNumber } from "./../../helpers/formatter";

import PageHeader from "./../../components/PageHeader";
import Section from "./../../components/Section";
import Input from "./../../components/FormHook/Input";
import WithMask from "./../../components/FormHook/WithMask";
import ReactSelect from "./../../components/FormHook/ReactSelect";
import Switch from "../../components/FormHook/Switch";
import Currency from "./../../components/FormHook/Currency";
import api from "../../config/api";
import { attendenceTypesOptions, portalSchooling } from "./../../helpers/dicts";
import { confirm } from "../../components/Confirmation";

import {
  portalEthnicGroup,
  portalHouseSituation,
  portalNacionality,
  portalPCD,
  portalSexualOrientation,
} from "./../../helpers/dicts";

import { optionsGender, optionsRegion } from "./../Jobs/database/index";

function CompanyRegisterAttendance() {
  const navigate = useNavigate();
  let { uuid } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const requireAccompanyingLetterActions = [
    "PRESENTIAL_UNEMPLOYMENT_INSURANCE_REQUIREMENT",
    "PRESENTIAL_UNEMPLOYMENT_INSURANCE_RETURN",
  ];

  const [attendancesRegister, setAttendanceRegister] = useState([]);
  const actionWatch = watch("action", "value");

  async function onSubmit(data) {
    let isValid = await validate(data);
    if (!isValid) {
      return false;
    }

    if (
      !(await confirm("Confirma esse registro de atendimento?", "Sim", "Não", {
        title: "Confirmar informações",
      }))
    ) {
      return false;
    }

    data.avg_rent = currencyToNumber(data.avg_rent);

    try {
      let response = await api.post(`noregister-attendance`, data);
      toast.success("Atendimento registrado com sucesso!");
      reset();
    } catch (e) {
      toast.error("Erro ao completar registro, verifique os dados informados.");
      console.log(e);
    }
  }

  async function validate(data) {
    let { cpf, rne, action, name } = data;

    if (!cpf && !rne) {
      toast.error("Digite o CPF ou o RNE");
      return false;
    }

    if (cpf) {
      if (!validateCPF(cpf)) {
        toast.error("CPF inválido");
        return false;
      }
    }

    if (!name) {
      toast.error("Digite um nome válido!");
      return false;
    }

    if (!action) {
      toast.error("Selecione o tipo de atendimento!");
      return false;
    }

    return true;
  }

  return (
    <SocialAttendanceStyled>
      <PageHeader title={`Atendimento sem cadastro`} />
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Section title="Formulário de registro de atendimento – sem cadastro no portal Cate">
            <Input
              required
              type="text"
              register={register}
              name="name"
              label="Nome:"
            />
            <Input
              type="text"
              register={register}
              name="social_name"
              label="Nome social:"
            />
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <WithMask
                  control={control}
                  name="cpf"
                  label={`CPF:`}
                  mask={`999.999.999-99`}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <Input
                  type="text"
                  register={register}
                  name="rne"
                  label="RNE:"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <Input
                  name="birth_date"
                  placeholder="Data de nascimento"
                  register={register}
                  type={"date"}
                  max={format(new Date(), "yyyy-MM-dd")}
                  label="Data de nascimento:"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="nacionality"
                  control={control}
                  options={portalNacionality}
                  label="Nacionalidade:"
                  placeholder={`Selecione a nacionalidade`}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="gender"
                  control={control}
                  options={optionsGender}
                  label="Gênero:"
                  placeholder={`Selecione o gênero`}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="sexual_orientation"
                  control={control}
                  options={portalSexualOrientation}
                  label="Orientação Sexual:"
                  placeholder={`Selecione a orientação sexual`}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="ethnic_group"
                  control={control}
                  options={portalEthnicGroup}
                  label="Raça/cor:"
                  placeholder={`Selecione a raça/cor`}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="pcd"
                  control={control}
                  options={portalPCD}
                  label="Deficiência:"
                  placeholder={`Selecione a deficiência`}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <WithMask
                  control={control}
                  name="phone"
                  label={`Telefone/Celular`}
                  phoneOrCell
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <Input
                  name="email"
                  type={`email`}
                  register={register}
                  label={`E-mail:`}
                  placeholder={`E-mail`}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12">
                <WithMask
                  name="cep"
                  mask={`99999-999`}
                  control={control}
                  label={`CEP:`}
                />
              </div>
              <div className="col-md-8 col-sm-12">
                <Input
                  name="address"
                  register={register}
                  label={`Endereço:`}
                  placeholder={`Endereço`}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="region"
                  control={control}
                  options={optionsRegion}
                  label="Região:"
                  placeholder={`Selecione a região`}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="house_situation"
                  control={control}
                  options={portalHouseSituation}
                  label="Situação de moradia:"
                  placeholder={`Selecione a situação de moradia`}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="schooling_level"
                  control={control}
                  options={portalSchooling}
                  label="Escolaridade:"
                  placeholder={`Selecione a escolaridade`}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <Currency
                  name="avg_rent"
                  control={control}
                  label={`Renda mensal média:`}
                />
              </div>
            </div>

            <ReactSelect
              name="action"
              control={control}
              required
              options={attendenceTypesOptions}
              label="Tipo de atendimento:"
              placeholder={`Selecione o tipo de atendimento`}
            />
            {requireAccompanyingLetterActions.indexOf(actionWatch) !== -1 ? (
              <Switch
                name="accompanying_letter"
                label="Teve carta de Encaminhamento"
                register={register}
              />
            ) : null}

            <div className="d-flex gap-3 mt-4">
              <button className="btn btn-primary" type="submit">
                Registrar atendimento
              </button>
            </div>
          </Section>
        </form>
      </div>
    </SocialAttendanceStyled>
  );
}

export default CompanyRegisterAttendance;
