import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { WP_PLUGIN_AUTH_PAGE } from "./../../config/consts";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

const CandidateAuthErrorStyled = styled.div`
  margin-top: 5rem;
  .info-card {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    width: 80%;
    margin: 0 auto;

    h2 {
      font-weight: 900;
      font-size: 1.7rem;
    }

    p {
      width: 80%;
      text-align: center;
      font-weight: 300;
      font-size: 1.2rem;
    }

    small {
      font-weight: 200;
      font-style: italic;
      margin-bottom: 20px;
    }
  }
`;

export default function CandidateAuthError() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <CandidateAuthErrorStyled>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="info-cards-container">
              <div className="info-card">
                <h2 className="secondary">SESSÃO ABERTA</h2>
                <p>
                  Você está tentando acessar a área de vagas do Cate como
                  candidato, porém, já está logado com uma conta administrativa.
                  Clique no botão abaixo para utilizar a área de atendimento
                  para acessar o perfil do usuário ou saia da sua conta para
                  acessar como candidato.
                </p>
                <Link to="/" className="btn btn-primary">
                  Voltar ao atendimento
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CandidateAuthErrorStyled>
  );
}
