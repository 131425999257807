export const optionsOccupation = [
  { label: "Professor de matematica", value: "Professor de matematica" },
  { label: "Atendente", value: "Atendente" },
];

export const optionsContractType = [
  { label: "CLT", value: "CLT" },
  { label: "PJ", value: "PJ" },
  { label: "Intermitente", value: "Intermitente" },
  { label: "Temporário", value: "Temporário" },
  { label: "Estagiário", value: "Estagiario" },
  { label: "Aprendiz", value: "Aprendiz" },
];

export const optionsJobScale = [
  {
    label: "Segunda a Sexta",
    value: "Segunda a Sexta",
  },
  {
    label: "Segunda a Sábado",
    value: "Segunda a Sábado",
  },
  {
    label: "12x24",
    value: "12x24",
  },
  {
    label: "6x1",
    value: "6x1",
  },
  {
    label: "12x36",
    value: "12x36",
  },
  {
    label: "5x2",
    value: "5x2",
  },
  {
    label: "Finais de semana",
    value: "Finais de semana",
  },
];

export const optionsBenefits = [
  { label: "Vale Transporte integração", value: "Vale transporte integração" },
  {
    label: "Vale Transporte intermunicipal",
    value: "Vale transporte intermunicipal",
  },
  { label: "Vale Transporte 1 condução", value: "Vale transporte 1 condução" },
  {
    label: "Vale Transporte 2 ou mais conduções",
    value: "Vale transporte 2 ou mais conduções",
  },
  { label: "Vale Refeição", value: "Vale refeição" },
  { label: "Refeição no local", value: "Refeição no local" },
  { label: "Vale Alimentação", value: "Vale alimentação" },
  { label: "Convênio Médico", value: "Convênio médico" },
  { label: "Convênio Odontológico", value: "Convênio Odontológico" },
  { label: "Participação nos lucros", value: "Participação nos lucros" },
  { label: "Comissão", value: "Comissão" },
  { label: "Auxílio-Creche", value: "Auxilio-creche" },
  { label: "Cesta Básica", value: "Cesta Básica" },
  { label: "Plano de cargos e salários", value: "Plano de cargos e salários" },
  { label: "Seguro de Vida", value: "Seguro de vida" },
];

export const optionsPCD = [
  { value: "amputacao", label: "Física - Amputação" },
  { value: "nanismo", label: "Física - Nanismo" },
  { value: "ostomia", label: "Física - Ostomia" },
  { value: "cadeirante", label: "Física - Cadeirante" },
  { value: "superior", label: "Física - Um membro superior" },
  { value: "inferior", label: "Física - Um membro inferior" },
  { value: "superiores", label: "Física - Membros superiores" },
  { value: "inferiores", label: "Física - Membros inferiores" },
  { value: "parcial", label: "Auditiva - Surdez Bilateral Parcial" },
  { value: "total", label: "Auditiva - Surdez Total" },
  { value: "baixa", label: "Visual - Baixa visão" },
  { value: "monocular", label: "Visual - Visão monocular" },
  { value: "cegueira", label: "Visual - Cegueira" },
  { value: "intelectual", label: "Intelectual" },
  { value: "paralisia", label: "Paralisia Cerebral" },
];

export const optionsGender = [
  { value: "male", label: "Masculino" },
  { value: "female", label: "Feminino" },
];

export const optionsRegion = [
  { value: "lest", label: "Zona Leste" },
  { value: "oest", label: "Zona Oeste" },
  { value: "north", label: "Zona Norte" },
  { value: "south", label: "Zona Sul" },
  { value: "center", label: "Central" },
  { value: "metropolitan", label: "Região metropolitana" },
  { value: "other", label: "Outro Município" },
];

export const optionsOwnVehicle = [
  { value: "own_vehicle_car", label: "Carro" },
  { value: "own_vehicle_bike", label: "Moto" },
  { value: "own_vehicle_truck", label: "Caminhão" },
  { value: "own_vehicle_other", label: "Outros" },
];

export const optionsEthnicGroup = [
  { value: "white", label: "Branca" },
  { value: "black", label: "Preta" },
  { value: "yellow", label: "Amarela" },
  { value: "brown", label: "Parda" },
  { value: "indigenous", label: "Indígena" },
];

export const optionsCNH = [
  { value: "ACC", label: "Categoria ACC" },
  { value: "A", label: "Categoria A" },
  { value: "B", label: "Categoria B" },
  { value: "C", label: "Categoria C" },
  { value: "D", label: "Categoria D" },
  { value: "E", label: "Categoria E" },
];

export const optionsLanguage = [
  { value: "german", label: "Alemão" },
  { value: "arabic", label: "Árabe" },
  { value: "bulgarian", label: "Búlgaro" },
  { value: "chinese (simplified)", label: "Chinês simplificado" },
  { value: "chinese (traditional)", label: "Chinês tradicional" },
  { value: "korean", label: "Coreano" },
  { value: "croatian", label: "Croata" },
  { value: "danish", label: "Dinamarquês" },
  { value: "slovak", label: "Eslovaco" },
  { value: "slovenian", label: "Esloveno" },
  { value: "spanish", label: "Espanhol" },
  { value: "spanish (mexico)", label: "Espanhol (México)" },
  { value: "estonian", label: "Estoniano" },
  { value: "finnish", label: "Finlandês" },
  { value: "french", label: "Francês" },
  { value: "french canadian", label: "Francês canadense" },
  { value: "greek", label: "Grego" },
  { value: "hebrew", label: "Hebraico" },
  { value: "dutch", label: "Holandês" },
  { value: "hungarian", label: "Húngaro" },
  { value: "english", label: "Inglês" },
  { value: "italian", label: "Italiano" },
  { value: "japanese", label: "Japonês" },
  { value: "latvian", label: "Letão" },
  { value: "lithuanian", label: "Lituano" },
  { value: "norwegian", label: "Norueguês" },
  { value: "polish", label: "Polonês" },
  { value: "romanian", label: "Romeno" },
  { value: "russian", label: "Russo" },
  { value: "serbian latin", label: "Sérvio (latino)" },
  { value: "swedish", label: "Sueco" },
  { value: "thai", label: "Tailandês" },
  { value: "czech", label: "Tcheco" },
  { value: "turkish", label: "Turco" },
  { value: "ukrainian", label: "Ucraniano" },
];

export const optionsOtherRequirements = [
  { value: "refugee", label: "Refugiado ou Imigrante" },
  { value: "egress", label: "Egresso do sistema prisional" },
  { value: "transexual", label: "Transexual" },
  { value: "lgbt", label: "LGBTQIA+" },
  { value: "50_age", label: "+50 anos" },
  { value: "street_population", label: "População de rua" },
  {
    value: "domestic_violence",
    label: "Mulher com histórico de violência domestica",
  },
];

export const professionalRequirements = [
  "own_vehicle",
  "pcd",
  "cnh",
  "region",
  "language",
];

export const optionsRequirements = [
  {
    label: "Gênero",
    key: "gender",
    options: [
      { value: "male", label: "Masculino" },
      { value: "female", label: "Feminino" },
    ],
  },
  {
    label: "Raça",
    key: "ethnic_group",
    options: [
      { value: "white", label: "Branca" },
      { value: "black", label: "Preta" },
      { value: "yellow", label: "Amarela" },
      { value: "brown", label: "Parda" },
      { value: "indigenous", label: "Indígena" },
    ],
  },
  {
    label: "Idade",
    key: "age",
    options: [
      { value: "18", label: "Ate 18 anos" },
      { value: "21", label: "19 a 21 anos" },
      { value: "30", label: "22 a 30 anos" },
      { value: "40", label: "31 a 40 anos" },
      { value: "50", label: "41 a 50 anos" },
      { value: "60", label: "51 a 60 anos" },
      { value: "61", label: "Acima de 61 anos" },
    ],
  },
  {
    label: "Região",
    key: "region",
    options: [
      { value: "lest", label: "Zona Leste" },
      { value: "oest", label: "Zona Oeste" },
      { value: "north", label: "Zona Norte" },
      { value: "south", label: "Zona Sul" },
      { value: "center", label: "Central" },
      { value: "metropolitan", label: "Região metropolitana" },
      { value: "other", label: "Outro Município" },
    ],
  },
  {
    label: "Possui CNH",
    key: "cnh",
    options: [
      { value: "ACC", label: "Categoria ACC" },
      { value: "A", label: "Categoria A" },
      { value: "B", label: "Categoria B" },
      { value: "C", label: "Categoria C" },
      { value: "D", label: "Categoria D" },
      { value: "E", label: "Categoria E" },
    ],
  },
  {
    label: "Ter veículo próprio",
    key: "own_vehicle",
    options: [
      { value: "own_vehicle_car", label: "Carro" },
      { value: "own_vehicle_bike", label: "Moto" },
      { value: "own_vehicle_truck", label: "Caminhão" },
      { value: "own_vehicle_other", label: "Outros" },
    ],
  },
  {
    label: "Idioma",
    key: "language",
    options: optionsLanguage,
  },
  {
    label: "Pessoa com deficiência",
    key: "pcd",
    options: [
      { value: "amputacao", label: "Física - Amputação" },
      { value: "nanismo", label: "Física - Nanismo" },
      { value: "ostomia", label: "Física - Ostomia" },
      { value: "cadeirante", label: "Física - Cadeirante" },
      { value: "superior", label: "Física - Um membro superior" },
      { value: "inferior", label: "Física - Um membro inferior" },
      { value: "superiores", label: "Física - Membros superiores" },
      { value: "inferiores", label: "Física - Membros inferiores" },
      { value: "parcial", label: "Auditiva - Surdez Bilateral Parcial" },
      { value: "total", label: "Auditiva - Surdez Total" },
      { value: "baixa", label: "Visual - Baixa visão" },
      { value: "monocular", label: "Visual - Visão monocular" },
      { value: "cegueira", label: "Visual - Cegueira" },
      { value: "intelectual", label: "Intelectual" },
      { value: "paralisia", label: "Paralisia Cerebral" },
    ],
  },
  {
    key: "refugee",
    label: "Refugiado ou imigrante",
    options: [{ value: "refugee", label: "Refugiado ou Imigrante" }],
  },
  {
    key: "egress",
    label: "Egresso do sistema prisional",
    options: [{ value: "egress", label: "Egresso do sistema prisional" }],
  },
  {
    key: "transexual",
    label: "Transexual",
    options: [{ value: "transexual", label: "Transexual" }],
  },
  {
    key: "lgbt",
    label: "LGBTQIA+",
    options: [{ value: "lgbt", label: "LGBTQIA+" }],
  },
  {
    key: "street_population",
    label: "População de rua",
    options: [{ value: "street_population", label: "População de rua" }],
  },
  {
    label: "+50 anos",
    key: "50_age",
    options: [{ value: "50_age", label: "+50 anos" }],
  },
  {
    key: "domestic_violence",
    label: "Mulher com histórico de violência domestica",
    options: [
      {
        value: "domestic_violence",
        label: "Mulher com histórico de violência domestica",
      },
    ],
  },
];
