import styled from "styled-components";

export const UsersContainerStyled = styled.div`
  .select-filter {
    flex: 1;
  }

  .text-filter {
    flex: 3;
    display: flex;
    gap: 5px;
  }

  .filters-container form {
    gap: 10px;
  }
`;
