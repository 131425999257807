import React from "react";
import styled from "styled-components";
import Select from "react-select/creatable";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledInput = styled.div``;

const selectStyles = {
  control: (styles) => ({
    ...styles,
    color: "#000",
    borderRadius: "0px",
    border: "1px solid #848484",
    fontSize: "0.8rem",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#000",
      ":active": {
        ...styles[":active"],
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
  }),
};

function CreatableSelect({
  label,
  name,
  isMulti = false,
  options = [],
  control,
  placeholder,
  disabled,
  required = false,
  requiredForJob = false,
}) {
  const id = uuidv4();
  const tooltipId = uuidv4();
  return (
    <StyledInput>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      {required ? <span className="required">*</span> : null}
      {requiredForJob ? (
        <span className="required required-secondary">*</span>
      ) : null}
      <span
        className="info-tooltip"
        data-tooltip-id={tooltipId}
        data-tooltip-content={
          "Você pode inserir um valor diferente nesse campo, basta digitar e pressionar enter"
        }
      >
        <FontAwesomeIcon icon="fa-solid fa-circle-info" />
      </span>
      <Tooltip id={tooltipId} />
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              options={options}
              placeholder={placeholder}
              isMulti={isMulti}
              isDisabled={disabled}
              isClearable={true}
              styles={selectStyles}
              onChange={(options) =>
                onChange(
                  isMulti
                    ? options?.map((option) => option.value)
                    : options?.value
                )
              }
              onBlur={onBlur}
              value={
                isMulti
                  ? value?.map((val) => {
                      return { label: val, value: val };
                    })
                  : { label: value, value }
              }
              defaultValue={
                isMulti
                  ? value?.map((val) => {
                      return { label: val, value: val };
                    })
                  : { label: value, value }
              }
            />
          );
        }}
      />
    </StyledInput>
  );
}

export default CreatableSelect;
