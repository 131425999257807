import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import styled from "styled-components";
import { confirmable, createConfirmation } from "react-confirm";

const ConfirmStyled = styled.div`
  .header {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
  }

  .header span {
    font-size: 1.8rem;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    background: #105ab5;
    color: white;
    padding: 0.3rem 2rem;
    text-align: center;
  }

  .content {
    text-align: center;
  }
  
  .content span {
    font-family: "Raleway", sans-serif;
    font-size: 1.2rem;
    text-align: center;
  }

  .content-actions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 1rem;
  }

  .modal-body {
    padding: 1rem 4rem;
  }
`;

// import { Container } from './styles';

function Confirmation({
  proceedLabel,
  cancelLabel,
  title,
  confirmation,
  show,
  proceed,
  enableEscape = true,
}) {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      backdrop={enableEscape ? true : "static"}
      keyboard={enableEscape}
    >
      <ConfirmStyled>
        <Modal.Body>
          <div className="header">
            <span>{title}</span>
          </div>
          <div className="content">
            <span>{confirmation}</span>
            <div className="content-actions">
              <button
                className="btn btn-secondary"
                onClick={() => proceed(false)}
              >
                {cancelLabel}
              </button>
              <button className="btn btn-primary" onClick={() => proceed(true)}>
                {proceedLabel}
              </button>
            </div>
          </div>
        </Modal.Body>
      </ConfirmStyled>
    </Modal>
  );
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function confirm(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}
