import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import api from "./../../../../config/api";
import { format } from "date-fns";
import Pagination from "./../../../../components/Pagination";
import JobHistoryLine from "./line";
const JobHistoryStyled = styled.div`
  .job-history-line-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .job-history-line {
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
  }

  .action-header {
    margin-bottom: 5px;

    h6 {
      margin-bottom: -5px;
    }

    small {
      font-size: 0.7rem;
      font-style: italic;
    }
  }

  .action-body {
    p {
      margin-bottom: 0.2rem;
    }
  }

  .history-line-single {
    margin-bottom: 20px;
  }
`;

export default function JobHistory({ jobId }) {
  const [histories, setHistories] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  useEffect(() => {
    api.get(`job-histories/${jobId}?page=${page}`).then((resp) => {
      setHistories(resp.data.data);
      setTotal(resp.data.last_page);
    });
  }, [page]);
  return (
    <JobHistoryStyled>
      <div>
        {histories.length > 0 ? (
          histories.map((history) => {
            return (
              <JobHistoryLine
                key={uuidv4()}
                histories={history.histories}
                date={history.action_time}
                user={history.name}
                action={history.type}
              />
            );
          })
        ) : (
          <div>Nenhuma alteração registrada.</div>
        )}
      </div>
      <div>
        <Pagination
          page={page}
          total={total}
          onChange={(page) => setPage(page)}
        />
      </div>
    </JobHistoryStyled>
  );
}
