import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import CandidateContext from "../../../contexts/candidate";
import PageHeader from "./../../../components/PageHeader";
import CandidatePanel from "./../components/CandidatePanel";

export default function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { candidate, getCandidate } = useContext(CandidateContext);
  useEffect(() => {
    async function init() {
      if (!candidate) {
        let token = searchParams.get("token");
        if (token) {
          await getCandidate(token);
          let redirect = searchParams.get("redirect");
          let target = searchParams.get("target");
          switch (redirect) {
            case "resume":
              navigate("/candidato/curriculo");
            case "application":
              if (target) navigate(`/candidato/vagas/detalhes?id=${target}`);
          }
        }
      }
    }
    init();
    window.scrollTo(0, 0);
  }, []);
  return candidate ? (
    <div>
      <PageHeader title={`Minhas Vagas`} />
      <div className="container">
        <CandidatePanel candidate={candidate} />
      </div>
    </div>
  ) : null;
}
