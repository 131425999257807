import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { maskCPF } from "./../../helpers/mask";
import api from "./../../config/api";
import { confirm } from "./../../components/Alert";
import PageHeader from "./../../components/PageHeader";
import Section from "./../../components/Section";
import WithMask from "./../../components/FormHook/WithMask";
import AttendanceContext from "./../../contexts/attendance";
import { WP_URL } from "./../../config/consts";

const HomeAttendanceStyled = styled.div`
  font-family: "Raleway", sans-serif;
  .form-search-candidates {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  .input {
    flex: 1;
    margin-right: 5rem;
  }

  .infos {
    margin-top: 2rem;

    ul {
      list-style: decimal;
    }

    h3 {
      color: black;
      text-transform: uppercase;
      font-size: 1.3rem;
    }
  }

  .candidate-user-card-infos {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    font-family: "Raleway", sans-serif;

    .user-picture {
      margin-right: 7rem;

      img {
        width: 180px;
        border-radius: 50%;
      }
    }

    .user-infos {
      .title {
        font-weight: bold;
        font-size: 1.4rem;
        text-transform: uppercase;
      }
      .user-infos-info {
        .strong {
          font-weight: bold;
          margin-right: 10px;
        }
      }
    }

    .warning-message {
      display: flex;
      align-items: center;
      font-weight: bold;
      background: #f0f0f0;
      padding: 1rem 2rem;
      border-radius: 10px;
      margin-top: 1rem;

      .icon {
        font-size: 2rem;
        margin-right: 1rem;
      }

      .resume-updated-message {
        color: #726d6d;
        margin-top: 4px;
        margin-bottom: 0;
      }
    }
    .actions {
      margin-top: 1rem;
      gap: 1rem;
      display: flex;
    }
  }

  .section-body {
    display: flex;
    .actions {
      flex: 1;
      border-right: 1px dotted #989898;
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .btn {
        width: 100%;
      }

      .kpis {
        margin-top: 1rem;
        p {
          font-size: 0.9rem;
          font-family: "Raleway", sans-serif;
          margin-bottom: 0px;
        }
      }
    }
    .text {
      flex: 3;
      padding: 1rem 2rem;
      p {
        font-size: 1rem;
        font-family: "Raleway", sans-serif;
      }
    }
  }
`;

const CandidateUserCardInfos = ({
  candidate,
  forceSyncCandidate,
  foundInVagas,
}) => {
  return (
    <div className="candidate-user-card-infos">
      <div className="user-picture">
        <img src={candidate.picture} alt="" />
      </div>
      <div className="user-infos">
        <span className="title">{candidate.full_name}</span>
        <div className="user-infos-info">
          <span className="strong">{candidate.email}</span>
          <span className="info"></span>
        </div>
        <div className="user-infos-info">
          <span className="strong">Idade:</span>
          <span className="info">{candidate.age_range}</span>
        </div>
        <div className="user-infos-info">
          <span className="strong">Experiência:</span>
          <span className="info">{candidate.work_history}</span>
        </div>
        <div className="user-infos-info">
          <span className="strong">Escolaridade:</span>
          <span className="info">{candidate.schooling_level?.name || "-"}</span>
        </div>
        <div className="user-infos-info">
          <span className="strong">Reside em SP?</span>
          <span className="info">{candidate.live_in_sp ? "Sim" : "Não"}</span>
        </div>
        <div className="user-infos-info">
          <span className="strong">CPF:</span>
          <span className="info">{maskCPF(candidate.cpf)}</span>
        </div>
        <div className="user-infos-info">
          <span className="strong">Raça:</span>
          <span className="info">{candidate.ethnic_group}</span>
        </div>
        <div className="user-infos-info">
          <span className="strong">Gênero:</span>
          <span className="info">{candidate.gender}</span>
        </div>
        <div className="warning-message">
          <div className="icon">
            <FontAwesomeIcon
              icon={
                candidate.resume_updated_at
                  ? "fa-solid fa-check"
                  : "fa-solid fa-triangle-exclamation"
              }
            />
          </div>
          <div className="message">
            {candidate.resume_updated_at
              ? `O Cidadão está cadastrado no Portal Cate, e tem currículo cadastrado no Portal Cate Vagas.`
              : `O Cidadão está cadastrado no Portal Cate, porém, ainda não tem
              currículo cadastrado no Portal Cate Vagas.`}
            {candidate.resume_updated_at ? (
              <p className="resume-update-message">
                Data da ultima atualização:{" "}
                {format(new Date(candidate.resume_updated_at), "dd/MM/yyyy")}
              </p>
            ) : null}
          </div>
        </div>
        <div className="actions">
          <Link
            to="/atendimento/candidato/curriculo"
            className="btn btn-primary"
          >
            {candidate.resume_updated_at
              ? `Atualizar Currículo`
              : `Cadastrar Currículo`}
          </Link>
          {candidate.resume_updated_at ? (
            <Link
              to="/atendimento/candidato/painel"
              className="btn btn-alternative"
            >
              Painel do candidato
            </Link>
          ) : null}
          <Link
            to={`/atendimento/registrar/${candidate.uuid}`}
            className="btn btn-secondary"
          >
            Registro dos atendimentos realizados
          </Link>
          {foundInVagas ? (
            <button
              onClick={() => forceSyncCandidate(candidate.cpf)}
              className="btn btn-secondary"
            >
              Atualizar dados com o Portal
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function Home() {
  const { register, handleSubmit, reset, control, getFieldState, watch } =
    useForm();
  const [foundCandidate, setFoundCandidate] = useState(null);
  const [foundInVagas, setFoundInVagas] = useState(true);
  const [kpis, setKpis] = useState(null);
  const { candidate, updateCandidate } = useContext(AttendanceContext);

  useEffect(() => {
    api.get(`jobs/attendance/kpis`).then((resp) => {
      setKpis(resp.data);
    });
  }, []);

  async function forceSyncCandidate(cpf) {
    try {
      let resp = await api.get(`attendance/candidates/sync`, {
        params: { cpf: cpf },
      });
      updateCandidate(resp.data);
    } catch (e) {
      toast.error("Erro ao sincronizar dados com o Portal.");
    }
  }

  async function onSubmit(data) {
    if (!data.cpf) {
      toast.error("Preencha o campo CPF.");
      return false;
    }

    try {
      let response = await api.get(`attendance/candidates`, { params: data });
      updateCandidate(response.data.candidate);
      setFoundInVagas(response.data.source == "VAGAS");
      setFoundCandidate("FOUND_IN_VAGAS");
    } catch (e) {
      setFoundCandidate("NOT_FOUND");
      await confirm({
        title: e.response.status === 422 ? "Cadastro incompleto" : "CPF não cadastrado",
        enableEscape: true,
        closeButton: true,
        actions: [
          <button className="btn btn-primary">Pesquisar novo CPF</button>,
          <button
            onClick={() => window.open(`${WP_URL}/cadastro-inicial`, `_blank`)}
            className="btn btn-alternative"
          >
            Cadastrar usuário no portal
          </button>,
        ],
        content: () => (
          <React.Fragment>
            <p>{e.response.data.message}</p>
            <span className="strong big">{data.cpf}</span>
          </React.Fragment>
        ),
      });
    }
  }

  function renderCandidateInfos() {
    switch (foundCandidate) {
      case "FOUND_IN_VAGAS":
        return (
          <React.Fragment>
            <CandidateUserCardInfos
              forceSyncCandidate={forceSyncCandidate}
              foundInVagas={foundInVagas}
              candidate={candidate}
            />
          </React.Fragment>
        );
        break;
      default:
        return (
          <React.Fragment>
            <h3>importante</h3>
            <p>
              Consulte o CPF para identificar se o cidadão já tem cadastro e
              qual o e-mail de acesso. Caso o cidadão não tenha cadastro no
              Portal Cate siga os seguintes passos:
            </p>
            <ul>
              <li>Cadastrar o cidadão no Portal Cate</li>
              <li>Buscar o cidadão no Portal Cate Vagas</li>
              <li>Cadastrar o currículo do cidadão</li>
            </ul>
          </React.Fragment>
        );
        break;
    }
  }

  return (
    <HomeAttendanceStyled>
      <PageHeader title={`Boas vindas!!`} />
      <div className="container">
        <div className="row">
          <Section title={`Atendimento Remoto`}>
            <div className="section-body">
              <div className="actions">
                <Link to={`/vagas`} className="btn btn-alternative">
                  Gerenciar vagas e candidatos
                </Link>
                <div className="kpis">
                  <p>{`${kpis?.active_jobs} Vagas abertas`}</p>
                  <p>{`${kpis?.total_applications_new} Candidatos aguardando análise`}</p>
                </div>
              </div>
              <div className="text">
                <p>
                  Se você está atendendode maneira remota, ou seja, à distância,
                  clique no botão ao lado para ver as vagas disponíveis,
                  gerenciar candidaturas ou convide um usuário para se
                  candidatar a uma vaga.
                </p>
              </div>
            </div>
          </Section>
        </div>
        <div className="row">
          <Section title="Atendimento Presencial/telefônico">
            <div className="container-attendance">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-search-candidates">
                  <div className="input">
                    <WithMask
                      label={`INFORME O CPF DO CANDIDATO:`}
                      name="cpf"
                      mask={`999.999.999-99`}
                      control={control}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary mt-3">
                    Pesquisar
                  </button>
                </div>
              </form>
              <div className="infos">{renderCandidateInfos()}</div>
            </div>
          </Section>
        </div>
      </div>
    </HomeAttendanceStyled>
  );
}

export default Home;
