import React from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";

const LoadingStyled = styled.div`
  .overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    width: 100%;
    z-index: 99999;
    overflow: hidden;
    display: none;
    justify-content: center;
    align-items: center;
  }
`;

export default function index() {
  return (
    <LoadingStyled>
      <div className="overlay" id={`loading-app-component`}>
        <ReactLoading
          type={`spin`}
          color={`#ffffff`}
          height={64}
          width={64}
        />
      </div>
    </LoadingStyled>
  );
}
