import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { validateCep } from "validations-br";
import { maskCEP } from "./../../helpers/mask";
import { cateSchema } from "./../../helpers/validatorSchemas";
import { useSearchParams, useNavigate } from "react-router-dom";
import api from "../../config/api";
import { UpdateStyeldContainer } from "./style";
import PageHeader from "./../../components/PageHeader";
import Input from "./../../components/FormHook/Input";
import SimpleSelect from "./../../components/FormHook/SimpleSelect";
import ReactSelect from "./../../components/FormHook/ReactSelect";

import { optionsRegion } from "./../Jobs/database";

export default function UpdateCate(props) {
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [leaders, setLeaders] = useState([]);
  const [skipCepValidation, setSkipCepValidation] = useState(false);
  const { register, handleSubmit, reset, watch, setValue, control } = useForm();
  const cepWatch = watch("cep", "value");

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let id = searchParams.get("id");
    if (id) {
      setSkipCepValidation(true);
      setId(id);
      api.get(`/cates/${id}`).then((resp) => {
        reset({
          name: resp.data.name,
          adress: resp.data.adress.adress,
          number: resp.data.adress.number,
          complement: resp.data.adress.complement,
          reference: resp.data.adress.reference,
          district: resp.data.adress.district,
          region: resp.data.adress.region,
          cep: resp.data.adress.cep,
          city: resp.data.adress.city,
          state: resp.data.adress.state,
          leader_id: resp.data.leader_id,
        });
      });
      api.get(`cates/${id}/leaders`).then((resp) => {
        setLeaders(resp.data);
      });
    }
  }, [reset]);

  useEffect(() => {
    setValue("cep", maskCEP(cepWatch));
    if (skipCepValidation) {
      return false;
    }
    if (cepWatch.length == 9) {
      axios.get(`https://viacep.com.br/ws/${cepWatch}/json/`).then((resp) => {
        if (resp.data.uf != "SP") {
          toast.error("CEP digitado nao pertence ao Estado de Sao paulo");
          return false;
        }
        setValue("adress", resp.data.logradouro);
        setValue("district", resp.data.bairro);
        setValue("city", resp.data.localidade);
      });
    }
  }, [cepWatch]);

  async function onSubmit(data) {
    let req = {
      name: data.name,
      leader_id: data.leader_id,
      adress: {
        ...data,
        city: "Sao Paulo",
      },
    };
    
    try {
      await cateSchema.validate(data);
    } catch (e) {
      toast.error(e.errors.join(", "));
      return false;
    }

    try {
      let response = id
        ? await api.put(`/cates/${id}`, req)
        : await api.post(`/cates`, req);
      toast.success(
        `Unidade Cate ${id ? `editada` : `cadastrada`} com sucesso`
      );
      navigate(`/unidades-cate`);
    } catch (e) {
      toast.error(e.response?.data?.message);
      console.log(e);
    }
  }

  return (
    <UpdateStyeldContainer>
      <PageHeader
        title={id ? `Editar unidade do CATE` : `Cadastrar unidade do CATE`}
        actions={[]}
      />
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input label={`Nome:`} name="name" register={register} />
          <div className="row">
            <div className="col-4">
              <Input label={`CEP:`} name="cep" register={register} />
            </div>
            <div className="col-4 skip-cep-container">
              <div className="form-check">
                <input
                  type="checkbox"
                  checked={skipCepValidation}
                  onChange={(e) => setSkipCepValidation(!skipCepValidation)}
                  className="form-check-input"
                  id="skip_cep_validation_check"
                />
                <label
                  className="form-check-label"
                  htmlFor="skip_cep_validation_check"
                >
                  Não validar o CEP (viacep)
                </label>
              </div>
            </div>
          </div>
          <Input
            label={`Endereço:`}
            disabled={!skipCepValidation}
            name="adress"
            register={register}
          />
          <div className="row">
            <div className="col-3">
              <Input label={`Número:`} name="number" register={register} />
            </div>
            <div className="col-3">
              <Input
                label={`Complemento:`}
                name="complement"
                register={register}
              />
            </div>
            <div className="col-6">
              <Input
                label={`Ponto de referência:`}
                name="reference"
                register={register}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Input
                label={`Bairro:`}
                disabled={!skipCepValidation}
                name="district"
                register={register}
              />
            </div>
            <div className="col-6">
              <ReactSelect
                name="region"
                control={control}
                options={optionsRegion}
                placeholder="Selecione uma região"
                label="Região:"
              />
            </div>
          </div>
          {id ? (
            <div className="row">
              <div className="col-12">
                <ReactSelect
                  name="leader_id"
                  control={control}
                  options={leaders}
                  placeholder="Selecione um líder da unidade"
                  label="Líder da unidade:"
                />
              </div>
            </div>
          ) : null}

          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary mt-3"
          >
            {id ? `Editar` : `Cadastrar`}
          </button>
        </form>
      </div>
    </UpdateStyeldContainer>
  );
}
