import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import ReactPaginate from "react-paginate";

const StyledPagination = styled.div`
  .pagination-container {
    display: flex;
    justify-content: center;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-top: 2rem;
    gap: 10px;

    li {
      cursor: pointer;
      color: #787878;
      border-radius: 0;
      background: white;
      border: 1px solid #d1c8c8;
      font-family: "Raleway", sans-serif;
      width: 30px;
      height: 30px;
      text-align: center;

      a {
        text-decoration: none;
        color: #787878;
      }
    }

    li.selected {
      border: 2px solid #787878;
      font-weight: bold;
    }
  }
`;

function Pagination({ page, total, onChange }) {
  function pageChange(page) {
    onChange(page.selected + 1);
  }

  return (
    <StyledPagination>
      <div className="pagination-container">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          nextAriaLabel="Próxima página"
          ariaLabelBuilder={(pageIndex, selectedPage) =>
            `Página ${pageIndex} ${
              selectedPage ? "é a página selecionada" : ""
            }`
          }
          extraAriaContext={"Paginação"}
          onPageChange={pageChange}
          pageRangeDisplayed={10}
          pageCount={total}
          previousLabel="<"
          previousAriaLabel="Página anterior"
          renderOnZeroPageCount={null}
        />
      </div>
    </StyledPagination>
  );
}

export default Pagination;
