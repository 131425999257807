import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LisPhonesContainer = styled.div``;

export default function MeetPhonesList({ meetPhones, handleDeletePhone }) {
  return meetPhones.length > 0 ? (
    <LisPhonesContainer>
      <div className="row schedules-container">
        <table>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Telefone</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {meetPhones.map((meetPhone, idx) => {
              return (
                <tr key={uuidv4()}>
                  <td>{meetPhone.name}</td>
                  <td>{meetPhone.phone}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleDeletePhone(idx)}
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />{" "}
                      Excluir
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </LisPhonesContainer>
  ) : null;
}
