import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CandidateContext from "./../../../contexts/candidate";
import {
  optionsContractType,
  optionsBenefits,
  optionsOtherRequirements,
  optionsPCD,
  optionsGender,
  optionsRegion,
  optionsCNH,
  optionsLanguage,
} from "./../../Jobs/database";
import { JobsContainer } from "./../../Jobs/style";
import JobListItem from "./../../Jobs/components/JobListItem";
import api from "./../../../config/api";
import Input from "./../../../components/FormHook/Input";
import WithMask from "./../../../components/FormHook/WithMask";
import Switch from "./../../../components/FormHook/Switch";
import ReactSelect from "./../../../components/FormHook/ReactSelect";
import OccupationSelect from "./../../../components/FormHook/OccupationSelect";
import PageHeader from "./../../../components/PageHeader";
import Pagination from "./../../../components/Pagination";

export default function Jobs({ role, attendanceMode = false }) {
  const { candidate } = useContext(CandidateContext);
  const { register, handleSubmit, reset, control, formState, watch } =
    useForm();
  const [jobs, setJobs] = useState([]);
  const [segments, setSegments] = useState([]);
  const [schoolingLevels, setSchoolingLevels] = useState([]);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [page, setPage] = useState(1);
  const [activeFilters, setActiveFilters] = useState(null);
  // TODO: Check what totalPages not used
  const [totalPages, setTotalPages] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const isCandidate = role === "candidate";

  const idFilterWatcher = watch("id");
  const idSineFilterWatcher = watch("sine_id");

  useEffect(async () => {
    if (!candidate) {
      return false;
    }
    getJobs();
    let segmentResponse = await api.get(`segments`);
    setSegments(segmentResponse.data);
    let schoolingResponse = await api.get(`schooling-levels`);
    setSchoolingLevels(schoolingResponse.data);
    let experienceResponse = await api.get(`experience-levels`);
    setExperienceLevels(experienceResponse.data);
  }, [candidate]);

  async function getJobs(nextPage = 1, filters = null) {
    if (filters) {
      if (!formState.dirtyFields.must_job_register) {
        delete filters.must_job_register;
      }
      filters.occupation_ids = filters.occupations?.map((occ) => occ.value);
      delete filters.occupations;
    }
    setPage(nextPage);
    setActiveFilters(filters);
    let reqParams = {
      page: nextPage,
      ...filters,
    };

    reqParams.status = "active";

    try {
      const response = await api.get(`candidates/jobs`, {
        params: reqParams,
      });
      setJobs(response.data.data);
      setTotalPages(response.data.last_page);
      setTotalJobs(response.data.total);
      setNotFound(response.data.data.length === 0);
    } catch (e) {
      setNotFound(true);
      setJobs([]);
      setTotalJobs(0);
      toast.error(e.response.data.message);
    }
    window.scrollTo(0, 0);
  }

  async function handleFilterJobs(data) {
    getJobs(1, data);
  }

  async function handleCleanFilter() {
    reset();
    reset({ occupations: null, cnpj: "" });
    getJobs(1, null);
  }

  return (
    <JobsContainer>
      <PageHeader title={`Vagas`} />
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <h4>BUSCA POR VAGAS</h4>
            <form onSubmit={handleSubmit(handleFilterJobs)}>
              <OccupationSelect
                isMulti
                control={control}
                name="occupations"
                label="Ocupação"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="segment_id"
                control={control}
                isMulti
                placeholder="Área de atuação/Segmento"
                options={segments.map((occ) => {
                  return { label: occ.name, value: occ.id };
                })}
                label="Área de atuação/Segmento:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />

              <ReactSelect
                name="schooling_level_id"
                control={control}
                options={schoolingLevels.map((sl) => {
                  return { label: sl.name, value: sl.id };
                })}
                placeholder="Selecione a escolaridade mínima"
                label="Escolaridade:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="experience_level_id"
                control={control}
                options={experienceLevels.map((el) => {
                  return { label: el.name, value: el.id };
                })}
                placeholder="Selecione a experiência mínima"
                label="Experiência:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <Switch
                name="must_job_register"
                label="Registro em Carteira"
                register={register}
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="benefits"
                placeholder="Selecione os benefícios oferecidos"
                isMulti
                control={control}
                options={optionsBenefits}
                label="Benefício:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="contract_type"
                placeholder="Selecione o tipo de contrato"
                control={control}
                options={optionsContractType}
                label="Tipo de contrato:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="job_type"
                placeholder="Selecione o tipo de trabalho"
                control={control}
                options={[
                  {
                    label: "Trabalho Presencial",
                    value: "presential",
                  },
                  {
                    label: "Trabalho Remoto",
                    value: "remote",
                  },
                  {
                    label: "Híbrido",
                    value: "hybrid",
                  },
                ]}
                label="Tipo de trabalho:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="work_regions"
                isMulti
                placeholder="Região do trabalho"
                control={control}
                options={optionsRegion}
                label="Região do trabalho:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.gender"
                isMulti
                placeholder="Gênero"
                control={control}
                options={optionsGender}
                label="Gênero:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.region"
                isMulti
                placeholder="Região"
                control={control}
                options={optionsRegion}
                label="Região (Requisito da vaga):"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.cnh"
                isMulti
                placeholder="Possui CNH"
                control={control}
                options={optionsCNH}
                label="Possui CNH:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.language"
                isMulti
                placeholder="Idioma"
                control={control}
                options={optionsLanguage}
                label="Idioma:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.pcd"
                isMulti
                placeholder="Pessoa com deficiência"
                control={control}
                options={optionsPCD}
                label="Pessoa com deficiência:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.others"
                isMulti
                placeholder="Candidatos preferenciais"
                control={control}
                options={optionsOtherRequirements}
                label="Candidatos preferenciais:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <div className="form-filters-actions">
                <button className="btn btn-primary" type="submit">
                  Aplicar filtros
                </button>
                <button
                  onClick={handleCleanFilter}
                  className="btn btn-secondary"
                  type="button"
                  role="button"
                >
                  Limpar
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-9 col-sm-12">
            <div className="title-jobs-count">
              <span>
                {notFound
                  ? `Nenhuma vaga encontrada para os filtros selecionados.`
                  : `${totalJobs} vagas encontradas`}
              </span>
            </div>
            {jobs.map((job) => (
              <JobListItem
                role={"candidate"}
                attendanceMode={attendanceMode}
                key={uuidv4()}
                {...job}
              />
            ))}
            <Pagination
              page={page}
              total={totalPages}
              onChange={(page) => getJobs(page, activeFilters)}
            />
          </div>
        </div>
      </div>
    </JobsContainer>
  );
}
