import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../config/api";

const CandidateContext = createContext({});

export const CandidateProvider = ({ children }) => {
  const [candidate, setCandidate] = useState(null);
  const navigator = useNavigate();

  useEffect(() => {
    async function loadUserData() {
      // Get user info from locastorage
      let token = await localStorage.getItem("@catevagas:candidate");
      if (token) {
        token = JSON.parse(token);
        api.defaults.headers.common["Authentication"] = token;
        try {
          let response = await api.get(`/candidates`);
          setCandidate(response.data);
        } catch (e) {
          signOut();
        }
      } else {
        navigator("/");
      }
    }
    loadUserData();
  }, []);

  async function getCandidate(token) {
    try {
      api.defaults.headers.common["Authentication"] = token;
      await localStorage.setItem("@catevagas:candidate", JSON.stringify(token));
      let response = await api.get(`/candidates`);
      setCandidate(response.data);
    } catch (e) {
      console.log(e);
      // signOut();
    }
  }

  async function updateCandidateData(candidate) {
    setCandidate(candidate);
  }

  async function signOut() {
    await localStorage.clear();
    setCandidate(null);
    // window.location.href = "/candidato/sessao-expirada";
  }

  return (
    <CandidateContext.Provider
      value={{
        uuid: null,
        getCandidate,
        candidate,
        signOut,
        updateCandidateData,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};

export default CandidateContext;
