export const currencyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const currencyToNumber = (currency) => {
    return currency ? Number(currency.replace(/[^0-9,-]+/g, "").replace(/,/g, ".")) : null;
};

export const updateCurrencyFormat = (currency) => {
  return currencyFormatter.format(currency).slice(3);
};
