import React, { useState, useEffect } from "react";
import { CompanyDetailsContainer } from "./style";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { maskPhone, maskCNPJ } from "../../helpers/mask";
import PageHeader from "./../../components/PageHeader";
import api from "../../config/api";
import JobListItemDetailed from "./../Jobs/components/JobListItemDetailed";
import Pagination from "./../../components/Pagination";

export default function CompanyDetails() {
  let [searchParams] = useSearchParams();
  let navigation = useNavigate();
  const [id, setId] = useState(null);
  const [company, setCompany] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [mainResponsable, setMainResponsable] = useState(null);
  useEffect(() => {
    let id = searchParams.get("id");
    if (id) {
      setId(id);
      api
        .get(`/companies/${id}`)
        .then((resp) => {
          setCompany(resp.data);
          setMainResponsable(
            resp.data.responsables.map((responsable) => {
              return responsable.primary ? responsable : null;
            })[0]
          );
        })
        .catch((e) => {
          navigation(`/empresas`);
        });
    }
  }, []);

  useEffect(() => {
    if (company) {
      getJobs();
    }
  }, [company]);

  async function getJobs(nextPage = 1) {
    if (!company) {
      return false;
    }
    let response = await api.get(
      `/jobs?page=${nextPage}&company_id=${company?.id}`
    );
    setJobs(response.data.data);
    setPage(nextPage);
    setTotalPages(response.data.last_page);
  }

  return company ? (
    <CompanyDetailsContainer>
      <PageHeader
        title={`Visão detalhada da empresa`}
        actions={[<Link to={`/empresas`}>Ver todas as empresas</Link>]}
      />
      <div className="container">
        <div className="row">
          <div className="company-header">
            <h2>{company.name}</h2>
            <div className="company-header-actions">
              <Link to={`/vagas/editar`} className="btn btn-primary">
                Criar vaga
              </Link>
              <Link
                to={`/empresas/editar?id=${company.id}`}
                className="btn btn-secondary"
              >
                Editar dados da empresa
              </Link>
            </div>
          </div>
          <hr />
          <div className="company-body">
            <div className="company-details">
              <div className="company-details-item">
                <b>Ramo de atividade:</b> {company.activity?.name}
              </div>
              <div className="company-details-item">
                <b>CNPJ:</b> {maskCNPJ(company.cnpj)}
              </div>
              <div className="company-details-item">
                <b>Email:</b> {company.email}
              </div>
              <div className="company-details-item">
                <b>Telefone:</b> {maskPhone(company.phone)}
              </div>
              <div className="company-details-item">
                <b>Endereço:</b>{" "}
                {`${company.adress?.adress}, ${company.adress?.number} - ${company.adress?.district}`}
              </div>
            </div>
            <div className="responsable-details">
              <h6 className="secondary">RESPONSÁVEL PRINCIPAL</h6>
              <div className="responsable-details-item">
                <b>Nome:</b> {mainResponsable?.full_name}
              </div>
              <div className="responsable-details-item">
                <b>Cargo:</b> {mainResponsable?.job_role}
              </div>
              <div className="responsable-details-item">
                <b>Email:</b> {mainResponsable?.email}
              </div>
              <div className="responsable-details-item">
                <b>Telefone:</b> {maskPhone(mainResponsable?.phone)}
              </div>
              <div className="responsable-details-item">
                <b>Celular:</b> {maskPhone(mainResponsable?.cell)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {jobs.map((job) => (
            <JobListItemDetailed key={uuidv4()} {...job} />
          ))}
          <Pagination
            page={page}
            total={totalPages}
            onChange={(page) => getJobs(page)}
          />
        </div>
      </div>
    </CompanyDetailsContainer>
  ) : null;
}
