import React, { useEffect } from "react";
import { HashRouter, Routes, Route, Outlet } from "react-router-dom";
import { CandidateProvider } from "./../contexts/candidate";
import Signin from "./../pages/Signin";
import ResetPassword from "./../pages/ResetPassword";
import ResetPasswordUpdate from "./../pages/ResetPassword/update";
import HeaderCandidates from "./../components/HeaderCandidates";
import HomeCandidate from "../pages/Candidate/Home";
import ResumeCandidate from "../pages/Candidate/Resume";
import AuthCandidate from "../pages/Candidate/Auth";
import Jobs from "../pages/Candidate/Jobs/";
import JobDetails from "../pages/Candidate/Jobs/details";

function CandidateBaseRoute(props) {
  return (
    <div>
      <CandidateProvider>
        <HeaderCandidates />
        <Outlet />
      </CandidateProvider>
    </div>
  );
}

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/resetar-senha" element={<ResetPassword />} />
        <Route path="/resetar-senha/:uuid" element={<ResetPasswordUpdate />} />
        <Route path="candidato" element={<CandidateBaseRoute />}>
          <Route index element={<HomeCandidate />} />
          <Route path="curriculo" element={<ResumeCandidate />} />
          <Route path="sessao-expirada" element={<AuthCandidate />} />
          <Route path="vagas">
            <Route index element={<Jobs role={`candidate`} />} />
            <Route path="detalhes" element={<JobDetails />} />
          </Route>
        </Route>
      </Routes>
      <div style={{ height: "100px" }}></div>
    </HashRouter>
  );
}
