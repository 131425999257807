import React from "react";
import styled from "styled-components";

// import { Container } from './styles';

const FiltersSidebarStyled = styled.div`
  button {
    margin-top: 1rem;
    width: 100%;
  }
  h4 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
`;

function FiltersSidebar({ title, children }) {
  return (
    <FiltersSidebarStyled>
      <h4>{title}</h4>
      {children}
    </FiltersSidebarStyled>
  );
}

export default FiltersSidebar;
