import React, { useContext } from "react";
import styled from "styled-components";
import CompaniesReport from "./models/companies";
import ResumesReport from "./models/resumes";
import JobsReport from "./models/jobs";
import BaseReport from "./models/base";
import JobApplicationsReport from "./models/jobApplications";
import AttendanceReport from "./models/attendances";
import BKReport from "./models/bk";
import ClosedJobCandidates from "./models/closedJobCandidates";
import FCDA from "./models/fcda";
import CompanyAttendancesReport from "./models/companyAttendence";
import NoregisterAttendanceReport from "./models/noregisterAttendance";
import PageHeader from "./../../components/PageHeader";
import AuthContext from "../../contexts/auth";

// import { Container } from './styles';

const DAYS_RANGE_REPORT = 45;

const ReportsContainerStyled = styled.div`
  .reports-container {
    margin-top: 3rem;
  }
`;

function Reports() {
  const { user } = useContext(AuthContext);
  return (
    <ReportsContainerStyled>
      <PageHeader title={`Relatórios`} />
      <div className="container">
        <div className="row">
          <div className="reports-container">
            {/* <BaseReport /> */}
            <CompaniesReport />
            <ResumesReport />
            <JobsReport />
            <ClosedJobCandidates />
            <JobApplicationsReport />
            {user?.auth?.user?.role == "acquirer" ? null : (
              <React.Fragment>
                <FCDA />
                <AttendanceReport />
                <BKReport />
                <CompanyAttendancesReport />
                <NoregisterAttendanceReport />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </ReportsContainerStyled>
  );
}

export default Reports;
