import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import api from "../../../../config/api";
import CandidateJobListItem from "./item";
import Divider from "./../../../../components/Divider";
import ReactSelect from "./../../../../components/FormHook/ReactSelect";
import WithMask from "./../../../../components/FormHook/WithMask";
import Switch from "./../../../../components/FormHook/Switch";
import Pagination from "./../../../../components/Pagination";
import { confirm } from "./../../../../components/Confirmation";
import { toast } from "react-toastify";

import {
  optionsOtherRequirements,
  optionsPCD,
  optionsGender,
  optionsRegion,
  optionsCNH,
  optionsLanguage,
  optionsEthnicGroup,
} from "./../../database";
import { Link } from "react-router-dom";

const CandidateJobListStyled = styled.div`
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;

  h3 {
    color: white;
    font-weight: 800;
    font-size: 2rem;
    text-transform: uppercase;
  }

  .infos {
    font-family: "Raleway", sans-serif;
  }
  .candidate-job-list {
    gap: 10px;
  }
  .card-add-candidate {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    background: white;
    gap: 1rem;
    font-family: "Raleway", sans-serif;
    border-radius: 10px;

    .form-switch {
      align-items: center;
    }

    .form-label {
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 800;
    }

    .strong {
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 800;
    }
  }
`;

function CandidateJobList({ jobId, job }) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    getFieldState,
    watch,
    formState,
  } = useForm();
  const [applications, setApplications] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
  const [currentParams, setCurrentParams] = useState({});
  const [currentActiveSearch, setCurrentActiveSearch] = useState(false);
  const activeSearch = watch("active_search", false);

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    if (currentActiveSearch !== activeSearch) {
      setCurrentParams({});
      handleSearch(1, {});
      setCurrentActiveSearch(activeSearch);
    }
  }, [activeSearch]);

  async function handleSearch(page = 1, params = {}) {
    params.page = page;
    let resp = await api.get(
      activeSearch
        ? `/jobs/${jobId}/active-search`
        : `/jobs/${jobId}/applications`,
      { params }
    );
    setApplications(resp.data.data);
    setLastPage(resp.data.lastPage);
    setPage(page);
  }

  async function handleFilter(data) {
    if (!data.just_with_experience) {
      delete data.just_with_experience;
    }
    setCurrentParams(data);
    handleSearch(1, data);
  }

  async function handleCleanFilters() {
    reset();
    handleSearch(1);
  }

  async function handleMassiveReprove() {
    if (
      await confirm(
        "Deseja reprovar todos os candidatos dessa vaga?",
        "Sim",
        "Não",
        {
          title: "Reprovar todos?",
        }
      )
    ) {
      await api.put(`jobs/${jobId}/applications/reprove/massive`, {});
      toast.success(
        `Todos os candidatos foram reprovados, nenhum deles será notificado!`
      );
      handleSearch(1);
    }
  }

  return (
    <CandidateJobListStyled
      style={{ background: activeSearch ? "#FF6600" : "#0D3691" }}
    >
      <div className="row">
        <h3>
          {activeSearch
            ? `busca ativa de candidatos`
            : `gerenciar candidaturas`}
        </h3>
      </div>
      <Divider />

      <form onSubmit={handleSubmit(handleFilter)}>
        <div className="row">
          <div className="col-8">
            {activeSearch ? null : (
              <p className="infos">
                Nesta área você pode filtrar as candidaturas de acordo com o
                perfil da vaga e realizar a aprovação ou não dos candidatos.
              </p>
            )}

            <div className="row">
              <div className="col-4">
                <ReactSelect
                  name="pcd"
                  isMulti
                  placeholder="Pessoa com deficiência"
                  control={control}
                  options={optionsPCD}
                  label="Pessoa com deficiência:"
                />
              </div>
              <div className="col-4">
                <ReactSelect
                  name="gender"
                  isMulti
                  placeholder="Gênero"
                  control={control}
                  options={optionsGender}
                  label="Gênero:"
                />
              </div>
              <div className="col-4">
                <ReactSelect
                  name="region"
                  isMulti
                  placeholder="Região"
                  control={control}
                  options={optionsRegion}
                  label="Região:"
                />
              </div>
              <div className="col-4">
                <ReactSelect
                  name="cnh"
                  isMulti
                  placeholder="Possui CNH"
                  control={control}
                  options={optionsCNH}
                  label="Possui CNH:"
                />
              </div>
              <div className="col-4">
                <ReactSelect
                  name="language"
                  isMulti
                  placeholder="Fala outro idioma"
                  control={control}
                  options={optionsLanguage}
                  label="Fala outro idioma:"
                />
              </div>
              <div className="col-4">
                <ReactSelect
                  name="others"
                  isMulti
                  placeholder="Outros"
                  control={control}
                  options={optionsOtherRequirements}
                  label="Outros:"
                />
              </div>
              {activeSearch ? null : (
                <div className="col-4">
                  <ReactSelect
                    name="status"
                    placeholder="Status"
                    control={control}
                    options={[
                      { label: "Aprovados", value: "APPROVED" },
                      { label: "Reprovados", value: "REJECTED" },
                      { label: "Reprovados em massa", value: "MASSIVE_REJECT" },
                      { label: "Aguardando análise", value: "NEW" },
                      { label: "Convidados", value: "INVITED" },
                      { label: "Todos", value: "ALL" },
                    ]}
                    label="Status:"
                  />
                </div>
              )}
              <div className="col-4">
                <WithMask
                  name="cpf"
                  placeholder="Digite o CPF"
                  mask={`999.999.999-99`}
                  control={control}
                  label="CPF do candidato:"
                />
              </div>
              <div className="col-4">
                <ReactSelect
                  name="ethnic_group"
                  isMulti
                  placeholder="Raça"
                  control={control}
                  options={optionsEthnicGroup}
                  label="Raça:"
                />
              </div>
              <div className="col-6">
                <Switch
                  label="Somente com experiência na ocupação"
                  name="just_with_experience"
                  register={register}
                />
              </div>
              <div
                className="col-6 d-flex align-items-end mt-4"
                style={{ gap: "10px" }}
              >
                <button
                  type="submit"
                  className={`btn ${
                    activeSearch ? "btn-primary" : "btn-alternative"
                  }`}
                >
                  Filtrar
                </button>
                <button
                  type="button"
                  onClick={() => handleCleanFilters()}
                  className={`btn btn-white`}
                >
                  Limpar
                </button>
                <button
                  type="button"
                  onClick={() => handleMassiveReprove()}
                  className={`btn btn-secondary`}
                >
                  Reprovar todos
                </button>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div
              className="card-add-candidate"
              style={{
                color: !activeSearch ? "#FF6600" : "#0D3691",
                border: `2px solid ${!activeSearch ? "#FF6600" : "#0D3691"}`,
              }}
            >
              <span className="strong">
                Não encontrou candidatos suficientes?
              </span>
              <span>
                Faça uma busca ativa e convide candidatos que nao se
                candidataram ainda a essa vaga. Você tambem pode buscar
                candidatos pelo CPF.
              </span>
              <Switch
                label="Ativar busca avançada"
                name="active_search"
                register={register}
              />
            </div>
          </div>
        </div>
      </form>

      <div className="row mt-5 candidate-job-list">
        {applications.length === 0 ? (
          <span>Nenhum candidato para essa vaga.</span>
        ) : null}
        {applications.map((application) => {
          return (
            <CandidateJobListItem
              activeSearch={activeSearch}
              updateList={() => handleSearch(page, currentParams)}
              jobId={jobId}
              job={job}
              key={uuidv4()}
              {...application}
            />
          );
        })}
        <Pagination
          page={page}
          total={lastPage}
          onChange={(page) => handleSearch(page, currentParams)}
        />
      </div>
    </CandidateJobListStyled>
  );
}

export default CandidateJobList;
