import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const SectionStyled = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  .header {
    position: absolute;
    margin-top: -2rem;
    font-size: 1.25rem;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    background: #105ab5;
    color: white;
    padding: 0.3rem 2rem;
  }

  .section-container {
    border: 1px solid #ccc;
    padding: 1rem 3rem;
  }

  .section-content {
    padding-top: 2rem;
  }

  @media only screen and (max-width: 600px) {
    .section-container {
      padding: 1rem;
    }
  }
`;

export default function Section({ children, title }) {
  const id = uuidv4();
  return (
    <SectionStyled>
      <div role="group" aria-labelledby={id} className="section-container">
        <h2 id={id} className="header">
          {title}
        </h2>
        <div className="section-content">{children}</div>
      </div>
    </SectionStyled>
  );
}
