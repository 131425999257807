import React, { useEffect, useState, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "./../../config/api";
import JobListItemDetailed from "./components/JobListItemDetailed";
import PageHeader from "./../../components/PageHeader";
import CandidateJobList from "./components/CandidateJobList";
import JobDash from "./components/JobDash";

const JobDetailsStyled = styled.div`
  .go-back-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .card-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #0d3691;
    color: white;
    width: 60%;
    margin: 0 auto;

    .btn-primary {
      background-color: white;
      color: #0d3691;
    }

    .card-action-text {
      flex: 2;
      padding: 0 1rem;
    }

    .card-action-actions {
      flex: 1;
      padding: 0 1rem;
      text-align: center;

      a.secondary-action {
        text-decoration: none;
        color: white;
        font-style: italic;
        font-size: 0.8rem;
      }
    }
  }
`;

function Details({ managingJob }) {
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const [job, setJob] = useState(null);
  const [id, setId] = useState(null);

  useEffect(async () => {
    let id = searchParams.get("id");
    if (id) {
      setId(id);
      let resp = await api.get(`/jobs/${id}`);
      setJob(resp.data);
      if (["active", "closed", "stopped"].indexOf(resp.data.status) === -1) {
        navigator(`/vagas`);
      }
    }
  }, []);

  return job ? (
    <JobDetailsStyled>
      <PageHeader title={`Detalhes da vaga`} />
      <div className="container">
        <JobListItemDetailed managingJob={managingJob} {...job} />
        <JobDash
          availableJobs={job?.available_jobs}
          availableSeats={job?.available_seats}
          seats={job?.schedule_seats}
          jobId={job.id}
        />
        <CandidateJobList job={job} jobId={job.id} />
      </div>
    </JobDetailsStyled>
  ) : null;
}

export default Details;
