import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "../../../../components/FormHook/ReactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TranslateJobRequirementKey,
  TranslateJobRequirementOption,
} from "./../../../../helpers/translateFields";

import {
  optionsRequirements,
  professionalRequirements,
} from "./../../database";
import { toast } from "react-toastify";

const RequirementsContainer = styled.div`
  .list {
    display: flex;
    justify-content: space-between;

    .required,
    .desired {
      flex: 1;
      width: 50%;
    }

    .req-list {
      display: flex;
      gap: 10px;
      flex-flow: wrap;
      font-family: "Raleway", sans-serif;

      .req {
        display: flex;
        padding: 0.5rem 1rem;
        background: #f0f0f0;
        border-radius: 10px;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        min-width: 160px;

        .title {
          font-size: 0.8rem;
          font-weight: bold;
        }
      }
    }
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
  }

  .form {
    margin-top: 2rem;
  }
`;

const ReqItem = ({ title, value, action, disabled = false }) => {
  return (
    <div className="req">
      <div className="content">
        <div className="title">{TranslateJobRequirementKey[title]}</div>
        <div className="value">{TranslateJobRequirementOption[value]}</div>
      </div>
      {!disabled ? (
        <div className="action">
          <FontAwesomeIcon
            icon="fa-solid fa-xmark"
            className="read-notification"
            onClick={() => action(title, value)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default function Requirements({
  requirements,
  control,
  watch,
  updateRequirements,
  getValues,
  setValue,
  disabled = false,
}) {
  const [currentOptions, setCurrentOptions] = useState([]);
  const [optionsRequirementsFiltered, setOptionsRequirementsFiltered] =
    useState([]);
  const keyWatch = watch("requirements.key");
  const optionWatch = watch("requirements.option");
  const isProfessionalRequirements = watch("requirement_type");

  function handleAdd() {
    let req = {
      key: getValues("requirements.key"),
      option: getValues("requirements.option"),
      value: getValues("requirements.value"),
    };

    let alreadyExists = requirements.filter((requirement) => {
      if (requirement.key === req.key && requirement.option === req.option) {
        return requirement;
      }
    });

    if (alreadyExists.length > 0) {
      toast.error("Requisitos da vaga: Requisito já adicionado!");
      return false;
    }

    if (!req.key) {
      toast.error("Requisitos da vaga: Selecione uma categoria!");
      return false;
    }

    if (!req.option) {
      toast.error("Requisitos da vaga: Selecione uma opção!");
      return false;
    }

    if (!req.value) {
      toast.error("Requisitos da vaga: Selecione se obrigatório ou desejado!");
      return false;
    }

    let updatedReqs = requirements;
    updatedReqs.push(req);
    updateRequirements(updatedReqs);
    setValue("requirements.key", null);
    setValue("requirements.option", null);
    setValue("requirements.value", null);
  }

  function handleDelete(key, option) {
    let updatedReqs = requirements.filter((req) => {
      if (!(req.key === key && req.option === option)) {
        return req;
      }
    });
    updateRequirements(updatedReqs);
  }

  useEffect(() => {
    if (keyWatch) {
      optionsRequirements.map((req) => {
        if (req.key === keyWatch) {
          setCurrentOptions(req.options);
        }
      });
    }
  }, [keyWatch]);

  useEffect(() => {
    let newOptions = [];
    optionsRequirements.map((req) => {
      if (isProfessionalRequirements === 1) {
        if (professionalRequirements.indexOf(req.key) !== -1) {
          newOptions.push(req);
        }
      } else {
        if (professionalRequirements.indexOf(req.key) === -1) {
          newOptions.push(req);
        }
      }
    });
    setOptionsRequirementsFiltered(newOptions);
  }, [isProfessionalRequirements]);

  return (
    <RequirementsContainer>
      <div className="list">
        <div className="required">
          <h6>Profissionais Obrigatórios:</h6>
          <div className="req-list">
            {requirements.filter((req) => req.value === "required").length ===
            0 ? (
              <p>Nenhum adicionado.</p>
            ) : null}
            {requirements.map((requirement) => {
              if (
                requirement.value === "required" &&
                professionalRequirements.indexOf(requirement.key) !== -1
              ) {
                return (
                  <ReqItem
                    key={`requirement_item_required_${requirement.key}`}
                    title={requirement.key}
                    value={requirement.option}
                    action={handleDelete}
                    disabled={disabled}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="desired">
          <h6>Profissionais Diferenciais:</h6>
          <div className="req-list">
            {requirements.filter((req) => req.value !== "required").length ===
            0 ? (
              <p>Nenhum adicionado.</p>
            ) : null}
            {requirements.map((requirement) => {
              if (
                requirement.value !== "required" &&
                professionalRequirements.indexOf(requirement.key) !== -1
              ) {
                return (
                  <ReqItem
                    key={`requirement_item_desired_${requirement.key}`}
                    title={requirement.key}
                    value={requirement.option}
                    action={handleDelete}
                    disabled={disabled}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="required">
          <h6>Pessoais Obrigatórios:</h6>
          <div className="req-list">
            {requirements.filter((req) => req.value === "required").length ===
            0 ? (
              <p>Nenhum adicionado.</p>
            ) : null}
            {requirements.map((requirement) => {
              if (
                requirement.value === "required" &&
                professionalRequirements.indexOf(requirement.key) === -1
              ) {
                return (
                  <ReqItem
                    key={`requirement_item_required_${requirement.key}`}
                    title={requirement.key}
                    value={requirement.option}
                    action={handleDelete}
                    disabled={disabled}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="desired">
          <h6>Pessoais Diferenciais:</h6>
          <div className="req-list">
            {requirements.filter((req) => req.value !== "required").length ===
            0 ? (
              <p>Nenhum adicionado.</p>
            ) : null}
            {requirements.map((requirement) => {
              if (
                requirement.value !== "required" &&
                professionalRequirements.indexOf(requirement.key) === -1
              ) {
                return (
                  <ReqItem
                    key={`requirement_item_desired_${requirement.key}`}
                    title={requirement.key}
                    value={requirement.option}
                    action={handleDelete}
                    disabled={disabled}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
      {disabled ? null : (
        <div className="form">
          <h6>Adicionar requisitos:</h6>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <ReactSelect
                name={`requirement_type`}
                control={control}
                label={"Tipo:"}
                placeholder={"Selecione o tipo de requisito"}
                options={[
                  { label: "Profissional", value: 1 },
                  { label: "Pessoal", value: 2 },
                ]}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <ReactSelect
                name={`requirements.key`}
                control={control}
                disabled={!isProfessionalRequirements}
                label={"Categoria:"}
                placeholder={"Selecione a categoria do requisito"}
                options={optionsRequirementsFiltered.map((req) => {
                  return { value: req.key, label: req.label };
                })}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <ReactSelect
                name={`requirements.option`}
                disabled={!keyWatch}
                control={control}
                label={"Requisito:"}
                placeholder={"Selecione a categoria do requisito"}
                options={currentOptions}
              />
            </div>
            <div className="col-md-3 col-sm-12">
              <ReactSelect
                name={`requirements.value`}
                disabled={!optionWatch}
                control={control}
                label={"Obrigatório ou Diferencial?"}
                placeholder={"Selecione"}
                options={[
                  { label: "Obrigatório", value: "required" },
                  { label: "Diferencial", value: "desired" },
                ]}
              />
            </div>
            <div className="col-md-12 col-sm-12 btn-container mt-4">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={handleAdd}
              >
                Adicionar Requisito
              </button>
            </div>
          </div>
        </div>
      )}
    </RequirementsContainer>
  );
}
