import React from "react";
import ReactSelect from "./../../../../components/FormHook/ReactSelect";
import Input from "./../../../../components/FormHook/Input";
import WithMask from "./../../../../components/FormHook/WithMask";
import Switch from "./../../../../components/FormHook/Switch";
import Currency from "./../../../../components/FormHook/Currency";
import { useEffect } from "react";

export default function Salary({
  canUpdate,
  control,
  register,
  errors,
  watch,
  setValue,
  dirtyFields,
}) {
  const typeWatch = watch("salary_type");
  return (
    <React.Fragment>
      <div className="col-md-3 col-sm-12">
        <ReactSelect
          name="salary_type"
          disabled={!canUpdate}
          requiredForJob
          placeholder="Selecione o tipo de remuneração"
          control={control}
          options={[
            {
              label: "R$ Mês",
              value: "month",
            },
            {
              label: "R$ Dia",
              value: "day",
            },
            {
              label: "R$ Hora",
              value: "hour",
            },
            {
              label: "% Comissão",
              value: "comission",
            },
            {
              label: "A combinar",
              value: "combine",
            },
          ]}
          label="Tipo de remuneração:"
        />
      </div>
      <div className="col-md-3 col-sm-12">
        {typeWatch && typeWatch !== "combine" ? (
          typeWatch === "comission" ? (
            <Input
              name="salary"
              register={register}
              ignoreNumberReturn
              type="number"
              step={1}
              max={100}
              label={`Remuneração:`}
              disabled={!canUpdate}
              requiredForJob
            />
          ) : (
            <Currency
              name="salary"
              control={control}
              label={`Remuneração:`}
              disabled={!canUpdate}
              requiredForJob
            />
          )
        ) : null}
      </div>
      <div className="col-md-4 col-sm-12">
        {typeWatch && typeWatch !== "combine" ? (
          <Switch
            register={register}
            disabled={!canUpdate}
            name="hidden_salary"
            requiredForJob
            label="Esconder remueração na exibição?"
          />
        ) : null}
      </div>
    </React.Fragment>
  );
}
