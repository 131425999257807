import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { NIL } from "uuid";
import api from "../config/api";
// import { toast } from "react-toastify";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadUserData() {
      // let query = new URLSearchParams(document.location.search);
      // // let query = new URLSearchParams(window.location);
      // let to = query.get("to");
      // let target = query.get("target");
      // console.log(to);
      // console.log(target);
      // Get user info from locastorage
      let authData = await localStorage.getItem("@catevagas:auth");
      if (authData) {
        authData = JSON.parse(authData);
        if (!authData.user) {
          signOut();
          return false;
        }
        api.defaults.headers.Authorization = `Bearer ${authData.token}`;
        setUser({
          auth: authData,
        });
      }
    }
    loadUserData();
  }, []);

  async function setUserStatus() {}
  async function signIn(email, password, consultantMode = false) {
    try {
      const response = await api.post(
        `/login${consultantMode ? `?consultant-mode=1` : ``}`,
        {
          email,
          password,
        }
      );
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      await localStorage.setItem(
        "@catevagas:auth",
        JSON.stringify(response.data)
      );
      setUser({
        auth: response.data,
      });
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  async function signOut() {
    api.defaults.headers.Authorization = "";
    setUser(null);
    await localStorage.clear();
    window.location.href =
      process.env.NODE_ENV === "production" ? "/cate-vagas-admin" : "/";
  }

  async function signUp(body) {}

  return loading ? null : (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        signOut,
        signUp,
        setUserStatus,
        role: user?.auth?.user?.role,
        isManager:
          user?.auth?.user?.role === "manager" ||
          user?.auth?.user?.role === "acquirer",
        isConsultant: user?.auth?.user?.role === "consultant",
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
