import styled from "styled-components";

export const CandidateApplicationsStyled = styled.div`
  .candidate-applications-container {
    margin: 30px auto;
    width: 80%;
  }

  .candidate-applications-legend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .candidate-applications-legend-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      img {
        width: 40%;
        margin-bottom: 10px;
      }

      span {
        font-family: "Raleway", sans-serif;
        font-size: 1rem;
        color: #606b5b;
        text-align: center;
      }
    }
  }

  .candidate-applications-list {
    max-height: 400px;
    overflow-y: auto;
  }

  .candidate-applications-bg {
    background: #f0f0f0;
    padding: 2rem 1rem;
    margin-top: 20px;
    .candidate-applications-card {
      background: white;
      padding: 2rem 1rem;

      .candidate-applications-card-header {
        font-family: "Raleway", sans-serif;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        color: black;
      }

      .candidate-applications-card-filter {
        display: flex;
        justify-content: space-between;
        padding: 2rem 0.5rem 1rem 0.5rem;
        align-items: center;

        .label {
          margin-right: 1rem;
          text-align: left;
        }

        .input {
          select {
            border-radius: 0;
            border-color: #a8a8a8;
            text-transform: uppercase;
            font-size: 0.8rem;

            * {
              text-transform: uppercase;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }

  .label {
    font-family: "Raleway", sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: #606b5b;
    margin: 0 auto;
    display: block;
  }

  .candidate-applications-list-item {
    display: flex;
    font-family: "Raleway", sans-serif;
    margin: 15px 0;
    border-bottom: 1px dashed #ccc3c3;

    .candidate-applications-list-item-icon {
      flex: 1;
      img {
        width: 80%;
      }
    }

    .candidate-applications-list-item-content {
      flex: 10;

      .candidate-applications-list-item-content-header {
        padding: 0.5rem 1rem;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .candidate-applications-list-item-content-header-btn {
          background-color: transparent;
          border: none;

          img {
            width: 32px;
          }
        }
      }

      .candidate-applications-list-item-content-info {
        padding: 0.5rem 1rem;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .candidate-applications-container {
      width: 100%;
    }

    .candidate-applications-legend .candidate-applications-legend-item span {
      font-size: 0.6rem;
    }

    .candidate-applications-bg
      .candidate-applications-card
      .candidate-applications-card-header {
      font-size: 1.2rem;
    }

    .candidate-applications-list-item {
      font-size: 0.8rem;
    }

    .candidate-applications-list-item
      .candidate-applications-list-item-content
      .candidate-applications-list-item-content-header
      .candidate-applications-list-item-content-header-btn
      img {
      width: 22px;
    }
  }
`;
