import React, { useState } from "react";
import styled from "styled-components";
import FileDownload from "js-file-download";
import slugify from "slugify";
import ModalResume from "../../components/ModalResume";
import api from "./../../config/api";

// import { Container } from './styles';

const CandidatesItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddbdb;
  align-items: center;
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;

  .infos-container {
    display: flex;
    flex: 1;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    color: #444;
    font-size: 0.8rem;
    flex-direction: column;
  }

  .match-icons-container {
    display: flex;
    gap: 12px;

    img {
      width: 30px;
    }
  }

  .actions-container {
    display: flex;
    gap: 10px;
    flex: 1;
    justify-content: end;
  }
`;

function CandidatesItem({ candidate }) {
  const [openResume, setOpenResume] = useState(false);

  async function handleDownloadResume(uuid, full_name) {
    api({
      url: `attendance/candidates/resume/pdf?uuid=${uuid}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      FileDownload(
        response.data,
        `currículo_${slugify(full_name, {
          replacement: "_", // replace spaces with replacement character, defaults to `-`
          lower: true, // convert to lower case, defaults to `false`
          trim: true, // trim leading and trailing replacement chars, defaults to `true`
        })}.pdf`
      );
    });
  }

  return (
    <div key={`candidate_item_${candidate.uuid}`}>
      <CandidatesItemStyled>
        <ModalResume
          open={openResume}
          onClose={() => setOpenResume(false)}
          resume={candidate}
        />
        <div className="infos-container">
          {candidate.full_name}
          {candidate.resume_updated_at ? null : (
            <span className="badge bg-warning">
              Usuário cadastrado no portal e integrado ao sistema de vagas,
              porém, sem currículo cadastrado.
            </span>
          )}
        </div>
        <div className="actions-container">
          {candidate.resume_updated_at ? (
            <React.Fragment>
              <button
                className="btn btn-primary"
                onClick={() => setOpenResume(true)}
              >
                Ver currículo
              </button>
              <button
                className="btn btn-alternative"
                onClick={() => handleDownloadResume(candidate.uuid, candidate.full_name)}
              >
                Baixar PDF
              </button>
            </React.Fragment>
          ) : null}
        </div>
      </CandidatesItemStyled>
    </div>
  );
}

export default CandidatesItem;
