import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Routes from "./routes";
import GlobalCSS from "./global.css";
import { AuthProvider } from "./contexts/auth";
import "react-toastify/dist/ReactToastify.css";
import 'react-tooltip/dist/react-tooltip.css'
import Loading from "./components/Loading";


library.add(fas);

if (window.location.hostname.includes("migpta.vanzolini-gte.org.br")) {
  Sentry.init({
    dsn: "https://a65c6d669beb44e189600edf5d360454@o443171.ingest.sentry.io/4504951192420352",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}


ReactDOM.render(
  <React.StrictMode>
    <GlobalCSS />
    <ToastContainer />
    <Loading />
    <AuthProvider>
      <Routes />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
