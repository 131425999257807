import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { maskPhone, maskCEP } from "./../../../helpers/mask";
import api from "./../../../config/api";
import {
  candidateSchema,
  adressSchema,
} from "./../../../helpers/validatorSchemas";
import CandidateContext from "../../../contexts/candidate";
import PageHeader from "../../../components/PageHeader";
import CandidateInfos from "../components/CandidateInfos";
import Section from "./../../../components/Section";
import Input from "./../../../components/FormHook/Input";
import Switch from "./../../../components/FormHook/Switch";
import WithMask from "./../../../components/FormHook/WithMask";
import ReactSelect from "./../../../components/FormHook/ReactSelect";
import OccupationSelect from "./../../../components/FormHook/OccupationSelect";
import TextArea from "./../../../components/FormHook/TextArea";
import ResumeExperiencesItens from "./../components/ResumeExperiencesItens";
import { resumeExperience } from "./../../../helpers/validatorSchemas";
import {
  optionsOtherRequirements,
  optionsPCD,
  optionsRegion,
  optionsCNH,
  optionsLanguage,
  optionsOwnVehicle,
} from "./../../Jobs/database";
import AddressForm from "../../../components/Forms/AddressForm";
import { techTerms } from "./../../../helpers/dicts";

const CandidateResumeStyled = styled.div`
  .info-text-form {
    margin-top: 1rem;
    font-weight: 300;
  }

  @media only screen and (max-width: 600px) {
  }
`;

export default function Resume() {
  const navigate = useNavigate();
  const { candidate, updateCandidateData } = useContext(CandidateContext);
  const [segments, setSegments] = useState([]);
  const [resumeExperiences, setResumeExperiences] = useState([]);
  const [mainOccupationDefaultOptions, setMainOccupationDefaultOptions] =
    useState([]);
  const [editingExperienceIndex, setEditingExperienceIndex] = useState(null);
  const [schoolingLevels, setSchoolingLevels] = useState([]);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields, dirtyFields },
  } = useForm();

  const startAtWatch = watch("experiences.start_at");
  const currentExperience = watch("current_experience");
  const isTech = watch("is_tech");
  const noExperiences = watch("no_experiences");

  useEffect(async () => {
    if (!candidate) {
      return false;
    }
    let schoolingResponse = await api.get(`schooling-levels`);
    setSchoolingLevels(schoolingResponse.data);
    let experienceResponse = await api.get(`experience-levels`);
    setExperienceLevels(experienceResponse.data);
    let segmentsResponse = await api.get(`segments`);
    setSegments(segmentsResponse.data);

    setResumeExperiences(candidate.experiences);
    setMainOccupationDefaultOptions(
      candidate.occupations?.map((occ) => {
        return { label: occ.name, value: occ.id };
      })
    );

    reset({
      full_name: candidate.full_name,
      phone: maskPhone(candidate.phone),
      cell: maskPhone(candidate.cell),
      pcd: candidate.pcd,
      other_requirements: candidate.other_requirements,
      occupation_preferences: candidate.occupations.map((occ) => {
        return { value: occ.id, label: occ.name };
      }),
      languages: candidate.languages,
      presentation: candidate.presentation,
      region: candidate.region,
      cnh: candidate.cnh,
      own_vehicle: candidate.own_vehicle,
      is_tech: candidate.is_tech,
      tech_keywords: candidate.tech_keywords,
      github_profile: candidate.github_profile,
      social_name: candidate.social_name,
      birth_date: candidate.birth_date
        ? format(new Date(`${candidate.birth_date}T00:00:00`), "yyyy-MM-dd")
        : null,
      academic_details: candidate.academic_details,
      address: candidate.address,
    });
  }, [candidate]);

  async function onSubmit(data) {
    let req = data;
    delete req.experiences;
    req.occupation_ids = data.occupation_preferences?.map((occ) => occ.value);
    delete req.occupation_preferences;
    req.phone = maskPhone(req.phone);
    req.cell = maskPhone(req.cell);

    if (!req.phone && !req.cell) {
      toast.error(`Preencha o telefone ou o celular`);
      return false;
    }

    try {
      await candidateSchema.validate(req);
    } catch (e) {
      console.log(e);
      toast.error(e.errors.join(", "));
      return false;
    }

    if (req.address?.cep) {
      req.address.cep = maskCEP(req.address?.cep);
    }

    try {
      await adressSchema.validate(req.address);
    } catch (e) {
      console.log(e);
      toast.error(e.errors.join(", "));
      return false;
    }

    req.languages = req.languages.join(",") || null;
    req.other_requirements = req.other_requirements.join(",") || null;
    req.cnh = req.cnh.join(",") || null;
    req.pcd = req.pcd.join(",") || null;
    req.own_vehicle = req.own_vehicle.join(",") || null;
    req.tech_keywords = data.tech_keywords.join(",") || null;
    req.experiences = resumeExperiences;

    if (req.experiences.length === 0 && !noExperiences) {
      toast.error(
        "Você precisa cadastrar uma experiência, ou informar que não possui experiência!"
      );
      return false;
    }

    if (req.is_tech) {
      if (req.github_profile) {
        if (
          !/^(http(s?):\/\/)?(www\.)?github\.([a-z])+\/([A-Za-z0-9]{1,})+\/?$/.test(
            req.github_profile
          )
        ) {
          toast.error("URL do perfil do github informada é inválida!");
          return false;
        }
      }
    } else {
      req.tech_keywords = null;
      req.github_profile = null;
    }

    try {
      let response = await api.put(`/candidates`, req);
      updateCandidateData(response.data);
      toast.success("Currículo atualizado com sucesso!");
      navigate(-1);
    } catch (e) {
      console.log(e);
      toast.error(e.errors.join(", "));
    }
  }

  async function handleDeleteExperience(idx) {
    let exclude = window.confirm("Deseja realmente remover essa experiência?");
    if (!exclude) {
      return false;
    }
    setResumeExperiences(resumeExperiences.filter((rx, i) => i !== idx));
  }

  async function handleEditExperience(idx) {
    setEditingExperienceIndex(idx);
    setValue("experiences.company", resumeExperiences[idx].company);
    setValue("experiences.occupation", resumeExperiences[idx].occupation);
    setValue("experiences.segment_id", resumeExperiences[idx].segment_id);
    setValue(
      "experiences.occupation_area_id",
      resumeExperiences[idx].occupation_area_id
    );
    setValue(
      "experiences.start_at",
      format(new Date(resumeExperiences[idx].start_at), "yyyy-MM-dd")
    );
    setValue(
      "experiences.end_at",
      resumeExperiences[idx].end_at
        ? format(new Date(resumeExperiences[idx].end_at), "yyyy-MM-dd")
        : false
    );
    setValue("experiences.description", resumeExperiences[idx].description);
    setValue("experiences.is_clt", resumeExperiences[idx].is_clt);
    setValue("current_experience", resumeExperiences[idx].current_experience);
  }

  async function handleAddExperience() {
    let experience = {
      company: getValues("experiences.company") || null,
      occupation: getValues("experiences.occupation") || null,
      segment_id: getValues("experiences.segment_id") || null,
      start_at: getValues("experiences.start_at") || null,
      end_at: getValues("experiences.end_at") || null,
      description: getValues("experiences.description") || null,
      is_clt: getValues("experiences.is_clt"),
      current_experience: getValues("current_experience"),
    };

    if (experience.current_experience) {
      experience.end_at = null;
    }

    let alreadyCurrentExp = resumeExperiences.filter(
      (exp, i) =>
        exp.current_experience === true && editingExperienceIndex !== i
    );

    if (alreadyCurrentExp.length > 0 && experience.current_experience) {
      toast.error(`Você só pode cadastrar uma experiência atual`);
      return false;
    }

    if (
      alreadyCurrentExp.length > 0 ||
      (alreadyCurrentExp.length === 0 && !experience.current_experience)
    ) {
      if (!experience.start_at || !experience.end_at) {
        toast.error(`Data de inicio e fim obrigatorios.`);
        return false;
      }
    }

    experience.occupation_id = experience.occupation?.value;

    try {
      await resumeExperience.validate(experience);
      if (editingExperienceIndex != null) {
        let aux = resumeExperiences.filter(
          (rx, i) => i !== editingExperienceIndex
        );
        setResumeExperiences([]);
        aux.push(experience);
        setResumeExperiences(aux);
        toast.success("Experiência editada com sucesso");
      } else {
        setResumeExperiences((prevArray) => [...prevArray, experience]);
        toast.success("Experiência adicionada com sucesso");
      }
      setValue("experiences.company", "");
      setValue("experiences.occupation", "");
      setValue("experiences.start_at", "");
      setValue("experiences.end_at", "");
      setValue("experiences.segment_id", "");
      setValue("experiences.description", "");
      setValue("experiences.is_clt", false);
      setValue("current_experience", false);
      setEditingExperienceIndex(null);
    } catch (e) {
      toast.error(e.errors.join(", "));
      return false;
    }
  }

  return candidate ? (
    <CandidateResumeStyled>
      <PageHeader
        title={`Meu Currículo`}
        actions={[<Link to={`/candidato`}>Minhas Vagas</Link>]}
      />
      <div className="container">
        <div className="row">
          <div className="hide-on-mobile col-4">
            <CandidateInfos candidate={candidate} />
          </div>
          <div className="col-md-8 col-sm-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Section title={`Dados pessoais`}>
                <p className="info-text-form">
                  Preencha os dados corretamente, eles são essenciais para a
                  criação do currículo e a busca de vagas assertivas com o
                  perfil.
                </p>
                <div className="row">
                  <Input
                    name="full_name"
                    register={register}
                    label={`Nome:`}
                    placeholder={`Nome completo`}
                  />
                  <Input
                    name="social_name"
                    register={register}
                    label={`Nome Social:`}
                    placeholder={`Nome Social`}
                  />
                  <div className="col-md-6 col-sm-12">
                    <WithMask
                      control={control}
                      name="phone"
                      label={`Telefone`}
                      phoneOrCell
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <WithMask
                      control={control}
                      name="cell"
                      label={`Celular`}
                      phoneOrCell
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <ReactSelect
                      name="region"
                      placeholder="Região"
                      control={control}
                      options={optionsRegion}
                      label="Região:"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <ReactSelect
                      name="cnh"
                      isMulti
                      placeholder="Possui CNH"
                      control={control}
                      options={optionsCNH}
                      label="Possui CNH:"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <ReactSelect
                      name="languages"
                      isMulti
                      placeholder="Fala outro idioma"
                      control={control}
                      options={optionsLanguage}
                      label="Fala outro idioma:"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <ReactSelect
                      name="pcd"
                      isMulti
                      placeholder="Pessoa com deficiência"
                      control={control}
                      options={optionsPCD}
                      label="Pessoa com deficiência:"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <ReactSelect
                      name="own_vehicle"
                      isMulti
                      placeholder="Tem veículo próprio"
                      control={control}
                      options={optionsOwnVehicle}
                      label="Tem veículo próprio:"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <Input
                      name="birth_date"
                      placeholder="Data de nascimento"
                      register={register}
                      type={"date"}
                      max={format(new Date(), "yyyy-MM-dd")}
                      label="Data de nascimento:"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <ReactSelect
                      name="other_requirements"
                      isMulti
                      placeholder="Outras características"
                      control={control}
                      options={optionsOtherRequirements}
                      label="Outras características:"
                    />
                    <small className="info">
                      Este campo deve ser preenchido para que você seja
                      informado (a) quando houver vagas específicas para estes
                      públicos. Não se preocupe isso não aparecerá no seu
                      currículo. 
                    </small>
                  </div>
                  {candidate?.schooling_level?.level >= 9 ? (
                    <Input
                      name="academic_details"
                      placeholder="Digite o nome do(s) curso(s) realizado(s)"
                      register={register}
                      label="Nome do(s) curso(s) realizado(s):"
                    />
                  ) : null}
                  <TextArea
                    register={register}
                    errors={errors}
                    validations={{
                      maxLength: {
                        value: 2000,
                        message: "Maximo 2000 caracteres atingido.", // JS only: <p>error message</p> TS only support string
                      },
                    }}
                    name="presentation"
                    label="Carta de apresentação (fale um pouco das suas experiências profissionais):"
                    rows={8}
                  />
                </div>
              </Section>
              <Section title={`Endereço`}>
                <AddressForm
                  dirtyFields={dirtyFields}
                  allowOutSP
                  control={control}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                />
              </Section>
              <Section title={`Ocupações de interesse`}>
                <p className="info-text-form">
                  Digite no campo abaixo as três primeiras letras da ocupação e
                  seleciona uma das opções desejadas. Todas as opções descritas
                  são registradas na CBO, Classificação Brasileira de Ocupações.
                  Caso não encontre a que você deseja, escolha a que mais se
                  aproxima. 
                </p>
                <OccupationSelect
                  isMulti
                  maxOptions={10}
                  control={control}
                  label="Ocupações:"
                  name="occupation_preferences"
                />
              </Section>
              <Section title={`Tecnologia da Informação`}>
                <p className="info-text-form">
                  Se você está buscando vagas de tecnologia, clique no botão
                  abaixo. Indique quais competências você possui e o link do seu
                  perfil no Github (se houver). Assim, você encontrará e poderá
                  se candidatar à vagas na área.
                </p>
                <Switch
                  register={register}
                  name="is_tech"
                  label="Você é um profissional de Tecnologia da Informação?"
                />
                {isTech ? (
                  <React.Fragment>
                    <small></small>
                    <ReactSelect
                      name="tech_keywords"
                      isMulti
                      control={control}
                      placeholder={
                        "Selecione as competências de tecnologia que você possui"
                      }
                      options={techTerms}
                      label="Competências de tecnologia:"
                    />
                    <Input
                      name="github_profile"
                      register={register}
                      label={`Perfil no Github:`}
                      placeholder={`https://github.com/seu-usuario`}
                    />
                  </React.Fragment>
                ) : null}
              </Section>
              <Section title={`Experiência profissional`}>
                <p className="info-text-form">
                  Descreva suas experiências profissionais, começando pela mais
                  recente.
                </p>
                {resumeExperiences.length !== 0 ? null : (
                  <div className="col-md-12 col-sm-12 mb-4">
                    <Switch
                      register={register}
                      name="no_experiences"
                      label="Ainda não possuo experiência"
                    />
                  </div>
                )}
                {!noExperiences ? (
                  <>
                    <ResumeExperiencesItens
                      handleEditExperience={handleEditExperience}
                      handleDeleteExperience={handleDeleteExperience}
                      expeiences={resumeExperiences}
                    />
                    <Input
                      name="experiences.company"
                      register={register}
                      label={`Empresa:`}
                      placeholder={`Nome da empresa`}
                    />
                    <OccupationSelect
                      control={control}
                      label="Ocupações:"
                      name="experiences.occupation"
                    />
                    <ReactSelect
                      name="experiences.segment_id"
                      control={control}
                      placeholder={"Selecione uma Área de atuação"}
                      options={segments.map((occ) => {
                        return { label: occ.name, value: occ.id };
                      })}
                      label="Áreas de atuação/Segmentos:"
                    />
                    <TextArea
                      register={register}
                      name="experiences.description"
                      label="Atividades realizadas:"
                      rows={8}
                    />
                    <div className="row align-items-end">
                      <div className="col">
                        <Switch
                          register={register}
                          name="current_experience"
                          label="Este é meu emprego atual"
                        />
                      </div>
                    </div>
                    <div className="row align-items-end">
                      <div className="col-md-3 col-sm-12">
                        <Input
                          name="experiences.start_at"
                          register={register}
                          type="date"
                          label={`Data de inicio:`}
                          max={format(new Date(), "yyyy-MM-dd")}
                        />
                      </div>

                      {!currentExperience ? (
                        <div className="col-md-3 col-sm-12">
                          <Input
                            name="experiences.end_at"
                            register={register}
                            type="date"
                            label={`Data de término:`}
                            disabled={!startAtWatch}
                            min={startAtWatch}
                            max={format(new Date(), "yyyy-MM-dd")}
                          />
                        </div>
                      ) : null}
                      <div className="col-md-4 col-sm-12 mobile-mt-1">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => handleAddExperience()}
                        >
                          {`${
                            editingExperienceIndex != null
                              ? "Salvar alteração"
                              : "Adicionar experiência"
                          } `}
                        </button>
                      </div>
                      <Switch
                        name="experiences.is_clt"
                        register={register}
                        label={`Experiência comprovada em carteira?`}
                      />
                    </div>
                  </>
                ) : null}
              </Section>
              <button type="submit" className="btn btn-primary">
                Salvar currículo
              </button>
            </form>
          </div>
        </div>
      </div>
    </CandidateResumeStyled>
  ) : null;
}
