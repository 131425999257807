import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ListItem from "./../../components/ListItem";
import Input from "./../../components/FormHook/Input";
import ReactSelect from "./../../components/FormHook/ReactSelect";
import api from "./../../config/api";
import PageHeader from "./../../components/PageHeader";
import Pagination from "./../../components/Pagination";
import { confirm } from "./../../components/Confirmation";
import FilterSidebar from "./../../components/FiltersSidebar";

import { optionsRegion } from "./../Jobs/database";

const ContainerCateStyled = styled.div``;

export default function Cate() {
  const { register, handleSubmit, reset, control, formState } = useForm();
  const [cates, setCates] = useState([]);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsNotFound, setResultsNotFound] = useState(false);
  const [activeFilters, setActiveFilters] = useState(null);

  useEffect(() => {
    handleSearch();
  }, []);

  async function handleFilter(data) {
    handleSearch(1, data);
  }

  async function handleDestroy(id) {
    if (
      await confirm("Deseja realmente a unidade do Cate?", "Sim", "Não", {
        title: "Excluir Unidade do Cate?",
      })
    ) {
      try {
        await api.delete(`cates/${id}`);
        toast.success("Unidade do CATE deletada com sucesso");
      } catch (e) {
        if (e.response.status === 409) {
          toast.error(e.response.data.message);
          return false;
        }
        toast.error("Erro ao deletar");
      }
      handleSearch();
    }
  }

  async function handleClearFilter() {
    reset({ name: null, region: null });
    handleSearch(1, null);
  }

  async function handleSearch(nextPage = 1, filters = null) {
    let resp = await api.get(`cates`, {
      params: { page: nextPage, ...filters },
    });
    setPage(nextPage);
    setActiveFilters(filters);
    setCates(resp.data.data);
    setResultsNotFound(resp.data.data.length === 0);
    setTotalPages(resp.data.last_page);
    window.scrollTo(0, 0);
  }

  return (
    <ContainerCateStyled>
      <PageHeader
        title={`Unidades do CATE`}
        actions={[
          <Link to={`/unidades-cate/editar`}>Cadastrar nova unidade</Link>,
        ]}
      />
      <div className="container">
        <div className="row">
          <div className="col-3">
            <FilterSidebar title={"Busca por unidades Cate"}>
              <form onSubmit={handleSubmit(handleFilter)}>
                <ReactSelect
                  name="region"
                  control={control}
                  options={optionsRegion}
                  placeholder="Filtre por região"
                  label="Região:"
                />
                <Input
                  register={register}
                  name="name"
                  label="Nome da unidade"
                />
                <button type="submit" className="btn btn-primary">
                  Buscar
                </button>
                <button
                  type="button"
                  onClick={handleClearFilter}
                  className="btn btn-secondary"
                >
                  Limpar
                </button>
              </form>
            </FilterSidebar>
          </div>
          <div className="col-9">
            {resultsNotFound ? (
              <span>Nenhum resultado encontrado para a busca.</span>
            ) : null}
            <div className="row">
              {cates.map((cate) => {
                return (
                  <ListItem
                    key={uuidv4()}
                    title={cate.name}
                    infos={[
                      { label: "Endereço", value: cate.adress?.adress },
                      { label: "Número", value: cate.adress?.number },
                      { label: "Complemento", value: cate.adress?.complement },
                      { label: "Bairro", value: cate.adress?.district },
                      { label: "CEP", value: cate.adress?.cep },
                      { label: "Cidade", value: cate.adress?.city },
                      { label: "Estado", value: cate.adress?.state },
                      { label: "Região", value: cate.adress?.region },
                    ]}
                    actions={[
                      <Link
                        to={`/unidades-cate/editar?id=${cate.id}`}
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />{" "}
                        Editar
                      </Link>,
                      <button
                        onClick={() => handleDestroy(cate.id)}
                        className="btn btn-secondary"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />{" "}
                        Excluir
                      </button>,
                    ]}
                  />
                );
              })}
              <Pagination
                page={page}
                total={totalPages}
                onChange={(page) => handleSearch(page, activeFilters)}
              />
            </div>
          </div>
        </div>
      </div>
    </ContainerCateStyled>
  );
}
