import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateApplicationsStyled } from "./style";
import api from "../../../../config/api";
import { confirm } from "./../../../../components/Alert";
import Divider from "./../../../../components/Divider";
import Pagination from "./../../../../components/Pagination";

const legendData = [
  {
    icon: process.env.PUBLIC_URL + "/assets/icons/invited_job_filled.svg",
    title: "Convite para entrevista",
  },
  {
    icon: process.env.PUBLIC_URL + "/assets/icons/scheduled_job_filled.svg",
    title: "Entrevista Agendada",
  },
  {
    icon: process.env.PUBLIC_URL + "/assets/icons/wait_job_filled.svg",
    title: "Aguardando Análise",
  },
  {
    icon: process.env.PUBLIC_URL + "/assets/icons/closed_job_filled.svg",
    title: "Vaga Encerrada",
  },
];

const StatusIcon = {
  NEW: process.env.PUBLIC_URL + "/assets/icons/wait_job.svg",
  REJECTED: process.env.PUBLIC_URL + "/assets/icons/closed_job.svg",
  MASSIVE_REJECT: process.env.PUBLIC_URL + "/assets/icons/closed_job.svg",
  INVITE_REJECTED: process.env.PUBLIC_URL + "/assets/icons/closed_job.svg",
  INVITE_EXPIRED: process.env.PUBLIC_URL + "/assets/icons/closed_job.svg",
  CLOSED: process.env.PUBLIC_URL + "/assets/icons/closed_job.svg",
  CANCELED: process.env.PUBLIC_URL + "/assets/icons/closed_job.svg",
  APPROVED: process.env.PUBLIC_URL + "/assets/icons/scheduled_job.svg",
  INVITED: process.env.PUBLIC_URL + "/assets/icons/invited_job.svg",
};

const StatusColor = {
  NEW: "warning",
  REJECTED: "danger",
  MASSIVE_REJECT: "danger",
  INVITE_REJECTED: "danger",
  INVITE_EXPIRED: "danger",
  CLOSED: "danger",
  CANCELED: "danger",
  APPROVED: "success",
  INVITED: "info",
};

const StatusLabel = {
  NEW: "Aguardando análise",
  REJECTED: "Não foi aprovado",
  MASSIVE_REJECT: "Não foi aprovado",
  APPROVED: "Entrevista agendada",
  INVITED: "Convidado para a entrevista",
  INVITE_REJECTED: "Convite recusado",
  INVITE_EXPIRED: "Convite expirado",
  CLOSED: "Vaga encerrada",
  CANCELED: "Candidatura cancelada",
};

function CandidateApplications({ uuid }) {
  const navigator = useNavigate();
  const [applications, setApplications] = useState([]);
  const [totalApplications, setTotalApplications] = useState(0);
  const [page, setPage] = useState(1);
  const [filtering, setFiltering] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(false);
  const { search } = useLocation();

  useEffect(async () => {
    await handleGetApplications();
    let query = new URLSearchParams(search);
    let hasNotyDetails = query.get("noty");
    if (hasNotyDetails) {
      api.get(`candidates/applications/${hasNotyDetails}`).then((resp) => {
        handleDetails(resp.data);
        window.history.pushState(
          "",
          "",
          "/" +
            window.location.href
              .substring(window.location.href.lastIndexOf("/") + 1)
              .split("?")[0]
        );
      });
    }
  }, [search]);

  async function handleGetApplications(nextPage = 1, currentStatus = null) {
    let params = { page: nextPage };
    if (uuid) {
      params["uuid"] = uuid;
    }
    if (currentStatus) {
      params["status"] = currentStatus;
    }
    let response = await api.get(
      !uuid ? `candidates/applications` : `attendance/candidates/applications`,
      { params }
    );
    setFiltering(currentStatus ? true : false);
    setApplications(response.data.data);
    setTotalApplications(response.data.last_page);
    setPage(nextPage);
    setCurrentFilter(currentStatus);
    document.getElementById(
      "candidate-applications-list-srollable"
    ).scrollTop = 0;
  }

  async function handleReplyInvite(
    uuid,
    rejected = false,
    candidateUuid = null
  ) {
    let response = await api.get(`invite/${uuid}`, {
      params: { rejected: rejected ? "1" : "0" },
    });
    if (candidateUuid) {
      localStorage.setItem(`@catevagas:atendimento:${candidateUuid}`, true);
    }
    toast.success("Convite repondido com sucesso!");
    handleGetApplications(1);
  }

  async function handleDetails(application = null) {
    let { status } = application;
    let title = "";
    let actions = [];
    let enableEscape = true;
    let content = () => "";
    switch (status) {
      case "NEW":
        title = "Seu interesse está em análise!";
        content = () => {
          return (
            <React.Fragment>
              <p>
                Seu currículo será analisado por uma equipe do Cate e, caso seja
                aprovado, você recebera o protocolo com o dia e o horário para
                realização da entrevista
              </p>
              <p>
                <b>
                  Verifique seu e-mail e o painel de candidaturas na página
                  inicial do sistema de vagas
                </b>
              </p>
            </React.Fragment>
          );
        };
        break;
      case "APPROVED":
        title = "Encaminhado para entrevista!";
        content = () => {
          return (
            <React.Fragment>
              {application ? (
                <React.Fragment>
                  <span className="mt-4">Esse é o protocolo de ativação:</span>
                  <span className="strong">{application?.protocol}</span>
                </React.Fragment>
              ) : null}
              <Divider />
              <span className="strong">Dados da entrevista:</span>

              {!application.job?.adress_id ? (
                <React.Fragment>
                  {application.job?.meet_phones.map((phones, idx) => {
                    return (
                      <span
                        key={`meet-phones-details-key-${phones.id}`}
                      >{`Contato ${idx + 1}: ${phones.name} - ${
                        phones.phone
                      }`}</span>
                    );
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span>{`${application.job?.adress?.adress}, ${application.job?.adress?.number} - ${application.job?.adress?.district}`}</span>
                  <span>{`CEP: ${application.job?.adress?.cep || "-"}`}</span>
                  <span>{`Complemento: ${
                    application.job?.adress?.complement || "-"
                  }`}</span>
                  <span>{`Referencia: ${
                    application.job?.adress?.reference || "-"
                  }`}</span>
                  <span>{`${application.job?.adress?.city} - ${application.job?.adress?.state}`}</span>
                </React.Fragment>
              )}
              <span className="mt-2">{`De: ${format(
                new Date(application.schedule.start_at),
                "dd/MM/yyyy"
              )} Até ${format(
                new Date(application.schedule.end_at),
                "dd/MM/yyyy"
              )}`}</span>
              <span>{`Das: ${format(
                new Date(application.schedule.start_at),
                "HH:mm"
              )} às ${format(
                new Date(application.schedule.end_at),
                "HH:mm"
              )}`}</span>
            </React.Fragment>
          );
        };
        break;
      case "INVITED":
        title = "Convidado para entrevista!";
        actions = [
          <button
            className="btn btn-primary"
            onClick={() =>
              handleReplyInvite(
                application.invite_uuid,
                false,
                application.candidate_uuid
              )
            }
          >
            Aceitar
          </button>,
          <button
            className="btn btn-secondary"
            onClick={() =>
              handleReplyInvite(
                application.invite_uuid,
                true,
                application.candidate_uuid
              )
            }
          >
            Recusar
          </button>,
        ];
        content = () => {
          return (
            <React.Fragment>
              {application ? (
                <React.Fragment>
                  <span className="mt-4">Esse é o protocolo de ativação:</span>
                  <span className="strong">{application?.protocol}</span>
                </React.Fragment>
              ) : null}
              <Divider />
              <span className="strong">Dados da entrevista:</span>

              {!application.job?.adress_id ? (
                <React.Fragment>
                  {application.job?.meet_phones.map((phones, idx) => {
                    return (
                      <span
                        key={`meet-phones-details-key-${phones.id}`}
                      >{`Contato ${idx + 1}: ${phones.name} - ${
                        phones.phone
                      }`}</span>
                    );
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span>{`${application.job?.adress?.adress}, ${application.job?.adress?.number} - ${application.job?.adress?.district}`}</span>
                  <span>{`CEP: ${application.job?.adress?.cep || "-"}`}</span>
                  <span>{`Complemento: ${
                    application.job?.adress?.complement || "-"
                  }`}</span>
                  <span>{`Referencia: ${
                    application.job?.adress?.reference || "-"
                  }`}</span>
                  <span>{`${application.job?.adress?.city} - ${application.job?.adress?.state}`}</span>
                </React.Fragment>
              )}
              <span className="mt-2">{`De: ${format(
                new Date(application.schedule.start_at),
                "dd/MM/yyyy"
              )} Até ${format(
                new Date(application.schedule.end_at),
                "dd/MM/yyyy"
              )}`}</span>
              <span>{`Das: ${format(
                new Date(application.schedule.start_at),
                "HH:mm"
              )} às ${format(
                new Date(application.schedule.end_at),
                "HH:mm"
              )}`}</span>
            </React.Fragment>
          );
        };
        break;
    }

    actions.push(
      <button
        onClick={() =>
          navigator(
            uuid
              ? `/vagas/detalhes?id=${application.job_id}`
              : `/candidato/vagas/detalhes?id=${application.job_id}`
          )
        }
        className="btn btn-alternative"
      >
        Ir para a vaga
      </button>
    );

    await confirm({
      title: title,
      enableEscape: enableEscape,
      closeButton: true,
      actions: actions,
      content: content,
    });
  }

  return (
    <CandidateApplicationsStyled>
      <div className="candidate-applications-container">
        <div className="candidate-applications-legend">
          {legendData.map((item) => {
            return (
              <div
                key={uuidv4()}
                className="candidate-applications-legend-item"
              >
                <img src={item.icon} alt={item.title} />
                <span>{item.title}</span>
              </div>
            );
          })}
        </div>
        <div className="candidate-applications-bg">
          <div className="candidate-applications-card">
            <h3 className="candidate-applications-card-header">
              HISTÓRICO DE CANDIDATURAS
            </h3>
            <div className="candidate-applications-card-filter">
              <div className="label">Filtro:</div>
              <div className="input">
                <select
                  onChange={(e) => handleGetApplications(1, e.target.value)}
                  className="form-control"
                >
                  <option value="">TODAS AS CANDIDATURAS</option>
                  {Object.keys(StatusLabel).map((key) => {
                    return (
                      <option key={`option_${key}`} value={key}>
                        {StatusLabel[key]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <Divider />
            <div
              className="candidate-applications-list"
              id="candidate-applications-list-srollable"
            >
              {applications.length === 0 && filtering ? (
                <span className="label">
                  Nenhuma candidatura para o filtro selecionado.
                </span>
              ) : null}
              {applications.length === 0 && !filtering ? (
                <span className="label">
                  Você ainda não se candidatou a nenhuma vaga.
                </span>
              ) : null}
              {applications.map((application) => {
                return (
                  <div
                    key={uuidv4()}
                    className="candidate-applications-list-item"
                  >
                    <div className="candidate-applications-list-item-icon">
                      <img src={StatusIcon[application.status]} alt="" />
                    </div>
                    <div className="candidate-applications-list-item-content">
                      <div
                        onClick={() =>
                          ["REJECTED", "INVITE_REJECTED"].indexOf(
                            application.status
                          ) === -1
                            ? handleDetails(application)
                            : null
                        }
                        style={{
                          cursor:
                            ["REJECTED", "INVITE_REJECTED"].indexOf(
                              application.status
                            ) === -1
                              ? "pointer"
                              : "",
                        }}
                        className={`candidate-applications-list-item-content-header bg-${
                          StatusColor[application.status]
                        }`}
                      >
                        {application.job?.occupation?.name}
                        {[
                          "REJECTED",
                          "INVITE_REJECTED",
                          "MASSIVE_REJECT",
                          "INVITE_EXPIRED",
                          "CLOSED"
                        ].indexOf(application.status) === -1 ? (
                          <button className="candidate-applications-list-item-content-header-btn">
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/icons/eye.svg"
                              }
                            />
                          </button>
                        ) : null}
                      </div>
                      <div
                        className={`candidate-applications-list-item-content-info text-${
                          StatusColor[application.status]
                        }`}
                      >
                        {StatusLabel[application.status]}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Pagination
              total={totalApplications}
              page={page}
              onChange={(page) => handleGetApplications(page, currentFilter)}
            />
          </div>
        </div>
      </div>
    </CandidateApplicationsStyled>
  );
}

export default CandidateApplications;
