import styled from "styled-components";

export const Container = styled.div`
  nav {
    background: #105ab5;
    padding: 0.5rem 2rem;
    img {
      width: 55%;
    }

    .nav .nav-item a{
      color: white;
      font-family: 'Raleway', sans-serif;
    }
  }
`;
