import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const StyledInput = styled.div``;

function SimpleSelect({
  children,
  label,
  name,
  type,
  register,
  disabled = false,
  required,
}) {
  const id = uuidv4();
  return (
    <StyledInput>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        type={type}
        disabled={disabled}
        name={name}
        className="form-control"
        id={id}
        {...register(name, { required })}
      >
        {children}
      </select>
    </StyledInput>
  );
}

export default SimpleSelect;
