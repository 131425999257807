import React from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

const StyledInput = styled.div``;

export default function WithMask({
  label,
  name,
  control,
  required = false,
  requiredForJob = false,
  placeholder,
  disabled = false,
  mask,
  phoneOrCell = false,
  alwaysShowMask = false,
}) {
  const id = uuidv4();
  return (
    <StyledInput>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      {required ? <span className="required">*</span> : null}
      {requiredForJob ? <span className="required required-secondary">*</span> : null}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <InputMask
              mask={
                phoneOrCell
                  ? value?.length <= 14
                    ? "(99) 9999-9999?"
                    : "(99) 99999-9999"
                  : mask
              }
              className="form-control"
              formatChars={{ 9: "[0-9]", "?": "[0-9 ]" }}
              required={required}
              disabled={disabled}
              id={id}
              maskChar={null}
              placeholder={placeholder}
              value={value}
              alwaysShowMask={alwaysShowMask}
              onChange={onChange}
              onBlur={onBlur}
            />
          );
        }}
      />
    </StyledInput>
  );
}
