import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../../contexts/auth";

import { SigninStyles } from "./styles";

function Signin() {
  const { signIn } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [consultantMode, setConsultantMode] = useState(false);

  async function handleSignIn(e) {
    e.preventDefault();
    if (!email || !password) {
      toast.error("Preencha todos os campos");
    }
    signIn(email, password, consultantMode);
  }

  return (
    <SigninStyles>
      <form onSubmit={handleSignIn}>
        <img
          width="100%"
          alt="Cate - Centro de Apoio ao Trabalhador e Empreendedor"
          src={process.env.PUBLIC_URL + "/assets/logo-cate-login.png"}
        />
        <div className="login-welcome">
          <h2>Seja bem vindo ao Cate Vagas!</h2>
        </div>
        <div>
          <label htmlFor="input_email" className="form-label">
            E-mail
          </label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="input_email"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="input_password" className="form-label">
            Senha
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            id="input_password"
          />
          <Link to="/resetar-senha" className="forgot-password">
            Esqueci minha senha
          </Link>
        </div>
        <div className="mb-3">
          <div className="form-check form-switch">
            <input
              type="checkbox"
              role="switch"
              onChange={(e) => setConsultantMode(e.target.checked)}
              className="form-check-input"
              id={"attendence_mode_switch"}
            />
            <label htmlFor={"attendence_mode_switch"} className="form-label">
              Modo atendente
            </label>
          </div>
          <small>
            Caso voce seja <b>Gestor</b> ou <b>Captador</b> e queira entrar como
            Atendente, basta selecionar essa opção.
          </small>
        </div>
        <button type="submit" className="btn btn-primary">
          Entrar
        </button>
      </form>
    </SigninStyles>
  );
}

export default Signin;
