export const TranslateJobJouney = {
  fixed: "Hora de entrada e saída fixa",
  to_combine: "Hora de entrada e saída a combinar",
};

export const TranslateJobType = {
  presential: "Trabalho Presencial",
  remote: "Trabalho Remoto",
  hybrid: "Híbrido",
};

export const TranslateJobSalaryType = {
  month: "Mês",
  day: "Dia",
  hour: "Hora",
  comission: "Comissão",
  combine: "A combinar",
  default: "-",
};

export const TranslateJobStatus = {
  active: "Vaga Aberta",
  stopped: "Vaga Suspensa",
  schedule: "Vaga com ativação agendada",
  draft: "Rascunho",
  closed: "Vaga Encerrada",
};

export const TranslateJobRequirementKey = {
  gender: "Gênero",
  ethnic_group: "Raça",
  age: "Idade",
  region: "Região",
  cnh: "Possui CNH",
  own_vehicle: "Ter veículo próprio",
  language: "Idioma",
  pcd: "Pessoa com deficiência",
  refugee: "Refugiado ou imigrante",
  egress: "Egresso do sistema prisional",
  transexual: "Transexual",
  lgbt: "LGBTQIA+",
  "50_age": "+50 anos",
  street_population: "Populacao de Rua",
  domestic_violence: "Mulher com histórico de violência domestica",
};

export const TranslateRegions = {
  lest: "Zona Leste",
  oest: "Zona Oeste",
  north: "Zona Norte",
  south: "Zona Sul",
  center: "Centro",
  metropolitan: "Região metropolitana",
  other: "Outro Monicipio",
};

export const TranslateJobRequirementOption = {
  male: "Masculino",
  female: "Feminino",
  white: "Branca",
  black: "Preta",
  yellow: "Amarela",
  brown: "Parda",
  indigenous: "Indígena",
  own_vehicle_car: "Carro",
  own_vehicle_bike: "Moto",
  own_vehicle_truck: "Caminhão",
  own_vehicle_other: "Outro",
  18: "Ate 18 anos",
  21: "19 a 21 anos",
  30: "22 a 30 anos",
  40: "31 a 40 anos",
  50: "41 a 50 anos",
  60: "51 a 60 anos",
  61: "Acima de 61 anos",
  lest: "Zona Leste",
  oest: "Zona Oeste",
  north: "Zona Norte",
  south: "Zona Sul",
  center: "Região metropolitana",
  other: "Outro Monicipio",
  ACC: "Categoria ACC",
  A: "Categoria A",
  B: "Categoria B",
  C: "Categoria C",
  D: "Categoria D",
  E: "Categoria E",
  german: "Alemão",
  arabic: "Árabe",
  bulgarian: "Búlgaro",
  "chinese (simplified)": "Chinês simplificado",
  "chinese (traditional)": "Chinês tradicional",
  korean: "Coreano",
  croatian: "Croata",
  danish: "Dinamarquês",
  slovak: "Eslovaco",
  slovenian: "Esloveno",
  spanish: "Espanhol",
  "spanish (mexico)": "Espanhol (México)",
  estonian: "Estoniano",
  finnish: "Finlandês",
  french: "Francês",
  "french canadian": "Francês canadense",
  greek: "Grego",
  hebrew: "Hebraico",
  dutch: "Holandês",
  hungarian: "Húngaro",
  english: "Inglês",
  italian: "Italiano",
  japanese: "Japonês",
  latvian: "Letão",
  lithuanian: "Lituano",
  norwegian: "Norueguês",
  polish: "Polonês",
  romanian: "Romeno",
  russian: "Russo",
  "serbian latin": "Sérvio (latino)",
  swedish: "Sueco",
  thai: "Tailandês",
  czech: "Tcheco",
  turkish: "Turco",
  ukrainian: "Ucraniano",
  amputacao: "Física - Amputacao",
  nanismo: "Física - Nanismo",
  ostomia: "Física - Ostomia",
  cadeirante: "Física - Cadeirante",
  superior: "Física - Um membro superior",
  inferior: "Física - Um membro inferior",
  superiores: "Física - Membros superiores",
  inferiores: "Física - Membros inferiores",
  parcial: "Auditiva - Surdez Bilateral Parcial",
  total: "Auditiva - Surdez Total",
  baixa: "Visual - Baixa visão",
  monocular: "Visual - Visão monocular",
  cegueira: "Visual - Cegueira",
  intelectual: "Intelectual",
  paralisia: "Paralisia Cerebral",
};
