import styled from "styled-components";

export const SeggestedJobsListStyled = styled.div`
  margin-top: 30px;

  p.infos {
    font-family: "Raleway", sans-serif;
    color: #737373;
    font-weight: 500;
  }

  .suggested-list {
    margin-top: 20px;
  }
`;
