import React, { useContext, useEffect } from "react";
import AttendanceContext from "./../../../contexts/attendance";
import CandidatePanel from "./../../Candidate/components/CandidatePanel";
import PageHeader from "./../../../components/PageHeader";
import AttendanceHeader from "./../components/AttendanceHeader";

export default function CandidateHome() {
  const { candidate } = useContext(AttendanceContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PageHeader title={`Painel do candidato`} />
      <div className="container">
        <AttendanceHeader alternativeButton />
        <CandidatePanel candidate={candidate} attendanceMode />
      </div>
    </div>
  );
}
