import React, { useEffect, useState, useContext } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "./../../../config/api";
import CandidateContext from "../../../contexts/candidate";
import JobListItemDetailed from "./../../Jobs/components/JobListItemDetailed";
import PageHeader from "./../../../components/PageHeader";
import { confirm } from "./../../../components/Alert";
import { confirm as confirmation } from "./../../../components/Confirmation";
import { toast } from "react-toastify";

const JobDetailsStyled = styled.div`
  .go-back-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .card-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #0d3691;
    color: white;
    width: 60%;
    margin: 0 auto;

    .btn-primary {
      background-color: white;
      color: #0d3691;
    }

    .card-action-text {
      flex: 2;
      padding: 0 1rem;
    }

    .card-action-actions {
      flex: 1;
      padding: 0 1rem;
      text-align: center;

      a.secondary-action {
        text-decoration: none;
        color: white;
        font-style: italic;
        font-size: 0.8rem;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .card-action {
      width: 100%;
      text-align: center;
      flex-direction: column;
    }
  }
`;

function Details() {
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const [job, setJob] = useState(null);
  const [id, setId] = useState(null);
  const [candidateApplication, setCandidateApplication] = useState(null);
  const { candidate } = useContext(CandidateContext);

  useEffect(async () => {
    if (!candidate) {
      return false;
    }
    let id = searchParams.get("id");
    if (id) {
      api
        .get(`/candidates/jobs/${id}`)
        .then((resp) => {
          if (resp.data.status !== "active") {
            toast.error(`Essa vaga não está mais ativa.`);
            navigator(`/candidato/vagas`);
          }
          api
            .get(`candidates/jobs/${resp.data.id}/apply`)
            .then((resp2) => {
              setJob(resp.data);
              setId(id);
              setCandidateApplication(resp2.data);
            })
            .catch((e) => {
              setJob(resp.data);
              setId(id);
              setCandidateApplication(null);
            });
        })
        .catch((e) => {
          toast.error(`Essa vaga não está mais disponível.`);
          navigator(`/candidato/vagas`);
        });
    }
  }, [candidate]);

  async function goBackJobs() {
    navigator(-1);
  }

  async function handleAppply() {
    if (job.candidate_match?.score === 0) {
      if (
        !(await confirmation("Seu perfil não atinge nenhum dos requisitos da vaga. Deseja continuar com a candidatura?", "Sim", "Não", {
          title:
            "Continuar?",
        }))
      ) {
        return false;
      }
    }

    try {
      let response = await api.post(`candidates/jobs/${id}/apply`);
    } catch (e) {
      toast.error(e.response.data.message);
      return false;
    }

    await confirm({
      title: "Seu interesse está em análise!",
      enableEscape: false,
      actions: [
        <button onClick={goBackJobs} className="btn btn-primary">
          Continuar procurando vagas
        </button>,
      ],
      content: () => {
        return (
          <React.Fragment>
            <p>
              Seu currículo será analisado por uma equipe do Cate e, caso seja
              aprovado, você recebera o protocolo com o dia e o horário para
              realização da entrevista
            </p>
            <p>
              <b>
                Verifique seu e-mail e o painel de candidaturas na página
                inicial do sistema de vagas
              </b>
            </p>
          </React.Fragment>
        );
      },
    });
  }

  return job && candidate ? (
    <JobDetailsStyled>
      <PageHeader title={`Vagas`} />
      <div className="container">
        <JobListItemDetailed
          isCandidate={true}
          detailsMode={true}
          defaultCollapsed={false}
          {...job}
        />
        {!candidateApplication ? (
          <div className="card-action">
            <div className="card-action-text">
              <p>
                {!candidate.resume_updated_at ? (
                  `Apenas usuários com currículo cadastrado podem se candidatar a
                  vaga`
                ) : (
                  <React.Fragment>
                    Ao se candidatar para essa vaga o Cate recebera a última
                    versão do seu currículo <br />{" "}
                    <i>
                      {" "}
                      Data da última atualização:
                      {` ${format(
                        new Date(candidate.resume_updated_at),
                        "dd/MM/yyyy"
                      )}`}
                    </i>
                  </React.Fragment>
                )}
              </p>
              <small>
                Atenção: Caso seja aprovado para realizar a entrevista e esteja
                recebendo seguro-desemprego, o mesmo será suspenso até o
                resultado.
              </small>
            </div>
            <div className="card-action-actions">
              {!candidate.resume_updated_at ? (
                <React.Fragment>
                  <Link to={`/candidato/curriculo`} className="btn btn-primary">
                    Cadastrar currículo
                  </Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="card-action-actions">
                    <button className="btn btn-primary" onClick={handleAppply}>
                      Me candidatar
                    </button>
                  </div>
                  <Link
                    className="secondary-action"
                    to={`/candidato/curriculo`}
                  >
                    Atualizar meu currículo
                  </Link>
                </React.Fragment>
              )}
            </div>
          </div>
        ) : null}

        <div className="go-back-container">
          <button onClick={goBackJobs} className="btn btn-alternative">
            <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
            Voltar
          </button>
        </div>
      </div>
    </JobDetailsStyled>
  ) : null;
}

export default Details;
