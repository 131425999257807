import styled from "styled-components";

export const SocialAttendanceStyled = styled.div`
  .attendance_list {
    display: flex;
    gap: 10px;
    flex-flow: wrap;
    font-family: "Raleway", sans-serif;

    .attendance_list_item {
      display: flex;
      padding: 0.5rem 1rem;
      background: #f0f0f0;
      border-radius: 10px;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      min-width: 160px;

      .title {
        font-size: 0.8rem;
        font-weight: bold;
      }

      .read-notification {
        cursor: pointer;
      }
    }
  }
`;
