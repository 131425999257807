import React, { useEffect, useState, useContext } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import styled from "styled-components";
import api from "./../../../config/api";
import ModalApproveCandidateApplication from "../../Jobs/components/ModalApproveCandidateApplication";
import AttandanceHeader from "./../components/AttendanceHeader";
import AttendanceContext from "../../../contexts/attendance";
import JobListItemDetailed from "./../../Jobs/components/JobListItemDetailed";
import PageHeader from "./../../../components/PageHeader";
import { confirm } from "./../../../components/Alert";
import { toast } from "react-toastify";

const JobDetailsStyled = styled.div`
  .go-back-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .aleready-applyed-message {
    text-align: center;
    font-weight: bold;
  }

  .card-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #0d3691;
    color: white;
    width: 60%;
    margin: 0 auto;

    .btn-primary {
      background-color: white;
      color: #0d3691;
    }

    .card-action-text {
      flex: 2;
      padding: 0 1rem;
    }

    .card-action-actions {
      flex: 1;
      padding: 0 1rem;
      text-align: center;

      a.secondary-action {
        text-decoration: none;
        color: white;
        font-style: italic;
        font-size: 0.8rem;
      }
    }
  }
`;

function CandidateJobDetails({ managingJob }) {
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const [job, setJob] = useState(null);
  const [openApprove, setOpenApprove] = useState(false);
  const [id, setId] = useState(null);
  const [candidateApplication, setCandidateApplication] = useState(null);
  const { candidate } = useContext(AttendanceContext);

  useEffect(async () => {
    let id = searchParams.get("id");
    if (id) {
      setId(id);
      let resp = await api.get(`/jobs/${id}`);
      setJob(resp.data);
      if (resp.data.status !== "active") {
        toast.error(`Essa vaga não está mais ativa.`);
        navigator(`/atendimento/candidato/vagas`);
      }
      try {
        let alreadyApplyed = await api.get(
          `attendance/candidates/applications/${job.id}?uuid=${candidate.uuid}`
        );
        setCandidateApplication(alreadyApplyed.data);
      } catch (e) {
        setCandidateApplication(null);
      }
    }
  }, []);

  async function goBack() {
    navigator(-1);
  }

  return job && candidate ? (
    <JobDetailsStyled>
      <PageHeader title={`Indicar candidato a vaga`} />
      <div className="container">
        <AttandanceHeader />
        <ModalApproveCandidateApplication
          candidate={candidate}
          open={openApprove}
          jobId={job.id}
          job={job}
          onClose={() => setOpenApprove(false)}
          attendanceMode
        />
        <JobListItemDetailed managingJob={managingJob} {...job} />
        {!candidateApplication ? (
          <div className="card-action">
            <div className="card-action-text">
              <p>
                {!candidate.resume_updated_at ? (
                  `Apenas usuários com currículo cadastrado podem se candidatar a
                  vaga`
                ) : (
                  <React.Fragment>
                    Ao se candidatar para essa vaga o Cate recebera a última
                    versão do seu currículo <br />{" "}
                    <i>
                      {" "}
                      Data da última atualização:
                      {` ${format(
                        new Date(candidate.resume_updated_at),
                        "dd/MM/yyyy"
                      )}`}
                    </i>
                  </React.Fragment>
                )}
              </p>
              <small>
                Atenção: Caso seja aprovado para realizar a entrevista e esteja
                recebendo seguro-desemprego, o mesmo será suspenso até o
                resultado.
              </small>
            </div>
            <div className="card-action-actions">
              {!candidate.resume_updated_at ? (
                <React.Fragment>
                  <Link
                    to={`/atendimento/candidato/curriculo`}
                    className="btn btn-primary"
                  >
                    Cadastrar currículo do candidato
                  </Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="card-action-actions">
                    <button
                      className="btn btn-primary"
                      onClick={() => setOpenApprove(true)}
                    >
                      Indicar candidato
                    </button>
                  </div>
                  <Link
                    className="secondary-action"
                    to={`/atendimento/candidato/curriculo`}
                  >
                    Atualizar currículo do candidato
                  </Link>
                </React.Fragment>
              )}
            </div>
          </div>
        ) : (
          <div className="card-action">
            <div className="card-action-text">
              <p className="aleready-applyed-message">
                Candidato já aplicado a essa vaga.
              </p>
            </div>
          </div>
        )}

        <div className="go-back-container">
          <button onClick={goBack} className="btn btn-alternative">
            Voltar
          </button>
        </div>
      </div>
    </JobDetailsStyled>
  ) : null;
}

export default CandidateJobDetails;
