import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { techTerms } from "./../../helpers/dicts";
import { TranslateRegions } from "./../../helpers/translateFields";

import Divider from "./../Divider";

const ModalResumeStyled = styled.div`
  font-family: "Raleway", sans-serif;
  padding: 1rem 3rem;

  h1 {
    font-size: 1.5rem;
    color: black;
  }

  h2 {
    font-size: 1.3rem;
    text-transform: uppercase;
    color: black;
  }

  .image-profile {
    margin: 0 auto;
    display: block;
    margin-top: 40px;
    border-radius: 100%;
    width: 65%;
  }

  .resume-infos {
    color: #727272;
    margin-bottom: 5px;
    .resume-infos-title {
      font-weight: bold;
    }
    .resume-infos-content {
      white-space: pre-line;
    }
  }
`;

const ResumeInfo = ({ title, content }) => {
  return (
    <div className="resume-infos">
      <span className="resume-infos-title">{title}: </span>
      <span className="resume-infos-content">{content}</span>
    </div>
  );
};

function translateTechTerms(tk) {
  let sourceTerms = techTerms
    .filter((tech) => (tk?.includes(tech.value) ? tech.label : null))
    .map((term) => term.label);
  return sourceTerms.join(", ");
}

function ModalResume({ open, resume, onClose }) {
  return resume ? (
    <Modal
      className="modal-resume-candidate"
      show={open}
      onHide={onClose}
      backdrop={true}
      keyboard={false}
    >
      <ModalResumeStyled>
        <Modal.Body>
          <div className="content">
            <div className="row">
              <div className="col-lg-4">
                <img className="image-profile" src={resume?.picture} />
              </div>
              <div className="col-lg-8">
                <h1>{resume?.full_name}</h1>
                <div className="resume-infos-container">
                  <ResumeInfo title="Email" content={resume?.email} />
                  <ResumeInfo title="Telefone" content={resume?.phone} />
                  <ResumeInfo title="Celular" content={resume?.cell} />
                  <ResumeInfo title="Idade" content={resume?.age_range} />
                  <ResumeInfo
                    title="Escolaridade"
                    content={resume?.schooling_level?.name}
                  />
                  <ResumeInfo
                    title="Reside na cidade de São Paulo"
                    content={resume?.live_in_sp ? "Sim" : "Não"}
                  />
                  <ResumeInfo
                    title="Região"
                    content={resume?.region ? TranslateRegions[resume?.region] : "-"}
                  />
                  <ResumeInfo title="CPF" content={resume?.cpf} />
                  <ResumeInfo title="Raça" content={resume?.ethnic_group} />
                  <ResumeInfo title="Gênero" content={resume?.gender} />
                  {resume.is_tech ? (
                    <ResumeInfo title="Profissional de T.I?" content={`Sim`} />
                  ) : null}
                  {resume.is_tech ? (
                    <ResumeInfo title="Perfil no Github" content={resume.github_profile || "-"} />
                  ) : null}
                  {resume.is_tech ? (
                    <ResumeInfo
                      title="Competências tecnológicas"
                      content={translateTechTerms(resume.tech_keywords)}
                    />
                  ) : null}

                  <ResumeInfo
                    title="Carta de apresentação"
                    content={resume?.presentation}
                  />
                </div>
                <Divider />
                <h2>Áreas de interesse</h2>
                <div className="container-interests">
                  <ul>
                    {resume?.occupations?.map((occ) => {
                      return <li key={uuidv4()}>{occ.name}</li>;
                    })}
                  </ul>
                </div>
                <Divider />
                <h2>Experiências anteriores</h2>
                <div className="container-experiences">
                  {resume?.experiences?.map((exp) => {
                    return (
                      <div key={uuidv4()} className="experience">
                        <ResumeInfo title="Empresa" content={exp?.company} />
                        <ResumeInfo
                          title="Área de atuação"
                          content={exp?.occupation?.label}
                        />
                        <ResumeInfo
                          title="Atividades realizadas"
                          content={exp?.description}
                        />
                        <ResumeInfo
                          title="Período"
                          content={`${format(
                            new Date(exp.start_at),
                            "dd/MM/yyyy"
                          )} - ${exp.end_at
                              ? format(new Date(exp.end_at), "dd/MM/yyyy")
                              : "Atualmente"
                            }`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </Modal.Body>
      </ModalResumeStyled>
    </Modal>
  ) : null;
}

export default ModalResume;
