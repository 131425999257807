import styled from "styled-components";

export const JobsContainer = styled.div`
  .title-jobs-count {
    margin-bottom: 20px;
    text-align: center;
    margin-top: 2rem;
    span {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .form-filters-actions {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const UpdateStyeldContainer = styled.div`
  .schedule-add-btn-container {
    align-items: end;
    display: flex;
    margin-bottom: 16px;
  }
  .schedule-form-div {
    margin-bottom: 1rem;
  }
`;
