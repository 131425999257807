import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RegisterAttendanceStyled } from "./style";
import AttendanceContext from "../../contexts/attendance";
import AttendanceHeader from "../../pages/Attendance/components/AttendanceHeader";
import {
  companySchema,
  responsableSchema,
  adressSchema,
} from "../../helpers/validatorSchemas";
import PageHeader from "./../../components/PageHeader";
import Section from "./../../components/Section";
import Input from "./../../components/FormHook/Input";
import TextArea from "./../../components/FormHook/TextArea";
import WithMask from "./../../components/FormHook/WithMask";
import ReactSelect from "./../../components/FormHook/ReactSelect";
import Switch from "../../components/FormHook/Switch";
import api from "../../config/api";
import { attendenceTypesOptions } from "./../../helpers/dicts";
import { confirm } from "../../components/Confirmation";

import { optionsRegion } from "./../Jobs/database";
import { validatePhone } from "validations-br";

function RegisterAttendance() {
  const { candidate } = useContext(AttendanceContext);
  const navigate = useNavigate();
  let { uuid } = useParams();

  const requireAccompanyingLetterActions = [
    "PRESENTIAL_UNEMPLOYMENT_INSURANCE_REQUIREMENT",
    "PRESENTIAL_UNEMPLOYMENT_INSURANCE_RETURN",
  ];

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const [attendancesRegister, setAttendanceRegister] = useState([]);
  const actionWatch = watch("action", "value");

  function translateActionRequirement(reqAction) {
    let translated = null;
    attendenceTypesOptions.map((r) => {
      if (r.value === reqAction) {
        translated = r.label;
      }
    });
    return translated;
  }

  async function onSubmit(data) {
    if (!data.action) {
      toast.error("Selecione o tipo de atendimento");
      return false;
    }
    let registeredActions = attendancesRegister.map((att) => att.action);
    if (registeredActions.indexOf(data.action) != -1) {
      toast.error("Atendimento já registrado");
      return false;
    }
    setAttendanceRegister([...attendancesRegister, data]);
    reset({
      accompanying_letter: false,
      action: null,
    });
  }

  async function handeRemoveRegister(action) {
    setAttendanceRegister(
      attendancesRegister.filter((att) => att.action !== action)
    );
  }

  async function handleFinish() {
    if (attendancesRegister.length === 0) {
      toast.error("Nenhum atendimento adicionado");
      return false;
    }
    let req = {
      uuid,
      actions: attendancesRegister,
    };

    if (
      await confirm(
        "Deseja enviar esse registro de atendimento?",
        "Sim",
        "Não",
        {
          title: "Confirmar informações",
        }
      )
    ) {
      try {
        await api.post(`attendance`, req);
        toast.success("Atendimento registrado com sucesso");
        localStorage.setItem(`@catevagas:atendimento:${uuid}`, true);
        setAttendanceRegister([]);
        reset();
      } catch (e) {
        toast.error("Erro ao registrar atendimento, tente novamente.");
      }
    }
  }

  return (
    <RegisterAttendanceStyled>
      <PageHeader
        title={`Registrar atendimento`}
        // actions={[<Link to={`/empresas`}>Ver todas as empresas</Link>]}
      />
      <div className="container">
        <AttendanceHeader />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Section title="Formulário de registro de atendimento">
            <Switch
              name="external_event"
              label="Atendimento em Evento Externo?"
              register={register}
            />
            <ReactSelect
              name="action"
              control={control}
              options={attendenceTypesOptions}
              label="Tipo de atendimento:"
              placeholder={`Selecione o tipo de atendimento`}
            />
            {requireAccompanyingLetterActions.indexOf(actionWatch) !== -1 ? (
              <Switch
                name="accompanying_letter"
                label="Teve carta de Encaminhamento"
                register={register}
              />
            ) : null}
            <div className="d-flex gap-3 mt-4">
              <button className="btn btn-primary" type="submit">
                Adicionar atendimento
              </button>
            </div>
          </Section>
          <Section title="Atendimentos registrados">
            <div className="attendance_list mb-4">
              {attendancesRegister.length > 0 ? (
                attendancesRegister.map((attend) => {
                  return (
                    <div className="attendance_list_item" key={`ATT_ITEM_${attend.action}`}>
                      <div className="content">
                        <div className="title">
                          {translateActionRequirement(attend.action)}
                        </div>
                      </div>
                      <div className="action">
                        <FontAwesomeIcon
                          icon="fa-solid fa-xmark"
                          className="read-notification"
                          onClick={() => handeRemoveRegister(attend.action)}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>
                  Nenhum atendimento adicionado ainda. Preencha o formulário
                  acima.
                </p>
              )}
            </div>
            <button
              className="btn btn-alternative"
              type="button"
              onClick={handleFinish}
            >
              Finalizar registro de atendimento
            </button>
          </Section>
        </form>
      </div>
    </RegisterAttendanceStyled>
  );
}

export default RegisterAttendance;
