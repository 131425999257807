import styled from "styled-components";

export const HeaderCandidatesStyled = styled.div`
  button.dropdown-toggle {
    background: transparent;
    box-shadow: none;
    border: none;
  }
  
  nav {
    background: #105ab5;
    padding: 0.5rem 2rem;
    img {
      width: 55%;
    }

    .user-profile {
      margin-right: 10px;

      .candidate-image {
        width: 45px;
        height: 45px;
      }
    }

    .menu-container {
      display: flex;
      align-items: center;

      .menu {
        display: flex;
        flex-direction: row;
        color: white;
        justify-content: center;
        font-size: 1.6rem;
        gap: 10px;
        align-items: center;
        cursor: pointer;

        .menu-label {
          font-size: 0.8rem;
          font-weight: bold;
        }
      }

      .badge {
        background: #ff6600;
        border-radius: 50%;
        position: absolute;
        font-size: 0.6rem;
      }

      .notifications {
        margin-left: 20px;
        color: white;
        font-size: 1.6rem;
      }

      .dropdown-menu {
        color: black;
        border-radius: 0px;
        border-bottom: 2px solid #005ab9;
        min-width: 20rem;

        .header {
          background-color: #ececec;
          padding: 1rem 2rem;
          text-transform: uppercase;
          margin: 1rem 0;
          display: flex;
          align-items: center;

          img {
            width: 32px;
          }
        }

        .links {
          padding: 0 1.5rem;
        }

        a {
          color: #454545;
          border-bottom: 1px solid #a2a2a2;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 500;
        }

        a:hover {
          color: #005ab9;
        }
      }

      .dropdown-toggle::after {
        display: none;
      }
    }
  }
  .warning-bar {
    display: flex;
    justify-content: end;
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    color: #105ab5;
    text-transform: uppercase;
  }

  .noty-header {
    text-align: center;
  }

  .notifications-menu {
    margin-top: 2rem;
  }

  .noty-list {
    list-style: none;
    margin: 0;
    padding: 0 0.5rem;
    gap: 5px;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;

    .noty {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #105ab5;
      padding: 0.5rem 1rem;
      color: white;
      border-radius: 8px;

      .noty-text {
        font-size: 0.8rem;
        display: flex;
        flex-direction: column;

        .noty-title {
          font-weight: bold;
        }
      }

      .noty-action {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-left: 2rem;
      }

      .read-notification {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .navbar-brand {
      width: 35%;
      img {
        width: 100%;
      }
    }

    .warning-bar {
      font-size: 0.8rem;
      justify-content: center;
    }
  }
`;
