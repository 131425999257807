import axios from "axios";
import { toast } from "react-toastify";
const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://catevagashom.vanzolini-gte.org.br/vagas-api/api"
      : "http://localhost:8000/api",
});

async function showLoading() {
  document.getElementById("loading-app-component").style.display = "flex";
  document.body.style.overflow = "hidden";
}

async function hideLoading() {
  document.getElementById("loading-app-component").style.display = "none";
  document.body.style.overflow = "auto";
}
// Response interceptor for EXPIRED TOKENS
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response.status === 401 &&
      (error.response.data.message === "Token has expired" ||
        error.response.data.message ===
          "Token Signature could not be verified.")
    ) {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.clear();
      window.location.href = process.env.PUBLIC_URL;
    }
    await hideLoading();
    return Promise.reject(error);
  }
);

api.interceptors.request.use(async (config) => {
  try {
    await showLoading();
  } catch (e) {
    console.log("interceptor" + e);
  }

  return config;
});

api.interceptors.response.use(async (config) => {
  try {
    await hideLoading();
  } catch (e) {
    console.log("interceptor" + e);
  }
  return config;
});

export default api;
