import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { Collapse } from "react-collapse";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { ReportItemStyled } from "./../styles";
import api from "./../../../config/api";

import Input from "./../../../components/FormHook/Input";
import WithMask from "./../../../components/FormHook/WithMask";
import ReactSelect from "./../../../components/FormHook/ReactSelect";
import DatePicker from "./../../../components/FormHook/DatePick";

import Divider from "./../../../components/Divider";
import FileDownload from "js-file-download";

function AttendancesReport() {
  const [isCollapsed, setIsCollapsed] = useState(true); //default collapsed
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState,
  } = useForm();

  const [finalDate, setFinalDate] = useState(null);
  const [cates, setCates] = useState([]);
  const startAtWatch = watch("start_at");

  useEffect(() => {
    api.get(`cates?per_page=500`).then((response) => {
      setCates(response.data.data);
    });
  }, []);

  useEffect(() => {
    if (!startAtWatch) {
      return false;
    }
    let start = new Date(startAtWatch);
    let target = new Date(startAtWatch);
    let max = target.setDate(start.getDate() + 45);
    let today = new Date();
    setFinalDate(max > today ? today : max);
    setValue("end_at", null);
  }, [startAtWatch]);

  async function onSubmit(data) {
    let now = format(new Date(), "yyyyMMddHmm");
    data = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != null && v != "")
    );
    if (!data.start_at || !data.end_at) {
      toast.warning("Selecione um periodo.");
      return false;
    }
    data.start_at = format(new Date(data.start_at), "yyyy-MM-dd");
    data.end_at = format(new Date(data.end_at), "yyyy-MM-dd");
    api({
      url: "reports/fcda/csv",
      method: "GET",
      responseType: "blob",
      params: data, // Important
    }).then((response) => {
      FileDownload(response.data, `relatorio_fcda_${now}.csv`);
    });
  }

  return (
    <ReportItemStyled>
      <div className="card" key={uuidv4()}>
        <div className="card-body">
          <div className="header-job">
            <h4 className="card-title">Relatório FCDA</h4>
            <span
              className="collapse-action"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              )}
              {isCollapsed ? "Abrir" : "Fechar"}
            </span>
          </div>
          <Divider />
          <Collapse isOpened={!isCollapsed}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <DatePicker
                    name="start_at"
                    control={control}
                    label="Data de inicio:"
                  />
                </div>
                <div className="col">
                  <DatePicker
                    name="end_at"
                    control={control}
                    label="Data de fim:"
                    max={finalDate ? new Date(finalDate) : null}
                    min={startAtWatch}
                    disabled={!startAtWatch}
                  />
                </div>
                <div className="col"></div>
              </div>
              {/* <div className="row">
                <div className="col">
                  <ReactSelect
                    name="type"
                    label="Tipo de atendimento:"
                    placeholder={`Selecione um tipo de atendimento`}
                    control={control}
                    options={[
                      { label: "Presencial", value: "PRESENTIAL" },
                      { label: "Remoto", value: "REMOTE" },
                    ]}
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="action"
                    label="Atividade realizada:"
                    placeholder={`Selecione uma atividade`}
                    control={control}
                    options={[
                      {
                        label: "Cadastro de Currículo presencial",
                        value: "PRESENTIAL_REGISTER_RESUME",
                      },
                      {
                        label: "Atualização de Currículo presencial",
                        value: "PRESENTIAL_UPDATE_RESUME",
                      },
                      {
                        label: "Análise e Candidatura presencial de Vaga",
                        value: "PRESENTIAL_CANDIDATE_APPROVE",
                      },
                      {
                        label: "Pesquisa de vaga",
                        value: "PRESENTIAL_JOB_SEARCH",
                      },
                      {
                        label: "Aceitar convite de candidatura presencial",
                        value: "PRESENTIAL_ACCEPT_INVITE",
                      },
                      {
                        label: "Rejeitar convite de candidatura presencial",
                        value: "PRESENTIAL_REJECT_INVITE",
                      },
                      {
                        label: "Aprovação de Candidatura remota do Candidato",
                        value: "REMOTE_CANDIDATE_APPROVED",
                      },
                      {
                        label: "Reprovação de Candidatura remota do Candidato",
                        value: "REMOTE_CANDIDATE_REJECTED",
                      },
                      {
                        label: "Envio de Convite para Entrevista",
                        value: "REMOTE_CANDIDATE_INVITE",
                      },
                    ]}
                  />
                </div>
              </div> */}
              <div className="row">
                <div className="col">
                  <Input
                    register={register}
                    name="user"
                    label="Nome do colaborador"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="cate_id"
                    label="Unidade Cate:"
                    placeholder={`Selecione uma Unidade Cate`}
                    control={control}
                    options={cates.map((c) => {
                      return { label: c.name, value: c.id };
                    })}
                  />
                </div>
              </div>
              <Divider />
              <div className="report-actions">
                <button className="btn btn-primary" type="submit">
                  Exportar
                </button>
                <button className="btn btn-secondary" type="button">
                  Limpar
                </button>
              </div>
            </form>
          </Collapse>
        </div>
      </div>
    </ReportItemStyled>
  );
}

export default AttendancesReport;
