import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useSearchParams, useNavigate } from "react-router-dom";
import { validateCPF } from "validations-br";
import api from "../../config/api";
import { maskCPF } from "../../helpers/mask";
import PageHeader from "./../../components/PageHeader";
import Input from "./../../components/FormHook/Input";
import SimpleSelect from "./../../components/FormHook/SimpleSelect";
import { format } from "date-fns";
import AuthContext from "../../contexts/auth";

let userSchema = Yup.object({
  name: Yup.string().required("Preencha o campo Nome"),
  email: Yup.string()
    .email("Compo Email com formato invalido")
    .required("Preencha o campo Email"),
  cpf: Yup.string()
    .required("Preencha o campo CPF")
    .test("is-cpf", "CPF invalido", (value) => validateCPF(value)),
  cate_id: Yup.number()
    .required()
    .min(1, "Você precisa selecionar uma unidade do CATE para esse usuário"),
  birthDate: Yup.date()
    .typeError("Data esta no formato errado")
    .required("data requirida")
    .min(new Date(1900, 0, 1)),
  updatePassword: Yup.boolean(),
  password: Yup.string()
    // .required("Senha requirida")
    .min(6, "A senha precisa ter no minimo 6 caracteres")
    .when("updatePassword", {
      is: true,
      then: Yup.string().required("Informe a senha do usuário"),
    })
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "A senha precisa ter um letra maiuscula, um letra minuscula, um número e um caracter especial"
    ),
});

export default function UpdateUser({ selfEdit }) {
  const { register, handleSubmit, reset, watch, setValue, getFieldState } =
    useForm();
  let [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [id, setId] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [cates, setCates] = useState([]);
  const [loading, setLoading] = useState(false);
  const cpfWatch = watch("cpf", "value");

  useEffect(async () => {
    let id = searchParams.get("id");
    if (selfEdit) {
      id = user?.auth?.user?.id;
    }
    await getCates();
    if (id) {
      setId(id);
      api.get(`/users/${id}`).then((resp) => {
        reset({
          name: resp.data.name,
          email: resp.data.email,
          role: resp.data.role,
          cpf: resp.data.cpf,
          birthDate: format(new Date(resp.data.birth_date), "yyyy-MM-dd"),
        });
        setTimeout(() => {
          setValue("cate_id", resp.data.cate_id);
        }, 500);
      });
    } else {
      reset({ cate_id: 0, role: 0 });
      setUpdatePassword(true);
    }
  }, []);

  useEffect(() => {
    setValue("cpf", maskCPF(cpfWatch));
  }, [cpfWatch]);

  async function getCates() {
    const response = await api.get(`cates?per_page=500`);
    setCates(response.data.data);
  }

  async function onSubmit(data) {
    if (!updatePassword) {
      delete data.password;
    }
    data.birth_date = data.birthDate;
    data.updatePassword = updatePassword;

    try {
      await userSchema.validateSync(data, { abortEarly: false });
    } catch (e) {
      toast.error(e.errors[0]);
      return false;
    }

    try {
      let response = id
        ? await api.put(`/users/${id}`, data)
        : await api.post(`/users`, data);
    } catch (e) {
      if (e.response.status === 422) {
        toast.error(e.response.data.message);
        return false;
      }
      toast.error(`Erro ao cadastrar usuário`);
      return false;
    }

    toast.success(`Usuário ${id ? `editado` : `criado`} com sucesso`);
    navigate(selfEdit ? `/meu-perfil` : `/usuarios`);
  }

  return (
    <div>
      <PageHeader
        title={
          selfEdit ? `Meu Perfil` : id ? `Editar usuário` : `Criar novo usuário`
        }
        actions={[]}
      />
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input label={`Nome:`} name="name" register={register} />
          <Input label={`Email:`} name="email" register={register} />

          <div className="row">
            <div className="col">
              <SimpleSelect
                disabled={selfEdit ? true : false}
                register={register}
                label={`Tipo de usuário:`}
                name="role"
              >
                <option value="0">Selecione um tipo para esse usuário</option>
                <option value="manager">Gestor</option>
                <option value="consultant">Atendente</option>
                <option value="acquirer">Captador</option>
              </SimpleSelect>
            </div>
            <div className="col">
              <Input label={`CPF:`} name="cpf" register={register} />
            </div>
            <div className="col">
              <Input
                label={`Data de nascimento:`}
                type="date"
                name="birthDate"
                register={register}
              />
            </div>
          </div>
          <SimpleSelect
            register={register}
            label={`Unidade do CATE:`}
            name="cate_id"
          >
            <option value="0">Selecione uma unidade do CATE</option>
            {cates.map((cate) => {
              return (
                <option key={uuidv4()} value={cate.id}>
                  {cate.name}
                </option>
              );
            })}
          </SimpleSelect>

          <Input
            label={`Senha:`}
            type="password"
            name="password"
            disabled={!updatePassword}
            register={register}
          />
          {id ? (
            <div className="form-check">
              <input
                type="checkbox"
                checked={updatePassword}
                onChange={(e) => setUpdatePassword(!updatePassword)}
                className="form-check-input"
                id="update_password"
              />
              <label className="form-check-label" htmlFor="update_password">
                Atualizar senha de usuário?
              </label>
            </div>
          ) : null}

          <div className="form-text">Mínimo 8 caracteres.</div>
          <div className="form-text">Ao menos 1 letra maiúscula.</div>
          <div className="form-text">Ao menos 1 número.</div>
          <div className="form-text">Ao menos 1 caractere especial.</div>
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary mt-3"
          >
            {id ? `Salvar` : `Cadastrar`}
          </button>
        </form>
      </div>
    </div>
  );
}
