export const maskCPF = (value = "") => {
  if (value === null) {
    return null;
  }
  return value
    .replace(/[^\d]/g, "")
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    .substring(0, 14);
};

export const maskCNPJ = (value = "") => {
  if (value === null) {
    return null;
  }
  return value
    .replace(/[^\d]/g, "")
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
    .substring(0, 18);
};

export const maskCEP = (value = "") => {
  if (value === null) {
    return null;
  }
  return value
    .replace(/[^\d]/g, "")
    .replace(/(\d{5})(\d{3})/, "$1-$2")
    .substring(0, 9);
};

export const maskPhone = (value = "") => {
  if (value === null) {
    return null;
  }
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/g, "($1)$2")
    .replace(/(\d)(\d{4})$/, "$1-$2")
    .replace(")", ") ")
    .substring(0, 15);
};
