import styled from "styled-components";

export const CompanyDetailsContainer = styled.div`
  .company-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    align-items: center;
  }

  .company-header-actions {
    display: flex;
    gap: 10px;
  }

  .company-body {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
  }

  .job-filters-bar {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    width: 100%;
    height: 60px;
    left: 0;
    margin-bottom: 20px;

    .container {
      height: 100%;
    }

    form {
      display: flex;
      height: 100%;
      align-items: center;

      .job-filters-bar-main {
        flex: 2;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .react-select-item {
          flex: 1;
        }
      }

      .job-filters-bar-order {
        flex: 1;
      }
    }
  }

  .job-list-action-container {
    display: flex;
    justify-content: end;
    padding: 1rem;
  }
`;

export const CompanyListContainer = styled.div`
  .companies-list {
    row-gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .kpis {
    display: flex;
    padding: 0;
    margin: 2rem 0;
    justify-content: space-between;
    gap: 1rem;
  }

  .kpis .kpi-item {
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    font-family: "Raleway", sans-serif;
    flex: 1;
    justify-content: space-between;
  }

  .kpis .kpi-item .kpi-item-title {
    font-size: 1rem;
  }

  .kpis .kpi-item .kpi-item-value {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .companies-list-item {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #b2aeae;
    padding: 1rem 2rem;
    font-family: "Raleway", sans-serif;
    margin-bottom: 10px;

    h3 {
      font-size: 1.4rem;
      margin-bottom: 0;
    }

    .total-jobs-label {
      font-weight: bold;
      font-size: 0.8rem;
    }

    .companies-list-item-actions {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .company-list-item-badges {
      display: flex;
      gap: 10px;
      margin: 10px 0;
    }
  }

  .company-job-status-container {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 2rem;
  }

  .company-job-status-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 50%;

    .job-status {
      width: 16px;
      height: 16px;
      background: #ccc;
      border-radius: 100%;
    }

    .job-status-active {
      background: #078484;
    }

    .job-status-schedule {
      background: #db9526;
    }

    .job-status-closed {
      background: #a50605;
    }
  }
`;

export const UpdateStyeldContainer = styled.div`
  .skip-cep-container {
    display: flex;
    align-items: end;
  }

  .card {
    margin-top: 30px;

    .card-body {
      padding: 2rem 3rem;
    }
  }

  .add-responsables-container {
    row-gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .add-responsable-list-item {
    border: 2px solid #656565;
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  .add-responsable-list-item-actions {
    display: flex;
    gap: 20px;
  }
`;
