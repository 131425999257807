import React from "react";
import styled from "styled-components";
import { maskCPF } from "./../../../../helpers/mask";
import { WP_PLUGIN_USER_PROFILE_PAGE } from "./../../../../config/consts";
// import { Container } from './styles';

const TranslateWorkHistory = {
  "Para quem já trabalhou e está desempregado": "Não estou trabalhando",
  "Para quem nunca trabalhou e busca o primeiro emprego": "Nunca trabalhei",
  "Para quem está trabalhando": "Estou trabalhando",
};

const CandidateInfoStyled = styled.div`
  font-family: "Raleway", sans-serif;
  .card {
    border: none;
    -webkit-box-shadow: 5px 5px 10px 2px #cccccc;
    box-shadow: 5px 5px 10px 2px #cccccc;
    align-items: center;

    .card-header {
      border-radius: 0;
      border: 0;
      padding: 0;
      background-color: #105ab5;
      color: white;
      margin-top: 15px;
      width: fit-content;
      padding: 0.5rem 1.5rem;
      font-family: "Raleway", sans-serif;
      font-weight: bold;
      font-size: 1.5rem;
    }

    .card-title {
      text-transform: uppercase;
      margin-bottom: 0px;
      margin-top: 1rem;
    }

    .card-img-top {
      width: 160px;
      height: 160px;
      margin: 0 auto;
      border-radius: 100%;
      margin-top: 20px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 10px 0;
      text-align: center;
    }

    li {
      color: #595959;
    }

    .info-tip {
      background: #f0f0f0;
      text-align: center;
      padding: 0.5rem 2rem;
      font-weight: 100;
      margin: 20px 0px;
    }
  }
`;

function CandidateInfos({ candidate, attendanceMode = false }) {
  return (
    <CandidateInfoStyled>
      <div className="card">
        <h2 className="card-header">Meu Perfil</h2>
        <img src={candidate.picture} alt="" className="card-img-top" />
        <div className="card-body">
          <h3 className="card-title">{candidate.full_name}</h3>
          <div>
            <ul>
              <li>{` ${candidate.email}`}</li>
              <li>
                <b>Idade:</b>
                {` ${candidate.age || "-"}`}
              </li>
              <li>
                <b>Nacionalidade:</b>
                {` ${candidate.nacionality || "-"}`}
              </li>
              <li>
                <b>Experiência:</b>
                {` ${TranslateWorkHistory[candidate.work_history]}`}
              </li>
              <li>
                <b>Escolaridate:</b>
                {` ${candidate.schooling_level?.name}`}
              </li>
              <li>
                <b>CPF:</b>
                {` ${maskCPF(candidate.cpf)}`}
              </li>
              <li>
                <b>Raça:</b>
                {` ${candidate.ethnic_group}`}
              </li>
              <li>
                <b>Gênero:</b>
                {` ${candidate.gender}`}
              </li>
              <li>
                <b>Endereço:</b>
                {` ${candidate.address_str || "-"}`}
              </li>
              <li>
                <b>Reside em São Paulo:</b>
                {` ${candidate.live_in_sp ? "Sim" : "Não"}`}
              </li>
            </ul>
          </div>
          {!attendanceMode ? (
            <React.Fragment>
              <div className="info-tip">
                Para alterar ou corrigir os dados de perfil clique no botão
                abaixo.  
              </div>
              <a
                className="btn btn-alternative"
                href={WP_PLUGIN_USER_PROFILE_PAGE}
              >
                Alterar ou corrigir dados de perfil
              </a>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </CandidateInfoStyled>
  );
}

export default CandidateInfos;
