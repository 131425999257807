import React from "react";
import styled from "styled-components";

const DividerStyled = styled.div`
  width: 100%;
  border-top: 1px dotted #989898;
  margin: 10px 0px;
`;

export default function index() {
  return <DividerStyled></DividerStyled>;
}
