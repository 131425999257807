import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select/async";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import api from "../../../config/api";

const StyledInput = styled.div``;

const selectStyles = {
  control: (styles) => ({
    ...styles,
    color: "#000",
    borderRadius: "0px",
    border: "1px solid #848484",
    fontSize: "0.8rem",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#000",
      ":active": {
        ...styles[":active"],
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
  }),
};

function CompanySelect({
  label,
  name,
  isMulti = false,
  control,
  disabled = false,
  required = false,
  requiredForJob = false,
  maxOptions = false,
  withCodePrefix = false,
}) {
  const id = uuidv4();

  async function promiseOptions(inputValue) {
    if (inputValue.length < 3) {
      return false;
    }
    let response = await api.get(`/companies-select`, { params: { q: inputValue } });
    return response.data;
  }

  return (
    <StyledInput>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      {required ? <span className="required">*</span> : null}
      {requiredForJob ? (
        <span className="required required-secondary">*</span>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              loadOptions={promiseOptions}
              cacheOptions
              inputId={id}
              placeholder={`Digite 3 caracteres para buscar uma empresa`}
              isMulti={isMulti}
              styles={selectStyles}
              noOptionsMessage={() => `Nenhuma empresa disponível`}
              onChange={(options) => {
                onChange(options);
              }}
              onBlur={onBlur}
              value={value}
              isOptionDisabled={(option) => !maxOptions ? false : value.length >= maxOptions}
              isClearable={true}
              defaultValue={value}
              isDisabled={disabled}
            />
          );
        }}
      />
      {maxOptions ? (
        <small className="alert">
          Maximo de {maxOptions} seleções
        </small>
      ) : null}
    </StyledInput>
  );
}

export default CompanySelect;
