import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { format, isValid } from "date-fns";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { UsersContainerStyled } from "./style";
import api from "./../../config/api";
import AuthContext from "./../../contexts/auth";
import ListItem from "./../../components/ListItem";
import { maskCPF } from "../../helpers/mask";
import PageHeader from "./../../components/PageHeader";
import Pagination from "./../../components/Pagination";
import { confirm } from "./../../components/Confirmation";
import FilterSidebar from "./../../components/FiltersSidebar";
import Input from "./../../components/FormHook/Input";
import ReactSelect from "./../../components/FormHook/ReactSelect";

const profiles = {
  manager: "Gestor",
  consultant: "Atendente",
  acquirer: "Captador",
};

export default function Users() {
  const { register, handleSubmit, reset, control, formState } = useForm();
  const [users, setUsers] = useState([]);
  const [activeFilters, setActiveFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    handleSearch();
  }, []);

  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   setPage(1);
  //   handleSearch();
  // }

  async function handleDestroy(id) {
    if (
      await confirm("Deseja realmente excluir o usuário?", "Sim", "Não", {
        title: "Excluir Usuário?",
      })
    ) {
      try {
        let response = await api.delete(`users/${id}`);
        toast.success("Usuário deletado com sucesso");
      } catch (e) {
        toast.error("Erro ao deletar o Usuário");
      }
    }
    handleSearch();
  }

  async function handleUnDestroy(id) {
    try {
      let response = await api.put(`users/deleted/${id}`);
      toast.success("usuário reativado com sucesso");
    } catch (e) {
      toast.error("Erro ao reativado o usuário");
    }
    handleSearch();
  }

  async function handleFilter(data) {
    handleSearch(1, data);
  }

  async function handleSearch(nextPage = 1, filters = null) {
    let resp = await api.get(`users`, {
      params: {
        page: nextPage,
        ...filters,
      },
    });
    setPage(nextPage);
    setActiveFilters(filters);
    setUsers(resp.data.data);
    setTotalPages(resp.data.last_page);
  }

  async function handleClearFilter() {
    reset({ role: null, q: null });
    handleSearch(1, null);
  }

  return (
    <UsersContainerStyled>
      <PageHeader
        title={`Todos os usuários`}
        actions={[
          <Link to={`/usuarios/editar?id=${user.auth.user.id}`}>
            Meu perfil
          </Link>,
          <Link to={`/usuarios/editar`}>Cadastrar novo usuário</Link>,
        ]}
      />
      <div className="container">
        <div className="row">
          <div className="col-3">
            <FilterSidebar title={"Busca por usuários"}>
              <form onSubmit={handleSubmit(handleFilter)}>
                <ReactSelect
                  name="role"
                  control={control}
                  options={[
                    { label: "Gestor", value: "manager" },
                    { label: "Atendente", value: "consultant" },
                    { label: "Captador", value: "acquirer" },
                  ]}
                  placeholder="Filtre por tipo"
                  label="Perfil:"
                />
                <Input
                  register={register}
                  name="q"
                  label="Nome ou CPF (sem pontos):"
                />
                <button type="submit" className="btn btn-primary">
                  Buscar
                </button>
                <button
                  type="button"
                  onClick={handleClearFilter}
                  className="btn btn-secondary"
                >
                  Limpar
                </button>
              </form>
            </FilterSidebar>
          </div>
          <div className="col-9">
            <div className="row">
              {users.map((user) => {
                return (
                  <ListItem
                    key={uuidv4()}
                    title={`${user.name}${
                      user.deleted_at ? ` (deletado)` : ``
                    }`}
                    subtitle={profiles[user.role]}
                    infos={[
                      { label: "Email", value: user.email },
                      { label: "CPF", value: maskCPF(user.cpf) },
                      {
                        label: "Data de nascimento",
                        value: format(new Date(user.birth_date), "dd/MM/yyyy"),
                      },
                    ]}
                    actions={[
                      <Link
                        to={`/usuarios/editar?id=${user.id}`}
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />{" "}
                        Editar
                      </Link>,
                      <button
                        onClick={() => handleDestroy(user.id)}
                        className="btn btn-secondary"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />{" "}
                        Excluir
                      </button>,
                      user.deleted_at ? (
                        <button
                          onClick={() => handleUnDestroy(user.id)}
                          className="btn btn-secondary"
                        >
                          <FontAwesomeIcon icon="fa-solid fa-trash-arrow-up" />{" "}
                          Reativar
                        </button>
                      ) : null,
                    ]}
                  />
                );
              })}
            </div>
            <Pagination
              page={page}
              total={totalPages}
              onChange={(page) => handleSearch(page, activeFilters)}
            />
          </div>
        </div>
      </div>
    </UsersContainerStyled>
  );
}
