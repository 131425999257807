import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CandidateContext from "./../../contexts/candidate";
import api from "../../config/api";
import { HeaderCandidatesStyled } from "./style";

function HeaderCandidates() {
  const { candidate } = useContext(CandidateContext);
  const [notifications, setNotifications] = useState([]);
  const navigator = useNavigate();

  useEffect(() => {
    if (candidate) {
      api.get(`candidates/notifications`).then((resp) => {
        setNotifications(resp.data);
      });
    }
  }, [candidate]);

  async function handleReadNotification(id) {
    api.put(`candidates/notifications/${id}`).then((resp) => {
      setNotifications(resp.data);
    });
  }

  async function handleDetailsNoty(id, job_application_id) {
    await handleReadNotification(id);
    navigator(`/candidato?noty=${job_application_id}`);
  }

  return candidate ? (
    <HeaderCandidatesStyled>
      <nav className="navbar navbar-light">
        <Link to="/candidato" className="navbar-brand">
          <img
            src={process.env.PUBLIC_URL + "/assets/header-logos-white.png"}
            className="d-inline-block align-top"
            alt="Cate vagas"
          />
        </Link>
        <div className="menu-container">
          <Dropdown>
            <Dropdown.Toggle as="button">
              <div className="menu">
                <span className="menu-label">
                  <span aria-hidden="true">Olá</span>,{" "}
                  {candidate?.full_name?.split(" ")[0]}
                </span>
                <div className="user-profile">
                  <img
                    src={
                      candidate.picture ||
                      `http://2.gravatar.com/avatar/b193c77f9a456cc136fce6851afd25e8?s=96&d=mm&r=g`
                    }
                    alt="perfil"
                    className="candidate-image rounded-circle"
                  />
                  <span className="badge">{notifications.length}<span class="visually-hidden"> notificações</span></span>
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="header">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/user.svg"}
                  alt=""
                />
                <span>Olá, {candidate?.full_name?.split(" ")[0]}</span>
              </div>
              <div className="links">
                <Link to="/candidato" className="nav-link">
                  VER MEU PERFIL
                </Link>
                <Link to="/candidato/vagas" className="nav-link">
                  VER TODAS AS VAGAS
                </Link>
                <Link to="/candidato/curriculo" className="nav-link">
                  VER MEU CURRÍCULO
                </Link>
              </div>

              <div className="notifications-menu">
                <h6 className="noty-header">
                  {notifications.length === 0
                    ? `Nenhuma notificacão`
                    : `Notificacões`}
                </h6>
                <ul className="noty-list">
                  {notifications.map((noty) => {
                    return (
                      <li key={`noty-id-${noty.id}`} className="noty">
                        <div className="noty-text">
                          <span className="noty-title">{noty.title}</span>
                          <span className="noty-text">{noty.text}</span>
                        </div>
                        <div className="noty-action">
                          <FontAwesomeIcon
                            icon="fa-solid fa-eye"
                            className="read-notification"
                            onClick={() =>
                              handleDetailsNoty(
                                noty.id,
                                noty.job_application_id
                              )
                            }
                          />
                          <FontAwesomeIcon
                            icon="fa-solid fa-xmark"
                            className="read-notification"
                            onClick={() => handleReadNotification(noty.id)}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
      <div className="warning-bar">
        Você está no ambiente do<b>&#160;vagas do cate</b>
      </div>
    </HeaderCandidatesStyled>
  ) : null;
}

export default HeaderCandidates;
