import React from "react";

// import { Container } from './styles';

function Footer() {
  return (
    <footer className="text-muted">
      <div className="container">
      </div>
    </footer>
  );
}

export default Footer;
