import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { Collapse } from "react-collapse";
import { useForm } from "react-hook-form";
import { format } from "date-fns";

import { ReportItemStyled } from "./../styles";
import api from "./../../../config/api";

import Input from "./../../../components/FormHook/Input";
import WithMask from "./../../../components/FormHook/WithMask";
import ReactSelect from "./../../../components/FormHook/ReactSelect";
import OccupationSelect from "./../../../components/FormHook/OccupationSelect";
import Switch from "./../../../components/FormHook/Switch";
import DatePicker from "./../../../components/FormHook/DatePick";

import {
  optionsBenefits,
  optionsGender,
  optionsRegion,
  optionsCNH,
  optionsPCD,
} from "./../../Jobs/database";

import Divider from "./../../../components/Divider";
import FileDownload from "js-file-download";
import { toast } from "react-toastify";

function ResumeReport() {
  const [isCollapsed, setIsCollapsed] = useState(true); //default collapsed
  const [schoolingLevels, setSchoolingLevels] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState,
  } = useForm();

  const [finalDate, setFinalDate] = useState(null);
  const startAtWatch = watch("start_at");

  useEffect(() => {
    api.get(`schooling-levels`).then((resp) => {
      setSchoolingLevels(resp.data);
    });
  }, []);

  useEffect(() => {
    if (!startAtWatch) {
      return false;
    }
    let start = new Date(startAtWatch);
    let target = new Date(startAtWatch);
    let max = target.setDate(start.getDate() + 45);
    let today = new Date();
    setFinalDate(max > today ? today : max);
    setValue("end_at", null);
  }, [startAtWatch]);

  async function onSubmit(data) {
    let now = format(new Date(), "yyyyMMddHmm");
    if (!data.start_at || !data.end_at) {
      toast.warning("Selecione um periodo.");
      return false;
    }
    data.start_at = format(new Date(data.start_at), "yyyy-MM-dd");
    data.end_at = format(new Date(data.end_at), "yyyy-MM-dd");
    data.occupation = data.occupation?.map((occ) => occ.value);
    if (!formState.dirtyFields.live_in_sp) {
      delete data.live_in_sp;
    }
    api({
      url: "reports/candidates",
      method: "GET",
      responseType: "blob",
      params: data, // Important
    }).then((response) => {
      FileDownload(response.data, `relatorio_currículos_${now}.xlsx`);
    });
  }

  function clear() {
    reset();
    reset({ occupations: null, cfp: "" });
  }

  return (
    <ReportItemStyled>
      <div className="card" key={uuidv4()}>
        <div className="card-body">
          <div className="header-job">
            <h4 className="card-title">Currículos cadastrados</h4>
            <span
              className="collapse-action"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              )}
              {isCollapsed ? "Abrir" : "Fechar"}
            </span>
          </div>
          <Divider />
          <Collapse isOpened={!isCollapsed}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <DatePicker
                    name="start_at"
                    max={new Date()}
                    control={control}
                    label="Data de inicio:"
                  />
                </div>
                <div className="col">
                  <DatePicker
                    name="end_at"
                    control={control}
                    label="Data de fim:"
                    max={finalDate ? new Date(finalDate) : null}
                    min={startAtWatch}
                    disabled={!startAtWatch}
                  />
                </div>
                <div className="col">
                  <WithMask
                    name="cpf"
                    placeholder="CPF"
                    control={control}
                    mask={"999.999.999-99"}
                    label="CPF:"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="schooling_level_id"
                    control={control}
                    options={schoolingLevels.map((sl) => {
                      return { label: sl.name, value: sl.id };
                    })}
                    placeholder="Selecione a escolaridade mínima"
                    label="Escolaridade miníma:"
                  />
                </div>
                <div className="col">
                  <OccupationSelect
                    name="occupation"
                    placeholder="Ocupações"
                    control={control}
                    isMulti
                    label="Ocupações:"
                  />
                </div>
                <div className="col">
                  <Switch
                    name="live_in_sp"
                    placeholder="Reside em SP"
                    register={register}
                    label="Reside em SP:"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="region"
                    placeholder="Região"
                    isMulti
                    control={control}
                    options={optionsRegion}
                    label="Região:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="gender"
                    placeholder="Gênero"
                    control={control}
                    options={optionsGender}
                    label="Gênero:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="ethnic_group"
                    placeholder="Raça"
                    isMulti
                    control={control}
                    options={[
                      { label: "Branca", value: "Branca" },
                      { label: "Parda", value: "Parda" },
                      { label: "Preta", value: "Preta" },
                      { label: "Amarela", value: "Amarela" },
                      { label: "Indígena", value: "Indígena" },
                    ]}
                    label="Raça:"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="age"
                    placeholder="Idade"
                    control={control}
                    options={[
                      "De 16 a 18 anos",
                      "Maiores de 60 anos",
                      "De 30 a 39 anos",
                      "De 40 a 59 anos",
                      "De 25 a 29 anos",
                      "De 18 a 24 anos",
                      "Menores de 18 anos",
                    ].map((op) => {
                      return { label: op, value: op };
                    })}
                    label="Idade:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="cnh"
                    isMulti
                    placeholder="Possui CNH"
                    control={control}
                    options={optionsCNH}
                    label="Possui CNH:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="pcd"
                    isMulti
                    placeholder="Pessoa com deficiência"
                    control={control}
                    options={optionsPCD}
                    label="Pessoa com deficiência:"
                  />
                </div>
              </div>
              <Divider />
              <div className="report-actions">
                <button className="btn btn-primary" type="submit">
                  Exportar
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={clear}
                  type="button"
                >
                  Limpar
                </button>
              </div>
            </form>
          </Collapse>
        </div>
      </div>
    </ReportItemStyled>
  );
}

export default ResumeReport;
