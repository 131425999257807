import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const ListItemStyled = styled.div`
  .card-infos span {
    font-weight: bold;
  }

  .card-actions {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
`;

function ListItem({ infos = [], title, subtitle, actions = [] }) {
  return (
    <div key={uuidv4()} className="col-4 mb-3">
      <ListItemStyled>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{subtitle}</h6>
            <hr />
            {infos.map((info) => {
              return (
                <div className="card-infos" key={uuidv4()}>
                  <span>{info.label}:</span>
                  {` ${info.value || 'N/A'}`}
                  <br />
                </div>
              );
            })}
            <hr />
            <div className="card-actions">
              {actions.map((action) => (
                <div className="action" key={uuidv4()}>
                  {action}
                </div>
              ))}
            </div>
          </div>
        </div>
      </ListItemStyled>
    </div>
  );
}

export default ListItem;
