import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import api from "../../config/api";
import AuthContext from "../../contexts/auth";

import { SigninStyles } from "./../Signin/styles";

function ResetPassword() {
  const { signIn } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [sendMail, setSendMail] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!setEmail) {
      toast.error("Preencha todos os campos");
    }

    try {
      let response = await api.post(`reset-password`, { email });
      setSendMail(true);
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  return (
    <SigninStyles>
      {sendMail ? (
        <p className="reset-pass-reply">
          Enviamos um email para para efetuar seu reset de senha. <br />{" "}
          <Link to="/">Voltar para login.</Link>
        </p>
      ) : (
        <form onSubmit={handleSubmit}>
          <img
            width="100%"
            alt="Cate - Centro de Apoio ao Trabalhador e Empreendedor"
            src={process.env.PUBLIC_URL + "/assets/logo-cate-login.png"}
          />
          <div className="login-welcome">
            <h2>Reset de senha</h2>
          </div>
          <div className="mb-3">
            <label htmlFor="input_email" className="form-label">
              E-mail
            </label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="input_email"
            />
            <Link to="/" className="forgot-password">
              Voltar para o login
            </Link>
          </div>
          <button type="submit" className="btn btn-primary">
            Enviar
          </button>
        </form>
      )}
    </SigninStyles>
  );
}

export default ResetPassword;
