import React from "react";
import styled from "styled-components";
import { CurrencyInput, Currencies, Locales } from "input-currency-react";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

const StyledInput = styled.div``;

export default function WithMask({
  label,
  name,
  control,
  required = false,
  requiredForJob = false,
  placeholder,
  disabled = false,
  mask,
  phoneOrCell = false,
  alwaysShowMask = false,
}) {
  const id = uuidv4();
  return (
    <StyledInput>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      {required ? <span className="required">*</span> : null}
      {requiredForJob ? (
        <span className="required required-secondary">*</span>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <CurrencyInput
              value={value ? value.toString() : ""}
              disabled={disabled}
              className="form-control"
              options={{
                style: "currency",
                allowNegative: false,
                locale: Locales["Portuguese (Brazil)"],
                i18nCurrency: Currencies["Brazilian Real"],
              }}
              onChangeEvent={(_, maskedValue) => {
                onChange(maskedValue);
              }}
              required={true}
            />
          );
        }}
      />
    </StyledInput>
  );
}
