import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { maskCPF } from "./../../../../helpers/mask";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import api from "../../../../config/api";
import Divider from "./../../../../components/Divider";
import ReactSelect from "./../../../../components/FormHook/ReactSelect";
import { toast } from "react-toastify";

// import Divider from "./../Divider";

const ModalApproveCandidateApplicationStyled = styled.div`
  font-family: "Raleway", sans-serif;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;

  .header {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .header .strong {
    font-weight: bold;
    font-size: 1.6rem;
  }

  .content {
    .react-select-item {
      padding: 1rem 0;
    }

    .actions-form {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
    }

    .actions-form-step2 {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
    }
  }

  .content .strong {
    font-weight: bold;
  }

  h1 {
    font-size: 1.5rem;
    color: black;
  }

  h2 {
    font-size: 1.3rem;
    text-transform: uppercase;
    color: black;
  }

  .image-profile {
    margin: 0 auto;
    display: block;
    margin-top: 40px;
    border-radius: 100%;
    width: 65%;
  }

  .adress-infos-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }

  .resume-infos {
    color: #727272;
    margin-bottom: 5px;
    .resume-infos-title {
      font-weight: bold;
    }
    .resume-infos-content {
    }
  }
`;

const ResumeInfo = ({ title, content }) => {
  return (
    <div className="resume-infos">
      <span className="resume-infos-title">{title}: </span>
      <span className="resume-infos-content">{content}</span>
    </div>
  );
};

function ModalApproveCandidateApplication({
  open,
  candidate,
  jobId,
  job,
  onClose,
  updateList,
  attendanceMode = false,
}) {
  const { register, handleSubmit, reset, control, formState } = useForm();
  const [jobSchedules, setJobSchedules] = useState([]);
  const [application, setApplication] = useState(null);
  useEffect(() => {
    if (!open) {
      return false;
    }
    api.get(`/jobs/${jobId}/schedules`).then((resp) => {
      setJobSchedules(resp.data);
    });
  }, [open]);

  async function onSubmit(data) {
    if (!data.schedule_id) {
      toast.error(`Selecione um horario para a entrevista`);
      return false;
    }
    try {
      let response = await api.put(`jobs/${jobId}/applications`, {
        schedule_id: data.schedule_id,
        candidate_id: candidate.id,
        status: "APPROVED",
        attendance: attendanceMode,
      });
      setApplication(response.data);

      if (attendanceMode) {
        localStorage.setItem(`@catevagas:atendimento:${candidate.uuid}`, true);
      }

      toast.success("Concluido.");
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  return jobSchedules ? (
    <Modal
      className="modal-approve-candidate-application"
      show={open}
      onHide={onClose}
      backdrop={true}
      keyboard={false}
    >
      <ModalApproveCandidateApplicationStyled>
        <Modal.Body>
          <div className="header">
            <span className="strong">{candidate?.full_name}</span>
            <span className="strong">{`CPF: ${maskCPF(candidate?.cpf)}`}</span>
            <span className="mt-4">Encaminhado para a vaga:</span>
            <span className="strong">{job.occupation?.name}</span>
            {application ? (
              <React.Fragment>
                <span className="mt-4">Esse é o protocolo de ativação:</span>
                <span className="strong">{application?.protocol}</span>
              </React.Fragment>
            ) : null}
          </div>
          <Divider />
          <div className="content mt-6">
            {!application ? (
              <React.Fragment>
                <span className="strong">
                  Datas e horários disponíveis para a realização da entrevista:
                </span>
                <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                  <ReactSelect
                    noOptionsMessage="Nenhuma agenda disponível"
                    name="schedule_id"
                    placeholder="Selecione uma data:"
                    control={control}
                    options={jobSchedules?.map((schedule) => {
                      return {
                        label: `De: ${format(
                          new Date(schedule.start_at),
                          "dd/MM/yyyy"
                        )} Até ${format(
                          new Date(schedule.end_at),
                          "dd/MM/yyyy"
                        )} - Das: ${format(
                          new Date(schedule.start_at),
                          "HH:mm"
                        )} às ${format(new Date(schedule.end_at), "HH:mm")}`,
                        value: schedule.id,
                      };
                    })}
                  />
                  <Divider />
                  <div className="actions-form">
                    <button
                      type="button"
                      onClick={onClose}
                      className="btn btn-secondary"
                    >
                      Cancelar
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Encaminhar Candidato
                    </button>
                  </div>
                </form>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="strong">Dados da entrevista:</span>
                <div className="adress-infos-container">
                  {application.job?.is_meet_tell ? (
                    <React.Fragment>
                      {application.job?.meet_phones.map((phones, idx) => {
                        return (
                          <span
                            key={`meet-phones-details-key-${phones.id}`}
                          >{`Contato ${idx + 1}: ${phones.name} - ${
                            phones.phone
                          }`}</span>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span>{`${application.job?.adress?.adress}, ${application.job?.adress?.number} - ${application.job?.adress?.district}`}</span>
                      <span>{`CEP: ${
                        application.job?.adress?.cep || "-"
                      }`}</span>
                      <span>{`Complemento: ${
                        application.job?.adress?.complement || "-"
                      }`}</span>
                      <span>{`Referencia: ${
                        application.job?.adress?.reference || "-"
                      }`}</span>
                      <span>{`${application.job?.adress?.city} - ${application.job?.adress?.state}`}</span>
                    </React.Fragment>
                  )}

                  <span className="mt-2">{`De: ${format(
                    new Date(application.schedule.start_at),
                    "dd/MM/yyyy"
                  )} Até ${format(
                    new Date(application.schedule.end_at),
                    "dd/MM/yyyy"
                  )}`}</span>
                  <span>{`Das: ${format(
                    new Date(application.schedule.start_at),
                    "HH:mm"
                  )} às ${format(
                    new Date(application.schedule.end_at),
                    "HH:mm"
                  )}`}</span>
                  <span className="strong mt-2">
                    *O cidadão irá receber todos os dados por email
                  </span>
                  <Divider />
                  <div className="actions-form-step2">
                    <button
                      type="submit"
                      onClick={onClose}
                      className="btn btn-primary"
                    >
                      Continuar gerenciando esta vaga
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
      </ModalApproveCandidateApplicationStyled>
    </Modal>
  ) : null;
}

export default ModalApproveCandidateApplication;
