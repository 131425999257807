import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    color: black;
    font-family: 'Open Sans', sans-serif;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    color: #0D3691;
  }

  small {
    font-size: 0.7rem;
    margin: 0 !important;
    padding: 0 !important;
  }

  small.info {
    color: #0c3691;
  }

  small.alert {
    color: #ff6600;
  }

  span.required-secondary {
    color: #ff6600 !important;
  }

  input {
    border-radius: 0px;
    border: 1px solid #848484;
    font-size: 0.8rem;
  }

  .skip-cep-container {
    display: flex;
    justify-content: start;
    align-items: end;
  }

  .input-error-label {
    color: red;
    font-size: 0.6rem;
  }

  .keep-format-text {
    white-space: pre-line;
  }

  .alert {
    padding: 0.3rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    font-size: 0.8rem;
    font-weight: bold;
  }

  .bg-warning {
    background-color: #db9526 !important;
  }

  .bg-danger {
    background-color: #a50605 !important;
  }

  .danger {
    color: #a50605 !important;
  }
  
  .bg-success {
    background-color: #078484 !important;
  }

  .bg-info {
    background-color: #1eabd3 !important;
  }

  .text-warning {
    color: #db9526 !important;
  }

  .text-danger {
    color: #a50605 !important;
  }
  
  .text-success {
    color: #078484 !important;
  }

  .text-info {
    color: #1eabd3 !important;
  }

  .small-danger {
    font-size: 0.7rem;
    font-weight: bold;
    color: #a50605;
  }

  h1.secondary,h2.secondary,h3.secondary,h4.secondary,h5.secondary,h6.secondary {
    color: #605b5b;
  }


  .form-label {
    margin-top: 1rem;
    font-family: 'Raleway', sans-serif;
  }

  span.required {
    color: #a50605;
    font-weight: bold;
    margin-left: 5px;
  }

  .btn {
    font-weight: 800;
    font-family: 'Raleway', sans-serif;
    border: 0;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    /* line-height: 2rem; */
  
    svg {
      /* margin-right: 0.5rem; */
    }
  }

  .btn:focus {
    outline: 2px solid black;
    box-shadow: inherit;
  }

  .variant-secondary {
    background: #0D3691 !important;
    color: white !important;
  }

  .btn-primary {
    background: #0D3691;
    color: white;
    border: 1px solid #0D3691;
  }

  .btn-primary:hover {
    background: white;
    color: #0D3691;
    border: 1px solid #0D3691;
  }

  .btn-secondary {
    background: #605B5B;
    color: white;
    border: 1px solid #605B5B;
  }

  .btn-secondary:hover {
    background: white;
    color: #605B5B;
    border: 1px solid #605B5B;
  }

  .btn-white {
    background: white;
    color: #0D3691;
    border: 1px solid white;
  }

  .btn-white:hover {
    background: white;
    color: #0D3691;
    border: 1px solid white;
  }

  .btn-alternative {
    background: #FF6600;
    color: white;
    border: 1px solid #FF6600;
  }

  .btn-alternative:hover {
    background: white;
    color: #FF6600;
    border: 1px solid #FF6600;
  }

  .form-control {
    border-radius: 0px;
    border: 1px solid #848484;
    font-size: 0.8rem;
  }

  .cursor-help {
    cursor: help;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  label {
    font-weight: bold;
    font-size: 1rem;
  }

  .info-tooltip {
    margin-left: 1rem;
    color: #ff6600;
    font-size: 1.3rem;
  }

  /* FOOTER */

  footer {
    /* background: #6c757d; */
    height: 200px;
    margin-top: 60px;
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
  }

  .modal-resume-candidate .modal-dialog {
    max-width: 60%;
  }
  // MOBILE
  @media only screen and (max-width: 600px) {
    .hide-on-mobile {
      display: none !important;
    }

    .mobile-mt-1 {
      margin-top: 1rem;
    }
  }

`;
