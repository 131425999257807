import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { maskCEP, maskPhone } from "../../helpers/mask";
import {
  companySchema,
  responsableSchema,
  adressSchema,
} from "../../helpers/validatorSchemas";
import PageHeader from "./../../components/PageHeader";
import Input from "./../../components/FormHook/Input";
import TextArea from "./../../components/FormHook/TextArea";
import WithMask from "./../../components/FormHook/WithMask";
import ReactSelect from "./../../components/FormHook/ReactSelect";
import SimpleSelect from "../../components/FormHook/SimpleSelect";
import { UpdateStyeldContainer } from "./style";
import api from "../../config/api";

import { optionsRegion } from "./../Jobs/database";
import { validatePhone } from "validations-br";

function CompaniesUpdate() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  const [skipCepValidation, setSkipCepValidation] = useState(false);
  const [id, setId] = useState(null);
  const [responsables, setResponsables] = useState([]);
  const [companyActivities, setCompanyActivities] = useState([]);
  const [editingResponsable, setEditingResponsable] = useState(null);
  const [hasPrimaryResponsable, setHasPrimaryResponsable] = useState(false);
  const [logo, setLogo] = useState(null);
  const [defaultLogo, setDefaultLogo] = useState(
    process.env.PUBLIC_URL + "/assets/default-logo.png"
  );
  let [searchParams] = useSearchParams();
  const cepWatch = watch("cep", "value");

  useEffect(() => {}, []);

  useEffect(async () => {
    let respCompanyActivities = await api.get(`/company-activities`);
    setCompanyActivities(respCompanyActivities.data);
    let id = searchParams.get("id");
    if (id) {
      setId(id);
      api.get(`/companies/${id}`).then((resp) => {
        setSkipCepValidation(true);
        reset({
          name: resp.data.name,
          description: resp.data.description,
          cnpj: resp.data.cnpj,
          collaborators_amount: resp.data.collaborators_amount,
          company_class: resp.data.company_class,
          email: resp.data.email,
          phone: resp.data.phone,
          activity_id: resp.data.activity_id,
          cep: resp.data.adress.cep,
          adress: resp.data.adress.adress,
          number: resp.data.adress.number,
          complement: resp.data.adress.complement,
          reference: resp.data.adress.reference,
          district: resp.data.adress.district,
          region: resp.data.adress.region,
          city: resp.data.adress.city,
        });
        if (resp.data.logo_url) {
          setDefaultLogo(resp.data.logo_url);
        }
        setResponsables(resp.data.responsables);
      });
    }
  }, [reset]);

  useEffect(() => checkHasPrimaryResponse(), [responsables]);

  useEffect(() => {
    setValue("cep", maskCEP(cepWatch));
    if (skipCepValidation) {
      return false;
    }
    if (cepWatch.length === 9) {
      axios.get(`https://viacep.com.br/ws/${cepWatch}/json/`).then((resp) => {
        if (resp.data.uf !== "SP") {
          toast.error("CEP digitado nao pertence ao Estado de Sao paulo");
          return false;
        }
        setValue("adress", resp.data.logradouro);
        setValue("district", resp.data.bairro);
        setValue("city", resp.data.localidade);
      });
    }
  }, [cepWatch]);

  async function validateImageLogo(file) {
    let validFileTypes = ["jpg", "jpeg", "png"];
    let reader = new FileReader();

    let extension = file.name.split(".").pop().toLowerCase();
    if (validFileTypes.indexOf(extension) === -1) {
      toast.error(
        "Formato inválido, a imagem precisa estar em um dos seguintes formatos: PNG, JPG, JPEG."
      );
      return false;
    }
    //Read the contents of Image File.
    reader.readAsDataURL(file);
    reader.onload = async function (e) {
      //Initiate the JavaScript Image object.
      let validationImage = new Image();
      validationImage.src = e.target.result;
      validationImage.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height != 480 || width > 640) {
          toast.error("O tamanho da imagem precisa ser de 640x480");
          return false;
        } else {
          setDefaultLogo(e.target.result);
          setLogo(file);
        }
      };
    };
  }

  async function updateLogo(companyId) {
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("logo_file", logo);

    api
      .post(`/companies/${companyId}/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success("Empresa cadastrada com sucesso");
        navigate(`/empresas`);
      })
      .catch((e) => {
        toast.error(e.response.message);
      });
  }

  async function onSubmit(data) {
    let adress = {
      adress: data.adress,
      number: data.number,
      complement: data.complement,
      reference: data.reference,
      district: data.district,
      region: data.region,
      cep: data.cep,
      city: data.city,
    };

    let company = {
      name: data.name,
      description: data.description,
      cnpj: data.cnpj,
      activity_id: data.activity_id,
      phone: maskPhone(data.phone),
      email: data.email,
      company_class: data.company_class,
      collaborators_amount: data.collaborators_amount,
    };

    try {
      await companySchema.validate(company, {
        abortEarly: false,
      });
      await adressSchema.validate(adress, {
        abortEarly: false,
      });
    } catch (e) {
      toast.error(e.errors[0]);
      return false;
    }

    if (!hasPrimaryResponsable) {
      toast.error("Nenhum responsável principal foi definido");
      return false;
    }

    if (responsables.length === 0) {
      toast.error("Adicione ao menos 1 responsável para essa empresa");
      return false;
    }

    let request = company;
    request.adress = adress;
    request.responsables = responsables;

    try {
      let response = id
        ? await api.put(`/companies/${id}`, request)
        : await api.post(`/companies`, request);

      if (logo) {
        updateLogo(response.data.id);
      } else {
        toast.success("Empresa cadastrada com sucesso");
        navigate(`/empresas`);
      }
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  async function handleDeleteResponsable(idx) {
    let exclude = window.confirm("Deseja realmente apagar o responsável?");
    if (!exclude) {
      return false;
    }
    if (responsables[idx].id) {
      try {
        await api.delete(`responsables/${responsables[idx].id}`);
      } catch (e) {
        toast.error(e.response.data.message);
        return false;
      }
    }

    setResponsables(responsables.filter((responsable, i) => i !== idx));
  }

  async function handleAddResponsable() {
    let responsable = {
      full_name: getValues("responsable_name"),
      email: getValues("responsable_email"),
      job_role: getValues("responsable_role"),
      phone: maskPhone(getValues("responsable_tell")),
      cell: maskPhone(getValues("responsable_cell")),
      primary: getValues("main_responsable"),
    };

    try {
      await responsableSchema.validate(responsable, { abortEarly: false });
    } catch (e) {
      toast.error(e.errors[0]);
      return false;
    }

    if (!responsable.phone && !responsable.cell) {
      toast.error(`Preencha o Telefone ou o Celular`);
      return false;
    }

    if (responsable.phone) {
      if (!validatePhone(responsable.phone)) {
        toast.error(`Telefone informado inválido.`);
        return false;
      }
    }

    if (responsable.cell) {
      if (!validatePhone(responsable.cell)) {
        toast.error(`Celular informado inválido.`);
        return false;
      }
    }

    resetResponsableForm();
    setResponsables((prevArray) => [...prevArray, responsable]);
  }

  async function checkHasPrimaryResponse() {
    let found = false;
    responsables.map((responsable) => {
      if (responsable.primary) {
        found = true;
      }
    });
    setHasPrimaryResponsable(found);
  }

  async function resetResponsableForm() {
    setValue("responsable_name", "");
    setValue("responsable_email", "");
    setValue("responsable_role", "");
    setValue("responsable_tell", "");
    setValue("responsable_cell", "");
    setValue("main_responsable", false);
    checkHasPrimaryResponse();
  }

  async function handleFillEditResponsable(index) {
    setValue("responsable_name", responsables[index].full_name);
    setValue("responsable_email", responsables[index].email);
    setValue("responsable_role", responsables[index].job_role);
    setValue("responsable_tell", responsables[index].phone);
    setValue("responsable_cell", responsables[index].cell);
    setValue("main_responsable", responsables[index].primary);
    setEditingResponsable(index);
  }

  async function handleEditResponsable() {
    let updatedResponsables = responsables;
    updatedResponsables[editingResponsable].full_name =
      getValues("responsable_name");
    updatedResponsables[editingResponsable].email =
      getValues("responsable_email");
    updatedResponsables[editingResponsable].job_role =
      getValues("responsable_role");
    updatedResponsables[editingResponsable].phone = maskPhone(
      getValues("responsable_tell")
    );
    updatedResponsables[editingResponsable].cell = maskPhone(
      getValues("responsable_cell")
    );
    updatedResponsables[editingResponsable].primary =
      getValues("main_responsable");

    setResponsables(updatedResponsables);
    setEditingResponsable(null);

    resetResponsableForm();
    toast.success("Responsável editado com sucesso");
  }
  return (
    <UpdateStyeldContainer>
      <PageHeader
        title={`Cadastrar nova empresa`}
        actions={[<Link to={`/empresas`}>Ver todas as empresas</Link>]}
      />
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Dados da empresa</h5>
              <div className="row mt-4">
                <div className="col-6">
                  <div style={{ display: "flex", gap: "30px" }}>
                    <img
                      className="icon-card-curriculo"
                      alt=""
                      width="40%"
                      src={defaultLogo}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label for="formFile">
                        Logo (será visivel no Portal Cate)
                      </label>

                      <div>
                        <input
                          class="form-control"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) => validateImageLogo(e.target.files[0])}
                          id="formFile"
                        />
                        <small>
                          Tamanho: 640 × 480
                          <br />
                          Formato: png, jpg, jpeg
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6"></div>
              </div>

              <Input name="name" register={register} label="Nome da empresa:" />
              <WithMask
                control={control}
                name="cnpj"
                label={`CNPJ:`}
                mask={`99.999.999/9999-99`}
              />
              <TextArea
                register={register}
                requiredForJob
                name="description"
                errors={errors}
                validations={{
                  maxLength: {
                    value: 2000,
                    message: "Maximo 2000 caracteres atingido.",
                  },
                }}
                label="Descrição da empresa:"
                rows={8}
              />
              <ReactSelect
                isMulti={false}
                control={control}
                label="Ramo de atividade:"
                placeholder={`Selecione o Ramo de atividade`}
                name="activity_id"
                options={companyActivities.map((ca) => {
                  return { label: ca.name, value: ca.id };
                })}
              />
              <div className="row">
                <div className="col-6">
                  <SimpleSelect
                    label={`Quantidade de funcionários:`}
                    name="collaborators_amount"
                    register={register}
                  >
                    <option value="2">Até 2</option>
                    <option value="20">Até 20</option>
                    <option value="100">Até 100</option>
                    <option value="499">Até 499</option>
                    <option value="500">mais de 500</option>
                  </SimpleSelect>
                </div>
                <div className="col-6">
                  <SimpleSelect
                    label={`Empresa classificada como:`}
                    name="company_class"
                    register={register}
                  >
                    <option value="MEI">
                      MEI - Microempreendedor Individual
                    </option>
                    <option value="ME_INDUSTRIA">
                      ME -Microempresa (indústria)
                    </option>
                    <option value="ME">
                      ME -Microempresa (comércio /serviço)
                    </option>
                    <option value="EPP_INDUSTRIA">EPP- Indústria</option>
                    <option value="EPP">EPP- Comércio e serviço</option>
                    <option value="MP_INDUSTRIA">
                      Empresa de médio porte - Indústria
                    </option>
                    <option value="MP_COMERCIO">
                      Empresa de médio porte - Comércio/serviço
                    </option>
                    <option value="GP_INDUSTRIA">
                      Empresa de grande porte - Indústria
                    </option>
                    <option value="GP_COMERCIO">
                      Empresa de grande porte - Comércio ou indústria
                    </option>
                    <option value="LTDA">LTDA</option>
                    <option value="SA">S/A - Sociedade Anônima</option>
                    <option value="EI">EI - Empresário Individual</option>
                    <option value="EIRELI">
                      EIRELI - Empresa Individual de Responsabilidade Limitada
                    </option>
                  </SimpleSelect>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <Input label={`CEP:`} name="cep" register={register} />
                </div>
                <div className="col-4 skip-cep-container">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      checked={skipCepValidation}
                      onChange={(e) => setSkipCepValidation(!skipCepValidation)}
                      className="form-check-input"
                      id="skip_cep_validation_check"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="skip_cep_validation_check"
                    >
                      Não validar o CEP (viacep)
                    </label>
                  </div>
                </div>
              </div>
              <Input
                label={`Endereço:`}
                disabled={!skipCepValidation}
                name="adress"
                register={register}
              />
              <div className="row">
                <div className="col-3">
                  <Input label={`Número:`} name="number" register={register} />
                </div>
                <div className="col-3">
                  <Input
                    label={`Complemento:`}
                    name="complement"
                    register={register}
                  />
                </div>
                <div className="col-6">
                  <Input
                    label={`Ponto de referência:`}
                    name="reference"
                    register={register}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Input
                    label={`Bairro:`}
                    disabled={!skipCepValidation}
                    name="district"
                    register={register}
                  />
                </div>
                <div className="col-6">
                  <ReactSelect
                    name="region"
                    placeholder="Região"
                    control={control}
                    options={optionsRegion}
                    label="Região:"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Input
                    label={`Cidade:`}
                    disabled={!skipCepValidation}
                    name="city"
                    register={register}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <WithMask
                    control={control}
                    name="phone"
                    label={`Telefone`}
                    phoneOrCell
                  />
                </div>
                <div className="col-8">
                  <Input
                    type="email"
                    name="email"
                    register={register}
                    label={`Email`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Responsável</h5>
              <div className="container add-responsables-container">
                {responsables.length > 0 ? (
                  responsables.map((responsable, idx) => {
                    return (
                      <div
                        key={uuidv4()}
                        className="row add-responsable-list-item"
                      >
                        <div className="col-8">{`${responsable.full_name}${
                          responsable.primary ? ` (Principal)` : ``
                        }`}</div>
                        <div className="col-4 add-responsable-list-item-actions">
                          <button
                            type="button"
                            onClick={() => handleDeleteResponsable(idx)}
                            className="btn btn-primary"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />{" "}
                            Excluir
                          </button>
                          <button
                            type="button"
                            onClick={() => handleFillEditResponsable(idx)}
                            className="btn btn-primary"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />{" "}
                            Editar
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>Nenhum responsável adicionado ainda</p>
                )}
              </div>
              <Input
                name="responsable_name"
                register={register}
                label="Nome:"
              />
              <Input
                name="responsable_role"
                register={register}
                label="Cargo:"
              />
              <Input
                type="email"
                name="responsable_email"
                register={register}
                label="Email:"
              />
              <div className="row">
                <div className="col-4">
                  <WithMask
                    control={control}
                    name="responsable_tell"
                    label={`Telefone`}
                    phoneOrCell
                  />
                </div>
                <div className="col-4">
                  <WithMask
                    control={control}
                    phoneOrCell
                    name="responsable_cell"
                    label={`Celular`}
                  />
                </div>
                <div className="col-4 skip-cep-container">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      disabled={
                        hasPrimaryResponsable && !getValues("main_responsable")
                      }
                      {...register("main_responsable", { required: false })}
                      name="main_responsable"
                      className="form-check-input"
                      id="main_responsable_check"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="main_responsable_check"
                    >
                      Responsável principal?
                    </label>
                  </div>
                </div>
              </div>
              <button
                onClick={() =>
                  editingResponsable !== null
                    ? handleEditResponsable()
                    : handleAddResponsable()
                }
                type="button"
                className="btn btn-secondary mt-3"
              >
                {editingResponsable !== null
                  ? `Salvar alterações`
                  : `Salvar responsável`}
              </button>
            </div>
          </div>
          <button className="btn btn-primary mt-4" type="submit">
            {id ? `Editar` : `Cadastrar`}
          </button>
        </form>
      </div>
    </UpdateStyeldContainer>
  );
}

export default CompaniesUpdate;
