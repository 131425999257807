import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { Collapse } from "react-collapse";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { ReportItemStyled } from "./../styles";
import api from "./../../../config/api";

import Input from "./../../../components/FormHook/Input";
import WithMask from "./../../../components/FormHook/WithMask";
import ReactSelect from "./../../../components/FormHook/ReactSelect";
import DatePicker from "./../../../components/FormHook/DatePick";

import Divider from "./../../../components/Divider";
import FileDownload from "js-file-download";

function CompanyAttendancesReport() {
  const [isCollapsed, setIsCollapsed] = useState(true); //default collapsed
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState,
  } = useForm();

  const [finalDate, setFinalDate] = useState(null);
  const startAtWatch = watch("start_at");

  useEffect(() => {
    if (!startAtWatch) {
      return false;
    }
    let start = new Date(startAtWatch);
    let target = new Date(startAtWatch);
    let max = target.setDate(start.getDate() + 45);
    let today = new Date();
    setFinalDate(max > today ? today : max);
    setValue("end_at", null);
  }, [startAtWatch]);

  async function onSubmit(data) {
    let now = format(new Date(), "yyyyMMddHmm");
    data = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != null && v != "")
    );
    if (!data.start_at || !data.end_at) {
      toast.warning("Selecione um periodo.");
      return false;
    }
    data.start_at = format(new Date(data.start_at), "yyyy-MM-dd");
    data.end_at = format(new Date(data.end_at), "yyyy-MM-dd");
    api({
      url: "reports/company-attendances",
      method: "GET",
      responseType: "blob",
      params: data, // Important
    }).then((response) => {
      FileDownload(response.data, `relatorio_atendimento_empresas_${now}.xlsx`);
    });
  }

  return (
    <ReportItemStyled>
      <div className="card" key={uuidv4()}>
        <div className="card-body">
          <div className="header-job">
            <h4 className="card-title">Relatório de Atendimento de Empresas</h4>
            <span
              className="collapse-action"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              )}
              {isCollapsed ? "Abrir" : "Fechar"}
            </span>
          </div>
          <Divider />
          <Collapse isOpened={!isCollapsed}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <DatePicker
                    name="start_at"
                    control={control}
                    label="Data de inicio:"
                  />
                </div>
                <div className="col">
                  <DatePicker
                    name="end_at"
                    control={control}
                    label="Data de fim:"
                    max={finalDate ? new Date(finalDate) : null}
                    min={startAtWatch}
                    disabled={!startAtWatch}
                  />
                </div>
                <div className="col"></div>
              </div>

              <div className="row">
                <div className="col">
                  <WithMask
                    control={control}
                    name="cnpj"
                    label="CNPJ:"
                    mask={`99.999.999/9999-99`}
                  />
                </div>
                <div className="col">
                  <Input
                    register={register}
                    name="user"
                    label="Nome do colaborador"
                  />
                </div>
              </div>
              <Divider />
              <div className="report-actions">
                <button className="btn btn-primary" type="submit">
                  Exportar
                </button>
                <button className="btn btn-secondary" type="button">
                  Limpar
                </button>
              </div>
            </form>
          </Collapse>
        </div>
      </div>
    </ReportItemStyled>
  );
}

export default CompanyAttendancesReport;
