import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const StyledInput = styled.div`
  .form-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-top: 2rem;
  }

  .form-switch .form-check-input {
    width: 3em;
    height: 1.5rem;
    margin-left: 0;
    margin-right: 15px;
    margin-top: 0;
  }

  label {
    margin-top: 0.5rem;
  }
`;

function Input({
  label,
  name,
  type,
  register,
  required = false,
  placeholder,
  disabled = false,
  requiredForJob = false,
}) {
  const id = uuidv4();
  return (
    <StyledInput>
      <div className="form-check form-switch">
        <input
          type="checkbox"
          name={name}
          disabled={disabled}
          role="switch"
          placeholder={placeholder}
          className="form-check-input"
          id={id}
          {...register(name)}
        />
        <label htmlFor={id} className="form-label">
          {label}
        </label>
        {requiredForJob ? (
          <span className="required required-secondary">*</span>
        ) : null}
      </div>
    </StyledInput>
  );
}

export default Input;
