import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SchedulesListContainer = styled.div`
  .schedules-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .schedules-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: #f0f0f0;
    gap: 1rem;
    font-family: "Raleway", sans-serif;
    border-radius: 10px;
  }

  .schedules-item-cell {
    display: flex;
    flex-direction: column;

    .schedules-item-cell-header {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
`;

export default function SchedulesList({
  schedules,
  handleDeleteSchedule,
  handleUpdateSchedule,
  disabled,
}) {
  return schedules.length > 0 ? (
    <SchedulesListContainer>
      <div className="schedules-container mb-4">
        {schedules.map((schedule, idx) => {
          return (
            <div className="schedules-item" key={uuidv4()}>
              <div className="schedules-item-cell">
                <span className="schedules-item-cell-header">
                  Data de Inicio:
                </span>
                <span>
                  {format(new Date(schedule.start_at), "dd/MM/yyyy HH:mm")}
                </span>
              </div>
              <div className="schedules-item-cell">
                <span className="schedules-item-cell-header">Data de Fim:</span>
                <span>
                  {format(new Date(schedule.end_at), "dd/MM/yyyy HH:mm")}
                </span>
              </div>
              <div className="schedules-item-cell">
                <span className="schedules-item-cell-header">
                  Limite de candidatos:
                </span>
                <span>{schedule.seats}</span>
              </div>
              <div className="schedules-item-cell">
                <span className="schedules-item-cell-header">
                  Vagas da agenda preenchidas:
                </span>
                <span>{schedule.used_seats || 0}</span>
              </div>
              {!disabled ? (
                <div className="schedules-item-cell">
                  {schedule.used_seats > 0 ? (
                    <button
                      type="button"
                      onClick={() => handleUpdateSchedule(idx)}
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Editar
                      Limite
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleDeleteSchedule(idx)}
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />{" "}
                      Excluir
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </SchedulesListContainer>
  ) : null;
}
