import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "./../../contexts/auth";
import { Container } from "./styles";

const AllowedRole = ({ roles = [], children }) => {
  const { user } = useContext(AuthContext);
  return roles.includes(user.auth.user.role) ? children : null;
};

function Header() {
  const { signOut, user } = useContext(AuthContext);
  return (
    <Container>
      <nav className="navbar navbar-light">
        <Link to="/" className="navbar-brand">
          <img
            src={process.env.PUBLIC_URL + "/assets/header-logos-white.png"}
            className="d-inline-block align-top"
            alt="Cate vagas"
          />
        </Link>
        <ul className="nav">
          <AllowedRole roles={["manager", "acquirer"]}>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["manager"]}>
            <li className="nav-item">
              <Link to="/usuarios" className="nav-link">
                Usuários
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["manager", "acquirer"]}>
            <li className="nav-item">
              <Link to="/unidades-cate" className="nav-link">
                Unidades Cate
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["manager", "acquirer"]}>
            <li className="nav-item">
              <Link to="/empresas" className="nav-link">
                Empresas
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["manager", "acquirer"]}>
            <li className="nav-item">
              <Link to="/vagas" className="nav-link">
                Vagas
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["manager", "acquirer"]}>
            <li className="nav-item">
              <Link to="/candidatos" className="nav-link">
                Consulta de CVs
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["manager", "acquirer"]}>
            <li className="nav-item">
              <Link to="/relatorios" className="nav-link">
                Relatórios
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["consultant"]}>
            <li className="nav-item">
              <Link to="/vagas" className="nav-link">
                Atendimento Remoto
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["consultant"]}>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Atendimento Presencial
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["consultant"]}>
            <li className="nav-item">
              <Link to="/atendimento/empresas" className="nav-link">
                Atendimento para empresas
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["consultant"]}>
            <li className="nav-item">
              <Link to="/atendimento/social" className="nav-link">
                Atendimento sem cadastro
              </Link>
            </li>
          </AllowedRole>
          <AllowedRole roles={["consultant", "acquirer", "manager"]}>
            <li className="nav-item">
              <Link to="/meu-perfil" className="nav-link">
                Meu Perfil
              </Link>
            </li>
          </AllowedRole>
          <li className="nav-item">
            <a onClick={() => signOut()} href="#" className="nav-link">
              Sair
            </a>
          </li>
        </ul>
      </nav>
    </Container>
  );
}

export default Header;
