import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Section from "../../components/Section";

const DashboardStyled = styled.div`
  .section-body {
    display: flex;
    .actions {
      flex: 1;
      border-right: 1px dotted #989898;
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .btn {
        width: 100%;
      }
    }
    .text {
      flex: 3;
      padding: 1rem 2rem;
      p {
        font-size: 1rem;
        font-family: "Raleway", sans-serif;
      }
    }
  }
`;

function Dashboard() {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const [redirect, setRedirect] = useState(null);
  useState(async () => {
    let to = searchParams.get("to");
    let target = searchParams.get("target");
    let url = null;
    if (to === "curriculos") {
      url = `/candidatos?uuid=${target}`;
    }
    if (to === "vagas") {
      url = `/vagas/detalhes?id=${target}`;
    }
    setRedirect(url);
  }, [searchParams]);
  return redirect ? (
    <Navigate to={redirect} replace />
  ) : (
    <DashboardStyled>
      <div className="container">
        <Section title={`Empresas`}>
          <div className="section-body">
            <div className="actions">
              <Link to={`/empresas/editar`} className="btn btn-alternative">
                Cadastrar empresas
              </Link>
              <Link to={`/empresas`} className="btn btn-primary">
                Empresas cadastradas
              </Link>
            </div>
            <div className="text">
              <p>
                Cadastre ou procure por empresas parceiras do Cate. Só é
                possível aplicar currículos para vagas de empresas que sejam
                cadastradas no sistema.
              </p>
            </div>
          </div>
        </Section>
        <Section title={`Vagas`}>
          <div className="section-body">
            <div className="actions">
              <Link to={`/vagas/editar`} className="btn btn-alternative">
                Cadastrar nova vaga
              </Link>
              <Link to={`/vagas`} className="btn btn-primary">
                Gerenciar vagas e candidatos
              </Link>
              <Link to={`/vagas?status=draft`} className="btn btn-secondary">
                Meus rasunhos
              </Link>
            </div>
            <div className="text">
              <p>
                Cadastre uma nova vaga de empego ou gerencie processos que já
                estão em andamento.
              </p>
            </div>
          </div>
        </Section>
        <Section title={`Usuários`}>
          <div className="section-body">
            <div className="actions">
              <Link to={`/usuarios/editar`} className="btn btn-alternative">
                Cadastrar novo usuário
              </Link>
              <Link to={`/usuarios`} className="btn btn-primary">
                Ver todos usuários
              </Link>
            </div>
            <div className="text">
              <p>
                Cadastre um novo usuário, gestor ou atendente, para utilizar a
                ferramenta. Você também pode buscar e editar usuários já
                cadastrados.
              </p>
            </div>
          </div>
        </Section>
      </div>
    </DashboardStyled>
  );
}

export default Dashboard;
