import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import api from "../../config/api";
import PageHeader from "../../components/PageHeader";
import Pagination from "../../components/Pagination";
import CandidatesItem from "./itens";
import Input from "../../components/FormHook/Input";
import WithMask from "../../components/FormHook/WithMask";
import Switch from "../../components/FormHook/Switch";
import ReactSelect from "../../components/FormHook/ReactSelect";
import FiltersSidebar from "../../components/FiltersSidebar";
import {
  optionsOtherRequirements,
  optionsPCD,
  optionsRegion,
  optionsCNH,
  optionsLanguage,
  optionsOwnVehicle,
} from "../Jobs/database";

const CandidatesStyled = styled.div`
  .candidates-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .kpis {
    display: flex;
    padding: 2rem 0;
    justify-content: space-around;
  }
`;

export default function Resumes() {
  const { register, handleSubmit, reset, control, formState, getValues } =
    useForm();
  const [candidates, setCandidates] = useState([]);
  const [kpis, setKpis] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [notFoundResults, setNotFoundResults] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(async () => {
    await getUrlUuid();
    handleSearch(1, getValues());
    getKpis();
  }, []);

  async function handleFilter(data) {
    handleSearch(1, data);
  }

  async function getKpis() {
    let response = await api.get(`resumes/kpis`);
    setKpis(response.data);
  }

  async function getUrlUuid() {
    let uuid = searchParams.get("uuid");
    reset({ uuid });
  }

  async function handleSearch(nextPage = 1, filters = null) {
    if (!formState.dirtyFields.has_updated_resume) {
      delete filters?.has_updated_resume;
    }

    if (!formState.dirtyFields.is_tech) {
      delete filters?.is_tech;
    }
    let resp = await api.get(`resumes`, {
      params: {
        page: nextPage,
        ...filters,
      },
    });
    setCandidates(resp.data.data);
    setTotalPages(resp.data.last_page);
    setPage(nextPage);
    setActiveFilters(filters);
    setNotFoundResults(resp.data.data.length === 0);
    window.scrollTo(0, 0);
  }

  async function handleCleanFilter() {
    reset();
    reset({ cpf: "" });
    handleSearch(1, null);
  }

  return (
    <CandidatesStyled>
      <PageHeader title={`Consulta de CVs`} />
      <div className="container">
        <div className="row">
          <div className="col-3">
            <FiltersSidebar title="Busca por CVs">
              <form onSubmit={handleSubmit(handleFilter)}>
                <Switch
                  name="is_tech"
                  register={register}
                  label="Profissional de T.I?"
                />
                <Input
                  register={register}
                  name="full_name"
                  label="Nome do candidato:"
                />
                <WithMask
                  control={control}
                  name="cpf"
                  label="CPF:"
                  mask={`999.999.999.99`}
                />
                <ReactSelect
                  name="region"
                  control={control}
                  options={optionsRegion}
                  placeholder="Filtre por região"
                  label="Região:"
                />
                <ReactSelect
                  name="pcd"
                  isMulti
                  placeholder="Pessoa com deficiência"
                  control={control}
                  options={optionsPCD}
                  label="Pessoa com deficiência:"
                />
                <ReactSelect
                  name="cnh"
                  placeholder="Possui CNH"
                  control={control}
                  options={optionsCNH}
                  label="Possui CNH:"
                />
                <ReactSelect
                  name="own_vehicle"
                  placeholder="Tem veículo próprio"
                  control={control}
                  options={optionsOwnVehicle}
                  label="Tem veículo próprio:"
                />
                <ReactSelect
                  name="languages"
                  isMulti
                  placeholder="Fala outro idioma"
                  control={control}
                  options={optionsLanguage}
                  label="Fala outro idioma:"
                />
                <ReactSelect
                  name="other_requirements"
                  isMulti
                  placeholder="Outras características"
                  control={control}
                  options={optionsOtherRequirements}
                  label="Outras características:"
                />
                <Switch
                  name="has_updated_resume"
                  register={register}
                  label="Possui currículo cadastrado?"
                />
                <button type="submit" className="btn btn-primary">
                  Filtrar
                </button>
                <button
                  type="button"
                  onClick={handleCleanFilter}
                  className="btn btn-secondary"
                >
                  Limpar
                </button>
              </form>
            </FiltersSidebar>
          </div>
          <div className="col-9">
            {kpis ? (
              <div className="kpis">
                <span className="badge bg-info">{`TOTAL DE CANDIDATOS NO SISTEMA: ${kpis.total}`}</span>
                <span className="badge bg-success">{`TOTAL DE CANDIDATOS COM CURRÍCULO: ${kpis.total_resumes}`}</span>
                <span className="badge bg-warning">{`TOTAL DE CANDIDATOS SEM CURRÍCULO ${kpis.total_without_resumes}`}</span>
              </div>
            ) : null}

            <div className="candidates-list">
              {notFoundResults ? (
                <span>Nenhum resultado encontrado para essa busca.</span>
              ) : null}
              {candidates.map((candidate) => {
                return (
                  <CandidatesItem
                    key={`candidate_item_out_${candidate.uuid}`}
                    candidate={candidate}
                  />
                );
              })}
            </div>
            <Pagination
              page={page}
              total={totalPages}
              onChange={(page) => handleSearch(page, activeFilters)}
            />
          </div>
        </div>
      </div>
    </CandidatesStyled>
  );
}
