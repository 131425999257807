import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResumeExperiencesContainer = styled.div`
  .experience-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 2rem;
  }

  .experience-infos h6 {
    margin-bottom: 0;
  }
  .experience-infos span {
    font-style: italic;
    font-size: 0.8rem;
    font-family: "Raleway", sans-serif;
  }
  .experience-infos p {
    font-size: 0.9rem;
  }

  .experience-infos {
    flex: 2;
  }

  .experience-actions {
    flex: 1;
    display: flex;
    justify-content: end;
    flex: 1;
    gap: 10px;
  }

  h6.secondary {
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 600px) {
    .experience-container {
      flex-direction: column;
      margin-bottom: 2rem;
    }
  }
`;

export default function ResumeExperiencesItens({
  expeiences,
  handleEditExperience,
  handleDeleteExperience,
}) {
  function truncateText(text, len) {
    let pgs = text.split(" ");
    if (pgs.length > len) {
      return `${pgs.slice(0, len).join(" ")} ...`;
    } else {
      return text;
    }
  }

  return expeiences?.length > 0 ? (
    <ResumeExperiencesContainer>
      {expeiences.map((experience, idx) => {
        return (
          <div className="experience-container" key={uuidv4()}>
            <div className="experience-infos">
              <h6>{experience.occupation?.label}</h6>
              <h6 className="secondary">{experience.company}</h6>
              <span>{`Inicio: ${format(
                new Date(experience.start_at),
                "dd/MM/yyyy"
              )} - Término: ${
                experience.end_at
                  ? format(new Date(experience.end_at), "dd/MM/yyyy")
                  : "Atualmente"
              }`}</span>
              <p>{truncateText(experience.description, 50)}</p>
            </div>
            <div className="experience-actions">
              <button
                type="button"
                onClick={() => handleEditExperience(idx)}
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Editar
              </button>
              <button
                type="button"
                onClick={() => handleDeleteExperience(idx)}
                className="btn btn-alternative"
              >
                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Excluir
              </button>
            </div>
          </div>
        );
      })}
    </ResumeExperiencesContainer>
  ) : null;
}
