import React from "react";
import styled from "styled-components";

const MeetAddressCardContainer = styled.div`
  margin: 1rem 0;
  padding: 1rem 2rem;
  background: #f0f0f0;
  gap: 1rem;
  font-family: "Raleway", sans-serif;
  border-radius: 10px;

  p {
    margin-bottom: 0.3rem;
  }
`;
// import { Container } from './styles';

function MeetAddressCard({ address }) {
  return (
    <MeetAddressCardContainer>
      <p>
        <b>Endereço:</b>{" "}
        {`${address.adress}, ${address.number} - ${
          address.complement || "Não informado"
        }`}{" "}
      </p>
      <p>
        <b>Ponto de referência:</b> {address.reference || "Não informado"}
      </p>
      <p>
        <b>Bairro:</b> {address.district || "-"}
      </p>
      <p>
        <b>CEP:</b> {address.cep || "-"}
      </p>
    </MeetAddressCardContainer>
  );
}

export default MeetAddressCard;
