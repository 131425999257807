import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Input from "./../../FormHook/Input";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { maskCPF } from "../../../helpers/mask";
import WithMask from "../../FormHook/WithMask";

const CloseJobFormStyled = styled.div`
  background: #f0f0f0;
  padding: 1rem;
  margin: 1rem 1rem;
  padding: 1rem 2rem;
  gap: 1rem;
  border-radius: 10px;
  font-family: "Raleway", sans-serif;

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .close-cpf-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 3rem;
    background: #f0f0f0;
    border-radius: 10px;
  }

  .title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .action-button-container {
    display: flex;
    align-items: end;
  }

  .close-cpf-line-cell {
    display: flex;
    flex-direction: column;
  }

  .close-cpf-line-cell-header {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .cpf-container {
    background: white;
    border-radius: 10px;
    margin: 1rem 0px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

function CloseJobForm({ register, watch, setValue, handleAddCpf, handleDeleteCpf, closeCpfs, control }) {
  const closeReasonWatch = watch("close_reason");

  return (
    <CloseJobFormStyled>
      <div className="row">
        <span className="title">Qual o motivo do encerramento?</span>
        <div className="form-check">
          <input
            className="form-check-input"
            {...register("close_reason", { required: false })}
            type="radio"
            name="close_reason"
            id="job_close_reason_company_canceled"
            value="company_cancel_order"
          />
          <label
            className="form-check-label"
            htmlFor="job_close_reason_company_canceled"
          >
            Contratação cancelada pela empresa
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            {...register("close_reason", { required: false })}
            type="radio"
            name="close_reason"
            id="job_close_reason_complete_cate"
            value="complete_cate"
          />
          <label
            className="form-check-label"
            htmlFor="job_close_reason_complete_cate"
          >
            Vagas preenchidas por indicacao do Cate
          </label>
        </div>
        {closeReasonWatch === "complete_cate" ? (
          <div className="cpf-container">
            <h6>Informe o CPF dos candidatos contratados: </h6>
            {closeCpfs?.map((cpfs, idx) => (
              <div className="close-cpf-line" key={uuidv4()}>
                <div className="close-cpf-line-cell">
                  <span className="close-cpf-line-cell-header">
                    CPF:
                  </span>
                  <span>{maskCPF(cpfs.cpf)}</span>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleDeleteCpf(idx)}
                    className="btn btn-primary"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Excluir
                  </button>
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-6">
                <WithMask
                  name="complete_cate.cpf"
                  control={control}
                  mask={`999.999.999-99`}
                  label="CPF:"
                />
              </div>
              <div className="col-6 action-button-container">
                <button
                  onClick={handleAddCpf}
                  className="btn btn-alternative"
                  type="button"
                >
                  Adiconar outro
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-check">
          <input
            className="form-check-input"
            {...register("close_reason", { required: false })}
            type="radio"
            name="close_reason"
            id="job_close_reason_complete_other"
            value="complete_other"
          />
          <label
            className="form-check-label"
            htmlFor="job_close_reason_complete_other"
          >
            Vagas preenchidas por outros meios
          </label>
        </div>
      </div>
    </CloseJobFormStyled>
  );
}

export default CloseJobForm;
