import React from "react";
import { Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import { v4 as uuidv4 } from "uuid";

// import { Container } from './styles';

function DatePick({
  label,
  name,
  control,
  placeholder,
  disabled = false,
  min = null,
  max = null,
}) {
  const id = uuidv4();
  return (
    <div className="react-select-item">
      {label ? (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <DatePicker
              className="form-control"
              maxDate={max}
              disabled={disabled}
              minDate={min}
              id={id}
              locale={ptBR}
              selected={value || null}
              onChange={onChange}
              dateFormat="dd/MM/yyyy"
            />
          );
        }}
      />
    </div>
  );
}

export default DatePick;
