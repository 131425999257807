export const techTerms = [
  { label: ".NET", value: ".net" },
  { label: ".NET Core", value: ".net core" },
  { label: "Agile", value: "agile" },
  { label: "AI", value: "ai" },
  { label: "Airflow", value: "airflow" },
  { label: "Android", value: "android" },
  { label: "Ansible", value: "ansible" },
  {
    label: "Angular 2",
    value: "angular 2",
  },
  { label: "AngularJS", value: "angularjs" },
  { label: "API", value: "api" },
  { label: "Apollo", value: "apollo" },
  {
    label: "App Engine",
    value: "app engine",
  },
  {
    label: "AR",
    value: "ar",
  },
  { label: "Assembly", value: "assembly" },
  { label: "Asp", value: "asp" },
  { label: "ASP.NET", value: "asp.net" },
  { label: "ASP.NET MVC", value: "asp.net mvc" },
  { label: "Aurora", value: "aurora" },
  { label: "AWS", value: "aws" },
  { label: "Azure", value: "azure" },
  {
    label: "Back End",
    value: "back end",
  },
  { label: "Backbone", value: "backbone" },
  { label: "Bash", value: "bash" },
  {
    label: "Big Data",
    value: "big data",
  },
  { label: "BigQuery", value: "bigquery" },
  { label: "Bitcoin", value: "bitcoin" },
  { label: "Blazor", value: "blazor" },
  { label: "Blockchain", value: "blockchain" },
  { label: "C", value: "c" },
  { label: "C++", value: "c++" },
  { label: "C#", value: "c#" },
  { label: "CakePHP", value: "cakephp" },
  { label: "Cassandra", value: "cassandra" },
  { label: "Celery", value: "celery" },
  { label: "Chef", value: "chef" },
  { label: "ClickHouse", value: "clickhouse" },
  { label: "Clojure", value: "clojure" },
  { label: "ClojureScript", value: "clojurescript" },
  { label: "Cloudflare", value: "cloudflare" },
  { label: "CloudFormation", value: "cloudformation" },
  { label: "CloudFront", value: "cloudfront" },
  { label: "Closure", value: "closure" },
  { label: "COBOL", value: "cobol" },
  { label: "CockroachDB", value: "cockroachdb" },
  { label: "CodeIgniter", value: "codeigniter" },
  { label: "CoffeeScript", value: "coffeescript" },
  { label: "Common Lisp", value: "common lisp" },
  { label: "Compass", value: "compass" },
  { label: "Computer Vision", value: "computer vision" },
  { label: "CouchDB", value: "couchdb" },
  { label: "CQRS", value: "cqrs" },
  { label: "Crystal", value: "crystal" },
  { label: "CSS", value: "css" },
  { label: "D3.js", value: "d3.js" },
  { label: "Dart", value: "dart" },
  { label: "Data Mining", value: "data mining" },
  { label: "Data Science", value: "data science" },
  { label: "dbt", value: "dbt" },
  { label: "Deep Learning", value: "deep learning" },
  { label: "Delphi", value: "delphi" },
  { label: "DevOps", value: "devops" },
  { label: "Distributed Systems", value: "distributed systems" },
  { label: "Django", value: "django" },
  { label: "Docker", value: "docker" },
  { label: "Drupal", value: "drupal" },
  { label: "DynamoDB", value: "dynamodb" },
  { label: "EC2", value: "ec2" },
  { label: "Eclipse", value: "eclipse" },
  { label: "ECS", value: "ecs" },
  { label: "EDI", value: "edi" },
  { label: "Elasticache", value: "elasticache" },
  {
    label: "Elasticsearch",
    value: "elasticsearch",
  },
  { label: "Electron", value: "electron" },
  { label: "Elixir", value: "elixir" },
  { label: "Elm", value: "elm" },
  { label: "Emacs", value: "emacs" },
  { label: "Embedded Systems", value: "embedded systems" },
  { label: "Ember", value: "ember" },
  { label: "Erlang", value: "erlang" },
  { label: "Ethereum", value: "ethereum" },
  { label: "ETL", value: "etl" },
  { label: "Express", value: "express" },
  { label: "F#", value: "f#" },
  { label: "Fargate", value: "fargate" },
  { label: "FastAPI", value: "fastapi" },
  { label: "Fastify", value: "fastify" },
  { label: "Fintech", value: "fintech" },
  { label: "Firebase", value: "firebase" },
  { label: "Flask", value: "flask" },
  { label: "Flutter", value: "flutter" },
  { label: "Flux", value: "flux" },
  {
    label: "Front End",
    value: "front end",
  },
  {
    label: "Full Stack",
    value: "full stack",
  },
  { label: "Gatsby", value: "gatsby" },
  { label: "GCP", value: "gcp" },
  { label: "Git", value: "git" },
  { label: "GitHub", value: "github" },
  { label: "GitLab", value: "gitlab" },
  { label: "golang", value: "golang" },
  { label: "Grafana", value: "grafana" },
  { label: "Graphite", value: "graphite" },
  { label: "GraphQL", value: "graphql" },
  { label: "Groovy", value: "groovy" },
  { label: "gRPC", value: "grpc" },
  { label: "Hadoop", value: "hadoop" },
  { label: "haproxy", value: "haproxy" },
  { label: "Haskell", value: "haskell" },
  { label: "HaXe", value: "haxe" },
  { label: "Heroku", value: "heroku" },
  { label: "Hive", value: "hive" },
  { label: "Hotwire", value: "hotwire" },
  { label: "HTML5", value: "html5" },
  { label: "InfluxDB", value: "influxdb" },
  { label: "Ionic", value: "ionic" },
  { label: "iOS", value: "ios" },
  { label: "Java", value: "java" },
  { label: "JavaScript", value: "javascript" },
  { label: "jenkins", value: "jenkins" },
  { label: "jQuery", value: "jquery" },
  { label: "JSON API", value: "json api" },
  { label: "JSP", value: "jsp" },
  { label: "Julia", value: "julia" },
  { label: "Junior", value: "junior" },
  { label: "JUnit", value: "junit" },
  { label: "Jupyter", value: "jupyter" },
  { label: "Kafka", value: "kafka" },
  { label: "Keras", value: "keras" },
  { label: "Kinesis", value: "kinesis" },
  { label: "Knockout", value: "knockout" },
  { label: "Kotlin", value: "kotlin" },
  { label: "Kubernetes", value: "kubernetes" },
  { label: "Lambda", value: "lambda" },
  { label: "LAMP", value: "lamp" },
  { label: "Laravel", value: "laravel" },
  { label: "less", value: "less" },
  { label: "linux", value: "linux" },
  { label: "Lisp", value: "lisp" },
  { label: "Lua", value: "lua" },
  { label: "Lucene", value: "lucene" },
  { label: "Mahout", value: "mahout" },
  { label: "Machine Learning", value: "machine learning" },
  { label: "MapReduce", value: "mapreduce" },
  { label: "MariaDB", value: "mariadb" },
  { label: "MATLAB", value: "matlab" },
  { label: "Maven", value: "maven" },
  {
    label: "memcached",
    value: "memcached",
  },
  { label: "Mesos", value: "mesos" },
  { label: "Meteor", value: "meteor" },
  {
    label: "Microservices",
    value: "microservices",
  },
  {
    label: "Mongodb",
    value: "mongodb",
  },
  { label: "MySQL", value: "mysql" },
  { label: "neo4j", value: "neo4j" },
  { label: "nginx", value: "nginx" },
  { label: "NLP", value: "nlp" },
  { label: "nodejs", value: "nodejs" },
  { label: "Nomad", value: "nomad" },
  { label: "NumPy", value: "numpy" },
  {
    label: "Objective-C",
    value: "objective-c",
  },
  { label: "OCaml", value: "ocaml" },
  { label: "Open Source", value: "open source" },
  { label: "OpenCV", value: "opencv" },
  { label: "OpenGL", value: "opengl" },
  { label: "OpenStack", value: "openstack" },
  { label: "Oracle", value: "oracle" },
  { label: "Pandas", value: "pandas" },
  { label: "Perl", value: "perl" },
  { label: "PhantomJS", value: "phantomjs" },
  { label: "Phoenix", value: "phoenix" },
  { label: "PHP", value: "php" },
  { label: "Pig", value: "pig" },
  {
    label: "Postgresql",
    value: "postgresql",
  },
  { label: "Principal", value: "principal" },
  { label: "Processing", value: "processing" },
  { label: "Prolog", value: "prolog" },
  {
    label: "Protocol Buffers",
    value: "protocol buffers",
  },
  { label: "Puppet", value: "puppet" },
  { label: "Python", value: "python" },
  { label: "PyTorch", value: "pytorch" },
  { label: "R", value: "r" },
  { label: "RabbitMQ", value: "rabbitmq" },
  { label: "Rails", value: "rails" },
  { label: "RDS", value: "rds" },
  { label: "React", value: "react" },
  { label: "React Native", value: "react native" },
  { label: "Redis", value: "redis" },
  { label: "Redshift", value: "redshift" },
  { label: "Redux", value: "redux" },
  { label: "Remote", value: "remote" },
  { label: "REST", value: "rest" },
  { label: "Riak", value: "riak" },
  { label: "Robotics", value: "robotics" },
  { label: "Ruby", value: "ruby" },
  { label: "Rust", value: "rust" },
  { label: "Sass", value: "sass" },
  { label: "S3", value: "s3" },
  { label: "Scala", value: "scala" },
  { label: "Scrum", value: "scrum" },
  { label: "Selenium", value: "selenium" },
  { label: "Senior", value: "senior" },
  { label: "Shell", value: "shell" },
  { label: "Sidekiq", value: "sidekiq" },
  { label: "SNS", value: "sns" },
  { label: "Solr", value: "solr" },
  { label: "Spark", value: "spark" },
  { label: "Spinnaker", value: "spinnaker" },
  { label: "Spring", value: "spring" },
  { label: "SQL", value: "sql" },
  { label: "SQL Server", value: "sql server" },
  { label: "SQS", value: "sqs" },
  { label: "Staff", value: "staff" },
  { label: "statistics", value: "statistics" },
  { label: "Stimulus", value: "stimulus" },
  { label: "Storm", value: "storm" },
  { label: "Struts", value: "struts" },
  { label: "Supabase", value: "supabase" },
  { label: "Swift", value: "swift" },
  { label: "Symfony", value: "symfony" },
  { label: "Tailwind", value: "tailwind" },
  { label: "TensorFlow", value: "tensorflow" },
  { label: "Terraform", value: "terraform" },
  { label: "TDD", value: "tdd" },
  { label: "TimescaleDB", value: "timescaledb" },
  { label: "Turbo", value: "turbo" },
  { label: "TypeScript", value: "typescript" },
  { label: "Unreal Engine", value: "unreal engine" },
  { label: "Vagrant", value: "vagrant" },
  { label: "Varnish", value: "varnish" },
  { label: "Vi", value: "vi" },
  { label: "Visual Basic", value: "visual basic" },
  { label: "Vue", value: "vue" },
  {
    label: "VR",
    value: "vr",
  },
  { label: "web3", value: "web3" },
  { label: "WebAssembly", value: "webassembly" },
  { label: "WebGL", value: "webgl" },
  { label: "Webpack", value: "webpack" },
  { label: "WebRTC", value: "webrtc" },
  { label: "WebSockets", value: "websockets" },
  { label: "WordPress", value: "wordpress" },
  { label: "WPF", value: "wpf" },
  { label: "Xamarin", value: "xamarin" },
  { label: "XML", value: "xml" },
  { label: "xR", value: "xr" },
  { label: "ZeroMQ", value: "zeromq" },
  { label: "zookeeper", value: "zookeeper" },
];

export const nationalityOptions = [
  {
    label: "Brasil",
    value: "brasil",
  },
  {
    label: "Abecásia",
    value: "abecasia",
  },
  {
    label: "Afeganistão",
    value: "afeganistao",
  },
  {
    label: "África do Sul",
    value: "africa-do-sul",
  },
  {
    label: "Albânia",
    value: "albania",
  },
  {
    label: "Alemanha",
    value: "alemanha",
  },
  {
    label: "Andorra",
    value: "andorra",
  },
  {
    label: "Angola",
    value: "angola",
  },
  {
    label: "Antígua e Barbuda",
    value: "antigua-e-barbuda",
  },
  {
    label: "Arábia Saudita",
    value: "arabia-saudita",
  },
  {
    label: "Argélia",
    value: "argelia",
  },
  {
    label: "Argentina",
    value: "argentina",
  },
  {
    label: "Armênia",
    value: "armenia",
  },
  {
    label: "Austrália",
    value: "australia",
  },
  {
    label: "Áustria",
    value: "austria",
  },
  {
    label: "Azerbaijão",
    value: "azerbaijao",
  },
  {
    label: "Bahamas",
    value: "bahamas",
  },
  {
    label: "Bahrein (ou Barein, ou Barém)",
    value: "bahrein",
  },
  {
    label: "Bangladesh",
    value: "bangladesh",
  },
  {
    label: "Barbados",
    value: "barbados",
  },
  {
    label: "Bélgica",
    value: "belgica",
  },
  {
    label: "Belize",
    value: "belize",
  },
  {
    label: "Benim",
    value: "benim",
  },
  {
    label: "Bielorrússia",
    value: "bielorrussia",
  },
  {
    label: "Bolívia",
    value: "bolivia",
  },
  {
    label: "Bósnia e Herzegovina",
    value: "bosnia-e-herzegovina",
  },
  {
    label: "Botswana (ou Botsuana)",
    value: "botswana",
  },
  {
    label: "Brunei",
    value: "brunei",
  },
  {
    label: "Bulgária",
    value: "bulgaria",
  },
  {
    label: "Burkina Faso (ou Burquina Faso)",
    value: "burkina-faso",
  },
  {
    label: "Burundi",
    value: "burundi",
  },
  {
    label: "Butão",
    value: "butao",
  },
  {
    label: "Cabo Verde",
    value: "cabo-verde",
  },
  {
    label: "Camarões",
    value: "camaroes",
  },
  {
    label: "Camboja",
    value: "camboja",
  },
  {
    label: "Canadá",
    value: "canada",
  },
  {
    label: "Catar (ou Qatar)",
    value: "catar",
  },
  {
    label: "Cazaquistão",
    value: "cazaquistao",
  },
  {
    label: "Chade",
    value: "chade",
  },
  {
    label: "Chile",
    value: "chile",
  },
  {
    label: "China",
    value: "china",
  },
  {
    label: "Chipre",
    value: "chipre",
  },
  {
    label: "Cingapura (ou Singapura)",
    value: "cingapura",
  },
  {
    label: "Colômbia",
    value: "colombia",
  },
  {
    label: "Comores",
    value: "comores",
  },
  {
    label: "Congo",
    value: "congo",
  },
  {
    label: "Coreia do Norte",
    value: "coreia-do-norte",
  },
  {
    label: "Coreia do Sul",
    value: "coreia-do-sul",
  },
  {
    label: "Costa do Marfim",
    value: "costa-do-marfim",
  },
  {
    label: "Costa Rica",
    value: "costa-rica",
  },
  {
    label: "Croácia",
    value: "croacia",
  },
  {
    label: "Cuba",
    value: "cuba",
  },
  {
    label: "Dinamarca",
    value: "dinamarca",
  },
  {
    label: "Djibouti (ou Djibuti)",
    value: "djibouti",
  },
  {
    label: "Dominica",
    value: "dominica",
  },
  {
    label: "Egito",
    value: "egito",
  },
  {
    label: "El Salvador",
    value: "el-salvador",
  },
  {
    label: "Emirados Árabes Unidos",
    value: "emirados-arabes-unidos",
  },
  {
    label: "Equador",
    value: "equador",
  },
  {
    label: "Eritreia",
    value: "eritreia",
  },
  {
    label: "Escócia",
    value: "escocia",
  },
  {
    label: "Eslováquia",
    value: "eslovaquia",
  },
  {
    label: "Eslovênia",
    value: "eslovenia",
  },
  {
    label: "Espanha",
    value: "espanha",
  },
  {
    label: "Estados Federados da Micronésia",
    value: "estados-federados-da-micronesia",
  },
  {
    label: "Estados Unidos da América",
    value: "estados-unidos-da-america",
  },
  {
    label: "Estônia",
    value: "estonia",
  },
  {
    label: "Eswatini (ou Essuatíni, ou Suazilândia)",
    value: "eswatini",
  },
  {
    label: "Etiópia",
    value: "etiopia",
  },
  {
    label: "Fiji",
    value: "fiji",
  },
  {
    label: "Filipinas",
    value: "filipinas",
  },
  {
    label: "Finlândia",
    value: "finlandia",
  },
  {
    label: "França",
    value: "franca",
  },
  {
    label: "Gabão",
    value: "gabao",
  },
  {
    label: "Gâmbia",
    value: "gambia",
  },
  {
    label: "Gana",
    value: "gana",
  },
  {
    label: "Geórgia",
    value: "georgia",
  },
  {
    label: "Granada",
    value: "granada",
  },
  {
    label: "Grécia",
    value: "grecia",
  },
  {
    label: "Guatemala",
    value: "guatemala",
  },
  {
    label: "Guiana",
    value: "guiana",
  },
  {
    label: "Guiné Equatorial",
    value: "guine-equatorial",
  },
  {
    label: "Guiné",
    value: "guine",
  },
  {
    label: "Guiné-Bissau",
    value: "guine-bissau",
  },
  {
    label: "Haiti",
    value: "haiti",
  },
  {
    label: "Holanda",
    value: "holanda",
  },
  {
    label: "Honduras",
    value: "honduras",
  },
  {
    label: "Hungria",
    value: "hungria",
  },
  {
    label: "Iêmen",
    value: "iemen",
  },
  {
    label: "Índia",
    value: "india",
  },
  {
    label: "Indonésia",
    value: "indonesia",
  },
  {
    label: "Inglaterra",
    value: "inglaterra",
  },
  {
    label: "Irã (ou Irão)",
    value: "ira",
  },
  {
    label: "Iraque",
    value: "iraque",
  },
  {
    label: "Irlanda do Norte",
    value: "irlanda-do-norte",
  },
  {
    label: "Irlanda",
    value: "irlanda",
  },
  {
    label: "Islândia",
    value: "islandia",
  },
  {
    label: "Israel",
    value: "israel",
  },
  {
    label: "Itália",
    value: "italia",
  },
  {
    label: "Jamaica",
    value: "jamaica",
  },
  {
    label: "Japão",
    value: "japao",
  },
  {
    label: "Jordânia",
    value: "jordania",
  },
  {
    label: "Kiribati (ou Quiribati)",
    value: "kiribati",
  },
  {
    label: "Kosovo",
    value: "kosovo",
  },
  {
    label: "Kuwait",
    value: "kuwait",
  },
  {
    label: "Laos",
    value: "laos",
  },
  {
    label: "Lesoto",
    value: "lesoto",
  },
  {
    label: "Letônia",
    value: "letonia",
  },
  {
    label: "Líbano",
    value: "libano",
  },
  {
    label: "Libéria",
    value: "liberia",
  },
  {
    label: "Líbia",
    value: "libia",
  },
  {
    label: "Liechtenstein (ou Listenstaine)",
    value: "liechtenstein",
  },
  {
    label: "Lituânia",
    value: "lituania",
  },
  {
    label: "Luxemburgo",
    value: "luxemburgo",
  },
  {
    label: "Macedônia do Norte",
    value: "macedonia-do-norte",
  },
  {
    label: "Madagascar (ou Madagáscar)",
    value: "madagascar",
  },
  {
    label: "Malásia",
    value: "malasia",
  },
  {
    label: "Malawi (ou Malauí)",
    value: "malawi",
  },
  {
    label: "Maldivas",
    value: "maldivas",
  },
  {
    label: "Mali",
    value: "mali",
  },
  {
    label: "Malta",
    value: "malta",
  },
  {
    label: "Marrocos",
    value: "marrocos",
  },
  {
    label: "Marshall",
    value: "marshall",
  },
  {
    label: "Maurícia",
    value: "mauricia",
  },
  {
    label: "Mauritânia",
    value: "mauritania",
  },
  {
    label: "México",
    value: "mexico",
  },
  {
    label: "Mianmar",
    value: "mianmar",
  },
  {
    label: "Micronésia",
    value: "micronesia",
  },
  {
    label: "Moçambique",
    value: "mocambique",
  },
  {
    label: "Moldávia",
    value: "moldavia",
  },
  {
    label: "Mônaco",
    value: "monaco",
  },
  {
    label: "Mongólia",
    value: "mongolia",
  },
  {
    label: "Montenegro",
    value: "montenegro",
  },
  {
    label: "Namíbia",
    value: "namibia",
  },
  {
    label: "Nauru",
    value: "nauru",
  },
  {
    label: "Nepal",
    value: "nepal",
  },
  {
    label: "Nicarágua:",
    value: "nicaragua:",
  },
  {
    label: "Níger",
    value: "niger",
  },
  {
    label: "Nigéria",
    value: "nigeria",
  },
  {
    label: "Noruega",
    value: "noruega",
  },
  {
    label: "Nova Zelândia",
    value: "nova-zelandia",
  },
  {
    label: "Omã",
    value: "oma",
  },
  {
    label: "Ossétia do Sul",
    value: "ossetia-do-sul",
  },
  {
    label: "País de Gales",
    value: "pais-de-gales",
  },
  {
    label: "Países Baixos",
    value: "paises-baixos",
  },
  {
    label: "Palau",
    value: "palau",
  },
  {
    label: "Palestina",
    value: "palestina",
  },
  {
    label: "Panamá",
    value: "panama",
  },
  {
    label: "Papua-Nova Guiné",
    value: "papua-nova-guine",
  },
  {
    label: "Paquistão",
    value: "paquistao",
  },
  {
    label: "Paraguai",
    value: "paraguai",
  },
  {
    label: "Peru",
    value: "peru",
  },
  {
    label: "Polônia",
    value: "polonia",
  },
  {
    label: "Portugal",
    value: "portugal",
  },
  {
    label: "Qatar (ou Catar)",
    value: "qatar",
  },
  {
    label: "Quênia",
    value: "quenia",
  },
  {
    label: "Quirguistão",
    value: "quirguistao",
  },
  {
    label: "Quiribati (ou Kiribati)",
    value: "quiribati",
  },
  {
    label: "Reino Unido",
    value: "reino-unido",
  },
  {
    label: "República Árabe Saaraui Democrática",
    value: "republica-arabe-saaraui-democratica",
  },
  {
    label: "República Centro-Africana",
    value: "republica-centro-africana",
  },
  {
    label: "República Democrática do Congo",
    value: "republica-democratica-do-congo",
  },
  {
    label: "República do Congo",
    value: "republica-do-congo",
  },
  {
    label: "República Dominicana",
    value: "republica-dominicana",
  },
  {
    label: "República Tcheca (ou Tchéquia)",
    value: "republica-tcheca",
  },
  {
    label: "República Turca de Chipre do Norte",
    value: "republica-turca-de-chipre-do-norte",
  },
  {
    label: "Romênia",
    value: "romenia",
  },
  {
    label: "Ruanda",
    value: "ruanda",
  },
  {
    label: "Rússia",
    value: "russia",
  },
  {
    label: "Salomão",
    value: "salomao",
  },
  {
    label: "Samoa",
    value: "samoa",
  },
  {
    label: "San Marino (ou São Marinho)",
    value: "san-marino",
  },
  {
    label: "Santa Lúcia",
    value: "santa-lucia",
  },
  {
    label: "São Cristóvão e Névis (ou São Cristóvão e Neves)",
    value: "sao-cristovao-e-nevis",
  },
  {
    label: "São Tomé e Príncipe",
    value: "sao-tome-e-principe",
  },
  {
    label: "São Vicente e Granadinas",
    value: "sao-vicente-e-granadinas",
  },
  {
    label: "Senegal",
    value: "senegal",
  },
  {
    label: "Serra Leoa",
    value: "serra-leoa",
  },
  {
    label: "Sérvia",
    value: "servia",
  },
  {
    label: "Seychelles (ou Seicheles)",
    value: "seychelles",
  },
  {
    label: "Singapura (ou Cingapura)",
    value: "singapura",
  },
  {
    label: "Síria",
    value: "siria",
  },
  {
    label: "Somália",
    value: "somalia",
  },
  {
    label: "Sri Lanka",
    value: "sri-lanka",
  },
  {
    label: "Suazilândia (ou Eswatini, ou Essuatíni)",
    value: "suazilandia",
  },
  {
    label: "Sudão do Sul",
    value: "sudao-do-sul",
  },
  {
    label: "Sudão",
    value: "sudao",
  },
  {
    label: "Suécia",
    value: "suecia",
  },
  {
    label: "Suíça",
    value: "suica",
  },
  {
    label: "Suriname",
    value: "suriname",
  },
  {
    label: "Tailândia",
    value: "tailandia",
  },
  {
    label: "Taiwan",
    value: "taiwan",
  },
  {
    label: "Tajiquistão (ou Tadjiquistão)",
    value: "tajiquistao",
  },
  {
    label: "Tanzânia",
    value: "tanzania",
  },
  {
    label: "Tchéquia (ou República Tcheca)",
    value: "tchequia",
  },
  {
    label: "Timor-Leste",
    value: "timor-leste",
  },
  {
    label: "Togo",
    value: "togo",
  },
  {
    label: "Tonga",
    value: "tonga",
  },
  {
    label: "Trinidad e Tobago",
    value: "trinidad-e-tobago",
  },
  {
    label: "Tunísia",
    value: "tunisia",
  },
  {
    label: "Turcomenistão (ou Turquemenistão)",
    value: "turcomenistao",
  },
  {
    label: "Turquia",
    value: "turquia",
  },
  {
    label: "Tuvalu",
    value: "tuvalu",
  },
  {
    label: "Ucrânia",
    value: "ucrania",
  },
  {
    label: "Uganda",
    value: "uganda",
  },
  {
    label: "Uruguai",
    value: "uruguai",
  },
  {
    label: "Uzbequistão",
    value: "uzbequistao",
  },
  {
    label: "Vanuatu",
    value: "vanuatu",
  },
  {
    label: "Vaticano",
    value: "vaticano",
  },
  {
    label: "Venezuela",
    value: "venezuela",
  },
  {
    label: "Vietnã (ou Vietname)",
    value: "vietna",
  },
  {
    label: "Zâmbia",
    value: "zambia",
  },
  {
    label: "Zimbábue",
    value: "zimbabue",
  },
  {
    label: "Não informado",
    value: "nao-informado",
  },
];

export const sexOrientationOptions = [
  {
    label: "Heterossexual",
    value: "heterosexual",
  },
  {
    label: "LGBTQIA+",
    value: "lgbtqia+",
  },
  {
    label: "Prefiro não informar",
    value: "private",
  },
];

export const houseSituationOptions = [
  {
    label: "Estou em situação de rua",
    value: "homeless",
  },
  {
    label: "Tenho residência",
    value: "has_home",
  },
];

export const companyAttendenceTypesOptions = [
  {
    label: "Contato",
    value: "REMOTE_COMPANY_CONTACT",
  },
];

export const attendenceTypesOptions = [
  {
    label: "Solicitação de Seguro Desemprego",
    value: "PRESENTIAL_UNEMPLOYMENT_INSURANCE_REQUIREMENT",
  },
  {
    label: "Atualização de CadÚnico",
    value: "PRESENTIAL_CADUNICO_UPDATE",
  },
  {
    label: "Cadastro de CadÚnico",
    value: "PRESENTIAL_CADUNICO_REGISTER",
  },
  {
    label: "Solicitação de Seguro Desemprego - Via DIGITAL",
    value: "PRESENTIAL_UNEMPLOYMENT_INSURANCE_REQUIREMENT_DIGITAL",
  },
  {
    label: "Pendência de Seguro-Desemprego",
    value: "PRESENTIAL_UNEMPLOYMENT_INSURANCE_PENDENCE",
  },
  {
    label: "Retorno de Seguro Desemprego",
    value: "PRESENTIAL_UNEMPLOYMENT_INSURANCE_RETURN",
  },
  {
    label: "Liberação de ATA",
    value: "PRESENTIAL_ATA_LIBERATION",
  },
  {
    label: "Empregador WEB",
    value: "PRESENTIAL_WEB_EPREGADOR",
  },
  {
    label: "MEI - Microempreendedor",
    value: "PRESENTIAL_MEI",
  },
  {
    label: "IMO (Intermediação de Mão de Obra)",
    value: "PRESENTIAL_IMO",
  },
  {
    label: "Atendimento carteira - DIGITAL",
    value: "PRESENTIAL_WORK_CARD_DIGITAL",
  },
  {
    label: "Programas de Benefícios",
    value: "PRESENTIAL_BENEFITS_PROGRAM",
  },
  {
    label: "Tem Saída",
    value: "PRESENTIAL_HAVE_EXIT",
  },
  {
    label: "Currículo",
    value: "PRESENTIAL_RESUME",
  },
  {
    label: "Processo Seletivo",
    value: "PRESENTIAL_SELECTIVE_PROCESS",
  },
];

export const portalSexualOrientation = [
  {
    label: "Heterossexual",
    value: "heterossexual",
  },
  {
    label: "LGBTQIA+",
    value: "lgbtqia",
  },
  {
    label: "Prefiro não informar",
    value: "confidential",
  },
];

export const portalPCD = [
  {
    label: "Não tem deficiência",
    value: null,
  },
  {
    label: "Auditiva",
    value: "ear",
  },
  {
    label: "Fala",
    value: "speach",
  },
  {
    label: "Física",
    value: "physical",
  },
  {
    label: "Intelectual",
    value: "intellectual",
  },
  {
    label: "Múltipla",
    value: "multiple",
  },
  {
    label: "Psicossocial",
    value: "psychosocial",
  },
  {
    label: "Visual",
    value: "eye",
  },
  {
    label: "Outras",
    value: "other",
  },
];

export const portalEthnicGroup = [
  {
    label: "Branca",
    value: "white",
  },
  {
    label: "Preta",
    value: "black",
  },
  {
    label: "Amarela",
    value: "yellow",
  },
  {
    label: "Parda",
    value: "brown",
  },
  {
    label: "Indígena",
    value: "indigenous",
  },
];

export const portalHouseSituation = [
  {
    label: "O beneficiário tem residência",
    value: "residence",
  },
  {
    label: "O beneficiário está em situação de rua",
    value: "street",
  },
];

export const portalNacionality = [
  {
    label: "Brasil",
    value: "brasil",
  },
  {
    label: "Abecásia",
    value: "abecásia",
  },
  {
    label: "Afeganistão",
    value: "afeganistão",
  },
  {
    label: "África do Sul",
    value: "África-do-sul",
  },
  {
    label: "Albânia",
    value: "albânia",
  },
  {
    label: "Alemanha",
    value: "alemanha",
  },
  {
    label: "Andorra",
    value: "andorra",
  },
  {
    label: "Angola",
    value: "angola",
  },
  {
    label: "Antígua e Barbuda",
    value: "antígua-e-barbuda",
  },
  {
    label: "Arábia Saudita",
    value: "arábia-saudita",
  },
  {
    label: "Argélia",
    value: "argélia",
  },
  {
    label: "Argentina",
    value: "argentina",
  },
  {
    label: "Armênia",
    value: "armênia",
  },
  {
    label: "Austrália",
    value: "austrália",
  },
  {
    label: "Áustria",
    value: "Áustria",
  },
  {
    label: "Azerbaijão",
    value: "azerbaijão",
  },
  {
    label: "Bahamas",
    value: "bahamas",
  },
  {
    label: "Bahrein (ou Barein, ou Barém)",
    value: "bahrein",
  },
  {
    label: "Bangladesh",
    value: "bangladesh",
  },
  {
    label: "Barbados",
    value: "barbados",
  },
  {
    label: "Bélgica",
    value: "bélgica",
  },
  {
    label: "Belize",
    value: "belize",
  },
  {
    label: "Benim",
    value: "benim",
  },
  {
    label: "Bielorrússia",
    value: "bielorrússia",
  },
  {
    label: "Bolívia",
    value: "bolívia",
  },
  {
    label: "Bósnia e Herzegovina",
    value: "bósnia-e-herzegovina",
  },
  {
    label: "Botswana (ou Botsuana)",
    value: "botswana",
  },
  {
    label: "Brunei",
    value: "brunei",
  },
  {
    label: "Bulgária",
    value: "bulgária",
  },
  {
    label: "Burkina Faso (ou Burquina Faso)",
    value: "burkina-faso",
  },
  {
    label: "Burundi",
    value: "burundi",
  },
  {
    label: "Butão",
    value: "butão",
  },
  {
    label: "Cabo Verde",
    value: "cabo-verde",
  },
  {
    label: "Camarões",
    value: "camarões",
  },
  {
    label: "Camboja",
    value: "camboja",
  },
  {
    label: "Canadá",
    value: "canadá",
  },
  {
    label: "Catar (ou Qatar)",
    value: "catar",
  },
  {
    label: "Cazaquistão",
    value: "cazaquistão",
  },
  {
    label: "Chade",
    value: "chade",
  },
  {
    label: "Chile",
    value: "chile",
  },
  {
    label: "China",
    value: "china",
  },
  {
    label: "Chipre",
    value: "chipre",
  },
  {
    label: "Cingapura (ou Singapura)",
    value: "cingapura",
  },
  {
    label: "Colômbia",
    value: "colômbia",
  },
  {
    label: "Comores",
    value: "comores",
  },
  {
    label: "Congo",
    value: "congo",
  },
  {
    label: "Coreia do Norte",
    value: "coreia-do-norte",
  },
  {
    label: "Coreia do Sul",
    value: "coreia-do-sul",
  },
  {
    label: "Costa do Marfim",
    value: "costa-do-marfim",
  },
  {
    label: "Costa Rica",
    value: "costa-rica",
  },
  {
    label: "Croácia",
    value: "croácia",
  },
  {
    label: "Cuba",
    value: "cuba",
  },
  {
    label: "Dinamarca",
    value: "dinamarca",
  },
  {
    label: "Djibouti (ou Djibuti)",
    value: "djibouti",
  },
  {
    label: "Dominica",
    value: "dominica",
  },
  {
    label: "Egito",
    value: "egito",
  },
  {
    label: "El Salvador",
    value: "el-salvador",
  },
  {
    label: "Emirados Árabes Unidos",
    value: "emirados-Árabes-unidos",
  },
  {
    label: "Equador",
    value: "equador",
  },
  {
    label: "Eritreia",
    value: "eritreia",
  },
  {
    label: "Escócia",
    value: "escócia",
  },
  {
    label: "Eslováquia",
    value: "eslováquia",
  },
  {
    label: "Eslovênia",
    value: "eslovênia",
  },
  {
    label: "Espanha",
    value: "espanha",
  },
  {
    label: "Estados Federados da Micronésia",
    value: "estados-federados-da-micronésia",
  },
  {
    label: "Estados Unidos da América",
    value: "estados-unidos-da-américa",
  },
  {
    label: "Estônia",
    value: "estônia",
  },
  {
    label: "Eswatini (ou Essuatíni, ou Suazilândia)",
    value: "eswatini",
  },
  {
    label: "Etiópia",
    value: "etiópia",
  },
  {
    label: "Fiji",
    value: "fiji",
  },
  {
    label: "Filipinas",
    value: "filipinas",
  },
  {
    label: "Finlândia",
    value: "finlândia",
  },
  {
    label: "França",
    value: "frança",
  },
  {
    label: "Gabão",
    value: "gabão",
  },
  {
    label: "Gâmbia",
    value: "gâmbia",
  },
  {
    label: "Gana",
    value: "gana",
  },
  {
    label: "Geórgia",
    value: "geórgia",
  },
  {
    label: "Granada",
    value: "granada",
  },
  {
    label: "Grécia",
    value: "grécia",
  },
  {
    label: "Guatemala",
    value: "guatemala",
  },
  {
    label: "Guiana",
    value: "guiana",
  },
  {
    label: "Guiné Equatorial",
    value: "guiné-equatorial",
  },
  {
    label: "Guiné",
    value: "guiné",
  },
  {
    label: "Guiné-Bissau",
    value: "guiné-bissau",
  },
  {
    label: "Haiti",
    value: "haiti",
  },
  {
    label: "Holanda",
    value: "holanda",
  },
  {
    label: "Honduras",
    value: "honduras",
  },
  {
    label: "Hungria",
    value: "hungria",
  },
  {
    label: "Iêmen",
    value: "iêmen",
  },
  {
    label: "Índia",
    value: "Índia",
  },
  {
    label: "Indonésia",
    value: "indonésia",
  },
  {
    label: "Inglaterra",
    value: "inglaterra",
  },
  {
    label: "Irã (ou Irão)",
    value: "irã",
  },
  {
    label: "Iraque",
    value: "iraque",
  },
  {
    label: "Irlanda do Norte",
    value: "irlanda-do-norte",
  },
  {
    label: "Irlanda",
    value: "irlanda",
  },
  {
    label: "Islândia",
    value: "islândia",
  },
  {
    label: "Israel",
    value: "israel",
  },
  {
    label: "Itália",
    value: "itália",
  },
  {
    label: "Jamaica",
    value: "jamaica",
  },
  {
    label: "Japão",
    value: "japão",
  },
  {
    label: "Jordânia",
    value: "jordânia",
  },
  {
    label: "Kiribati (ou Quiribati)",
    value: "kiribati",
  },
  {
    label: "Kosovo",
    value: "kosovo",
  },
  {
    label: "Kuwait",
    value: "kuwait",
  },
  {
    label: "Laos",
    value: "laos",
  },
  {
    label: "Lesoto",
    value: "lesoto",
  },
  {
    label: "Letônia",
    value: "letônia",
  },
  {
    label: "Líbano",
    value: "líbano",
  },
  {
    label: "Libéria",
    value: "libéria",
  },
  {
    label: "Líbia",
    value: "líbia",
  },
  {
    label: "Liechtenstein (ou Listenstaine)",
    value: "liechtenstein",
  },
  {
    label: "Lituânia",
    value: "lituânia",
  },
  {
    label: "Luxemburgo",
    value: "luxemburgo",
  },
  {
    label: "Macedônia do Norte",
    value: "macedônia-do-norte",
  },
  {
    label: "Madagascar (ou Madagáscar)",
    value: "madagascar",
  },
  {
    label: "Malásia",
    value: "malásia",
  },
  {
    label: "Malawi (ou Malauí)",
    value: "malawi",
  },
  {
    label: "Maldivas",
    value: "maldivas",
  },
  {
    label: "Mali",
    value: "mali",
  },
  {
    label: "Malta",
    value: "malta",
  },
  {
    label: "Marrocos",
    value: "marrocos",
  },
  {
    label: "Marshall",
    value: "marshall",
  },
  {
    label: "Maurícia",
    value: "maurícia",
  },
  {
    label: "Mauritânia",
    value: "mauritânia",
  },
  {
    label: "México",
    value: "méxico",
  },
  {
    label: "Mianmar",
    value: "mianmar",
  },
  {
    label: "Micronésia",
    value: "micronésia",
  },
  {
    label: "Moçambique",
    value: "moçambique",
  },
  {
    label: "Moldávia",
    value: "moldávia",
  },
  {
    label: "Mônaco",
    value: "mônaco",
  },
  {
    label: "Mongólia",
    value: "mongólia",
  },
  {
    label: "Montenegro",
    value: "montenegro",
  },
  {
    label: "Namíbia",
    value: "namíbia",
  },
  {
    label: "Nauru",
    value: "nauru",
  },
  {
    label: "Nepal",
    value: "nepal",
  },
  {
    label: "Nicarágua:",
    value: "nicarágua:",
  },
  {
    label: "Níger",
    value: "níger",
  },
  {
    label: "Nigéria",
    value: "nigéria",
  },
  {
    label: "Noruega",
    value: "noruega",
  },
  {
    label: "Nova Zelândia",
    value: "nova-zelândia",
  },
  {
    label: "Omã",
    value: "omã",
  },
  {
    label: "Ossétia do Sul",
    value: "ossétia-do-sul",
  },
  {
    label: "País de Gales",
    value: "país-de-gales",
  },
  {
    label: "Países Baixos",
    value: "países-baixos",
  },
  {
    label: "Palau",
    value: "palau",
  },
  {
    label: "Palestina",
    value: "palestina",
  },
  {
    label: "Panamá",
    value: "panamá",
  },
  {
    label: "Papua-Nova Guiné",
    value: "papua-nova-guiné",
  },
  {
    label: "Paquistão",
    value: "paquistão",
  },
  {
    label: "Paraguai",
    value: "paraguai",
  },
  {
    label: "Peru",
    value: "peru",
  },
  {
    label: "Polônia",
    value: "polônia",
  },
  {
    label: "Portugal",
    value: "portugal",
  },
  {
    label: "Qatar (ou Catar)",
    value: "qatar",
  },
  {
    label: "Quênia",
    value: "quênia",
  },
  {
    label: "Quirguistão",
    value: "quirguistão",
  },
  {
    label: "Quiribati (ou Kiribati)",
    value: "quiribati",
  },
  {
    label: "Reino Unido",
    value: "reino-unido",
  },
  {
    label: "República Árabe Saaraui Democrática",
    value: "república-Árabe-saaraui-democrática",
  },
  {
    label: "República Centro-Africana",
    value: "república-centro-africana",
  },
  {
    label: "República Democrática do Congo",
    value: "república-democrática-do-congo",
  },
  {
    label: "República do Congo",
    value: "república-do-congo",
  },
  {
    label: "República Dominicana",
    value: "república-dominicana",
  },
  {
    label: "República Tcheca (ou Tchéquia)",
    value: "república-tcheca",
  },
  {
    label: "República Turca de Chipre do Norte",
    value: "república-turca-de-chipre-do-norte",
  },
  {
    label: "Romênia",
    value: "romênia",
  },
  {
    label: "Ruanda",
    value: "ruanda",
  },
  {
    label: "Rússia",
    value: "rússia",
  },
  {
    label: "Salomão",
    value: "salomão",
  },
  {
    label: "Samoa",
    value: "samoa",
  },
  {
    label: "San Marino (ou São Marinho)",
    value: "san-marino",
  },
  {
    label: "Santa Lúcia",
    value: "santa-lúcia",
  },
  {
    label: "São Cristóvão e Névis (ou São Cristóvão e Neves)",
    value: "são-cristóvão-e-névis",
  },
  {
    label: "São Tomé e Príncipe",
    value: "são-tomé-e-príncipe",
  },
  {
    label: "São Vicente e Granadinas",
    value: "são-vicente-e-granadinas",
  },
  {
    label: "Senegal",
    value: "senegal",
  },
  {
    label: "Serra Leoa",
    value: "serra-leoa",
  },
  {
    label: "Sérvia",
    value: "sérvia",
  },
  {
    label: "Seychelles (ou Seicheles)",
    value: "seychelles",
  },
  {
    label: "Singapura (ou Cingapura)",
    value: "singapura",
  },
  {
    label: "Síria",
    value: "síria",
  },
  {
    label: "Somália",
    value: "somália",
  },
  {
    label: "Sri Lanka",
    value: "sri-lanka",
  },
  {
    label: "Suazilândia (ou Eswatini, ou Essuatíni)",
    value: "suazilândia",
  },
  {
    label: "Sudão do Sul",
    value: "sudão-do-sul",
  },
  {
    label: "Sudão",
    value: "sudão",
  },
  {
    label: "Suécia",
    value: "suécia",
  },
  {
    label: "Suíça",
    value: "suíça",
  },
  {
    label: "Suriname",
    value: "suriname",
  },
  {
    label: "Tailândia",
    value: "tailândia",
  },
  {
    label: "Taiwan",
    value: "taiwan",
  },
  {
    label: "Tajiquistão (ou Tadjiquistão)",
    value: "tajiquistão",
  },
  {
    label: "Tanzânia",
    value: "tanzânia",
  },
  {
    label: "Tchéquia (ou República Tcheca)",
    value: "tchéquia",
  },
  {
    label: "Timor-Leste",
    value: "timor-leste",
  },
  {
    label: "Togo",
    value: "togo",
  },
  {
    label: "Tonga",
    value: "tonga",
  },
  {
    label: "Trinidad e Tobago",
    value: "trinidad-e-tobago",
  },
  {
    label: "Tunísia",
    value: "tunísia",
  },
  {
    label: "Turcomenistão (ou Turquemenistão)",
    value: "turcomenistão",
  },
  {
    label: "Turquia",
    value: "turquia",
  },
  {
    label: "Tuvalu",
    value: "tuvalu",
  },
  {
    label: "Ucrânia",
    value: "ucrânia",
  },
  {
    label: "Uganda",
    value: "uganda",
  },
  {
    label: "Uruguai",
    value: "uruguai",
  },
  {
    label: "Uzbequistão",
    value: "uzbequistão",
  },
  {
    label: "Vanuatu",
    value: "vanuatu",
  },
  {
    label: "Vaticano",
    value: "vaticano",
  },
  {
    label: "Venezuela",
    value: "venezuela",
  },
  {
    label: "Vietnã (ou Vietname)",
    value: "vietnã",
  },
  {
    label: "Zâmbia",
    value: "zâmbia",
  },
  {
    label: "Zimbábue",
    value: "zimbábue",
  },
  {
    label: "Não informado",
    value: "não-informado",
  },
];

export const portalSchooling = [
  {
    label: "Fundamental Incompleto",
    value: "ef_incompleto",
  },
  {
    label: "Fundamental Cursando",
    value: "ef_cursando",
  },
  {
    label: "Fundamental Completo",
    value: "ef_completo",
  },
  {
    label: "Ensino Médio Incompleto",
    value: "em_incompleto",
  },
  {
    label: "Ensino Médio Cursando",
    value: "em_cursando",
  },
  {
    label: "Ensino Médio Completo",
    value: "em_completo",
  },

  {
    label: "Ensino Superior Incompleto",
    value: "es_incompleto",
  },
  {
    label: "Ensino Superior Cursando",
    value: "es_cursando",
  },
  {
    label: "Ensino Superior Completo",
    value: "es_completo",
  },

  {
    label: "Pós-graduação (Lato senso) Incompleto",
    value: "pos_incompleto",
  },
  {
    label: "Pós-graduação (Lato senso) Cursando",
    value: "pos_cursando",
  },
  {
    label: "Pós-graduação (Lato senso) Completo",
    value: "pos_completo",
  },
];
