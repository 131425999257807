import React, { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useSearchParams } from "react-router-dom";
import { TranslateJobStatus } from "./../../helpers/translateFields";
import { CompanyListContainer } from "./style";
import api from "./../../config/api";
import PageHeader from "./../../components/PageHeader";
import Pagination from "./../../components/Pagination";
import Input from "./../../components/FormHook/Input";
import Switch from "./../../components/FormHook/Switch";
import WithMask from "./../../components/FormHook/WithMask";
import ReactSelect from "./../../components/FormHook/ReactSelect";
import FiltersSidebar from "./../../components/FiltersSidebar";
import { confirm } from "./../../components/Confirmation";

import { optionsRegion } from "./../Jobs/database";

const badgeColors = {
  active: "info",
  closed: "danger",
  schedule: "warning",
};

export default function Cate() {
  const { register, handleSubmit, reset, control, formState, getValues } =
    useForm({ defaultValues: { is_tech: null, must_job_register: null } });
  const [companies, setCompanies] = useState([]);
  const [kpis, setKpis] = useState(null);
  const [searchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [notFoundResults, setNotFoundResults] = useState(false);

  useEffect(() => {
    getDash();
    getFilterQuery();
    handleSearch(1, getValues());
  }, []);

  async function getDash() {
    try {
      let resp = await api.get(`dashboard/companies`);
      setKpis(resp.data);
    } catch (e) {}
  }

  async function handleFilter(data) {
    handleSearch(1, data);
  }

  async function handleDestroy(id, hasJobs = false) {
    if (hasJobs) {
      toast.error(
        `Essa empresa possui vagas cadastradas. Nao é possivel exclui-la`
      );
      return false;
    }

    if (
      await confirm("Deseja realmente excluir essa empresa?", "Sim", "Não", {
        title: "Excluir Empresa?",
      })
    ) {
      try {
        await api.delete(`companies/${id}`);
        toast.success("Empresa deletada com sucesso");
      } catch (e) {
        if (e.response.status === 409 || e.response.status === 401) {
          toast.error(e.response.data.message);
          return false;
        }
        toast.error("Erro ao deletar");
      }
      handleSearch();
    }
  }

  async function handleSearch(nextPage = 1, filters = null) {
    setFilterQuery(filters);
    let resp = await api.get(`companies`, {
      params: {
        page: nextPage,
        ...filters,
      },
    });
    setCompanies(resp.data.data);
    setTotalPages(resp.data.last_page);
    setPage(nextPage);
    setActiveFilters(filters);
    setNotFoundResults(resp.data.data.length === 0);
    window.scrollTo(0, 0);
  }

  async function getFilterQuery() {
    let filters = {};
    // simple fields
    searchParams.forEach((value, key) => {
      if (!key.includes("[]")) {
        let parsedValue = Number.isInteger(Number(value))
          ? Number(value)
          : value;
        filters[key] = parsedValue;
      }
    });

    if (filters?.is_tech) {
      filters.is_tech = filters?.is_tech === "true" ? true : false;
    }

    if (filters?.must_job_register) {
      filters.must_job_register =
        filters?.must_job_register === "true" ? true : false;
    }

    reset(filters);
  }

  async function setFilterQuery(filters) {
    filters.occupation_ids = filters.occupations?.map((occ) => occ.value);
    let simpleFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([_, v]) => v != null && v != "" && typeof v != "object"
      )
    );
    let simpleQuery = Object.keys(simpleFilters).map(
      (key) => key + "=" + simpleFilters[key]
    );

    let queryString = simpleQuery.join("&");
    window.history.replaceState(null, null, `/#/empresas/?${queryString}`);
  }

  async function handleCleanFilter() {
    reset();
    handleSearch(1, null);
  }

  async function handleReviewCompany(id) {
    if (
      await confirm("Deseja aprovar essa empresa?", "Sim", "Não", {
        title: "Confirmar Aprovação",
      })
    ) {
      try {
        let resp = await api.post(`companies/review/${id}`);
        toast.success("Empresa aprovada com sucesso.");
        handleSearch(1, getValues());
      } catch (e) {
        toast.error("Erro ao aprovar empresa.");
      }
    }
  }

  return (
    <CompanyListContainer>
      <PageHeader
        title={`Empresas`}
        actions={[
          <Link to={`/empresas/editar`}>Cadastrar nova empresa</Link>,
          <Link className="variant-secondary" to={`/atendimento/empresas`}>
            Registrar atendimento de empresas
          </Link>,
        ]}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {kpis ? (
              <div className="kpis">
                <div className="kpi-item bg-success">
                  <span className="kpi-item-title">TOTAL DE EMPRESAS</span>
                  <span className="kpi-item-value">{kpis.total}</span>
                </div>
                <div className="kpi-item bg-info">
                  <span className="kpi-item-title">
                    EMPRESAS VINDAS PELO PORTAL CATE (TOTAL)
                  </span>
                  <span className="kpi-item-value">
                    {kpis.public_published}
                  </span>
                </div>
                <div className="kpi-item bg-danger">
                  <span className="kpi-item-title">
                    EMPRESAS AGUARDANDO APROVAÇÃO
                  </span>
                  <span className="kpi-item-value">{kpis.to_review}</span>
                </div>
                <div className="kpi-item bg-warning">
                  <span className="kpi-item-title">EMPRESAS APROVADAS</span>
                  <span className="kpi-item-value">{kpis.reviewd}</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <FiltersSidebar title="Busca por empresas">
              <form onSubmit={handleSubmit(handleFilter)}>
                <Input
                  register={register}
                  name="name"
                  label="Nome da empresa:"
                />
                <WithMask
                  control={control}
                  name="cnpj"
                  label="CNPJ:"
                  mask={`99.999.999/9999-99`}
                />
                <ReactSelect
                  name="region"
                  control={control}
                  options={optionsRegion}
                  placeholder="Filtre por região"
                  label="Região:"
                />
                <Switch
                  name="waiting_review"
                  label="Aguardando aprovação?"
                  register={register}
                />
                <Switch
                  name="is_public_published"
                  label="Empresas cadastradas pelo Portal Cate"
                  register={register}
                />
                <button type="submit" className="btn btn-primary">
                  Filtrar
                </button>
                <button
                  type="button"
                  onClick={handleCleanFilter}
                  className="btn btn-secondary"
                >
                  Limpar
                </button>
              </form>
            </FiltersSidebar>
          </div>
          <div className="col-9">
            <div className="companies-list">
              {notFoundResults ? (
                <span>Nenhum resultado encontrado para essa busca.</span>
              ) : null}
              {companies.map((company) => {
                return (
                  <div key={uuidv4()} className="companies-list-item">
                    <div className="company-list-item-infos">
                      <h3 className="compani-list-item-title">
                        {company.name}
                      </h3>
                      <span>
                        {company.total_applications > 0
                          ? `Existem ${company.total_applications} candidatos para as
                        vagas abertas`
                          : null}
                      </span>

                      <div className="company-list-item-badges">
                        <span
                          className={`badge ${
                            company?.reviewed ? "bg-success" : "bg-warning"
                          }`}
                        >
                          {company?.reviewed
                            ? "Empresa Aprovada"
                            : "Aguardando Aprovação"}
                        </span>
                        {company?.jobs_amount.map((jobAmount, idx) => {
                          return (
                            <span
                              key={`job-amount-tag-${idx}`}
                              className={`badge bg-${
                                badgeColors[jobAmount.status]
                              }`}
                            >{`${TranslateJobStatus[jobAmount.status]}: ${
                              jobAmount.amount
                            }`}</span>
                          );
                        })}
                      </div>

                      <span className="total-jobs-label">
                        {company.total_jobs > 0
                          ? `Total de vagas cadastradas: ${company.total_jobs}.`
                          : `Nenhuma vaga cadastrada.`}
                      </span>
                    </div>
                    <div className="companies-list-item-actions">
                      {company.reviewed ? (
                        <Link
                          to={`/empresas/detalhes?id=${company.id}`}
                          className="btn btn-primary"
                        >
                          Ver detalhes
                        </Link>
                      ) : (
                        <button
                          onClick={() => handleReviewCompany(company.id)}
                          className="btn btn-primary"
                        >
                          Aprovar
                        </button>
                      )}

                      <Link
                        to={`/empresas/editar?id=${company.id}`}
                        className="btn btn-alternative"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() =>
                          handleDestroy(company.id, company.total_jobs > 0)
                        }
                        className="btn btn-secondary"
                      >
                        Excluir
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <Pagination
              page={page}
              total={totalPages}
              onChange={(page) => handleSearch(page, activeFilters)}
            />
          </div>
        </div>
      </div>
    </CompanyListContainer>
  );
}
