import React from "react";
import styled from "styled-components";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

const StyledInput = styled.div``;

const selectStyles = {
  control: (styles) => ({
    ...styles,
    color: "#000",
    borderRadius: "0px",
    border: "1px solid #848484",
    fontSize: "0.8rem",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#000",
      ":active": {
        ...styles[":active"],
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
  }),
};

function SimpleSelect({
  label,
  name,
  isMulti = false,
  options = [],
  control,
  placeholder,
  noOptionsMessage = "Nenhuma opção disponivel",
  disabled = false,
  required = false,
  requiredForJob = false,
}) {
  const id = uuidv4();
  return (
    <div className="react-select-item">
      {label ? (
        <React.Fragment>
          <label htmlFor={id} className="form-label">
            {label}
          </label>
          {required ? <span className="required">*</span> : null}
          {requiredForJob ? <span className="required required-secondary">*</span> : null}
        </React.Fragment>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              options={options}
              inputId={id}
              styles={selectStyles}
              placeholder={placeholder}
              noOptionsMessage={() => noOptionsMessage}
              isDisabled={disabled}
              isClearable={true}
              isMulti={isMulti}
              onChange={(options) =>
                onChange(
                  isMulti
                    ? options?.map((option) => option.value)
                    : options?.value
                )
              }
              onBlur={onBlur}
              value={
                isMulti
                  ? options.filter((option) => value?.includes(option.value))
                  : options.filter((option) => value === option.value)
              }
              defaultValue={
                isMulti
                  ? options.filter((option) => value?.includes(option.value))
                  : options.filter((option) => value === option.value)
              }
            />
          );
        }}
      />
    </div>
  );
}

export default SimpleSelect;
