import React, { useEffect, useState } from "react";
import api from "../../../../config/api";
import JobListItemDetailed from "../../../Jobs/components/JobListItemDetailed";
import Divider from "./../../../../components/Divider";
import Pagination from "../../../../components/Pagination";
import { v4 as uuidv4 } from "uuid";

import { SeggestedJobsListStyled } from "./styles";

function SeggestedJobsList({ uuid, attendanceMode = false }) {
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(async () => {
    getSuggestedList();
  }, []);

  async function getSuggestedList(nextPage = 1) {
    let response = await api.get(
      !uuid
        ? `/candidates/jobs/suggested?page=${nextPage}`
        : `/attendance/candidates/jobs/suggested?uuid=${uuid}&page=${nextPage}`
    );
    setJobs(response.data.data);
    setPage(nextPage);
    setTotalPages(response.data.lastPage);
    if (nextPage !== 1) {
      window.scrollTo(0, document.getElementById("suggested-list").offsetTop);
    }
  }

  return jobs.length > 0 ? (
    <SeggestedJobsListStyled>
      <Divider />
      <div className="row suggested-list" id="suggested-list">
        <h3>Vagas de emprego recomendadas para você</h3>
        <p className="infos">
          Veja aqui as vagas de emprego recomendadas para o seu perfil.
        </p>
      </div>
      <div className="row">
        {jobs.map((job) => (
          <JobListItemDetailed
            key={uuidv4()}
            attendanceMode={attendanceMode}
            {...job}
            isCandidate={true}
          />
        ))}
      </div>
      <Pagination
        page={page}
        total={totalPages}
        onChange={(page) => getSuggestedList(page)}
      />
    </SeggestedJobsListStyled>
  ) : null;
}

export default SeggestedJobsList;
