import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useSearchParams } from "react-router-dom";
import {
  optionsContractType,
  optionsBenefits,
  optionsOtherRequirements,
  optionsPCD,
  optionsGender,
  optionsRegion,
  optionsCNH,
  optionsLanguage,
} from "./database";
import { JobsContainer } from "./style";
import JobListItem from "./components/JobListItem";
import api from "./../../config/api";
import Input from "./../../components/FormHook/Input";
import WithMask from "./../../components/FormHook/WithMask";
import Switch from "../../components/FormHook/Switch";
import ReactSelect from "./../../components/FormHook/ReactSelect";
import OccupationSelect from "./../../components/FormHook/OccupationSelect";
import PageHeader from "./../../components/PageHeader";
import Pagination from "./../../components/Pagination";
import AuthContext from "../../contexts/auth";
import AttendanceHeader from "./../Attendance/components/AttendanceHeader";

export default function Jobs({ attendanceMode = false }) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState,
    watch,
    getValues,
    setValue,
  } = useForm({ defaultValues: { is_tech: null, must_job_register: null } });
  const { user } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const currentRole = user.auth.user.role;
  const [jobs, setJobs] = useState([]);
  const [segments, setSegments] = useState([]);
  const [schoolingLevels, setSchoolingLevels] = useState([]);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [page, setPage] = useState(1);
  const [activeFilters, setActiveFilters] = useState(null);
  // TODO: Check what totalPages not used
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const idFilterWatcher = watch("id");
  const idSineFilterWatcher = watch("sine_id");
  const cnpjFilterWatcher = watch("cnpj");

  useEffect(async () => {
    await getFilterQuery();
    getJobs(1, getValues());
    let segmentResponse = await api.get(`segments`);
    setSegments(segmentResponse.data);
    let schoolingResponse = await api.get(`schooling-levels`);
    setSchoolingLevels(schoolingResponse.data);
    let experienceResponse = await api.get(`experience-levels`);
    setExperienceLevels(experienceResponse.data);
  }, []);

  async function getJobs(nextPage = 1, filters = null) {
    if (filters) {
      if (searchParams.get("must_job_register") === null) {
        if (!formState.dirtyFields.must_job_register) {
          delete filters.must_job_register;
        }
      }
      if (searchParams.get("is_tech") === null) {
        if (!formState.dirtyFields.is_tech) {
          delete filters.is_tech;
        }
      }
      filters.occupation_ids = filters.occupations?.map((occ) => occ.value);
      delete filters.occupations;
    }
    setPage(nextPage);
    setActiveFilters(filters);
    let reqParams = {
      page: nextPage,
      ...filters,
    };

    try {
      const response = await api.get(`jobs`, {
        params: reqParams,
      });
      setJobs(response.data.data);
      setTotalPages(response.data.last_page);
      setTotalJobs(response.data.total);
      setNotFound(response.data.data.length === 0);
    } catch (e) {
      setNotFound(true);
      setJobs([]);
      setTotalJobs(0);
      toast.error(e.response.data.message);
    }
    window.scrollTo(0, 0);
  }

  async function getFilterQuery() {
    let filters = {};
    // simple fields
    searchParams.forEach((value, key) => {
      if (!key.includes("[]")) {
        let parsedValue = Number.isInteger(Number(value))
          ? Number(value)
          : value;
        filters[key] = parsedValue;
      }
    });

    if (filters?.is_tech) {
      filters.is_tech = filters?.is_tech === "true" ? true : false;
    }

    if (filters?.must_job_register) {
      filters.must_job_register =
        filters?.must_job_register === "true" ? true : false;
    }

    // array fields
    let fromToKeys = {
      "occupation_label[]": "occupation_labels",
      "segment_id[]": "segment_id",
      "benefits[]": "benefits",
      "cnh[]": "requirements.cnh",
      "gender[]": "requirements.gender",
      "language[]": "requirements.language",
      "others[]": "requirements.others",
      "pcd[]": "requirements.pcd",
      "region[]": "requirements.region",
    };

    searchParams.forEach((value, key) => {
      if (key.includes("[]")) {
        if (key !== "occupations[]") {
          let parsedKey = fromToKeys[key];
          let parsedValue = Number.isInteger(Number(value))
            ? Number(value)
            : value;
          if (parsedKey in filters) {
            filters[parsedKey].push(parsedValue);
          } else {
            filters[parsedKey] = [parsedValue];
          }
        } else {
          if ("occupations" in filters) {
            filters["occupations"].push({
              value: Number(value.split(",")[0]),
              label: value.split(",")[1],
            });
          } else {
            filters["occupations"] = [
              {
                value: Number(value.split(",")[0]),
                label: value.split(",")[1],
              },
            ];
          }
        }
      }
    });
    reset(filters);
  }

  async function setFilterQuery(filters) {
    filters.occupation_ids = filters.occupations?.map((occ) => occ.value);
    let simpleFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([_, v]) => v != null && v != "" && typeof v != "object"
      )
    );
    let simpleQuery = Object.keys(simpleFilters).map(
      (key) => key + "=" + simpleFilters[key]
    );

    let arrayQuery = [];
    if (filters.occupations?.length > 0) {
      filters.occupations?.map((occ) => {
        arrayQuery.push(`occupations[]=${occ.value},${occ.label}`);
      });
    }

    if (filters.segment_id?.length > 0) {
      filters.segment_id.map((seg) => {
        arrayQuery.push(`segment_id[]=${seg}`);
      });
    }

    if (filters.benefits?.length > 0) {
      filters.benefits.map((b) => {
        arrayQuery.push(`benefits[]=${encodeURI(b)}`);
      });
    }

    if (filters.requirements?.cnh?.length > 0) {
      filters.requirements?.cnh?.map((f) => {
        arrayQuery.push(`cnh[]=${encodeURI(f)}`);
      });
    }

    if (filters.requirements?.gender?.length > 0) {
      filters.requirements?.gender?.map((f) => {
        arrayQuery.push(`gender[]=${encodeURI(f)}`);
      });
    }

    if (filters.requirements?.language?.length > 0) {
      filters.requirements?.language?.map((f) => {
        arrayQuery.push(`language[]=${encodeURI(f)}`);
      });
    }

    if (filters.requirements?.others?.length > 0) {
      filters.requirements?.others?.map((f) => {
        arrayQuery.push(`others[]=${encodeURI(f)}`);
      });
    }

    if (filters.requirements?.pcd?.length > 0) {
      filters.requirements?.pcd?.map((f) => {
        arrayQuery.push(`pcd[]=${encodeURI(f)}`);
      });
    }

    if (filters.requirements?.region?.length > 0) {
      filters.requirements?.region?.map((f) => {
        arrayQuery.push(`region[]=${encodeURI(f)}`);
      });
    }
    let queryString = simpleQuery.concat(arrayQuery).join("&");
    window.history.replaceState(null, null, `/#/vagas/?${queryString}`);
  }

  async function handleFilterJobs(data) {
    await setFilterQuery(data);
    getJobs(1, data);
  }

  async function handleCleanFilter() {
    reset();
    reset({ occupations: null, cnpj: "" });
    getJobs(1, null);
  }

  return (
    <JobsContainer>
      <PageHeader
        title={`Vagas cadastradas`}
        actions={
          currentRole !== "consultant"
            ? [<Link to={`/vagas/editar`}>Cadastrar nova vaga</Link>]
            : null
        }
      />
      <div className="container">
        <div className="row">
          {attendanceMode ? <AttendanceHeader /> : null}
          <div className="col-md-3 col-sm-12">
            <h4>BUSCA POR VAGAS</h4>
            <form onSubmit={handleSubmit(handleFilterJobs)}>
              <React.Fragment>
                <Input
                  register={register}
                  disabled={!!idSineFilterWatcher}
                  name="id"
                  label="ID da vaga"
                />
                <Input
                  register={register}
                  disabled={!!idFilterWatcher}
                  name="sine_id"
                  label="ID SINE"
                />
                <Switch
                  name="is_tech"
                  label="Vaga para T.I?"
                  register={register}
                  disabled={!!idFilterWatcher || !!idSineFilterWatcher}
                />
                <Input
                  register={register}
                  name="company_name"
                  label="Nome da empresa"
                  disabled={
                    !!idFilterWatcher ||
                    !!idSineFilterWatcher ||
                    !!cnpjFilterWatcher
                  }
                />
                <WithMask
                  control={control}
                  name="cnpj"
                  mask="99.999.999/9999-99"
                  label="CNPJ da empresa"
                  disabled={!!idFilterWatcher || !!idSineFilterWatcher}
                />
                <Input
                  register={register}
                  name="responsable_name"
                  label="Responsável pela empresa"
                  disabled={!!idFilterWatcher || !!idSineFilterWatcher}
                />
                <ReactSelect
                  name="status"
                  control={control}
                  options={[
                    { label: "Rascunhos (Somente seus)", value: "draft" },
                    { label: "Vagas Ativas", value: "active" },
                    { label: "Vagas Agendadas", value: "schedule" },
                  ]}
                  placeholder="Selecione o status"
                  label="Status:"
                  disabled={!!idFilterWatcher || !!idSineFilterWatcher}
                />
                <Input
                  register={register}
                  name="created_at_start"
                  label="Período da criação da vaga"
                  type="date"
                  disabled={!!idFilterWatcher || !!idSineFilterWatcher}
                />
                <Input
                  register={register}
                  type="date"
                  name="created_at_end"
                  label="Fim:"
                  disabled={!!idFilterWatcher || !!idSineFilterWatcher}
                />
              </React.Fragment>
              <OccupationSelect
                isMulti
                control={control}
                withCodePrefix
                name="occupations"
                label="Ocupação"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="segment_id"
                control={control}
                isMulti
                placeholder="Área de atuação/Segmento"
                options={segments.map((occ) => {
                  return { label: occ.name, value: occ.id };
                })}
                label="Área de atuação/Segmento:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="schooling_level_id"
                control={control}
                options={schoolingLevels.map((sl) => {
                  return { label: sl.name, value: sl.id };
                })}
                placeholder="Selecione a escolaridade mínima"
                label="Escolaridade:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="experience_level_id"
                control={control}
                options={experienceLevels.map((el) => {
                  return { label: el.name, value: el.id };
                })}
                placeholder="Selecione a experiência mínima"
                label="Experiência:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <Switch
                name="must_job_register"
                label="Registro em Carteira"
                register={register}
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="benefits"
                placeholder="Selecione os benefícios oferecidos"
                isMulti
                control={control}
                options={optionsBenefits}
                label="Benefício:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="contract_type"
                placeholder="Selecione o tipo de contrato"
                control={control}
                options={optionsContractType}
                label="Tipo de contrato:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="job_type"
                placeholder="Selecione o tipo de trabalho"
                control={control}
                options={[
                  {
                    label: "Trabalho Presencial",
                    value: "presential",
                  },
                  {
                    label: "Trabalho Remoto",
                    value: "remote",
                  },
                  {
                    label: "Híbrido",
                    value: "hybrid",
                  },
                ]}
                label="Tipo de trabalho:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="work_regions"
                isMulti
                placeholder="Região do trabalho"
                control={control}
                options={optionsRegion}
                label="Região do trabalho:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.gender"
                isMulti
                placeholder="Gênero"
                control={control}
                options={optionsGender}
                label="Gênero:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.region"
                isMulti
                placeholder="Região (Requisito da vaga)"
                control={control}
                options={optionsRegion}
                label="Região (Requisito da vaga):"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.cnh"
                isMulti
                placeholder="Possui CNH"
                control={control}
                options={optionsCNH}
                label="Possui CNH:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.language"
                isMulti
                placeholder="Idioma"
                control={control}
                options={optionsLanguage}
                label="Idioma:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.pcd"
                isMulti
                placeholder="Pessoa com deficiência"
                control={control}
                options={optionsPCD}
                label="Pessoa com deficiência:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <ReactSelect
                name="requirements.others"
                isMulti
                placeholder="Candidatos preferenciais"
                control={control}
                options={optionsOtherRequirements}
                label="Candidatos preferenciais:"
                disabled={!!idFilterWatcher || !!idSineFilterWatcher}
              />
              <div className="form-filters-actions">
                <button className="btn btn-primary" type="submit">
                  Aplicar filtros
                </button>
                <button
                  onClick={handleCleanFilter}
                  className="btn btn-secondary"
                  type="button"
                  role="button"
                >
                  Limpar
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-9 col-sm-12">
            <div className="title-jobs-count">
              <span>
                {notFound
                  ? `Nenhuma vaga encontrada para os filtros selecionados.`
                  : `${totalJobs} vagas encontradas`}
              </span>
            </div>
            {jobs.map((job) => (
              <JobListItem
                attendanceMode={attendanceMode}
                key={uuidv4()}
                {...job}
              />
            ))}
            <Pagination
              page={page}
              total={totalPages}
              onChange={(page) => getJobs(page, activeFilters)}
            />
          </div>
        </div>
      </div>
    </JobsContainer>
  );
}
