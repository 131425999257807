import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const ContainerStyled = styled.div`
  .main-content {
    height: 100px;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: black;
    padding: 20px 8%;

    h1 {
      font-weight: 800;
      font-family: "Raleway", sans-serif;
      color: white;
      text-transform: uppercase;
    }

    .page-header-actions {
      display: flex;
      gap: 20px;
    }
  }

  .secondary-content {
    border-bottom: 1px solid #838383;
    padding: 1rem;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;

    .actions {
      display: flex;
      gap: 1rem;
    }

    a {
      color: black;
      text-decoration: none;
      font-family: "Raleway", sans-serif;
      font-weight: bold;
      color: white;
      background: #ff6600;
      padding: 5px 10px;
      border-radius: 4px;
      text-transform: uppercase;
      padding: 0.7rem 1rem;
      font-size: 0.8rem;
    }
  }
`;

function PageHeader({ title, actions = [] }) {
  return (
    <ContainerStyled>
      <div className="main-content">
        <h1>{title}</h1>
      </div>
      <div className="secondary-content">
        <div className="actions">
          {actions
            ? actions.map((action) => (
                <div className="action" key={uuidv4()}>
                  {action}
                </div>
              ))
            : null}
        </div>
      </div>
    </ContainerStyled>
  );
}

export default PageHeader;
