import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { Collapse } from "react-collapse";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { ReportItemStyled } from "./../styles";
import api from "./../../../config/api";

import Input from "./../../../components/FormHook/Input";
import WithMask from "./../../../components/FormHook/WithMask";
import ReactSelect from "./../../../components/FormHook/ReactSelect";
import DatePicker from "./../../../components/FormHook/DatePick";
import Switch from "./../../../components/FormHook/Switch";
import OccupationSelect from "./../../../components/FormHook/OccupationSelect";

import Divider from "./../../../components/Divider";
import FileDownload from "js-file-download";

import {
  optionsContractType,
  optionsBenefits,
  optionsOtherRequirements,
  optionsPCD,
  optionsGender,
  optionsRegion,
  optionsCNH,
  optionsLanguage,
  optionsOwnVehicle,
  optionsEthnicGroup,
} from "./../../Jobs/database";

function JobsReport() {
  const [isCollapsed, setIsCollapsed] = useState(true); //default collapsed
  const [finalDate, setFinalDate] = useState(null);
  const [segments, setSegments] = useState([]);
  const [schoolingLevels, setSchoolingLevels] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState,
  } = useForm();

  useEffect(async () => {
    let segmentResponse = await api.get(`segments`);
    setSegments(segmentResponse.data);
    let schoolingResponse = await api.get(`schooling-levels`);
    setSchoolingLevels(schoolingResponse.data);
  }, []);

  const startAtWatch = watch("start_at");

  useEffect(() => {
    if (!startAtWatch) {
      return false;
    }
    let start = new Date(startAtWatch);
    let target = new Date(startAtWatch);
    let max = target.setDate(start.getDate() + 45);
    let today = new Date();
    setFinalDate(max > today ? today : max);
    setValue("end_at", null);
  }, [startAtWatch]);

  async function onSubmit(data) {
    let now = format(new Date(), "yyyyMMddHmm");
    data = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != null && v != "")
    );
    if (!data.period_type) {
      toast.warning("Selecione um tipo de periodo.");
      return false;
    }
    if (!data.start_at || !data.end_at) {
      toast.warning("Selecione um periodo.");
      return false;
    }
    data.start_at = format(new Date(data.start_at), "yyyy-MM-dd");
    data.end_at = format(new Date(data.end_at), "yyyy-MM-dd");
    if (!formState.dirtyFields.is_cate_managed) {
      delete data.is_cate_managed;
    }

    api({
      url: "reports/jobs",
      method: "GET",
      responseType: "blob",
      params: data, // Important
    }).then((response) => {
      FileDownload(response.data, `relatorio_vagas_${now}.xlsx`);
    });
  }

  function clear() {
    reset();
    reset({ occupations: null, cnpj: "" });
  }

  return (
    <ReportItemStyled>
      <div className="card" key={uuidv4()}>
        <div className="card-body">
          <div className="header-job">
            <h4 className="card-title">Vagas de emprego</h4>
            <span
              className="collapse-action"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              )}
              {isCollapsed ? "Abrir" : "Fechar"}
            </span>
          </div>
          <Divider />
          <Collapse isOpened={!isCollapsed}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="period_type"
                    label="Periodo por:"
                    control={control}
                    options={[
                      { label: "Data de publicação", value: "active_at" },
                      { label: "Data de encerramento", value: "closed_at" },
                      { label: "Data de criação", value: "created_at" },
                      {
                        label: "Data da ultima atualização",
                        value: "updated_at",
                      },
                    ]}
                  />
                </div>

                <div className="col">
                  <DatePicker
                    name="start_at"
                    control={control}
                    label="Data de inicio:"
                  />
                </div>
                <div className="col">
                  <DatePicker
                    name="end_at"
                    control={control}
                    label="Data de fim:"
                    max={finalDate ? new Date(finalDate) : null}
                    min={startAtWatch}
                    disabled={!startAtWatch}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="status"
                    label="Status:"
                    control={control}
                    options={[
                      { label: "Vagas Ativas", value: "active" },
                      { label: "Vagas Encerradas", value: "closed" },
                      { label: "Agendadas", value: "schedule" },
                      { label: "Rascunho", value: "draft" },
                      { label: "Vagas Suspensas", value: "stopped" },
                    ]}
                  />
                </div>
                <div className="col">
                  <Input register={register} name="id" label="ID da vaga" />
                </div>
                <div className="col">
                  <Input register={register} name="sine_id" label="ID SINE" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Input
                    register={register}
                    name="company_name"
                    label="Nome da empresa"
                  />
                </div>
                <div className="col">
                  <WithMask
                    control={control}
                    name="cnpj"
                    mask="99.999.999/9999-99"
                    label="CNPJ da empresa"
                  />
                </div>
                <div className="col">
                  <Switch
                    name="is_cate_managed"
                    label="Vaga gerenciada pelo Cate"
                    register={register}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <OccupationSelect
                    isMulti
                    control={control}
                    name="occupations"
                    label="Ocupações"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="segment_id"
                    control={control}
                    isMulti
                    placeholder="Área de atuação/Segmento"
                    options={segments.map((occ) => {
                      return { label: occ.name, value: occ.id };
                    })}
                    label="Área de atuação/Segmento:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="benefits"
                    placeholder="Selecione os Benefícios oferecidos"
                    isMulti
                    control={control}
                    options={optionsBenefits}
                    label="Oferece benefícios:"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="contract_type"
                    placeholder="Selecione o tipo de contrato"
                    control={control}
                    options={optionsContractType}
                    label="Tipo de contrato:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="job_type"
                    placeholder="Selecione o tipo de trabalho"
                    control={control}
                    options={[
                      {
                        label: "Trabalho Presencial",
                        value: "presential",
                      },
                      {
                        label: "Trabalho Remoto",
                        value: "remote",
                      },
                      {
                        label: "Híbrido",
                        value: "hybrid",
                      },
                    ]}
                    label="Tipo de trabalho:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="schooling_level_id"
                    control={control}
                    options={schoolingLevels.map((sl) => {
                      return { label: sl.name, value: sl.id };
                    })}
                    placeholder="Selecione a escolaridade mínima"
                    label="Escolaridade:"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="gender"
                    isMulti
                    placeholder="Gênero"
                    control={control}
                    options={optionsGender}
                    label="Gênero:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="region"
                    isMulti
                    placeholder="Região"
                    control={control}
                    options={optionsRegion}
                    label="Região:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="cnh"
                    isMulti
                    placeholder="Possui CNH"
                    control={control}
                    options={optionsCNH}
                    label="Possui CNH:"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="language"
                    isMulti
                    placeholder="Fala outro idioma"
                    control={control}
                    options={optionsLanguage}
                    label="Fala outro idioma:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="others"
                    isMulti
                    placeholder="Outros requisitos"
                    control={control}
                    options={optionsOtherRequirements}
                    label="Outros requisitos:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="pcd"
                    isMulti
                    placeholder="Pessoa com deficiência"
                    control={control}
                    options={optionsPCD}
                    label="Pessoa com deficiência:"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ReactSelect
                    name="own_vehicle"
                    isMulti
                    placeholder="Veiculo próprio"
                    control={control}
                    options={optionsOwnVehicle}
                    label="Veiculo próprio:"
                  />
                </div>
                <div className="col">
                  <ReactSelect
                    name="ethnic_group"
                    isMulti
                    placeholder="Raça"
                    control={control}
                    options={optionsEthnicGroup}
                    label="Raça:"
                  />
                </div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <Divider />
              <div className="report-actions">
                <button className="btn btn-primary" type="submit">
                  Exportar
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={clear}
                  type="button"
                >
                  Limpar
                </button>
              </div>
            </form>
          </Collapse>
        </div>
      </div>
    </ReportItemStyled>
  );
}

export default JobsReport;
