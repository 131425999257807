import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AttendanceContext from "./../../../../contexts/attendance";
import FileDownload from "js-file-download";
import slugify from "slugify";
import api from "./../../../../config/api";
// import { Container } from './styles';

const AttendanceHeaderStyled = styled.div`
  display: flex;
  padding: 1rem 2rem;
  background: #f0f0f0;
  border-radius: 10px;
  font-family: "Raleway", sans-serif;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0px;

  .infos {
    display: flex;
    flex-direction: column;

    .header {
      text-transform: uppercase;
    }

    .info {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }

  .actions {
    display: flex;
    gap: 1rem;
  }
`;

function AttendanceHeader({ alternativeButton }) {
  const { candidate, finishAttendance } = useContext(AttendanceContext);
  if (!candidate) {
    window.location.href = process.env.PUBLIC_URL;
  }
  async function handleDownloadResumePdf() {
    api({
      url: `attendance/candidates/resume/pdf?uuid=${candidate.uuid}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      FileDownload(
        response.data,
        `currículo_${slugify(candidate.full_name, {
          replacement: "_", // replace spaces with replacement character, defaults to `-`
          lower: true, // convert to lower case, defaults to `false`
          trim: true, // trim leading and trailing replacement chars, defaults to `true`
        })}.pdf`
      );
    });
  }

  return (
    <AttendanceHeaderStyled>
      <div className="infos">
        <span className="header">Você está atendendo:</span>
        <span className="info">{candidate.full_name}</span>
      </div>
      <div className="actions">
        {candidate.resume_updated_at ? (
          <button
            className="btn btn-alternative"
            onClick={handleDownloadResumePdf}
          >
            Baixar Curriculo
          </button>
        ) : null}
        {alternativeButton ? (
          <Link
            to={`/atendimento/candidato/curriculo`}
            className="btn btn-primary"
          >
            {candidate.resume_updated_at
              ? `Atualizar Currículo`
              : `Cadastrar Currículo`}
          </Link>
        ) : (
          <Link
            to={`/atendimento/candidato/painel`}
            className="btn btn-primary"
          >
            Painel do candidato
          </Link>
        )}
        <Link
          to={`/atendimento/registrar/${candidate.uuid}`}
          className="btn btn-alternative"
        >
          Registro dos atendimentos realizados
        </Link>

        <button
          className="btn btn-secondary"
          onClick={() => finishAttendance(candidate.uuid)}
        >
          Encerrar atendimento
        </button>
      </div>
    </AttendanceHeaderStyled>
  );
}

export default AttendanceHeader;
