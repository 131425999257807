import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { WP_PLUGIN_AUTH_PAGE } from "./../../../config/consts";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import CandidateContext from "../../../contexts/candidate";
import PageHeader from "../../../components/PageHeader";
import CandidateInfos from "../components/CandidateInfos";

const CandidateHomeStyled = styled.div`
  .info-card {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    width: 80%;
    margin: 0 auto;

    h2 {
      font-weight: 900;
      font-size: 1.7rem;
    }

    p {
      width: 80%;
      text-align: center;
      font-weight: 300;
      font-size: 1.2rem;
    }

    small {
      font-weight: 200;
      font-style: italic;
      margin-bottom: 20px;
    }
  }
`;

export default function Auth() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <CandidateHomeStyled>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="info-cards-container">
              <div className="info-card">
                <h2 className="secondary">SESSÃO EXPIRADA</h2>
                <p>
                  Infelizmente, por motivos de segurança, sua sessão foi
                  encerrada. Clique no botão abaixo para iniciar uma nova
                  sessão.
                </p>
                <a href={WP_PLUGIN_AUTH_PAGE} className="btn btn-primary">
                  Voltar ao Portal Cate
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CandidateHomeStyled>
  );
}
