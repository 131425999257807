import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "@hookform/error-message";

const StyledInput = styled.div``;

function Input({
  label,
  name,
  type,
  register,
  placeholder,
  onBlur = null,
  disabled = false,
  min,
  max,
  validations = {},
  errors,
  required = false,
  requiredForJob = false,
  ignoreNumberReturn = false,
  disableWrite = false,
}) {
  const id = uuidv4();
  const conditionalAttributes = {};
  return (
    <StyledInput>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      {required ? <span className="required">*</span> : null}
      {requiredForJob ? (
        <span className="required required-secondary">*</span>
      ) : null}
      <input
        autoComplete={
          navigator.userAgentData?.brands?.some(
            (b) => b.brand === "Google Chrome"
          )
            ? "off"
            : id
        }
        type={type}
        name={name}
        disabled={disabled}
        min={min}
        max={max}
        onKeyDown={(e) => (disableWrite ? e.preventDefault() : null)}
        placeholder={placeholder}
        className="form-control"
        {...conditionalAttributes}
        id={id}
        {...register(name, {
          valueAsNumber: type === "number" && !ignoreNumberReturn,
          ...validations,
          onBlur: onBlur,
        })}
      />
      {errors ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <span className="input-error-label">{message}</span>
          )}
        />
      ) : null}
    </StyledInput>
  );
}

export default Input;
