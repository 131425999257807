import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import slugify from "slugify";
import PageHeader from "./../../../../components/PageHeader";
import CandidateInfos from "../CandidateInfos";
import SuggestedJobsList from "./../SeggestedJobsList";
import CandidateApplications from "./../CandidateApplications";
import { format } from "date-fns";
import FileDownload from "js-file-download";
import api from "../../../../config/api";

const CandidateHomeStyled = styled.div`
  .info-card {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    position: relative;

    .icon-card-curriculo {
      position: absolute;
      left: 6%;
      bottom: 10%;
      width: 100px;
    }

    h2 {
      font-weight: 900;
      font-size: 1.7rem;
    }

    p {
      width: 80%;
      text-align: center;
      font-weight: 300;
      font-size: 1.2rem;
    }

    small {
      font-weight: 200;
      font-style: italic;
      margin-bottom: 20px;
    }
  }

  .found-jobs-card {
    background: #0d3691;
    margin-top: 30px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1rem;



    .btn-primary {
      background-color: white;
      color: #0d3691;
    }

    h2 {
      color: white;
      font-size: 1.6rem;
    }
  }

  @media (max-width: 768px) {
    .info-card {
      width: 100% !important;
      margin-top: 1rem !important;
    }

    .icon-card-curriculo {
      display: none;
    }
  }
`;

export default function CandidatePanel({ candidate, attendanceMode = false }) {
  async function handleDownloadResumePdf() {
    api({
      url: attendanceMode
        ? `attendance/candidates/resume/pdf?uuid=${candidate.uuid}`
        : "candidates/resume/pdf",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      FileDownload(
        response.data,
        `currículo_${slugify(candidate.full_name, {
          replacement: "_", // replace spaces with replacement character, defaults to `-`
          lower: true, // convert to lower case, defaults to `false`
          trim: true, // trim leading and trailing replacement chars, defaults to `true`
        })}.pdf`
      );
    });
  }
  return candidate ? (
    <CandidateHomeStyled>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <CandidateInfos
            candidate={candidate}
            attendanceMode={attendanceMode}
          />
          <div className="found-jobs-card">
            <h2 className="light">Encontrar vagas</h2>
            <p>Encontre vagas de emprego.</p>
            <Link
              to={
                attendanceMode
                  ? `/atendimento/candidato/vagas`
                  : `/candidato/vagas`
              }
              className="btn btn-primary"
            >
              Encontrar vagas
            </Link>
          </div>
        </div>
        <div className="col-md-8 col-sm-12">
          {!attendanceMode ? (
            <div className="info-cards-container">
              <div className="info-card">
                <img
                  className="icon-card-curriculo"
                  alt=""
                  src={process.env.PUBLIC_URL + "/assets/icons/curriculo.svg"}
                />
                <h2 className="secondary">MANTENHA SEU CURRÍCULO ATUALIZADO</h2>
                <p>
                  Cadastre seu currículo e mantenha-o atualizado para se
                  candidatar as vagas de emprego
                </p>
                <small>
                  Data da última atualização:{" "}
                  {candidate.resume_updated_at
                    ? format(
                        new Date(candidate.resume_updated_at),
                        "dd/MM/yyyy"
                      )
                    : "Nunca atualizado"}
                </small>
                <Link to={`/candidato/curriculo`} className="btn btn-primary">
                  Atualizar currículo
                </Link>
                {candidate.resume_updated_at ? (
                  <button
                    onClick={handleDownloadResumePdf}
                    className="mt-2 btn btn-alternative"
                  >
                    Baixar currículo
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
          <CandidateApplications
            uuid={attendanceMode ? candidate.uuid : null}
          />
        </div>
      </div>
      <div className="row">
        {candidate.resume_updated_at ? (
          <SuggestedJobsList
            attendanceMode={attendanceMode}
            uuid={attendanceMode ? candidate.uuid : null}
          />
        ) : null}
      </div>
    </CandidateHomeStyled>
  ) : null;
}
