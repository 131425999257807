import styled from "styled-components";

export const ReportItemStyled = styled.div`
  .card {
    -webkit-box-shadow: 0px 10px 13px -7px #ccc,
      5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #ccc, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    margin-bottom: 20px;
    border-radius: 0;
    border-color: #dddbdb;
    color: #737373;
  }

  .card-title {
    text-transform: uppercase;
  }

  .report-actions {
    display: flex;
    justify-content: end;
    gap: 20px;
  }

  .header-job {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .collapse-action {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
`;
