import styled from "styled-components";

export const SigninStyles = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  height: 100vh;
  background: #105ab5;

  h2 {
    margin-top: 30px;
    color: black;
    font-size: 1.6rem;
  }

  .forgot-password {
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .reset-pass-reply {
    color: white;
    font-weight: bold;
    text-align: center;
    width: 100%;

    a {
      color: white;
    }
  }

  form {
    width: 100%;
    max-width: 380px;
    padding: 4rem 2rem;
    margin: auto;
    background: white;

    button {
      width: 100%;
    }
  }

  .form-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-top: 2rem;
  }

  .form-switch .form-check-input {
    width: 3em;
    height: 1.5rem;
    margin-left: 0;
    margin-right: 15px;
    margin-top: 0;
  }

  .form-switch label {
    margin-top: 0.5rem;
  }
`;
