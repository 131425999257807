import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { Collapse } from "react-collapse";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { toast } from "react-toastify";

import api from "./../../../config/api";

import Input from "./../../../components/FormHook/Input";
import WithMask from "./../../../components/FormHook/WithMask";
import DatePicker from "./../../../components/FormHook/DatePick";
import OccupationSelect from "./../../../components/FormHook/OccupationSelect";

import { optionsRegion } from "./../../Jobs/database";
import Divider from "./../../../components/Divider";
import FileDownload from "js-file-download";

const ReportStyled = styled.div`
  .card {
    -webkit-box-shadow: 0px 10px 13px -7px #ccc,
      5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #ccc, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    margin-bottom: 20px;
    border-radius: 0;
    border-color: #dddbdb;
    color: #737373;
  }

  .card-title {
    text-transform: uppercase;
  }

  .report-actions {
    display: flex;
    justify-content: end;
    gap: 20px;
  }

  .header-job {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .collapse-action {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
`;

function ClosedJobCandidatesReport() {
  const [isCollapsed, setIsCollapsed] = useState(true); //default collapsed
  const [finalDate, setFinalDate] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const startAtWatch = watch("start_at");

  useEffect(() => {
    if (!startAtWatch) {
      return false;
    }
    let start = new Date(startAtWatch);
    let target = new Date(startAtWatch);
    let max = target.setDate(start.getDate() + 45);
    let today = new Date();
    setFinalDate(max > today ? today : max);
    setValue("end_at", null);
  }, [startAtWatch]);

  async function onSubmit(data) {
    let now = format(new Date(), "yyyyMMddHmm");
    if (!data.start_at || !data.end_at) {
      toast.warning("Selecione um periodo.");
      return false;
    }
    data.start_at = format(new Date(data.start_at), "yyyy-MM-dd");
    data.end_at = format(new Date(data.end_at), "yyyy-MM-dd");
    data.occupation_ids = data.occupations?.map((occ) => occ.value) ?? null;
    delete data.occupations;
    api({
      url: "reports/closed-jobs-candidates",
      method: "GET",
      responseType: "blob",
      params: data, // Important
    }).then((response) => {
      FileDownload(response.data, `relatorio_vagas_preenchidas_por_indicacao_do_cate_${now}.xlsx`);
    });
  }

  function clear() {
    reset();
    reset({ occupations: null, cnpj: "" });
  }

  return (
    <ReportStyled>
      <div className="card" key={uuidv4()}>
        <div className="card-body">
          <div className="header-job">
            <h4 className="card-title">
              Relatório de Vagas preenchidas por indicacao do Cate
            </h4>
            <span
              className="collapse-action"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              )}
              {isCollapsed ? "Abrir" : "Fechar"}
            </span>
          </div>
          <Divider />
          <Collapse isOpened={!isCollapsed}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <DatePicker
                    name="start_at"
                    control={control}
                    label="Data de inicio:"
                  />
                </div>
                <div className="col">
                  <DatePicker
                    name="end_at"
                    control={control}
                    label="Data de fim:"
                    max={finalDate ? new Date(finalDate) : null}
                    min={startAtWatch}
                    disabled={!startAtWatch}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Input
                    register={register}
                    name="job_id"
                    label="ID da vaga"
                  />
                </div>
                <div className="col">
                  <OccupationSelect
                    isMulti
                    control={control}
                    name="occupations"
                    label="Ocupações"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Input
                    register={register}
                    name="company_name"
                    label="Nome da empresa"
                  />
                </div>
                <div className="col">
                  <WithMask
                    control={control}
                    name="cpf"
                    mask="999.999.999-99"
                    label="CPF do candidato"
                  />
                </div>
              </div>
              <Divider />
              <div className="report-actions">
                <button className="btn btn-primary" type="submit">
                  Exportar
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={clear}
                  type="button"
                >
                  Limpar
                </button>
              </div>
            </form>
          </Collapse>
        </div>
      </div>
    </ReportStyled>
  );
}

export default ClosedJobCandidatesReport;
