import React, { useContext } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AuthContext from "./../../../../contexts/auth";
import { Tooltip } from "react-tooltip";
import Divider from "./../../../../components/Divider";
import { WP_URL, WP_PLUGIN_AUTH_PAGE } from "./../../../../config/consts";

import {
  TranslateJobJouney,
  TranslateJobType,
  TranslateJobSalaryType,
  TranslateJobRequirementKey,
  TranslateJobRequirementOption,
  TranslateJobStatus,
} from "./../../../../helpers/translateFields";

import { techTerms } from "./../../../../helpers/dicts";
import { currencyFormatter } from "./../../../../helpers/formatter";
import { professionalRequirements } from "../../database";

const tooltipId = uuidv4();

const JobListItemStyled = styled.div`
  font-family: "Raleway", sans-serif;
  .card {
    box-shadow: 0px 10px 13px -7px #ccc, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    margin-bottom: 20px;
    border-radius: 0;
    border-color: #dddbdb;
    color: #737373;
  }

  .card-title {
    text-transform: uppercase;
  }

  .footer-card-job {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    align-items: center;
  }

  .actions-container {
    display: flex;
    gap: 1rem;
    justify-content: end;
    align-items: end;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
  }

  .attribute-lists-container,
  .requirements-container {
    display: flex;
    justify-content: space-between;
  }

  .copy-input-container {
    width: 100%;
  }

  .attribute-list {
    flex: 1;
    list-style: none;
    padding-left: 0;
  }

  .attribute-list-item {
    max-width: 60%;
  }

  .requirements-list {
    flex: 1;
    list-style: none;
    padding-left: 0;
  }

  .active-date-info {
    font-weight: bold;
  }
  .active-date-label {
  }

  .badge-container {
    margin: 5px 0 10px;
    display: flex;
    gap: 10px;
  }

  .footer-date-infos {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    .attribute-lists-container,
    .requirements-container {
      flex-direction: column;
    }

    .collapse-action {
      font-size: 0.8rem !important;
    }

    .card-title {
      font-size: 1.2rem;
    }
    .footer-card-job {
      flex-direction: column;
      gap: 1rem;
    }
    .actions-container a {
      width: 100%;
    }

    .actions-container {
      flex-direction: column;
      gap: 10px;
    }
  }
`;

const JobStatusBagde = {
  active: (
    <span className="badge bg-success">{TranslateJobStatus["active"]}</span>
  ),
  schedule: (
    <span className="badge bg-info">{TranslateJobStatus["schedule"]}</span>
  ),
  closed: (
    <span className="badge bg-danger">{TranslateJobStatus["closed"]}</span>
  ),
  stopped: (
    <span className="badge bg-secondary">{TranslateJobStatus["stopped"]}</span>
  ),
  draft: (
    <span className="badge bg-warning">{TranslateJobStatus["draft"]}</span>
  ),
};
// import { Container } from './styles';

function JobListItem({
  id,
  occupation,
  segment,
  company,
  occupation_area,
  salary,
  hidden_salary,
  salary_type,
  schooling_level,
  experience_level,
  journey,
  job_type,
  requirements,
  active_at,
  closed_at,
  status,
  attendanceMode,
  expire_at,
  is_tech,
  tech_keywords,
  already_applied = false,
  total_waiting_applications = null,
  registered_by,
  job_location_string,
  cate_responsable = null,
}) {
  const { isConsultant, isManager, user } = useContext(AuthContext);
  const simpleView = !(isConsultant || isManager);

  function renderSalary(type, salary) {
    type = type || "default";
    switch (type) {
      case "combine":
        return ` ${TranslateJobSalaryType[type]}`;
        break;
      case "comission":
        return ` ${salary}% de ${TranslateJobSalaryType[type]}`;
        break;
      default:
        return ` ${currencyFormatter.format(salary)}/${
          TranslateJobSalaryType[type]
        }`;
        break;
    }
  }

  function translateTechTerms(tk) {
    let sourceTerms = techTerms
      .filter((tech) => (tk?.includes(tech.value) ? tech.label : null))
      .map((term) => term.label);
    return sourceTerms.join(", ");
  }

  function handleCopyApplicationLink(id) {
    var copyText = document.getElementById(`job-application-link-input=${id}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    toast.success("Link copiado!");
  }

  return (
    <JobListItemStyled>
      <div className="card" key={uuidv4()}>
        <div className="card-body">
          <h5 className="card-title">
            {!simpleView
              ? `#ID: ${id} - ${occupation?.name}`
              : `${occupation?.name}`}
          </h5>
          {!simpleView && isManager ? (
            <h6 className="card-subtitle mb-2 text-muted">{company.name}</h6>
          ) : null}

          <div className="badge-container">
            {already_applied ? (
              <span className="badge bg-success">
                VOCÊ JÁ SE CANDIDATOU A ESSA VAGA
              </span>
            ) : null}
            {!simpleView ? (
              <React.Fragment>
                <span className="badge bg-warning">{`${total_waiting_applications} CANDIDATOS AGUARDANDO APROVACÃO`}</span>
                {JobStatusBagde[status]}
              </React.Fragment>
            ) : null}
            {is_tech ? (
              <span className="badge bg-info">Vaga de T.I</span>
            ) : null}
          </div>
          <Divider />
          <ul className="attribute-list">
            <li className="attribute-list-item">
              <b>Área de atuação/Segmento:</b> {segment?.name || "-"}
            </li>
            {is_tech ? (
              <li className="attribute-list-item">
                <b>Competências tecnológicas:</b>{" "}
                {translateTechTerms(tech_keywords)}
              </li>
            ) : null}
            {simpleView && hidden_salary ? null : (
              <li className="attribute-list-item">
                <b>Remuneração:</b>
                {renderSalary(salary_type, salary)}
              </li>
            )}
            <li className="attribute-list-item">
              <b>Escolaridade mínima:</b> {schooling_level?.name || "-"}
            </li>
            <li className="attribute-list-item">
              <b>Experiência mínima:</b> {experience_level?.name || "-"}
            </li>
            <li className="attribute-list-item">
              <b>Jornada de trabalho:</b>{" "}
              {journey ? TranslateJobJouney[journey] : "-"}
            </li>
            <li className="attribute-list-item">
              <b>Tipo de trabalho:</b>{" "}
              {job_type ? TranslateJobType[job_type] : "-"}
            </li>
            {!simpleView ? (
              <li className="attribute-list-item">
                <b>Local de trabalho:</b>
                {` ${job_location_string}`}
              </li>
            ) : null}
          </ul>
          <div className="row">
            <h3>Requisitos</h3>
            <div className="col-md-3 col-sm-12">
              <b>Profissionais Obrigatórios:</b>
              <div>
                <ul className="requirements-list">
                  {requirements.map((requirement) => {
                    if (
                      requirement.value === "required" &&
                      professionalRequirements.indexOf(requirement.key) !== -1
                    ) {
                      return (
                        <li key={uuidv4()}>
                          {requirement.key !== requirement.option
                            ? `${
                                TranslateJobRequirementKey[requirement.key]
                              }: ${
                                TranslateJobRequirementOption[
                                  requirement.option
                                ]
                              }`
                            : `${TranslateJobRequirementKey[requirement.key]}`}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <b>Profissionais Diferenciais:</b>
              <div>
                <ul className="requirements-list">
                  {requirements.map((requirement) => {
                    if (
                      requirement.value === "desired" &&
                      professionalRequirements.indexOf(requirement.key) !== -1
                    ) {
                      return (
                        <li key={uuidv4()}>
                          {requirement.key !== requirement.option
                            ? `${
                                TranslateJobRequirementKey[requirement.key]
                              }: ${
                                TranslateJobRequirementOption[
                                  requirement.option
                                ]
                              }`
                            : `${TranslateJobRequirementKey[requirement.key]}`}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <b>Pessoais Obrigatórios:</b>
              <div>
                <ul className="requirements-list">
                  {requirements.map((requirement) => {
                    if (
                      requirement.value === "required" &&
                      professionalRequirements.indexOf(requirement.key) === -1
                    ) {
                      return (
                        <li key={uuidv4()}>
                          {requirement.key !== requirement.option
                            ? `${
                                TranslateJobRequirementKey[requirement.key]
                              }: ${
                                TranslateJobRequirementOption[
                                  requirement.option
                                ]
                              }`
                            : `${TranslateJobRequirementKey[requirement.key]}`}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <b>Pessoais Diferenciais:</b>
              <div>
                <ul className="requirements-list">
                  {requirements.map((requirement) => {
                    if (
                      requirement.value === "desired" &&
                      professionalRequirements.indexOf(requirement.key) === -1
                    ) {
                      return (
                        <li key={uuidv4()}>
                          {requirement.key !== requirement.option
                            ? `${
                                TranslateJobRequirementKey[requirement.key]
                              }: ${
                                TranslateJobRequirementOption[
                                  requirement.option
                                ]
                              }`
                            : `${TranslateJobRequirementKey[requirement.key]}`}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
          <Divider />
          <div className="footer-card-job">
            <div className="footer-date-infos">
              <div>
                <span className="active-date-label">Vaga publicada em: </span>
                <span className="active-date-info">
                  {active_at
                    ? format(new Date(active_at), "dd/MM/yyyy HH:mm")
                    : "-"}
                </span>
              </div>
              {!simpleView ? (
                <React.Fragment>
                  {closed_at ? (
                    <div>
                      <span className="active-date-label">
                        Vaga encerrada em:{" "}
                      </span>
                      <span className="active-date-info">
                        {format(new Date(closed_at), "dd/MM/yyyy HH:mm")}
                      </span>
                    </div>
                  ) : null}
                  <div>
                    <span className="active-date-label">
                      Vaga expira em (Data da ultima agenda):{" "}
                    </span>
                    <span className="active-date-info danger">{expire_at}</span>
                  </div>
                  <div>
                    <span className="active-date-label">
                      Vaga publicada por:{" "}
                    </span>
                    {registered_by ? (
                      <>
                        <span
                          data-tooltip-id={tooltipId}
                          data-tooltip-content={registered_by?.email}
                          className="active-date-info cursor-help"
                        >
                          {registered_by?.name} ({registered_by?.cate})
                        </span>
                        <Tooltip id={tooltipId} />
                      </>
                    ) : null}
                  </div>
                  <div>
                    <span className="active-date-label">
                      Responsável Cate:{" "}
                    </span>
                    {cate_responsable ? (
                      <>
                        <span className="active-date-info cursor-help">
                          {cate_responsable}
                        </span>
                      </>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <Divider />
          {!simpleView ? (
            <div className="actions-container">
              {status === "active" ? (
                <div className="copy-input-container">
                  <label className="form-label">Link de candidatura:</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id={`job-application-link-input=${id}`}
                      readOnly
                      value={`${WP_PLUGIN_AUTH_PAGE}?redirect_to=application&target=${id}`}
                    />
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => handleCopyApplicationLink(id)}
                    >
                      Copiar
                    </button>
                  </div>
                </div>
              ) : null}
              {["active", "closed"].indexOf(status) !== -1 ? (
                <Link
                  to={
                    attendanceMode
                      ? `/atendimento/candidato/vagas/detalhes?id=${id}`
                      : `/vagas/detalhes?id=${id}`
                  }
                  className="btn btn-primary"
                >
                  <span class="visually-hidden">{occupation?.name}</span>
                  Ver detalhes
                </Link>
              ) : null}
              {isConsultant ? null : (
                <Link
                  to={`/vagas/editar?id=${id}`}
                  className="btn btn-alternative"
                >
                  Editar vaga
                </Link>
              )}
            </div>
          ) : (
            <div className="actions-container">
              {status === "active" ? (
                <Link
                  to={`/candidato/vagas/detalhes?id=${id}`}
                  className="btn btn-primary"
                >
                  <span class="visually-hidden">{occupation?.name}</span>
                  Ver detalhes
                </Link>
              ) : null}
              <a
                className="btn btn-alternative"
                href={`${WP_URL}/cursos/`}
                target="_blank"
              >
                Quer aumentar suas chances de emprego?
              </a>
            </div>
          )}
        </div>
      </div>
    </JobListItemStyled>
  );
}

export default JobListItem;
