import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalResume from "../../../../components/ModalResume";
import ModalApproveCandidateApplication from "../ModalApproveCandidateApplication";
import { confirm } from "./../../../../components/Confirmation";
import api from "../../../../config/api";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { Tooltip } from "react-tooltip";

const CandidateJobListItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddbdb;
  align-items: center;
  background: #fff;
  padding: 0.5rem 2rem;
  border-radius: 10px;

  .infos-container {
    display: flex;
    flex: 1;
    padding: 0 1rem;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    color: #444;
    font-size: 0.8rem;
    flex: 2;
    gap: 5px;
  }

  .match-icons-container {
    display: flex;
    gap: 12px;
    flex: 1;

    img {
      width: 30px;
    }
  }

  .tag-status {
    background: #605b5b;
    padding: 5px 10px;
    color: white;
    border-radius: 5px;
    font-size: 0.6rem;
  }

  .tag-info {
    background: #2196f3;
    padding: 5px 10px;
    color: white;
    border-radius: 5px;
    font-size: 0.6rem;
    border: 1px solid #2196f3;
    color: white;
    cursor: pointer;
  }

  .tag-info svg {
    margin-right: 5px;
  }

  .tag-info:hover {
    background: white;
    padding: 5px 10px;
    color: white;
    border-radius: 5px;
    font-size: 0.6rem;
    border: 2px solid #2196f3;
    color: #2196f3;
  }

  .mail-reply {
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    font-size: 0.6rem;
    display: flex;
    align-items: center;
  }

  .mail-reply:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
  }

  .mail-reply.reject {
    color: #a50605;
  }

  .mail-reply.reject:before {
    background: #a50605;
  }

  .mail-reply.wait {
    color: #db9526;
  }

  .mail-reply.wait:before {
    background: #db9526;
  }

  .mail-reply.approved {
    color: #078484;
  }

  .mail-reply.approved:before {
    background: #078484;
  }

  .actions-container {
    display: flex;
    gap: 10px;
    flex: 3;
    align-items: center;
    justify-content: end;
  }
`;

const MailConfirmationLabel = {
  WAIT: "Aguardando confirmação",
  REJECT: "Convite recusado",
  APPROVED: "Confirmado",
};

const StatusLabel = {
  NEW: "Aguardando análise",
  REJECTED: "Não foi aprovado",
  MASSIVE_REJECT: "Reprovado em massa",
  APPROVED: "Entrevista agendada",
  INVITED: "Convidado para a entrevista",
  INVITE_REJECTED: "Convite recusado",
};

const MatchIcons = {
  schooling: process.env.PUBLIC_URL + "/assets/icons/schooling.svg",
  experience: process.env.PUBLIC_URL + "/assets/icons/experience.svg",
  interests: process.env.PUBLIC_URL + "/assets/icons/interest.svg",
  tech: process.env.PUBLIC_URL + "/assets/icons/tech.svg",
};

const MatchTooltipContent = {
  schooling: "Escolaridade do candidato é compatível com a vaga",
  experience: "Experiência do candidato é compatível com a vaga",
  interests: "Interesse do candidato é compatível com a vaga",
  tech: "Profissional da área de ti compatível com a vaga",
};

const MatchTooltipId = {
  schooling: uuidv4(),
  experience: uuidv4(),
  interests: uuidv4(),
  tech: uuidv4(),
};

function CandidateJobListItem({
  match,
  candidate,
  schedule,
  jobId,
  job,
  status,
  invite_mail_confirmation,
  activeSearch,
  updateList,
}) {
  const [openResume, setOpenResume] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const tooltipId = uuidv4();
  async function handleReject() {
    if (
      await confirm(
        "Deseja realmente reprovar o candidato a essa vaga?",
        "Sim",
        "Não",
        { title: "Reprovar candidato?" }
      )
    ) {
      let response = await api.put(`jobs/${jobId}/applications`, {
        candidate_id: candidate.id,
        status: "REJECTED",
      });
      toast.success(`Candidato reprovado. O mesmo será notificado pelo email`);
      updateList();
    }
  }

  return (
    <div key={uuidv4()}>
      <ModalResume
        open={openResume}
        onClose={() => setOpenResume(false)}
        resume={candidate}
      />
      <ModalApproveCandidateApplication
        candidate={candidate}
        open={openApprove}
        jobId={jobId}
        job={job}
        updateList={updateList}
        onClose={() => {
          updateList();
          setOpenApprove(false);
        }}
      />
      <CandidateJobListItemStyled>
        <div className="match-icons-container">
          {match?.map((mt) => {
            return (
              <>
                <img
                  data-tooltip-id={MatchTooltipId[mt]}
                  data-tooltip-content={MatchTooltipContent[mt]}
                  key={uuidv4()}
                  src={MatchIcons[mt]}
                  alt=""
                />
                <Tooltip id={MatchTooltipId[mt]} />
              </>
            );
          })}
        </div>
        <div className="infos-container">{candidate.full_name}</div>
        <div className="infos-container">
          {invite_mail_confirmation ? (
            <span
              className={`mail-reply ${invite_mail_confirmation.toLowerCase()}`}
            >
              {MailConfirmationLabel[invite_mail_confirmation]}
            </span>
          ) : null}
        </div>
        {schedule ? (
          <div className="infos-container">
            <span
              data-tooltip-id={tooltipId}
              data-tooltip-content={`De: ${format(
                new Date(schedule.start_at),
                "dd/MM/yyyy"
              )} Até ${format(
                new Date(schedule.end_at),
                "dd/MM/yyyy"
              )} Das: ${format(
                new Date(schedule.start_at),
                "HH:mm"
              )} às ${format(new Date(schedule.end_at), "HH:mm")}`}
              className="tag-info"
            >
              <FontAwesomeIcon icon="fa-solid fa-circle-info" /> Horário da
              entrevista
            </span>
            <Tooltip id={tooltipId} />
          </div>
        ) : null}
        <div className="infos-container justify-content-end">
          {status ? (
            <span className="tag-status">{StatusLabel[status]}</span>
          ) : null}
        </div>
        <div className="actions-container">
          <button
            className="btn btn-secondary"
            onClick={() => setOpenResume(true)}
          >
            Ver currículo
          </button>
          {(activeSearch && job.status == "active") || status == "MASSIVE_REJECT"  ? (
            <button
              className="btn btn-primary"
              onClick={() => setOpenApprove(true)}
            >
              Convidar
            </button>
          ) : null}
          {status === "NEW" && job.status == "active"? (
            <React.Fragment>
              <button
                className="btn btn-primary"
                onClick={() => setOpenApprove(true)}
              >
                <FontAwesomeIcon icon="fa-solid fa-circle-check" />
              </button>
              <button onClick={handleReject} className="btn btn-alternative">
                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
              </button>
            </React.Fragment>
          ) : null}
        </div>
      </CandidateJobListItemStyled>
    </div>
  );
}

export default CandidateJobListItem;
