import React, { useContext } from "react";
import AuthContext from "../../contexts/auth";
import ManagerHome from "./manager";
import ConsultantHome from "./consultant";
import AcquirerHome from "./acquirer";

export default function Home() {
  const { role } = useContext(AuthContext);
  switch (role) {
    case "manager":
      return <ManagerHome />;
      break;
    case "consultant":
      return <ConsultantHome />;
      break;
    case "acquirer":
      return <AcquirerHome />;
      break;
  }
}
