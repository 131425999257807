import React, { createContext, useState, useEffect } from "react";
import api from "../config/api";
import { toast } from "react-toastify";
import { confirm } from "./../components/Confirmation";

const AttendanceContext = createContext({});

export const AttendanceProvider = ({ children }) => {
  const [candidate, setCandidate] = useState(null);

  async function updateCandidate(candidate) {
    setCandidate(candidate);
  }

  async function finishAttendance(uuid) {
    let didAttendence = JSON.parse(
      await localStorage.getItem(`@catevagas:atendimento:${uuid}`)
    );
    if (!didAttendence) {
      if (
        await confirm(
          "Deseja encerrar o atendimento?",
          "Sim",
          "Não",
          {
            title: "Nenhum atendimento registrado",
          }
        )
      ) {
        try {
          await api.post(`attendance`, {
            actions: [{ action: "PRESENTIAL_IMO_NO_FORWARDING" }],
            uuid,
          });
        } catch (e) {
          toast.warning("Atendimento não registrado!");
        }
        window.location.href = process.env.PUBLIC_URL;
      }
    } else {
      await localStorage.removeItem(`@catevagas:atendimento:${uuid}`);
      window.location.href = process.env.PUBLIC_URL;
    }
  }

  return (
    <AttendanceContext.Provider
      value={{
        candidate,
        updateCandidate,
        finishAttendance,
      }}
    >
      {children}
    </AttendanceContext.Provider>
  );
};

export default AttendanceContext;
