import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import { Container } from './styles';

function NotFound({ authenticated }) {
  const navigation = useNavigate();
  useEffect(() => {
    if (!authenticated) {
      navigation("/");
    }
  }, [authenticated]);
  return <div>Not found.</div>;
}

export default NotFound;
