import React, { useContext, useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from "../../../../contexts/auth";
import { Tooltip } from "react-tooltip";
import { WP_URL } from "./../../../../config/consts";

import {
  TranslateJobJouney,
  TranslateJobType,
  TranslateJobSalaryType,
  TranslateJobRequirementKey,
  TranslateJobRequirementOption,
  TranslateJobStatus,
} from "./../../../../helpers/translateFields";

import { professionalRequirements } from "./../../database";

import { techTerms } from "../../../../helpers/dicts";
import { currencyFormatter } from "../../../../helpers/formatter";

import Divider from "./../../../../components/Divider";

const JobListItemStyled = styled.div`
  font-family: "Raleway", sans-serif;

  hr {
    border-top: 1px dotted;
  }

  .card {
    -webkit-box-shadow: 0px 10px 13px -7px #ccc,
      5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #ccc, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    margin-bottom: 20px;
    border-radius: 0;
    border-color: #dddbdb;
    color: #737373;
  }

  .card-title {
    text-transform: uppercase;
  }

  .job-match-container {
    display: flex;
    gap: 10px;
    margin: 20px 0px;

    img {
      width: 30px;
    }
  }

  .footer-card-job {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
  }

  .header-job {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .collapse-action {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .footer-card-job-actions {
    display: flex;
    gap: 10px;
  }

  .attribute-lists-container,
  .requirements-container {
    display: flex;
    justify-content: space-between;
  }

  .attribute-list {
    flex: 1;
    list-style: none;
    padding-left: 0;
  }

  .requirements-list-container {
    flex: 1;
  }

  .requirements-list {
    list-style: none;
    padding-left: 0;
  }

  .active-date-info {
    font-weight: bold;
  }
  .active-date-label {
  }

  .badge-container {
    margin: 5px 0 10px;
    display: flex;
    gap: 10px;
  }

  .schedules-list {
    list-style: none;
    padding-left: 0;
    font-style: italic;
  }

  .footer-card-job-candidate {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-card-job-candidate-button {
      margin-top: 20px;
      display: flex;
      gap: 20px;
    }
  }

  @media only screen and (max-width: 600px) {
    .attribute-lists-container,
    .requirements-container {
      flex-direction: column;
    }

    .collapse-action {
      font-size: 0.8rem !important;
    }

    .card-title {
      font-size: 1.2rem;
    }
    .footer-card-job-candidate .footer-card-job-candidate-button {
      gap: 10px;
      flex-direction: column;
    }
  }
`;

const JobStatusBagde = {
  active: (
    <span className="badge bg-success">{TranslateJobStatus["active"]}</span>
  ),
  schedule: (
    <span className="badge bg-info">{TranslateJobStatus["schedule"]}</span>
  ),
  closed: (
    <span className="badge bg-danger">{TranslateJobStatus["closed"]}</span>
  ),
  stopped: (
    <span className="badge bg-secondary">{TranslateJobStatus["stopped"]}</span>
  ),
  draft: (
    <span className="badge bg-warning">{TranslateJobStatus["draft"]}</span>
  ),
};

const MatchIcons = {
  schooling: process.env.PUBLIC_URL + "/assets/icons/schooling.svg",
  experience: process.env.PUBLIC_URL + "/assets/icons/experience.svg",
  interests: process.env.PUBLIC_URL + "/assets/icons/interest.svg",
  tech: process.env.PUBLIC_URL + "/assets/icons/tech.svg",
};

const MatchTooltipContent = {
  schooling: "Escolaridade do candidato é compatível com a vaga",
  experience: "Experiência do candidato é compatível com a vaga",
  interests: "Interesse do candidato é compatível com a vaga",
  tech: "Profissional da área de ti compatível com a vaga",
};

const MatchTooltipId = {
  schooling: uuidv4(),
  experience: uuidv4(),
  interests: uuidv4(),
  tech: uuidv4(),
};

const tooltipId = uuidv4();

function JobListItemDetailed({
  id,
  occupation,
  segment,
  company,
  salary,
  hidden_salary,
  salary_type,
  schooling_level,
  experience_level,
  interview_obs,
  journey,
  job_type,
  obs,
  requirements,
  active_at,
  status,
  description,
  available_jobs,
  seats,
  is_cate_managed,
  job_scale,
  sine_id,
  benefits,
  responsable,
  schedules,
  is_tech,
  tech_keywords,
  adress,
  is_meet_tell,
  isCandidate = false,
  match = null,
  score = null,
  detailsMode = false,
  attendanceMode = false,
  managingJob = false,
  defaultCollapsed = true,
  job_location_string,
  registered_by = null,
  cate_responsable = null,
}) {
  const { user } = useContext(AuthContext);
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed); //default collapsed

  function renderSalary(type, salary) {
    type = type || "default";
    switch (type) {
      case "combine":
        return ` ${TranslateJobSalaryType[type]}`;
        break;
      case "comission":
        return ` ${salary}% de ${TranslateJobSalaryType[type]}`;
        break;
      default:
        return ` R$ ${currencyFormatter.format(salary)}/${
          TranslateJobSalaryType[type]
        }`;
        break;
    }
  }

  function translateTechTerms(tk) {
    let sourceTerms = techTerms
      .filter((tech) => (tk?.includes(tech.value) ? tech.label : null))
      .map((term) => term.label);
    return sourceTerms.join(", ");
  }

  return (
    <JobListItemStyled>
      <div className="card" key={uuidv4()}>
        <div className="card-body">
          <div className="header-job">
            <h4 className="card-title">
              {isCandidate
                ? `${occupation?.name}`
                : `#ID: ${id} - ${occupation?.name}`}
            </h4>
            <span
              className="collapse-action"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <FontAwesomeIcon icon="fa-solid fa-angle-down" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-angle-up" />
              )}
              {isCollapsed ? "Abrir" : "Fechar"}
            </span>
          </div>
          <Divider />
          {isCandidate && match ? (
            <div className="job-match-container">
              {match.map((mt) => (
                <>
                  <img
                    data-tooltip-id={MatchTooltipId[mt]}
                    data-tooltip-content={MatchTooltipContent[mt]}
                    key={uuidv4()}
                    src={MatchIcons[mt]}
                    alt=""
                  />
                  <Tooltip id={MatchTooltipId[mt]} />
                </>
              ))}
            </div>
          ) : null}
          {!isCandidate ? (
            <h6 className="card-subtitle mb-2 text-muted">{company?.name}</h6>
          ) : null}
          <div className="badge-container">
            {!isCandidate ? JobStatusBagde[status] : null}
            {is_tech ? (
              <span className="badge bg-info">Vaga de T.I</span>
            ) : null}
          </div>
          <ul className="attribute-list">
            <li className="attribute-list-item">
              <b>Área de atuação/Segmento:</b> {segment?.name || "-"}
            </li>
            {is_tech && tech_keywords.length > 0 ? (
              <li className="attribute-list-item">
                <b>Competências tecnológicas:</b>{" "}
                {translateTechTerms(tech_keywords)}
              </li>
            ) : null}
          </ul>
          {company.description ? (
            <div>
              <b>Descrição da empresa:</b>
              <p className="keep-format-text">{company.description}</p>
            </div>
          ) : null}
          <div>
            <b>Descrição da vaga:</b>
            <p className="keep-format-text">{description}</p>
          </div>
          {!isCandidate ? (
            <>
              <div>
                <b>Vaga publicada por: </b>
                {registered_by ? (
                  <>
                    <span
                      data-tooltip-id={tooltipId}
                      data-tooltip-content={registered_by?.email}
                      className="cursor-help"
                    >
                      {registered_by?.name} ({registered_by?.cate})
                    </span>
                    <Tooltip id={tooltipId} />
                  </>
                ) : null}
              </div>
              <div>
                <b>Responsável Cate: </b>
                {cate_responsable ? (
                  <>
                    <span>{cate_responsable}</span>
                  </>
                ) : null}
              </div>
            </>
          ) : null}

          <Collapse isOpened={!isCollapsed}>
            <Divider />
            <div className="attribute-lists-container">
              <ul className="attribute-list">
                <li className="attribute-list-item">
                  <b>Vagas disponíveis:</b> {available_jobs}
                </li>
                {!isCandidate ? (
                  <li className="attribute-list-item">
                    <b>Gerenciamento de candidatos pelo CATE:</b>{" "}
                    {is_cate_managed ? "Sim" : "Nao"}
                  </li>
                ) : null}
                {!hidden_salary ? (
                  <li className="attribute-list-item">
                    <b>Remuneração:</b>
                    {renderSalary(salary_type, salary)}
                  </li>
                ) : null}
                <li className="attribute-list-item">
                  <b>Jornada de trabalho:</b>{" "}
                  {journey ? TranslateJobJouney[journey] : "-"}
                </li>
                <li className="attribute-list-item">
                  <b>Escala de trabalho:</b> {job_scale || "-"}
                </li>
                <li className="attribute-list-item">
                  <b>Escolaridade mínima:</b> {schooling_level?.name || "-"}
                </li>
                <li className="attribute-list-item">
                  <b>Experiência mínima:</b> {experience_level?.name || "-"}
                </li>
              </ul>
              <ul className="attribute-list">
                {!isCandidate ? (
                  <li className="attribute-list-item">
                    <b>Limite de candidatos:</b> {seats || "-"}
                  </li>
                ) : null}
                {!isCandidate ? (
                  <li className="attribute-list-item">
                    <b>ID Sine:</b> {sine_id || "-"}
                  </li>
                ) : null}
                <li className="attribute-list-item">
                  <b>Tipo de trabalho:</b>{" "}
                  {job_type ? TranslateJobType[job_type] : "-"}
                </li>
                <li className="attribute-list-item">
                  <b>Benefícios:</b> {benefits ? benefits.join(", ") : "-"}
                </li>
                {!isCandidate ? (
                  <li className="attribute-list-item">
                    <b>Local de trabalho:</b>
                    {` ${job_location_string}`}
                  </li>
                ) : null}
              </ul>
            </div>
            <div>
              <b>Observações:</b>
              <p>{obs}</p>
            </div>
            <Divider />
            <h3>Requisitos</h3>
            <div className="requirements-container">
              <div className="requirements-list-container">
                <b>Profissionais Obrigatórios:</b>
                <div>
                  <ul className="requirements-list">
                    {requirements.map((requirement) => {
                      if (
                        requirement.value === "required" &&
                        professionalRequirements.indexOf(requirement.key) !== -1
                      ) {
                        return (
                          <li key={uuidv4()}>
                            {requirement.key !== requirement.option
                              ? `${
                                  TranslateJobRequirementKey[requirement.key]
                                }: ${
                                  TranslateJobRequirementOption[
                                    requirement.option
                                  ]
                                }`
                              : `${
                                  TranslateJobRequirementKey[requirement.key]
                                }`}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="requirements-list-container">
                <b>Profissionais Diferenciais:</b>
                <div>
                  <ul className="requirements-list">
                    {requirements.map((requirement) => {
                      if (
                        requirement.value === "desired" &&
                        professionalRequirements.indexOf(requirement.key) !== -1
                      ) {
                        return (
                          <li key={uuidv4()}>
                            {requirement.key !== requirement.option
                              ? `${
                                  TranslateJobRequirementKey[requirement.key]
                                }: ${
                                  TranslateJobRequirementOption[
                                    requirement.option
                                  ]
                                }`
                              : `${
                                  TranslateJobRequirementKey[requirement.key]
                                }`}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="requirements-list-container">
                <b>Pessoais Obrigatórios:</b>
                <div>
                  <ul className="requirements-list">
                    {requirements.map((requirement) => {
                      if (
                        requirement.value === "required" &&
                        professionalRequirements.indexOf(requirement.key) === -1
                      ) {
                        return (
                          <li key={uuidv4()}>
                            {requirement.key !== requirement.option
                              ? `${
                                  TranslateJobRequirementKey[requirement.key]
                                }: ${
                                  TranslateJobRequirementOption[
                                    requirement.option
                                  ]
                                }`
                              : `${
                                  TranslateJobRequirementKey[requirement.key]
                                }`}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="requirements-list-container">
                <b>Pessoais Diferenciais:</b>
                <div>
                  <ul className="requirements-list">
                    {requirements.map((requirement) => {
                      if (
                        requirement.value === "desired" &&
                        professionalRequirements.indexOf(requirement.key) === -1
                      ) {
                        return (
                          <li key={uuidv4()}>
                            {requirement.key !== requirement.option
                              ? `${
                                  TranslateJobRequirementKey[requirement.key]
                                }: ${
                                  TranslateJobRequirementOption[
                                    requirement.option
                                  ]
                                }`
                              : `${
                                  TranslateJobRequirementKey[requirement.key]
                                }`}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {user && !isCandidate ? (
              <React.Fragment>
                <Divider />
                <div className="attribute-lists-container">
                  <ul className="attribute-list">
                    <h6 className="secondary">Responsável pela vaga</h6>
                    <li className="attribute-list-item">
                      <b>Nome:</b> {responsable?.full_name || "-"}
                    </li>
                    <li className="attribute-list-item">
                      <b>Cargo do responsável:</b>{" "}
                      {responsable?.job_role || "-"}
                    </li>
                    <li className="attribute-list-item">
                      <b>Email:</b> {responsable?.email || "-"}
                    </li>
                    <li className="attribute-list-item">
                      <b>Telefone:</b> {responsable?.phone || "-"}
                    </li>
                    <li className="attribute-list-item">
                      <b>Celular:</b> {responsable?.cell || "-"}
                    </li>
                  </ul>
                  <ul className="attribute-list">
                    <h6 className="secondary">
                      Data(s) para a realização das entrevistas
                    </h6>
                    <li className="attribute-list-item">
                      <ul className="schedules-list">
                        {schedules?.map((schedule) => {
                          return (
                            <li key={uuidv4()}>
                              <b>De: </b>
                              {`De: ${format(
                                new Date(schedule.start_at),
                                "dd/MM/yyyy"
                              )} Até ${format(
                                new Date(schedule.end_at),
                                "dd/MM/yyyy"
                              )} - Das: ${format(
                                new Date(schedule.start_at),
                                "HH:mm"
                              )} às ${format(
                                new Date(schedule.end_at),
                                "HH:mm"
                              )}`}
                              - <b>{`${schedule.seats} vagas disponíveis`}</b>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                    <li className="attribute-list-item">
                      {is_meet_tell ? (
                        <div>
                          <b>Primeiro contato por telefone:</b> Sim
                        </div>
                      ) : (
                        <div>
                          <b>Local da entrevista:</b>
                          {` ${adress?.adress}, ${adress?.number} - ${adress?.district}`}
                        </div>
                      )}
                    </li>
                    <li className="attribute-list-item">
                      <div>
                        <b>Observações para entrevista:</b>
                        {` ${interview_obs || "-"}`}
                      </div>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            ) : null}
          </Collapse>
          <Divider />
          {user && !isCandidate ? (
            <div className="footer-card-job">
              <div>
                <span className="active-date-label">Vaga publicada em: </span>
                <span className="active-date-info">
                  {active_at
                    ? format(new Date(active_at), "dd/MM/yyyy HH:mm")
                    : "-"}
                </span>
              </div>
              <div className="footer-card-job-actions">
                {!managingJob ? (
                  <Link
                    to={`/vagas/detalhes?id=${id}`}
                    className="btn btn-primary"
                  >
                    Gerenciar vaga
                  </Link>
                ) : null}
                {user.role == "manager" || user.role == "acquirer" ? (
                  <Link
                    to={`/vagas/editar?id=${id}`}
                    className="btn btn-secondary"
                  >
                    Editar vaga
                  </Link>
                ) : null}
              </div>
            </div>
          ) : null}
          {isCandidate && !detailsMode ? (
            <div className="footer-card-job-candidate">
              <div>
                <span className="active-date-label">Vaga publicada em: </span>
                <span className="active-date-info">
                  {active_at
                    ? format(new Date(active_at), "dd/MM/yyyy HH:mm")
                    : "-"}
                </span>
              </div>
              <div className="footer-card-job-candidate-button">
                <Link
                  to={
                    attendanceMode
                      ? `/atendimento/candidato/vagas/detalhes?id=${id}`
                      : `/candidato/vagas/detalhes?id=${id}`
                  }
                  className="btn btn-primary"
                >
                  {attendanceMode ? "Candidatar" : "Quero me candidatar"}
                </Link>
                <a
                  className="btn btn-alternative"
                  href={`${WP_URL}/cursos/`}
                  target="_blank"
                >
                  Quer aumentar suas chances de emprego?
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </JobListItemStyled>
  );
}

export default JobListItemDetailed;
