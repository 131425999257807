import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Input from "../../FormHook/Input";
import WithMask from "../../FormHook/WithMask";
import ReactSelect from "../../FormHook/ReactSelect";
import axios from "axios";
import { optionsRegion } from "./../../../pages/Jobs/database";
// import { Container } from './styles';

function AddressForm({
  control,
  register,
  watch,
  setValue,
  skipCepValdiation,
  allowOutSP = false,
  dirtyFields = {},
}) {
  const [skipCepValidation, setSkipCepValidation] = useState(skipCepValdiation);
  const cepWatch = watch("address.cep");

  useEffect(() => {
    if (!cepWatch) {
      return false;
    }
    if (skipCepValidation) {
      return false;
    }
    if (!dirtyFields?.address?.cep) {
      return false;
    }
    if (cepWatch.length === 9) {
      axios.get(`https://viacep.com.br/ws/${cepWatch}/json/`).then((resp) => {
        if (resp.data.uf !== "SP" && !allowOutSP) {
          toast.error("CEP digitado nao pertence ao Estado de Sao paulo");
          return false;
        }
        setValue("address.adress", resp.data.logradouro);
        setValue("address.district", resp.data.bairro);
        setValue("address.city", resp.data.localidade);
        if (allowOutSP) {
          setValue("address.state", resp.data.uf);
        }
      });
    }
  }, [cepWatch]);

  return (
    <div>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <WithMask
            name="address.cep"
            mask={`99999-999`}
            control={control}
            label={`CEP:`}
          />
        </div>
        {allowOutSP ? null : (
          <div className="col-md-4 col-sm-12 skip-cep-container">
            <div className="form-check">
              <input
                type="checkbox"
                checked={skipCepValidation}
                onChange={(e) => setSkipCepValidation(!skipCepValidation)}
                className="form-check-input"
                id="skip_cep_validation_check"
              />
              <label
                className="form-check-label"
                htmlFor="skip_cep_validation_check"
              >
                Não validar o CEP (viacep)
              </label>
            </div>
          </div>
        )}
      </div>
      <Input
        label={`Endereço:`}
        // disabled={!skipCepValidation}
        name="address.adress"
        register={register}
      />
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <Input label={`Número:`} name="address.number" register={register} />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input
            label={`Complemento:`}
            name="address.complement"
            register={register}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Input
            label={`Ponto de referência:`}
            name="address.reference"
            register={register}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <Input
            label={`Bairro:`}
            // disabled={!allowOutSP && !skipCepValidation}
            name="address.district"
            register={register}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <ReactSelect
            name="address.region"
            placeholder="Região"
            control={control}
            options={optionsRegion}
            label="Região:"
          />
        </div>
      </div>
      {allowOutSP ? (
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Input
              label={`Cidade:`}
              disabled={!skipCepValidation}
              name="address.city"
              register={register}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Input
              label={`Estado:`}
              disabled={!skipCepValidation}
              name="address.state"
              register={register}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <Input
              label={`Cidade:`}
              disabled={!skipCepValidation}
              name="address.city"
              register={register}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AddressForm;
