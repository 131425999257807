import React, { useContext } from "react";
import { HashRouter, Routes, Route, Outlet } from "react-router-dom";
import AuthContext from "../contexts/auth";
import { AttendanceProvider } from "./../contexts/attendance";
import Header from "./../components/Header";
import Footer from "./../components/Footer";

import Home from "../pages/Home";
import UpdateUser from "../pages/Users/update";
import Users from "../pages/Users";
import Cate from "../pages/Cate";
import CateUpdate from "../pages/Cate/update";
import Company from "../pages/Companies";
import CompanyUpdate from "../pages/Companies/update";
import CompanyDetails from "../pages/Companies/details";
import JobUpdate from "../pages/Jobs/update";
import JobDetails from "../pages/Jobs/details";
import JobCandidates from "../pages/Jobs/candidates";
import Jobs from "../pages/Jobs/";
import Resumes from "../pages/Resumes/";
import Reports from "../pages/Reports/";
import NotFound from "./../pages/NotFound";
import CandidateAuthError from "./../pages/Erros/candidateAuth";
import RegisterAttendance from "./../pages/RegisterAttendance";
import CompanyRegisterAttendence from "./../pages/CompanyRegisterAttendance";
import SocialAttendance from "./../pages/SocialAttendance";

import AttendanceCandidateResume from "./../pages/Attendance/CandidateResume/update";
import CandidateHome from "./../pages/Attendance/CandidateHome";
import CandidateJobDetails from "./../pages/Attendance/CandidateJobDetails";

const BaseRoute = (props) => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

const MenagerRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BaseRoute />}>
        <Route index element={<Home />} />
        <Route path="usuarios">
          <Route index element={<Users />} />
          <Route path="editar" element={<UpdateUser />} />
        </Route>
        <Route path="unidades-cate">
          <Route index element={<Cate />} />
          <Route path="editar" element={<CateUpdate />} />
        </Route>
        <Route path="empresas">
          <Route index element={<Company />} />
          <Route path="editar" element={<CompanyUpdate />} />
          <Route path="detalhes" element={<CompanyDetails />} />
        </Route>
        <Route path="vagas">
          <Route index element={<Jobs role="manager" />} />
          <Route path="editar" element={<JobUpdate />} />
          <Route path="detalhes" element={<JobDetails managingJob={true} />} />
          <Route path="candidatos" element={<JobCandidates />} />
        </Route>
        <Route
          path="atendimento/empresas"
          element={<CompanyRegisterAttendence />}
        />
        <Route path="candidatos" element={<Resumes />} />
        <Route path="relatorios" element={<Reports />} />
        <Route path="meu-perfil" element={<UpdateUser selfEdit={true} />} />
        <Route path="candidato" element={<CandidateAuthError />} />
      </Route>
    </Routes>
  );
};

const AcquirerRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BaseRoute />}>
        <Route index element={<Home />} />
        <Route path="unidades-cate">
          <Route index element={<Cate />} />
          <Route path="editar" element={<CateUpdate />} />
        </Route>
        <Route
          path="atendimento/empresas"
          element={<CompanyRegisterAttendence />}
        />
        <Route path="empresas">
          <Route index element={<Company />} />
          <Route path="editar" element={<CompanyUpdate />} />
          <Route path="detalhes" element={<CompanyDetails />} />
        </Route>
        <Route path="vagas">
          <Route index element={<Jobs role="manager" />} />
          <Route path="editar" element={<JobUpdate />} />
          <Route path="detalhes" element={<JobDetails managingJob={true} />} />
          <Route path="candidatos" element={<JobCandidates />} />
        </Route>
        <Route path="candidatos" element={<Resumes />} />
        <Route path="relatorios" element={<Reports />} />
        <Route path="meu-perfil" element={<UpdateUser selfEdit={true} />} />
        <Route path="candidato" element={<CandidateAuthError />} />
      </Route>
    </Routes>
  );
};

const ConsultantRoutes = () => {
  return (
    <AttendanceProvider>
      <Routes>
        <Route path="/" element={<BaseRoute />}>
          <Route index element={<Home />} />
          <Route path="atendimento/registrar/:uuid">
            <Route index element={<RegisterAttendance />} />
          </Route>
          <Route
            path="atendimento/empresas"
            element={<CompanyRegisterAttendence />}
          />
          <Route path="atendimento/social" element={<SocialAttendance />} />
          <Route path="atendimento/candidato">
            <Route path="curriculo" element={<AttendanceCandidateResume />} />
            <Route path="painel" element={<CandidateHome />} />
            <Route path="vagas" element={<BaseRoute />}>
              <Route
                index
                element={<Jobs attendanceMode role="consultant" />}
              />
              <Route
                path="detalhes"
                element={<CandidateJobDetails managingJob={true} />}
              />
            </Route>
          </Route>
          <Route path="vagas">
            <Route index element={<Jobs role="consultant" />} />
            <Route
              path="detalhes"
              element={<JobDetails managingJob={true} />}
            />
          </Route>
          <Route path="meu-perfil" element={<UpdateUser selfEdit={true} />} />
          <Route path="candidato" element={<CandidateAuthError />} />
        </Route>
      </Routes>
    </AttendanceProvider>
  );
};

export default function PrivateRoutes() {
  const { role } = useContext(AuthContext);
  function getRoleRoutes() {
    switch (role) {
      case "manager":
        return <MenagerRoutes />;
        break;
      case "acquirer":
        return <AcquirerRoutes />;
        break;
      case "consultant":
        return <ConsultantRoutes />;
        break;
    }
  }
  return (
    <HashRouter>
      <Header />
      <div>{getRoleRoutes()}</div>
      <Footer />
    </HashRouter>
  );
}
