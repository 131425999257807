import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { validateCPF } from "validations-br";
import FileDownload from "js-file-download";
import {
  scheduleSchema,
  meetPhoneSchema,
  jobSchema,
  adressSchema,
  draftJobSchema,
  responsableSchema,
} from "../../helpers/validatorSchemas";
import {
  currencyFormatter,
  currencyToNumber,
  updateCurrencyFormat,
} from "../../helpers/formatter";
import {
  optionsContractType,
  optionsBenefits,
  optionsJobScale,
  optionsRegion,
} from "./database";
import Requirements from "./components/Requirements";
import MeetAddressCard from "./components/MeetAddressCard";
import MeetPhonesList from "./components/MeetPhonesList";
import PageHeader from "./../../components/PageHeader";
import Input from "./../../components/FormHook/Input";
import WithMask from "./../../components/FormHook/WithMask";
import TextArea from "./../../components/FormHook/TextArea";
import ReactSelect from "./../../components/FormHook/ReactSelect";
import ReactSelectCreatable from "./../../components/FormHook/ReactSelectCreatable";
import OccupationSelect from "../../components/FormHook/OccupationSelect";
import CompanySelect from "../../components/FormHook/CompanySelect";
import AddressForm from "../../components/Forms/AddressForm";
import Switch from "../../components/FormHook/Switch";
import { UpdateStyeldContainer } from "./style";
import { maskPhone, maskCPF } from "./../../helpers/mask";
import api from "../../config/api";
import SchedulesList from "./components/SchedulesList";
import CloseJobForm from "../../components/Forms/CloseJobForm";
import Section from "./../../components/Section";
import { TranslateJobRequirementKey } from "../../helpers/translateFields";
import JobHistory from "./components/JobHistory";
import { techTerms } from "./../../helpers/dicts";
import JobLocation from "./components/Form/JobLocation";
import { confirm } from "./../../components/Confirmation";

import Salary from "./components/Form/Salary";

function JobsUpdate() {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    defaultValues: {
      is_cate_managed: true,
      is_meet_on_company: false,
      is_meet_on_cate: false,
      is_meet_tell: false,
      available_jobs: 1,
      status: "draft",
    },
  });
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const [warningCompany, setWarningCompany] = useState(null);
  const [prevJob, setPrevJob] = useState(null);
  const [cates, setCates] = useState([]);
  const [history, setHistory] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [segments, setSegments] = useState([]);
  const [schoolingLevels, setSchoolingLevels] = useState([]);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const [meetAddress, setMeetAddress] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [meetPhones, setMeetPhones] = useState([]);
  const [closeCpfs, setCloseCpfs] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [companyAddress, setCompanyAddress] = useState(null);
  const [scheduleDisableDateFields, setScheduleDisableDateFields] =
    useState(false);
  const [currentEditingSchedule, setCurrentEditingSchedule] = useState(null);
  const [remainingSeats, setRemainingSeats] = useState(0);
  const sameResponsableWatch = watch("same_responsable_comany");
  const isCateAdressWatch = watch("is_meet_on_cate");
  const isCompanyAdressWatch = watch("is_meet_on_company");
  const isMeetTellWatch = watch("is_meet_tell");
  const companyWatch = watch("company_select");
  const cateIdWatch = watch("cate_id");
  const jobStatusWatch = watch("status");
  const scheduleStartDateWatch = watch("schedule.start_at");
  const seatsWatch = watch("seats");
  const expWatch = watch("experience_level_id");
  const isTech = watch("is_tech");

  const canUpdateStatus = !id
    ? true
    : prevJob?.total_approved_applications === 0;
  const canDraft = !id || prevJob?.status === "draft";
  const canUpdate = true;

  // Check watch companies - get companies
  useEffect(() => {
    if (!companyWatch) {
      return false;
    }
    api.get(`companies/${companyWatch.value}`).then((resp) => {
      setCompanyAddress(resp.data.adress);
      setResponsables(resp.data.responsables);
      setWarningCompany(resp.data.unclosed_jobs);
    });
  }, [companyWatch]);

  useEffect(() => {
    if (expWatch === 1) {
      setValue("must_job_register", 0);
    }
  }, [expWatch]);

  function validateScheduleLimits() {
    let totalSchedules = schedules.reduce(
      (previousValue, currentValue) => previousValue + currentValue.seats,
      0
    );

    if (schedules.length > 0) {
      if (seatsWatch < totalSchedules) {
        toast.error(
          `Limite minimo de candidatos tem que ser maior que ${totalSchedules}. Agenda já preenchida.`
        );
        setValue("seats", totalSchedules);
      }
    }
  }

  useEffect(() => {
    if (seatsWatch) {
      let totalfilledSeats = schedules.reduce(
        (accumulator, currentValue) => accumulator + currentValue.seats,
        0
      );
      setRemainingSeats(seatsWatch - totalfilledSeats);
    }
  }, [schedules, seatsWatch]);

  // // Check watch cate_address
  useEffect(() => {
    if (isCateAdressWatch && cates.length > 0) {
      cates.map((cate) => {
        if (cate.id === cateIdWatch) {
          setMeetAddress(cate.adress);
        }
      });
    }
  }, [cateIdWatch, cates, isCateAdressWatch]);

  useEffect(() => {
    if (isCompanyAdressWatch) {
      setMeetAddress(companyAddress);
    }
  }, [isCompanyAdressWatch, companyAddress]);

  function getTomorrow() {
    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return format(tomorrow, "yyyy-MM-dd'T'HH:mm");
  }

  // Get select infos
  useEffect(async () => {
    let catesResponse = await api.get(`cates?mode=list`);
    setCates(catesResponse.data);
    let schoolingResponse = await api.get(`schooling-levels`);
    setSchoolingLevels(schoolingResponse.data);
    let experienceResponse = await api.get(`experience-levels`);
    setExperienceLevels(experienceResponse.data);
    let segmentsResponse = await api.get(`segments`);
    setSegments(segmentsResponse.data);
    if (searchParams.get("id")) {
      await getJob(searchParams.get("id"));
    }
  }, []);

  // Load By ID

  async function getJob(id) {
    api.get(`/jobs/${id}`).then((resp) => {
      setPrevJob(resp.data);
      setSchedules(resp.data.schedules);
      setCloseCpfs(resp.data.closed_cpf || []);
      if (resp.data.is_meet_tell) {
        setMeetPhones(resp.data.meet_phones);
      }
      setRequirements(resp.data.requirements);
      setHistory(resp.data.history);
      // setWarningCompany(resp.data?.company?.unclosed_jobs);
      reset({
        ...resp.data,
        same_responsable_comany: true,
        work_regions: resp.data.work_regions
          ? resp.data.work_regions.split(",")
          : [],
        salary: updateCurrencyFormat(resp.data.salary),
        occupation_select: {
          label: resp.data.occupation?.name,
          value: resp.data.occupation?.id,
        },
        company_select: {
          label: resp.data?.company?.name,
          value: resp.data?.company?.id,
        },
      });
      setId(id);
    });
  }

  async function validateStatus(req) {
    let isValid = true;
    if (req.status === "closed") {
      if (!req.close_reason) {
        toast.error("Selecione um motivo de fechamento da vaga");
        isValid = false;
      } else {
        if (req.close_reason === "complete_cate") {
          if (closeCpfs.length === 0) {
            toast.error("Adicione os CPF dos candidatos aprovados");
            isValid = false;
          }
        }
      }
    }
    if (req.status === "schedule") {
      if (!req.active_at) {
        toast.error("Escolha uma data de ativação da vaga.");
        isValid = false;
      }
    }
    return isValid;
  }

  async function validateResponsables(req) {
    let isValid = true;
    if (req.same_responsable_comany) {
      if (!req.responsable_id) {
        isValid = false;
        toast.error("Responsável: Selecione um responsável");
      }
    } else {
      try {
        await responsableSchema.validate(req.responsable, {
          abortEarly: false,
        });
      } catch (e) {
        isValid = false;
        toast.error(`Responsável: ${toast.error(e.errors[0])}`);
      }
    }

    return isValid;
  }

  async function validateAddress(req) {
    let isValid = true;
    if (!req.is_meet_on_cate && !req.is_meet_tell && !req.is_meet_on_company) {
      try {
        await adressSchema.validate(req.address, { abortEarly: false });
      } catch (e) {
        isValid = false;
        toast.error(`Entrevista: ${e.errors[0]}`);
      }
    }

    if (req.is_meet_on_cate) {
      if (!req.cate_id) {
        toast.error("Selecione uma unidade do CATE para entrevista");
        isValid = false;
      }
    }

    if (req.is_meet_tell) {
      if (req.meet_phones.length === 0) {
        toast.error("Adicione pelomenos 1 telefone para contato.");
        return false;
      }
    }

    return isValid;
  }

  async function validateJobLocation(req) {
    let isValid = true;

    if (req.same_job_location) {
      delete req.job_location;
    } else {
      try {
        await adressSchema.validate(req.job_location, { abortEarly: false });
      } catch (e) {
        isValid = false;
        toast.error(`Local de trabalho: ${e.errors[0]}`);
      }
    }

    return isValid;
  }

  async function handleAffectedUsersAlert() {
    if (
      await confirm(
        "Deseja notificar os candidatos a esta vaga sobre a alteração?",
        "Sim",
        "Não",
        {
          title: "Notificar alteração?",
        }
      )
    ) {
      let response = await api.post(
        `job-applications/update-notification/${id}`
      );
    }
    if (
      await confirm(
        "Deseja baixar um relatório dos usuários afetados pela alteração?",
        "Sim",
        "Não",
        {
          title: "Baixar relatório?",
        }
      )
    ) {
      api({
        url: "reports/job-update-notification",
        method: "GET",
        responseType: "blob",
        params: { job_id: id }, // Important
      }).then((response) => {
        FileDownload(response.data, `candidatos_afetados_vaga_${id}.xlsx`);
      });
    }
    navigate(-1);
  }

  async function onSubmit(data) {
    let req = data;
    req.occupation_id = data.occupation_select?.value ?? null;
    req.company_id = data.company_select?.value ?? null;
    try {
      await draftJobSchema.validate(req, { abortEarly: false });
    } catch (e) {
      toast.error(e.errors[0]);
      return false;
    }

    delete req.schedule;
    delete req.meet_phone;

    // Remove address form and add id if is on cate or company
    if (data.is_meet_on_company || data.is_meet_on_cate) {
      delete req.address;
    }
    // Remove address form and add phones if is on phone meet
    if (data.is_meet_tell) {
      req.meet_phones = meetPhones;
      delete req.address;
    }
    // Remove address form and add phones if is on phone meet
    if (data.same_responsable_comany) {
      delete req.responsable;
    } else {
      delete req.responsable_id;
      req.responsable.cell = maskPhone(req.responsable.cell);
      req.responsable.phone = maskPhone(req.responsable.phone);
    }

    req.salary = currencyToNumber(req.salary);

    req.requirements = requirements;
    req.schedules = schedules;

    let validations = [];

    if (req.status !== "draft") {
      validations.push(await validateResponsables(req));
      validations.push(await validateStatus(req));
      validations.push(await validateAddress(req));
      // validations.push(await validateJobLocation(req));
    }

    if (validations.includes(false)) {
      return false;
    }

    if (req.is_tech) {
      if (!req.tech_keywords) {
        toast.error("Preencha ao menos 1 competências de tecnologia");
        return false;
      } else {
        req.tech_keywords = req.tech_keywords.join(",");
      }
    } else {
      req.tech_keywords = null;
    }

    if (req.work_regions.length == 0) {
      toast.error("Preencha as regiões de trabalho");
      return false;
    }
    req.work_regions = req.work_regions.join(",");

    delete req.complete_cate;
    req.close_cpfs = closeCpfs;

    try {
      if (getValues("status") !== "draft") {
        await jobSchema.validate(req, { abortEarly: false });
      }
      let response = !id
        ? await api.post(`jobs`, req)
        : await api.put(`jobs/${id}`, req);
      toast.success(`Vaga #ID ${response.data.id} cadastrada com sucesso`);
      if (response.data.has_changes_to_notificate) {
        handleAffectedUsersAlert();
      } else {
        navigate(-1);
      }
    } catch (e) {
      toast.error(e.errors[0]);
      return false;
    }
  }

  async function handleAddCpf() {
    let cpf = getValues("complete_cate.cpf");
    try {
      await Yup.string()
        .required("Preencha o campo CNPJ")
        .test("is-cpf", "CPF Invalido", (value) => validateCPF(value))
        .validate(cpf);

      if (closeCpfs.length + 1 > getValues("available_jobs")) {
        toast.error(
          "Não e permitido adicionar mais CPF do que a quantidade de vagas"
        );
        return false;
      }

      if (closeCpfs) {
        if (closeCpfs.filter((c) => c.cpf === cpf).length !== 0) {
          toast.error("Esse CPF já foi adicionado.");
          return false;
        }
      }

      if (prevJob?.closed_cpf) {
        if (
          prevJob?.closed_cpf?.filter((c) => maskCPF(c.cpf) === cpf).length !==
          0
        ) {
          toast.error("Esse CPF já foi adicionado.");
          return false;
        }
      }

      setCloseCpfs((prevArray) => [...prevArray, { cpf }]);
      toast.success("CPF adicionado com sucesso");
      setValue("complete_cate.cpf", "");
    } catch (e) {
      toast.error(e.errors.join(", "));
      return false;
    }
  }

  async function handleDeleteCpf(idx) {
    let exclude = window.confirm("Deseja realmente remover esse cpf?");
    if (!exclude) {
      return false;
    }
    setCloseCpfs(closeCpfs.filter((cpf, i) => i !== idx));
  }

  async function handleAddPhone() {
    let phone = {
      name: getValues("meet_phone.name") || null,
      phone: maskPhone(getValues("meet_phone.phone")) || null,
    };
    try {
      await meetPhoneSchema.validate(phone);
      setMeetPhones((prevArray) => [...prevArray, phone]);
      toast.success("Telefone adicionado com sucesso");
      setValue("meet_phone.name", "");
      setValue("meet_phone.phone", "");
    } catch (e) {
      toast.error(e.errors.join(", "));
      return false;
    }
  }

  async function handleDeletePhone(idx) {
    let exclude = window.confirm("Deseja realmente remover esse telefone?");
    if (!exclude) {
      return false;
    }
    setMeetPhones(meetPhones.filter((phone, i) => i !== idx));
  }

  async function handleSubmitSchedule() {
    // editing schedule
    if (currentEditingSchedule !== null) {
      let scheduleId = schedules[currentEditingSchedule].id;
      let usedSeats = schedules[currentEditingSchedule].used_seats;
      let seats = getValues("schedule.seats");
      if (usedSeats > seats) {
        toast.error(
          `Minimo de candidatos possivel é de ${usedSeats}. Pois essa vaga já tem candidaturas aprovadas.`
        );
        return false;
      }
      try {
        let updateScheduleResponse = await api.put(
          `jobs/schedules/${scheduleId}`,
          { seats: seats }
        );
        toast.success(`Agenda atualizada com sucesso`);
        setSchedules(updateScheduleResponse.data);
      } catch (e) {
        if (e.response?.data?.message) {
          toast.error(e.response?.data?.message);
        }
      }
      return false;
    }
    // adding schedule
    let schedule = {
      start_at: new Date(getValues("schedule.start_at")) || null,
      end_at: new Date(getValues("schedule.end_at")) || null,
      seats: getValues("schedule.seats") || null,
    };

    let hasInstersectionError = false;
    // check intersection between periods
    schedules.map((s) => {
      if (
        new Date(s.start_at) <= new Date(schedule.end_at) &&
        new Date(schedule.start_at) <= new Date(s.end_at)
      ) {
        //overlapping dates
        hasInstersectionError = true;
      }
    });

    if (hasInstersectionError) {
      toast.error("Data/Hora está em conflito com outras agendas dessa vaga.");
      return false;
    }

    if (schedule.end_at < schedule.start_at) {
      toast.error("Data/Hora de fim menor que a Data/Hora de início.");
      return false;
    }
    let totalSeats = getValues("seats");
    let schedulesSeats = schedules.reduce(
      (total, schedule) => parseInt(total) + parseInt(schedule.seats),
      0
    );
    if (!totalSeats) {
      toast.error(
        "Preencha o campo limite de candidatos antes de inserir agenda"
      );
      return false;
    }
    if (parseInt(totalSeats) < schedulesSeats + parseInt(schedule.seats)) {
      toast.error(
        "A quantidade de limite de candidatos da agenda é maior que a quantidade de limites de candidatos da vaga."
      );
      return false;
    }

    try {
      await scheduleSchema.validate(schedule, { abortEarly: false });
      setSchedules((prevArray) => [...prevArray, schedule]);
      toast.success("Agenda adicionada com sucesso");
      setValue("schedule.start_at", "");
      setValue("schedule.end_at", "");
      setValue("schedule.seats", "");
    } catch (e) {
      toast.error(e.errors[0]);
      return false;
    }
  }

  async function handleUpdateSchedule(idx) {
    setValue(
      "schedule.start_at",
      format(new Date(schedules[idx].start_at), "yyyy-MM-dd'T'HH:mm")
    );
    setValue(
      "schedule.end_at",
      format(new Date(schedules[idx].end_at), "yyyy-MM-dd'T'HH:mm")
    );
    setValue("schedule.seats", schedules[idx].seats);
    setScheduleDisableDateFields(true);
    setCurrentEditingSchedule(idx);
  }

  async function handleDeleteSchedule(idx) {
    let exclude = window.confirm("Deseja realmente remover essa agenda?");
    if (!exclude) {
      return false;
    }
    setSchedules(schedules.filter((schedule, i) => i !== idx));
  }

  return (
    <UpdateStyeldContainer>
      <PageHeader
        title={`Cadastrar nova vaga`}
        actions={[<Link to={`/vagas`}>Ver todas as vagas</Link>]}
      />
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Section title="Dados da vaga">
            <CompanySelect
              name="company_select"
              control={control}
              label="Empresa:"
              required
              requiredForJob
              disabled={!!id}
            />
            {warningCompany > 5 ? (
              <div className="alert alert-danger" role="alert">
                Essa empresa possui {warningCompany} vagas expiradas sem retorno
                de contratações.
              </div>
            ) : null}
            <OccupationSelect
              name="occupation_select"
              control={control}
              label="Ocupação:"
              required
              requiredForJob
              withCodePrefix
              disabled={!canUpdate}
            />
            <Switch
              register={register}
              disabled={!canUpdate}
              name="is_tech"
              requiredForJob
              label="Vaga para T.I?"
            />
            {isTech ? (
              <ReactSelect
                name="tech_keywords"
                isMulti
                requiredForJob
                control={control}
                placeholder={"Selecione as competências de tecnologia da vaga"}
                options={techTerms}
                label="Competências de tecnologia:"
              />
            ) : null}
            <ReactSelect
              name="segment_id"
              control={control}
              requiredForJob
              options={segments.map((occ) => {
                return { label: occ.name, value: occ.id };
              })}
              disabled={!canUpdate}
              label="Área de atuação/Segmento:"
              placeholder={`Selecione uma Área de atuação/Segmento`}
            />
            <TextArea
              register={register}
              errors={errors}
              requiredForJob
              disabled={!canUpdate}
              name="description"
              validations={{
                maxLength: {
                  value: 2000,
                  message: "Maximo 2000 caracteres atingido.", // JS only: <p>error message</p> TS only support string
                },
              }}
              label="Descrição da vaga:"
              rows={8}
            />
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <Input
                  disabled={!canUpdate}
                  type="number"
                  requiredForJob
                  register={register}
                  errors={errors}
                  name="available_jobs"
                  validations={{
                    min: {
                      value: 1,
                      message: "Não permitido valores negativos, minimo 1",
                    },
                  }}
                  label="Vagas disponíveis:"
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <Input
                  type="number"
                  requiredForJob
                  disabled={!canUpdate}
                  register={register}
                  min={prevJob?.total_approved_applications}
                  name="seats"
                  onBlur={validateScheduleLimits}
                  errors={errors}
                  validations={{
                    min: {
                      value: 1,
                      message: "Não permitido valores negativos, minimo 1",
                    },
                  }}
                  label="Limite de candidatos:"
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <Input
                  type="text"
                  disabled={!canUpdate}
                  register={register}
                  name="sine_id"
                  label="ID SINE:"
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <Switch
                  register={register}
                  disabled={!canUpdate}
                  name="is_cate_managed"
                  requiredForJob
                  label="Vaga gerenciada pelo cate?"
                />
              </div>
            </div>
            <div className="row">
              <Salary
                watch={watch}
                setValue={setValue}
                canUpdate={canUpdate}
                control={control}
                register={register}
                errors={errors}
                dirtyFields={dirtyFields}
              />
            </div>
            <ReactSelectCreatable
              name="benefits"
              placeholder="Selecione os benefícios oferecidos"
              isMulti
              creatable
              disabled={!canUpdate}
              control={control}
              options={optionsBenefits}
              label="Oferece benefícios:"
            />
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <ReactSelectCreatable
                  name="contract_type"
                  requiredForJob
                  creatable
                  disabled={!canUpdate}
                  placeholder="Selecione o tipo de contrato"
                  control={control}
                  options={optionsContractType}
                  label="Tipo de contrato:"
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <ReactSelect
                  name="journey"
                  requiredForJob
                  disabled={!canUpdate}
                  placeholder="Selecione a jornada de trabalho"
                  control={control}
                  options={[
                    {
                      label: "Hora de entrada e saída fixa",
                      value: "fixed",
                    },
                    {
                      label: "Hora de entrada e saída a combinar",
                      value: "to_combine",
                    },
                  ]}
                  label="Jornada de trabalho:"
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <ReactSelectCreatable
                  name="job_scale"
                  requiredForJob
                  creatable
                  disabled={!canUpdate}
                  control={control}
                  placeholder="Selecione a escala de tarbalho"
                  options={optionsJobScale}
                  label="Escala de trabalho:"
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <ReactSelect
                  name="job_type"
                  requiredForJob
                  disabled={!canUpdate}
                  placeholder="Selecione o tipo de trabalho"
                  control={control}
                  options={[
                    {
                      label: "Trabalho Presencial",
                      value: "presential",
                    },
                    {
                      label: "Trabalho Remoto",
                      value: "remote",
                    },
                    {
                      label: "Híbrido",
                      value: "hybrid",
                    },
                  ]}
                  label="Tipo de trabalho:"
                />
              </div>
            </div>
            <TextArea
              register={register}
              name="obs"
              requiredForJob
              disabled={!canUpdate}
              label="Observações:"
              validations={{
                maxLength: {
                  value: 2000,
                  message: "Maximo 2000 caracteres atingido.", // JS only: <p>error message</p> TS only support string
                },
              }}
              rows={8}
            />
          </Section>

          <Section title="Exigências">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="schooling_level_id"
                  requiredForJob
                  disabled={!canUpdate}
                  control={control}
                  options={schoolingLevels.map((sl) => {
                    return { label: sl.name, value: sl.id };
                  })}
                  placeholder="Selecione a escolaridade mínima"
                  label="Escolaridade miníma:"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <ReactSelect
                  name="experience_level_id"
                  requiredForJob
                  disabled={!canUpdate}
                  control={control}
                  options={experienceLevels.map((el) => {
                    return { label: el.name, value: el.id };
                  })}
                  placeholder="Selecione a experiência mínima"
                  label="Experiência mínima:"
                />
              </div>
            </div>
            <Switch
              name="must_job_register"
              requiredForJob
              disabled={expWatch === 1 || !canUpdate}
              label="Deve apresentar registro em carteira"
              register={register}
            />
          </Section>

          <Section title="Requisitos da vaga">
            <p>
              Preencha com atenção os requisitos das vagas. Eles são essenciais
              para a seleção dos currículos.
            </p>
            <Requirements
              disabled={!canUpdate}
              control={control}
              requirements={requirements}
              watch={watch}
              getValues={getValues}
              updateRequirements={setRequirements}
              setValue={setValue}
            />
          </Section>

          <Section title="Responsável pela vaga">
            {companyWatch ? (
              <Switch
                register={register}
                name="same_responsable_comany"
                disabled={!canUpdate}
                label="O responsável ja esta cadastrado na empresa?"
              />
            ) : null}

            {sameResponsableWatch ? (
              <div className="row">
                <ReactSelect
                  name="responsable_id"
                  requiredForJob
                  control={control}
                  disabled={!canUpdate}
                  options={responsables.map((responsable) => {
                    return {
                      label: `${responsable.full_name} ${
                        responsable.primary ? ` (Principal)` : ``
                      }`,
                      value: responsable.id,
                    };
                  })}
                  label="Responsável pela empresa:"
                />
              </div>
            ) : (
              <div className="row">
                <Input
                  name="responsable.full_name"
                  register={register}
                  disabled={!canUpdate}
                  label="Nome:"
                />
                <Input
                  name="responsable.job_role"
                  register={register}
                  disabled={!canUpdate}
                  label="Cargo:"
                />
                <Input
                  type="email"
                  name="responsable.email"
                  register={register}
                  disabled={!canUpdate}
                  label="Email:"
                />
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <WithMask
                      name="responsable.phone"
                      control={control}
                      label={`Telefone:`}
                      disabled={!canUpdate}
                      phoneOrCell
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <WithMask
                      name="responsable.cell"
                      phoneOrCell
                      control={control}
                      label={`Celular:`}
                      disabled={!canUpdate}
                    />
                  </div>
                </div>
              </div>
            )}
            <Input
              type="text"
              disabled={!canUpdate}
              register={register}
              name="cate_responsable"
              label="Pessoa responsável pela vaga no Cate:"
            />
          </Section>

          <Section title="Entrevista">
            <p>
              Para as agendas que já possuem candidaturas, só é possivel editar
              o limite de candidatos, desde que respeite o minimo das
              candidaturas. Ao editar, os dados de agenda serão recarregados,
              sendo assim, faça a edição antes de inclusão de novas agendas.
            </p>
            <SchedulesList
              disabled={prevJob?.status === "closed"}
              schedules={schedules}
              handleDeleteSchedule={handleDeleteSchedule}
              handleUpdateSchedule={handleUpdateSchedule}
            />
            {prevJob?.status !== "closed" ? (
              <>
                {seatsWatch ? (
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div
                        class={`alert alert-${
                          remainingSeats > 0 ? "warning" : "success"
                        }`}
                        role="alert"
                      >
                        {remainingSeats > 0
                          ? `Ainda falta ${remainingSeats} vagas na agenda a serem preechidas.`
                          : "Todas as agendas preenchidas"}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-md-3 col-sm-12 schedule-form-div">
                    <Input
                      register={register}
                      requiredForJob
                      name="schedule.start_at"
                      type="datetime-local"
                      label="Data início:"
                      disabled={scheduleDisableDateFields}
                      min={getTomorrow()}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 schedule-form-div">
                    <Input
                      register={register}
                      requiredForJob
                      name="schedule.end_at"
                      type="datetime-local"
                      label="Data fim:"
                      disabled={
                        !scheduleStartDateWatch || scheduleDisableDateFields
                      }
                      min={scheduleStartDateWatch}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 schedule-form-div">
                    <Input
                      register={register}
                      requiredForJob
                      name="schedule.seats"
                      type="number"
                      min={
                        currentEditingSchedule !== null
                          ? schedules[currentEditingSchedule]?.used_seats
                          : 1
                      }
                      label="Limite de candidatos:"
                    />
                    {currentEditingSchedule !== null ? (
                      <span className="small-danger">
                        {`Minimo ${schedules[currentEditingSchedule]?.used_seats} (agendas já utilizadas).`}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-3 col-sm-12 schedule-add-btn-container">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => handleSubmitSchedule()}
                    >
                      {currentEditingSchedule !== null
                        ? `Editar agenda`
                        : `Adicionar agenda`}
                    </button>
                  </div>
                </div>
              </>
            ) : null}
            {!isCompanyAdressWatch && !isCateAdressWatch ? (
              <React.Fragment>
                <Switch
                  register={register}
                  label="Primeiro contato por telefone?"
                  name="is_meet_tell"
                  disabled={!canUpdate}
                />
                {getValues("is_meet_tell") ? (
                  <div>
                    <MeetPhonesList
                      meetPhones={meetPhones}
                      handleDeletePhone={handleDeletePhone}
                    />
                    <div className="row">
                      <div className="col-md-3 col-sm-12">
                        <Input
                          register={register}
                          name="meet_phone.name"
                          type="text"
                          label="Nome:"
                        />
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <WithMask
                          name="meet_phone.phone"
                          control={control}
                          phoneOrCell
                          label={`Telefone:`}
                        />
                      </div>
                      <div className="col-md-3 col-sm-12 schedule-add-btn-container">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => handleAddPhone()}
                        >
                          Adicionar telefone
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
            {!isMeetTellWatch && !isCateAdressWatch && companyWatch ? (
              <Switch
                register={register}
                label="O endereço da entrevista será o mesmo endereço da empresa?"
                name="is_meet_on_company"
              />
            ) : null}
            {!isMeetTellWatch && !isCompanyAdressWatch ? (
              <Switch
                register={register}
                label="A entrevista será realizada em uma unidade do cate?"
                name="is_meet_on_cate"
              />
            ) : null}

            {isCateAdressWatch ? (
              <ReactSelect
                name="cate_id"
                control={control}
                options={cates.map((cate) => {
                  return { label: cate.name, value: cate.id };
                })}
                disabled={!canUpdate}
                label="Selecione um posto de atendimento do cate:"
              />
            ) : null}

            {(meetAddress && isCompanyAdressWatch) ||
            (isCateAdressWatch && cateIdWatch) ? (
              <MeetAddressCard address={meetAddress} />
            ) : null}

            {isCateAdressWatch || isCompanyAdressWatch || isMeetTellWatch ? (
              <div />
            ) : (
              <React.Fragment>
                <AddressForm
                  dirtyFields={dirtyFields}
                  watch={watch}
                  control={control}
                  setValue={setValue}
                  register={register}
                  disabled={!canUpdate}
                  skipCepValdiation={!!id}
                />
              </React.Fragment>
            )}
            <TextArea
              register={register}
              errors={errors}
              requiredForJob
              disabled={!canUpdate}
              name="interview_obs"
              validations={{
                maxLength: {
                  value: 2000,
                  message: "Maximo 2000 caracteres atingido.", // JS only: <p>error message</p> TS only support string
                },
              }}
              label="Observações para entrevista:"
              rows={8}
            />
          </Section>
          <Section title="Local de trabalho">
            <p>
              Preencha aqui o local de trabalho desta vaga. Caso a vaga possua
              mais de um local, adicione as regiões de trabalho desta vaga, uma
              de cada vez.
            </p>
            <ReactSelect
              name="work_regions"
              placeholder="Região"
              control={control}
              isMulti
              options={optionsRegion}
              label="Selecione as regiões:"
            />
            {/* <JobLocation
              control={control}
              register={register}
              watch={watch}
              setValue={setValue}
              companyAddress={companyAddress}
            /> */}
          </Section>

          <Section title="Status da vaga">
            <div className="row">
              {schedules.length > 0 && prevJob?.status !== "closed" ? (
                <React.Fragment>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      {...register("status", { required: false })}
                      type="radio"
                      name="status"
                      id="job_active"
                      value="active"
                    />
                    <label className="form-check-label" htmlFor="job_active">
                      Vaga Ativa
                    </label>
                  </div>
                  {canUpdateStatus ? (
                    <div className="form-check">
                      <input
                        {...register("status", { required: false })}
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="job_schedule"
                        value="schedule"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="job_schedule"
                      >
                        Agendar publicação da vaga
                      </label>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
              {canDraft ? (
                <div className="form-check">
                  <input
                    {...register("status", { required: false })}
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="job_draft"
                    value="draft"
                  />
                  <label className="form-check-label" htmlFor="job_draft">
                    Rascunho
                  </label>
                </div>
              ) : null}
              {id &&
              (prevJob?.status === "active" ||
                prevJob?.status === "closed" ||
                prevJob?.status === "stopped") ? (
                <>
                  {prevJob.total_waiting_applications === 0 ? (
                    <div className="form-check">
                      <input
                        {...register("status", { required: false })}
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="job_stopped"
                        value="stopped"
                      />
                      <label className="form-check-label" htmlFor="job_stopped">
                        Suspender a vaga
                      </label>
                    </div>
                  ) : null}
                  <div className="form-check">
                    <input
                      {...register("status", { required: false })}
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="job_closed"
                      value="closed"
                    />
                    <label className="form-check-label" htmlFor="job_closed">
                      Encerrar vaga
                    </label>
                  </div>
                </>
              ) : null}
            </div>
            {jobStatusWatch === "schedule" ? (
              <div className="row">
                <Input
                  register={register}
                  name="active_at"
                  type="datetime-local"
                  label="Data de ativação da vaga"
                  min={getTomorrow()}
                />
              </div>
            ) : null}

            {jobStatusWatch === "closed" ? (
              <CloseJobForm
                register={register}
                control={control}
                watch={watch}
                setValue={setValue}
                handleAddCpf={handleAddCpf}
                handleDeleteCpf={handleDeleteCpf}
                closeCpfs={closeCpfs}
              />
            ) : null}
          </Section>

          <button className="btn btn-primary mt-4" type="submit">
            {id ? `Salvar alterações` : `Cadastrar vaga`}
          </button>
        </form>
        {id ? (
          <Section title="Registro de atualizações">
            <JobHistory jobId={id} />
          </Section>
        ) : null}
      </div>
    </UpdateStyeldContainer>
  );
}

export default JobsUpdate;
